import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCurrentDate, getDateFormat } from '../../../utils/dateFormat';

import './style.scss';
import LanguageLabel from '../../LanguageLabel';

const Timeline = ({
  timestamps,
  currentDate,
  customTimeLine,
  returnValueToGroups,
  groupTimeLine,
}) => {
  const [currentItems, setCurrentItems] = useState(4);
  const [showForwardButton, setShowForwardButton] = useState(false); // timestamps.length
  const [showBackwardButton, setShowBackwardButton] = useState(true); // timestamps.length
  const [activeCLass, setActiveClass] = useState(0);
  const [groupTag, setGroupTag] = useState('Vandaag0');

  const setActiveDate = (date, key) => {
    if (timestamps[key].type === 'Vandaag' || timestamps[key].type === 'Voorheen') {
      const var1 = timestamps[key].type.toString();
      const var2 = key.toString();
      const newClass = var1.concat(var2);
      returnValueToGroups(newClass);
      setGroupTag(newClass);
    }
    setActiveClass(date + key);
    currentDate(date);
  };

  const checkIsToday = (date) => {
    const dateformat = getDateFormat(date, 'YYYY-MM-DD');
    const currentdateformat = getDateFormat(getCurrentDate(), 'YYYY-MM-DD');

    if (dateformat === currentdateformat) {
      return 'Vandaag';
    }

    return getDateFormat(date, 'YYYY-MM-DD');
  };

  const setTimelineForward = () => {
    if (currentItems > 4) {
      if (currentItems <= 4) {
        setCurrentItems(4);
      }
      setCurrentItems(currentItems - 4);
    }
  };

  const setTimelineBackward = () => {
    if (currentItems < timestamps.length) {
      if (currentItems >= timestamps.length) {
        setCurrentItems(timestamps.length);
      }
      setCurrentItems(currentItems + 4);
    }
  };

  useEffect(() => {
    if (customTimeLine) {
      setShowBackwardButton(false);
      setShowForwardButton(false);
      return;
    }
    if (currentItems === 4) {
      setShowForwardButton(false);
      setShowBackwardButton(true);
      return;
    }
    if (currentItems >= timestamps.length) {
      setShowBackwardButton(false);
      setShowForwardButton(true);
      return;
    }
    setShowForwardButton(true);
    setShowBackwardButton(true);
  }, [currentItems, customTimeLine, timestamps]);

  return (
    <section className="timeline">
      <div className="line">
        {showBackwardButton && (
        <div
          className={classNames('bol backward')}
          onClick={() => setTimelineBackward()}
        >&lt;<span><LanguageLabel id="timelineEarlierLabel" /></span>
        </div>
        )}
        {timestamps.length > 0 && (
          timestamps.slice(currentItems - 4, currentItems).map((item, key) => (
            <div key={`${item.dateCreated + key}`} className="timeline-timestamp" onClick={() => setActiveDate(item.dateCreated, key)}>
              {!groupTimeLine ? (
                <div
                  className={classNames(
                    'timeline-timestamp-bol',
                    activeCLass === key
                      ? 'active'
                      : activeCLass === item.dateCreated + key && 'active',
                  )}
                >
                  <span>{checkIsToday(item.dateCreated) || item.type}</span>
                </div>
              ) : (
                <>
                  <div
                    className={classNames(
                      'timeline-timestamp-bol text',
                      groupTag === item.type.concat(key) && 'active',
                    )}
                  >
                    <span>{item.type}</span>
                  </div>
                </>
              )}
            </div>
          ))
        )}
        {showForwardButton && (
        <div
          className={classNames('bol forward')}
          onClick={() => setTimelineForward()}
        >&gt;<span><LanguageLabel id="timelineLaterLabel" /></span>
        </div>
        )}
      </div>
    </section>
  );
};

Timeline.propTypes = {
  timestamps: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
  })),
  currentDate: PropTypes.func.isRequired,
  customTimeLine: PropTypes.bool,
  returnValueToGroups: PropTypes.func,
  groupTimeLine: PropTypes.bool,
};

Timeline.defaultProps = {
  returnValueToGroups: null,
  timestamps: [{}],
  customTimeLine: false,
  groupTimeLine: false,
};

export default Timeline;
