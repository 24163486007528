import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import {
  QUESTION_FOOD,
} from '../../constants/questions';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSliderDial, makeTooltipsSlider } from '../../utils/functions';

import Heading from '../../components/Heading';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Counter from '../../components/Counter';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-overig-eten.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-overig-eten.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_0.svg';

import './style.scss';

const QuestionFood = ({
  fetchTooltips,
  fetchCounterGroup,
  counterGroup,
  tooltips,
  setQuestionResult,
  setSwipeOnContainer,
  shouldIupdate,
  openGenericModal,
  openSpecificModal,
  mobileView,
  results,
}) => {
  const [values, setValues] = useState({});
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [mode, setMode] = useState(false);

  const questionId = QUESTION_FOOD;

  const currentItemIndex = order.indexOf(questionId);

  const quickQuestions = content.quick.questions;
  const specificQuestions = content.specific.questions;
  const specificMobileQuestions = content.specific.questionsMobile;

  const quickLength = quickQuestions.length;
  const specificMobileLength = specificMobileQuestions.length;

  const submitClicked = () => {
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const makeCounterGroup = (id) => {
    const filterCounterGroup = counterGroup.filter((counters) => (counters.id === id));
    return (
      filterCounterGroup[0] && filterCounterGroup[0].counters && filterCounterGroup[0].counters.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_FOOD] && results[QUESTION_FOOD][item.id]) defaultValue = results[QUESTION_FOOD][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <Counter
            key={item.id}
            counterValue={(value) => sliderChange(item.id, value)}
            content={item.label}
            count={item.count}
            max={item.max}
            defaultValue={values[item.id] || defaultValue}
          />
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (counterGroup.length === 0) {
      fetchCounterGroup();
    }
  }, [fetchCounterGroup]);

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  return (
    <>
      <div className="question-food">
        <Heading className="primary" type="h1">
          <span>
            <LanguageLabel id="FoodQuestionTitle" />
          </span>
          <Button
            label="?"
            className={classNames('info', 'info-header')}
            onClick={() => openGenericModal('foodQuestion-modal')}
          />
        </Heading>
        <Paragraph>
          <LanguageLabel id="FoodQuestionDescription" />
        </Paragraph>
        <ToggleButton
          beforeText="snel"
          toggleButtonValue={!mode}
          onClick={() => updateMode(!mode)}
          afterText="precies"
          className="show-toggle"
        />
        {!mode ? (
          !mobileView ? (
            <>
              <div className="vertical-align-wrapper-flex">
                <div className="vertical-align-sliders">
                  {quickQuestions.map((item) => (
                    makeTooltipsSlider(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      swipeOnQuestions,
                      tooltips,
                      updateSwipe,
                      values,
                    )
                  ))}
                </div>
              </div>
              <div className="questionButton-box">
                <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
              </div>
            </>
          ) : (
            <>
              <div className="vertical-align-wrapper-flex">
                <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                  {quickQuestions.map((item) => (
                    makeTooltipsSlider(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      swipeOnQuestions,
                      tooltips,
                      updateSwipe,
                      values,
                    )
                  ))}
                </QuestionSwiper>
              </div>
              <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
              <div className="questionButton-box">
                {currentSlide === quickLength - 1
                  ? (
                    <>
                      <QuestionButtons
                        questionId={questionId}
                        nextClick={() => submitClicked()}
                      />
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    </>
                  )
                  : (
                    <>
                      <Button
                        label={<LanguageLabel id="questionNextLabel" />}
                        onClick={() => setCurrentSlide(currentSlide + 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                      />
                      {currentSlide === 0 ? (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      ) : (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          onClick={() => setCurrentSlide(currentSlide - 1)}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      )}
                    </>
                  )}
              </div>
            </>
          )
        ) : (
          !mobileView ? (
            <>
              <div className="vertical-align-wrapper-flex">
                {specificQuestions.map((item) => (
                  makeTooltipsSlider(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    swipeOnQuestions,
                    tooltips,
                    updateSwipe,
                    values,
                  )
                ))}
                <div className="counter-wrapper">
                  {makeCounterGroup('food-drink')}
                </div>
              </div>
              <div className="questionButton-box">
                <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
              </div>
            </>
          ) : (
            <>
              <div className="vertical-align-wrapper-flex">
                <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                  {specificMobileQuestions.map((item) => (
                    <div className="vertical-align-sliderdial">
                      {makeTooltipsSliderDial(
                        item.id,
                        openSpecificModal,
                        questionId,
                        results,
                        sliderChange,
                        tooltips,
                        updateSwipe,
                        values,
                      )}
                    </div>
                  ))}
                </QuestionSwiper>
              </div>
              <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificMobileLength} />
              <div className="questionButton-box">
                {currentSpecificSlide === specificMobileLength - 1
                  ? (
                    <>
                      <QuestionButtons
                        questionId={questionId}
                        nextClick={() => submitClicked()}
                      />
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    </>
                  )
                  : (
                    <>
                      <Button
                        label={<LanguageLabel id="questionNextLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                      />
                      {currentSpecificSlide === 0 ? (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      ) : (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      )}
                    </>
                  )}
              </div>

            </>
          )
        )}
        <>
          {!mobileView ? (
            <>
              <div className="background-wrapper">
                <Background />
              </div>
              <div className="illustration-wrapper">
                <Illustration />
              </div>
            </>
          )
            : (
              <div className="illustration-wrapper">
                <IllustrationMobile />
              </div>
            )}
        </>
      </div>
    </>
  );
};

QuestionFood.propTypes = {
  fetchCounterGroup: PropTypes.func.isRequired,
  counterGroup: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionFood.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionFood);
