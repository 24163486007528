import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import {
  QUESTION_POSSESSIONS,
} from '../../constants/questions';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSliderDial } from '../../utils/functions';

import Heading from '../../components/Heading';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Slider from '../../components/Slider';
import Button from '../../components/Button';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import QuestionSwiper from '../../components/QuestionSwiper';
import LanguageLabel from '../../components/LanguageLabel';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-spullen.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-spullen.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_4.svg';

import './style.scss';

const QuestionPossessions = ({
  fetchTooltips,
  tooltips,
  setQuestionResult,
  setSwipeOnContainer,
  openGenericModal,
  openSpecificModal,
  mobileView,
  results,
  shouldIupdate,
}) => {
  const [values, setValues] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [mode, setMode] = useState(false);

  const questionId = QUESTION_POSSESSIONS;

  const currentItemIndex = order.indexOf(questionId);

  const quickQuestions = content.quick.questions;
  const specificQuestions = content.specific.questions;

  const specificLength = specificQuestions.length;

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const submitClicked = () => {
    // Go to next in line, check in order
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  const makeTooltipsSlider = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_POSSESSIONS] && results[QUESTION_POSSESSIONS][item.id]) defaultValue = results[QUESTION_POSSESSIONS][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <Slider
            key={item.id}
            defaultValue={values[item.id] || defaultValue}
            min={item.min}
            max={item.max}
            updateParent={(value) => sliderChange(item.id, value)}
            text={item.label}
            unit={item.unit}
            money={item.money}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            customValue={values.possessions || 0}
            descriptions={item.descriptions}
            swiping={swipeOnQuestions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
          />
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  return (
    <div className="question-possessions">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="possessionsQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('possessionsQuestion-modal')}
        />
      </Heading>
      {!mobileView ? (
        <Paragraph>
          <LanguageLabel id="possessionsQuestionDescription" />
        </Paragraph>
      ) : (
        <Paragraph>
          <LanguageLabel id="possessionsQuestionDescription-mobile" />
        </Paragraph>
      )}
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              {quickQuestions.map((item) => (
                makeTooltipsSlider(item.id)
              ))}
            </div>

            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quickQuestions.map((item) => (
                  makeTooltipsSlider(item.id)
                ))}
              </QuestionSwiper>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
              <Button
                label={<LanguageLabel id="questionPreviousLabel" />}
                to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
              />
            </div>
          </>
        )
      ) : (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              <div className="vertical-wrap-row">
                {specificQuestions.map((item) => (
                  makeTooltipsSliderDial(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    tooltips,
                    updateSwipe,
                    values,
                  )
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                {specificQuestions.map((item) => (
                  <div key={item.id} className="vertical-align-sliderdial">
                    {makeTooltipsSliderDial(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      tooltips,
                      updateSwipe,
                      values,
                    )}
                  </div>
                ))}
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
            <div className="questionButton-box">
              {currentSpecificSlide === specificLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSpecificSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionPossessions.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  mode: PropTypes.bool.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionPossessions.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPossessions);
