import { call, put } from 'redux-saga/effects';
import cache from 'memory-cache-pro';

import { SET_ACTIONCARDS } from '../actions/actionCardsActionTypes';

import { findActionCards } from '../connectors/contentful';

function* fetchActionCards() {
  let items = cache.get('actionCards');

  if (!items) {
    const response = yield call(() => findActionCards());
    items = response.items;
    cache.put('actionCards', items);
  }

  yield put({
    type: SET_ACTIONCARDS,
    actionCards: items,
  });
}

export default fetchActionCards;
