import {
  ACTIONS,
  APIMOCK,
  CHECKPAGE,
  COMPONENTS,
  CREATEUSERSUCCES,
  DASHBOARD,
  FORGOTPASSWORD,
  GOALDETAIL,
  GOALEDIT,
  GOALEDITCREATE,
  GOALS,
  GROUPDETAIL,
  GROUPEDIT,
  GROUPS,
  GROUPSUB,
  HOME,
  LOGIN,
  LOGINACCOUNT,
  MAILSEND,
  NEWACTION,
  NEWGOAL,
  NEWGROUP,
  NEWMEMBER,
  NEWPASSWORD,
  PROFILEBASE,
  PROFILEDETAIL,
  QUESTIONS,
  QUESTIONSHOME,
  RESULTS,
  TOP10INFO,
  VERIFYEMAIL,
} from '../constants/pages';
import { lang } from '../utils/locale';

// console.log(window.location.pathname.split('/')[1]);

// console.log('lang', lang);

const paths = {
  [ACTIONS]: `/${lang}/actions`,
  [APIMOCK]: `/${lang}/apimock`,
  [CHECKPAGE]: `/${lang}/confirm`,
  [COMPONENTS]: `/${lang}/components`,
  [CREATEUSERSUCCES]: `/${lang}/createusersucces`,
  [DASHBOARD]: `/${lang}/dashboard`,
  [FORGOTPASSWORD]: `/${lang}/forgotpassword`,
  [GOALDETAIL]: `/${lang}/goals/:id`,
  [GOALEDIT]: `/${lang}/goalsedit/:id`,
  [GOALEDITCREATE]: `/${lang}/goalsedit/create`,
  [GOALS]: `/${lang}/goals`,
  [GROUPDETAIL]: `/${lang}/groups/:id`,
  [GROUPEDIT]: `/${lang}/groupsedit/:id`,
  [GROUPSUB]: `/${lang}/groupsubscription/:type/:id`,
  [GROUPS]: `/${lang}/groups`,
  [HOME]: `/${lang}/`,
  [LOGIN]: `/${lang}/login`,
  [LOGINACCOUNT]: `/${lang}/login?account`,
  [MAILSEND]: `/${lang}/mailsend`,
  [NEWACTION]: `/${lang}/newaction`,
  [NEWGOAL]: `/${lang}/newgoal`,
  [NEWGROUP]: `/${lang}/newgroup`,
  [NEWMEMBER]: `/${lang}/newmember`,
  [NEWPASSWORD]: `/${lang}/newpassword`,
  [PROFILEBASE]: `/${lang}/profile`,
  [PROFILEDETAIL]: `/${lang}/profile/:id`,
  [QUESTIONS]: `/${lang}/questions/:id`,
  [QUESTIONSHOME]: `/${lang}/questions`,
  [RESULTS]: `/${lang}/results`,
  [TOP10INFO]: `/${lang}/top10`,
  [VERIFYEMAIL]: `/${lang}/verifyemail`,
  AMSTERDAM: `/${lang}/amsterdam`,
};

export default paths;
