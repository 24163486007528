import { SET_MODAL_TOP_10 } from '../actions/modalTop10ActionTypes';

import { getModalTop10 } from '../transformers/modalTop10Transformer';

const initialState = {
  modalTop10: [],
};

const modalTop10 = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_TOP_10: {
      const modalTop10 = getModalTop10(action.modalTop10);

      return {
        ...state,
        modalTop10,
      };
    }
    default:
      return state;
  }
};

export default modalTop10;
