const mapStateToProps = (state) => {
  const { user, questions, mobileView } = state;
  return {
    user: user.user,
    results: questions.results,
    mobileView: mobileView.mobileView,
  };
};

export default mapStateToProps;
