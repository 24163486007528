import React from 'react';
import PropTypes from 'prop-types';

import cloud from '../../assets/icons/ic-cloud.svg';

import './style.scss';

const FormContainer = ({ children }) => (
  <div className="formcontainer-bg">
    <img className="formcontainer-cloud first" src={cloud} alt="cloud" />
    <img className="formcontainer-cloud sec" src={cloud} alt="cloud" />
    {children}
  </div>
);

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormContainer;
