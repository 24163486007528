import {
  ACTIONS,
  APIMOCK,
  CHECKPAGE,
  CREATEUSERSUCCES,
  GOALEDIT,
  COMPONENTS,
  DASHBOARD,
  FORGOTPASSWORD,
  GOALDETAIL,
  GOALS,
  GROUPDETAIL,
  GROUPS,
  GROUPEDIT,
  HOME,
  GROUPSUB,
  LOGIN,
  MAILSEND,
  NEWACTION,
  NEWGOAL,
  NEWGROUP,
  NEWMEMBER,
  NEWPASSWORD,
  PROFILEBASE,
  PROFILEDETAIL,
  QUESTIONS,
  QUESTIONSHOME,
  RESULTS,
  TOP10INFO,
  VERIFYEMAIL,
} from '../constants/pages';

const basePaths = {
  [ACTIONS]: '/actions',
  [APIMOCK]: '/apimock',
  [CHECKPAGE]: '/confirm',
  [COMPONENTS]: '/components',
  [CREATEUSERSUCCES]: '/createusersucces',
  [DASHBOARD]: '/dashboard',
  [FORGOTPASSWORD]: '/forgotpassword',
  [GOALDETAIL]: '/goals/:id',
  [GOALEDIT]: '/goalsedit/:id',
  [GOALS]: '/goals',
  [GROUPDETAIL]: '/groups/:id',
  [GROUPEDIT]: '/groupsedit/:id',
  [GROUPSUB]: '/groupsubscription/:type/:id',
  [GROUPS]: '/groups',
  [HOME]: '/',
  [LOGIN]: '/login',
  [MAILSEND]: '/mailsend',
  [NEWACTION]: '/newaction',
  [NEWGOAL]: '/newgoal',
  [NEWGROUP]: '/newgroup',
  [NEWMEMBER]: '/newmember',
  [NEWPASSWORD]: '/newpassword',
  [PROFILEBASE]: '/profile',
  [PROFILEDETAIL]: '/profile/:id',
  [QUESTIONS]: '/questions/:id',
  [QUESTIONSHOME]: '/questions',
  [RESULTS]: '/results',
  [TOP10INFO]: '/top10',
  [VERIFYEMAIL]: '/verifyemail',
  AMSTERDAM: '/amsterdam',
};

export default basePaths;
