import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Auth } from 'aws-amplify';
import Form from '../../../components/Form';
import PasswordInput from '../../../components/Input/PasswordInput';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import FormContainer from '../../../components/FormContainer';

import { errorConfirmPassword, errorPassword } from '../../../utils/errorHandling';
import urlParser from '../../../utils/urlParser';

import './style.scss';
import LanguageLabel from '../../../components/LanguageLabel';
import LanguageParagraph from '../../../components/LanguageParagraph';

const NewPassword = ({ history }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorPasswordMessage, setErrorPasswordMessage] = useState(true);
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] = useState(true);
  const [showError, setShowError] = useState(false);

  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';

  const handlePassword = (value) => {
    setPassword(value);
    setErrorPasswordMessage(errorPassword(value, lang));
  };

  const handlePasswordConfirm = (value) => {
    setConfirmPassword(value);
    setErrorConfirmPasswordMessage(errorConfirmPassword(value, password, lang));
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    const sArray = urlParser(location.search);
    if (
      !errorPasswordMessage
      && !errorConfirmPasswordMessage
    ) {
      try {
        await Auth.forgotPasswordSubmit(sArray.user, sArray.code, confirmPassword);
      } catch (error) {
        // console.log(error);
      }
      history.push('/login');
    }
    setShowError(true);
    handlePassword(password);
    handlePasswordConfirm(confirmPassword);
  };

  return (
    <FormContainer>
      <div className="formcontainer newpassword">
        <Heading className="primary" type="h1">
          <LanguageLabel id="forgotPasswordTitle" />
        </Heading>
        <Paragraph>
          <LanguageParagraph id="forgotPasswordParagraph" />
        </Paragraph>
        <Form>
          <PasswordInput
            className=""
            id={uuidv4()}
            error={showError}
            errorMessage={errorPasswordMessage}
            label={<LanguageLabel id="forgotInputPasswordTitle" />}
            onChange={(e) => handlePassword(e.target.value)}
            placeholder={lang === 'nl' ? 'Gebruik een hoofdletter en 2 symbolen of cijfers' : 'Use a capital letter and 2 symbols or numbers'}
            value={password}
          />
          <PasswordInput
            className=""
            id={uuidv4()}
            error={showError}
            errorMessage={errorConfirmPasswordMessage}
            label={<LanguageLabel id="forgotInputConfirmPasswordTitle" />}
            onChange={(e) => handlePasswordConfirm(e.target.value)}
            placeholder={lang === 'nl' ? 'Gebruik een hoofdletter en 2 symbolen of cijfers' : 'Use a capital letter and 2 symbols or numbers'}
            value={confirmPassword}
          />
          <Button
            onClick={onFormSubmit}
            label={<LanguageLabel id="forgotPasswordButton" />}
            className="rounded secondary"
          />
          <div className="row">
            <Button
              label={<LanguageLabel id="forgotPasswordButtonToLoginTitle" />}
              to="/login"
            />
          </div>
        </Form>
      </div>
    </FormContainer>
  );
};

NewPassword.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default NewPassword;
