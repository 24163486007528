const mapStateToProps = (state) => {
  const { questions, products, user } = state;
  return {
    questions,
    products: products.products,
    user: user.user,
  };
};

export default mapStateToProps;
