
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Auth,
} from 'aws-amplify';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Header from '../Header';
import Footer from '../Footer';
import Cloud from '../Cloud';

import './style.scss';
import getUser from '../../lib/user';

const Layout = ({
  children, updateMobileView, user,
}) => {
  const { pathname } = useLocation();
  const [mobile, setMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) setMobile(true);
    else {
      setMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    updateMobileView(mobile);
  }, [mobile]);

  useEffect(() => {
    if (!Object.values(user).includes('username')) {
      const loadData = async () => {
        const resp = await Auth.currentUserInfo();
        if (!resp) return;
        // console.log(resp);
        getUser(resp.username);
      };
      loadData();
    }
  }, []);

  return (
    <div className={classNames(['questions', 'amsterdam'].some((route) => pathname.includes(route)) ? 'hide-layout' : 'show-layout', 'layout')}>
      <Header />
      <Cloud pathname={pathname} />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  updateMobileView: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
    }),
  }),
};

Layout.defaultProps = {
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
