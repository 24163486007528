import { SET_TOOLTIPS_PERCENTAGE } from '../actions/tooltipPercentageActionTypes';

import { getTooltipsPercentage } from '../transformers/tooltipPercentageTransformer';

const initialState = {
  tooltipsPercentage: [],
};

const tooltipsPercentage = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOOLTIPS_PERCENTAGE: {
      const tooltipsPercentage = getTooltipsPercentage(action.tooltipsPercentage);

      return {
        ...state,
        tooltipsPercentage,
      };
    }
    default:
      return state;
  }
};

export default tooltipsPercentage;
