import { call, put } from 'redux-saga/effects';

import { SET_PRODUCT } from '../actions/productActionTypes';

import { findProducts } from '../connectors/contentful';

function* fetchProducts() {
  const response = yield call(() => findProducts());
  yield put({
    type: SET_PRODUCT,
    products: response.items,
  });
}

export default fetchProducts;
