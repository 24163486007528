import { call, put } from 'redux-saga/effects';

import { SET_TOOLTIPS } from '../actions/tooltipTypes';
import { findToolTip } from '../connectors/contentful';

function* fetchTooltips() {
  const response = yield call(() => findToolTip());

  yield put({
    type: SET_TOOLTIPS,
    tooltips: response.items,
  });
}

export default fetchTooltips;
