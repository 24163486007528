const calculate = (questionResults, average, mode) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const calories = linearScaling('calories') || 0;
  // if (calories === 0) calories = (800 / average.calories.value) * average.calories.recipe;

  let recipe = 0;

  if (mode) {
    // const coffee = (392 / average.coffee.value) * average.coffee.recipe;
    // const juice = (55 / average.juice.value) * average.juice.recipe;
    // const soda = (350 / average.soda.value) * average.soda.recipe;
    // const tea = (225 / average.tea.value) * average.tea.recipe;
    // const water = (590 / average.water.value) * average.water.recipe;
    // const wine = (38.4 / average.wine.value) * average.wine.recipe;
    // const liquor = (4.2 / average.liquor.value) * average.liquor.recipe;
    // const beer = (92.3 / average.beer.value) * average.beer.recipe;

    const coffee = linearScaling('coffee') || 0;
    const juice = linearScaling('juice') || 0;
    const soda = linearScaling('soda') || 0;
    const tea = linearScaling('tea') || 0;
    const water = linearScaling('water') || 0;

    const wine = linearScaling('wine') || 0;
    const liquor = linearScaling('liquor') || 0;
    const beer = linearScaling('beer') || 0;

    recipe = (beer
        + coffee
        + juice
        + liquor
        + soda
        + tea
        + water
        + wine
        + calories) || 0;
  } else {
    const drinkNoAlco = linearScaling('drinkNoAlco') || 0;
    const drinkAlco = linearScaling('drinkAlco') || 0;
    recipe = (calories + drinkNoAlco + (drinkAlco / 7)) || 0;
  }

  return {
    recipe,
  };
};

export default calculate;
