import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import reactStringReplace from 'react-string-replace';
import RCSlider from 'rc-slider';

import Button from '../Button';

import 'rc-slider/assets/index.css';
import './style.scss';

const { Handle } = RCSlider;

const Slider = ({
  defaultValue,
  descriptions,
  max,
  min,
  text,
  updateParent,
  popModal,
  popModalButton,
  money,
  isSliding,
  valueTransformer,
}) => {
  const [value, setValue] = useState(defaultValue || 0);
  const [transformedValue, setTransformedValue] = useState();

  const setSliding = (sliding) => {
    isSliding(sliding);
  };

  const getDescription = (value) => {
    const description = descriptions.filter(({ start, end }) => value >= start && value <= end);
    return description.length > 0 ? description[0].label : '';
  };

  const handle = (props) => {
    const {
      value, dragging, index, ...restProps
    } = props;

    let side = 'left';
    const p = value * (max === 0 ? 1 : 100 / max);

    if (p < 33) side = 'left';
    if (p > 33 && p < 66) side = 'middle';
    if (p > 66) side = 'right';

    const description = getDescription(value);

    return (
      <div className="wrappers">
        <Handle value={value} {...restProps}>
          <div className={classNames('tooltip-custom', side, !description && 'hide')}>
            <div className="tooltip-square" />
            <span>{description}</span>
          </div>
        </Handle>
      </div>
    );
  };

  handle.propTypes = {
    dragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  };

  const onChange = (val) => {
    if (valueTransformer) {
      const transformResult = valueTransformer(val);
      setTransformedValue(transformResult);
    }
    setValue(val);
    updateParent(val);
  };

  useEffect(() => {
    if (defaultValue !== 0) {
      setValue(defaultValue || 0);
    }
  }, []);

  useEffect(() => {
    setSliding(false);
    setSliding(true);
  }, []);

  return (
    <div
      className="slider"
      onMouseEnter={() => setSliding(false)}
      onMouseLeave={() => setSliding(true)}
      onTouchMove={() => setSliding(false)}
      onTouchEnd={() => setSliding(true)}
    >
      <div>
        <p>
          {reactStringReplace(text, '@@', (match) => (
            <strong key={match}>{money && '€'}{transformedValue || value}</strong>
          ))}
        </p>
        {popModalButton && (<Button label="?" className="info" onClick={popModal} />)}
        <RCSlider min={min} max={max} defaultValue={defaultValue} handle={handle} onChange={onChange} />
      </div>
    </div>
  );
};

Slider.propTypes = {
  defaultValue: PropTypes.number,
  descriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateParent: PropTypes.func,
  popModal: PropTypes.func,
  popModalButton: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  money: PropTypes.bool,
  isSliding: PropTypes.func.isRequired,
  valueTransformer: PropTypes.func,
};

Slider.defaultProps = {
  updateParent: null,
  popModal: null,
  popModalButton: '',
  money: false,
  max: 1,
  defaultValue: 0,
  valueTransformer: null,
};

export default (Slider);
