const mapStateToProps = (state) => {
  const { questions, modalExplaining, mobileView } = state;
  return {
    results: questions.results,
    calculatedResults: questions.calculatedResults,
    modalExplaining: modalExplaining.modalExplaining,
    mobileView: mobileView.mobileView,
  };
};

export default mapStateToProps;
