import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import order from '../../config/questions';
import { QUESTIONSHOME, RESULTS } from '../../constants/pages';
import paths from '../../routes/paths';

import Button from '../Button';

import './style.scss';
import LanguageLabel from '../LanguageLabel';
import { cookiesAccepted, isLocalhost, isTestEnvironment } from '../../utils/environmentutil';

const QuestionButtons = ({
  questionId,
  nextClick,
  onClick,
}) => {
  const currentItemIndex = order.indexOf(questionId);

  const nextAction = () => {
    if (cookiesAccepted && !isLocalhost && !isTestEnvironment) {
      ReactGA.event({
        category: 'Next question button clicked',
        action: `From questions/${currentItemIndex} to questions/${currentItemIndex + 1}`,
      });
    }
    nextClick();
  };

  return (
    <div className="questionButtons">
      {onClick ? (
        <Button
          onClick={onClick}
          label={<LanguageLabel id="questionNextLabel" />}
          className={classNames('outline-mobile', 'nextbutton')}
        />
      ) : (
        <>
          {currentItemIndex < (order.length - 1) && (
          <Button
            onClick={nextAction}
            to={`${paths[QUESTIONSHOME]}/${currentItemIndex + 1}`}
            label={<LanguageLabel id="questionNextLabel" />}
            className={classNames('outline-mobile', 'nextbutton')}
          />
          )}
          {currentItemIndex === (order.length - 1) && (
            <>
              <Button
                onClick={nextAction}
                to={paths[RESULTS]}
                label={<LanguageLabel id="publicTransportQuestionButton" />}
                className={classNames('outline-mobile', 'nextbutton', 'highlight')}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

QuestionButtons.propTypes = {
  questionId: PropTypes.string,
  nextClick: PropTypes.func,
  onClick: PropTypes.func,
};

QuestionButtons.defaultProps = {
  questionId: '',
  nextClick: null,
  onClick: null,
};

export default (QuestionButtons);
