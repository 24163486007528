import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Checkbox = ({
  id,
  label,
  onChange,
  checked,
}) => (
  <label htmlFor={id} className="checkbox">
    {label}
    <input
      id={id}
      type="checkbox"
      onChange={onChange}
      defaultChecked={checked}
    />
    <span className="checkmark" />
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
