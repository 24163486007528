import PropTypes from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ReactHtmlParser from 'react-html-parser';

const RichText = ({ text }) => ReactHtmlParser(documentToHtmlString(text).replace(/\n/g, '</br>'));

RichText.propTypes = {
  text: PropTypes.shape({}),
};

RichText.defaultProps = {
  text: {},
};
export default RichText;
