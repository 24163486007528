import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  graphqlOperation, API,
  Storage, Auth,
} from 'aws-amplify';

import {
  updateGoal as UpdateGoal,
} from '../../graphql/mutations';

import mapStateToProps from './mapState';

import {
  getGoal as GetGoal,
} from '../../graphql/queries';

import { getEarths } from '../../utils/functions';

import Button from '../../components/Button';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import GroupCard from '../../components/GroupCard';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import { ReactComponent as Plus } from '../../assets/icons/ic-plus.svg';

import './style.scss';
import { lang } from '../../utils/locale';

const Goals = ({ user, history }) => {
  const [goalsList, setGoalsList] = useState([]);
  const [imageError, setImageError] = useState();
  const [numberOfEarths, setNumberOfEarths] = useState(0);
  const [numberOfEarthsTotal, setNumberOfEarthsTotal] = useState(0);

  const getGoalList = async () => {
    const resp = [];
    try {
      await Promise.all(user.goals.map(async (goals) => {
        if (goals !== '' && goals !== undefined && goals.length !== 0) {
          const contents = await API.graphql(graphqlOperation(GetGoal, { id: goals }));
          resp.push(contents.data.getGoal);
        }
      }));
      const oldArray = resp.filter((item) => item.createdOn === null);
      const newArray = resp.filter((item) => item.createdOn !== null);

      newArray.sort((a, b) => a.createdOn.localeCompare(b.createdOn)).reverse();

      const mergedArry = newArray.concat(oldArray);

      setGoalsList(mergedArry);
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const chartResultsFormat = (resp, objectName, location) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp[location].bathing || 0 },
        [QUESTION_CAR]: { recipe: resp[location].car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp[location].clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp[location].diary || 0 },
        [QUESTION_FLYING]: { recipe: resp[location].flying || 0 },
        [QUESTION_FOOD]: { recipe: resp[location].food || 0 },
        [QUESTION_HOUSING]: { recipe: resp[location].housing || 0 },
        [QUESTION_MEAT]: { recipe: resp[location].meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp[location].possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp[location].publictransport || 0 },
      },
    };
    return preFilled;
  };

  const loadData = async () => {
    const resp = user.sortedResults;
    try {
      let highestIndex = 0;
      let oldEarth = 0;
      resp.forEach((x, i) => {
        const earth = getEarths(chartResultsFormat(resp, 'calculatedResults', i).calculatedResults);
        if (earth > oldEarth) {
          oldEarth = earth;
          highestIndex = i;
        }
      });
      setNumberOfEarthsTotal(
        // Transparent earths
        getEarths(chartResultsFormat(resp, 'calculatedResults', highestIndex).calculatedResults),
      );
      setNumberOfEarths(getEarths(chartResultsFormat(resp, 'calculatedResults', 0).calculatedResults));
    } catch (err) {
      // console.log(err);
    }
  };

  const updateUserGoalImage = async (file, goalId) => {
    if (file === 'random') {
      const key = `images/${Math.ceil(Math.random() * 8)}.jpg`;
      const url = `https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/${key}`;
      try {
        await API.graphql(
          graphqlOperation(UpdateGoal, {
            input: {
              id: goalId,
              image: url,
            },
          }),
        );
      } catch (err) {
        // console.log('random error: ', err);
      }
    } else if (file) {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      const { type: mimeType } = file;
      const key = `images/${user.id}.${extension}`;
      const url = `https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/${key}`;
      try {
        await Storage.put(key, file, {
          contentType: mimeType,
        });
        await API.graphql(
          graphqlOperation(UpdateGoal, {
            input: {
              id: goalId,
              image: url,
            },
          }),
        );
      } catch (err) {
        setImageError(true);
      }
    }
    getGoalList();
  };

  useEffect(() => {
    const checkUser = async () => {
      const resp = await Auth.currentUserInfo();
      if (resp === null) history.push(`/${lang}/dashboard`);
    };
    checkUser();
    if (Object.keys(user).length > 2 && user.goals && user.goals.length !== 0) {
      getGoalList();
      loadData();
    }
  }, [user]);

  return (
    <main className="goals">
      <section className="goals-container">
        <Heading className="primary"><LanguageLabel id="goalsListHeaderTitle" /></Heading>
        {goalsList.length > 0 ? (
          <>
            <Button
              className="outline"
              to={`/${lang}/goalsedit/create`}
              label={<LanguageLabel id="goalsAddGoalLabel" />}
            />
            <div className="button-box">
              <Button label={<Plus />} to={`/${lang}/goalsedit/create`} className="add" />
            </div>
            <div className="cardlayout">
              {goalsList.map((item) => (
                <GroupCard
                  goal
                  goalId={item.id || 0}
                  key={item.id || 0}
                  img={item.image}
                  name={item.title}
                  people={item.people}
                  goalEarth={item.earthsGoal || 0}
                  earth={numberOfEarths || 0}
                  link={`/${lang}/goals/${item.id}`}
                  updateUserAvatar={updateUserGoalImage}
                  imageError={imageError}
                  transparentEarths={numberOfEarthsTotal}
                  totalEarths={numberOfEarths}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="empty">
            <div className="empty-content">
              <Heading className="secondary">
                <LanguageLabel id="goalsListEmptyTitle" />
              </Heading>
              <Paragraph>
                <LanguageParagraph id="goalsListEmptyParagraph" />
              </Paragraph>
            </div>
            <div className="empty-placeholder">
              <Button className="outline" label={<LanguageLabel id="goalsCreateGoalButtonLabel" />} to={`/${lang}/goalsedit/create`} />
              <span className="duo-penguin" />
            </div>
          </div>
        )}
      </section>
      <div className="background">
        {goalsList.length > 0 ? (
          <div className="base-bg" />
        ) : (
          <div className="penguin" />
        )}
      </div>
    </main>
  );
};

Goals.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
    sortedResults: PropTypes.arrayOf(PropTypes.shape({})),
    goals: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps)(Goals);
