import { call, put } from 'redux-saga/effects';

import { SET_LANGUAGE_PARAGRAPH } from '../actions/languageActionTypes';

import { findLanguageParagraphs } from '../connectors/contentful';

function* fetchLanguageParagraphs({ payload }) {
  const { lang } = payload;
  const response = yield call(() => findLanguageParagraphs(lang));

  yield put({
    type: SET_LANGUAGE_PARAGRAPH,
    languageParagraphs: response.items,
  });
}

export default fetchLanguageParagraphs;
