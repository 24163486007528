const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const milk = linearScaling('milk') || 0;
  const dairy = linearScaling('dairy') || 0;
  const cheese = linearScaling('cheese') || 0;
  const eggs = linearScaling('eggs') || 0;

  const recipe = (milk + dairy + cheese + eggs) || 0;

  return {
    milk,
    dairy,
    cheese,
    eggs,
    recipe,
  };
};

export default calculate;
