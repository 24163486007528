import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import Modal from '../Modal';
import Button from '../Button';

import { HOME } from '../../constants/pages';
import paths from '../../routes/paths';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const Question = ({
  children,
  className,
  modalContent,
  cloudPosition,
  modalCloser,
  hideToggle,
}) => {
  const [modalStateVisible, setModalVisibleState] = useState(false);
  const [modalStateContent, setModalContentState] = useState(false);

  const setModal = () => {
    setModalVisibleState(true);
    setModalContentState(true);
  };

  const resetModal = () => {
    setModalVisibleState(false);
    setModalContentState(false);
  };

  useEffect(() => {
    resetModal();
  }, [modalCloser]);

  return (
    <div className={classNames('question', className)}>
      <NavLink
        className="close-questions"
        to={paths[HOME]}
      />
      <div className="question__content">
        <div className={classNames('question__top-overlay', !hideToggle && 'change-hight')} />
        <div className={classNames('question__clouds', `cloud-position-${cloudPosition}`)}>
          <div className="cloud-1" />
          <div className="cloud-2" />
          <div className="cloud-3" />
        </div>
        {modalContent.length > 0 && (
          <>
            {!modalStateVisible
              ? (
                <div className="icon menu" onClick={() => setModal()}>
                  <Button label="?" className="info" onClick={() => setModal()} />
                </div>
              )
              : <div className="icon close" onClick={() => resetModal()} />}
          </>
        )}
        <div className="question__children">{children}</div>
      </div>

      <Modal
        onClick={() => resetModal()}
        modalSwitch={modalStateVisible}
        className={classNames('question-modal', !modalStateContent && 'hide-elements')}
      >
        <>
          <div className="logo" />
          {!modalStateContent
            ? (
              <div className="landing">
                {modalContent[0] ? (
                  <Button
                    label={<LanguageLabel id="modalExplanationLabel" />}
                    onClick={() => setModalContentState(true)}
                    className="square"
                  />
                ) : (
                  <Button
                    label={<LanguageLabel id="modalNoItemsLabel" />}
                    className="square"
                  />
                )}
              </div>
            )
            : (
              <div className="explained">
                <p>{ReactHtmlParser(modalContent[0].content)}</p>
              </div>
            )}
        </>
      </Modal>
    </div>
  );
};

Question.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  modalContent: PropTypes.arrayOf(PropTypes.object),
  cloudPosition: PropTypes.string,
  modalCloser: PropTypes.bool.isRequired,
  hideToggle: PropTypes.bool.isRequired,
};

Question.defaultProps = {
  className: '',
  modalContent: [],
  cloudPosition: '',
};

export default (Question);
