import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import '../style.scss';

const TextAreaInput = ({
  className,
  error,
  errorGlobal,
  errorMessage,
  id,
  label,
  onChange,
  placeholder,
  type,
  value,
}) => (
  <label
    htmlFor={id}
    className={classNames('input', className, (errorMessage && error) || errorGlobal ? 'error' : '')}
  >
    <span>{label}</span>
    <textarea
      autoComplete={0}
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
    />
    {error && (<span className="error-text">{errorMessage}</span>)}
  </label>
);

TextAreaInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  errorGlobal: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

TextAreaInput.defaultProps = {
  className: '',
  error: false,
  errorGlobal: '',
  errorMessage: '',
  label: '',
  placeholder: '',
  type: 'text',
};

export default TextAreaInput;
