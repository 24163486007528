import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import {
  QUESTION_HOUSING,
} from '../../constants/questions';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSlider } from '../../utils/functions';

import CardSmall from '../../components/CardSmall';
import DropDown from '../../components/DropDown';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import QuestionButtons from '../../components/QuestionButtons';
import QuestionSwiper from '../../components/QuestionSwiper';
import SliderDial from '../../components/SliderDial';
import Button from '../../components/Button';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-wonen.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-wonen.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_5.svg';

import './style.scss';

const QuestionHousing = ({
  fetchTooltips,
  tooltips,
  fetchDropdown,
  dropdown,
  setQuestionResult,
  setSwipeOnContainer,
  shouldIupdate,
  openGenericModal,
  openSpecificModal,
  mobileView,
  results,
}) => {
  const quickQuestions = content.quick.questions;
  const quickCardSmallQuestions = content.quick.cardsmall;

  const specificQuestions = content.specific.questions;
  const specificCardSmallQuestions = content.specific.cardsmall;

  const quickLength = quickQuestions.length + quickCardSmallQuestions.length;
  const specificLength = specificCardSmallQuestions.length + specificQuestions.length;

  const [values, setValues] = useState({
    housetype: 'Tussenwoning',
    energylabel: 'C',
    duurzaam: 'voor 1990',
    building: 'Bouw',
  });

  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [mode, setMode] = useState(false);

  const questionId = QUESTION_HOUSING;

  const currentItemIndex = order.indexOf(questionId);

  const submitClicked = () => {
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const radioChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const dropdownChange = (name, value) => {
    setValues({ ...values, [name]: value.value });
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  const updateFromSwiperSpecific = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSpecificSlide(value);
    }
  };

  useEffect(() => {
    setValues({ ...values, mode });
  }, [mode]);

  const getSpecialId = (sliderID) => sliderID.split('-')[0];

  const replaceTextToBold = (content, data, item) => {
    let val;

    if (data) {
      const defaultOption = item.options.find((option) => option.value === data);
      val = defaultOption.label;
    } else if (item.defaultValue) {
      const defaultOption = item.options.find((option) => option.value === item.defaultValue);
      val = defaultOption.label;
    } else {
      val = item.defaultValue;
    }
    if (data === 'standaard') val = 'Bouw';
    const text = reactStringReplace(content, '{value}', () => (
      <strong key={data}>{val}</strong>
    ));
    return text;
  };

  const makeTooltipsSliderDial = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_HOUSING] && results[QUESTION_HOUSING][getSpecialId(item.id)]) defaultValue = results[QUESTION_HOUSING][getSpecialId(item.id)];
        else if (values && values[getSpecialId(item.id)]) defaultValue = values[getSpecialId(item.id)];
        if (item.id === 'house-specific' || item.id === 'people-specific') {
          if (results[QUESTION_HOUSING] && results[QUESTION_HOUSING][getSpecialId(item.id)]) defaultValue = results[QUESTION_HOUSING][getSpecialId(item.id)];
          else if (values && values[getSpecialId(item.id)]) defaultValue = values[getSpecialId(item.id)];
        }
        return (
          <SliderDial
            snapToZero={item.snapToZero}
            key={item.id}
            defaultValue={values[item.id] || defaultValue}
            min={item.min}
            max={item.max}
            money={item.money}
            changed={(value) => sliderChange(getSpecialId(item.id), value)}
            description={item.label}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            unit={item.unit}
            descriptions={item.descriptions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
          />
        );
      })
    );
  };

  const makeDropdown = (id) => {
    const filterDropdown = dropdown.filter((dropdownn) => (dropdownn.id === id));
    return (
      filterDropdown.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_HOUSING] && results[QUESTION_HOUSING][item.id]) defaultValue = results[QUESTION_HOUSING][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <CardSmall key={item.id} className={item.classname}>
            <span className="boxlabel">
              {replaceTextToBold(item.label, values[item.id], item)}
              {item.modal && (<Button label="?" className="info" onClick={() => openSpecificModal(item.modal)} />)}
            </span>
            {item.type === 'radioButton' ? (
              <RadioButtonGroup
                defaultValue={values[item.id] || defaultValue}
                name="electric"
                onChange={(value) => radioChange(item.id, value)}
                options={item.options}
              />
            ) : (
              <>
                <DropDown
                  onChange={(value) => dropdownChange(item.id, value)}
                  options={item.options}
                  className="max-options"
                  defaultValue={values[item.id] || defaultValue}
                  customValue={values[item.id]}
                />
              </>
            )}
          </CardSmall>
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (dropdown.length === 0) {
      fetchDropdown();
    }
  }, [fetchDropdown]);

  return (
    <div className="question-housing">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="housingQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('housingQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="HousingQuestionDescription" />
      </Paragraph>
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              {quickQuestions.map((item) => (
                <div key={item.id} className="row">
                  {makeTooltipsSlider(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    swipeOnQuestions,
                    tooltips,
                    updateSwipe,
                    values,
                  )}
                </div>
              ))}
              <div className="dropdown-wrapper">
                {quickCardSmallQuestions.map((item) => (
                  makeDropdown(item.id)
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quickQuestions.map((item) => (
                  makeTooltipsSlider(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    swipeOnQuestions,
                    tooltips,
                    updateSwipe,
                    values,
                  )
                ))}
                {quickCardSmallQuestions.map((item) => (
                  <div key={item.id} className="vertical-align-cards">
                    {makeDropdown(item.id)}
                  </div>
                ))}

              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
            <div className="questionButton-box">
              {currentSlide === quickLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSlide(currentSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      ) : (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              <div className="vertical-wrap-row">
                {specificQuestions.map((item) => (
                  makeTooltipsSliderDial(item.id)
                ))}
              </div>
              <div className="dropdown-wrapper">
                {specificCardSmallQuestions.map((item) => (
                  makeDropdown(item.id)
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiperSpecific} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                {specificQuestions.map((item) => (
                  <div key={item.id} className="vertical-align-sliderdial">
                    {makeTooltipsSliderDial(item.id)}
                  </div>
                ))}
                {specificCardSmallQuestions.map((item) => (
                  <div key={item.id} className="vertical-align-cards">
                    {makeDropdown(item.id)}
                  </div>
                ))}
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
            <div className="questionButton-box">
              {currentSpecificSlide === specificLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSpecificSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionHousing.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchDropdown: PropTypes.func.isRequired,
  dropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionHousing.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionHousing);
