import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../constants/questions';

const average = {
  [QUESTION_BATHING]: {
    shower: {
      recipe: 23.95,
      value: 7.6,
    },
    bath: {
      recipe: 1.827,
      value: 0.917,
    },
  },
  [QUESTION_CLOTHES]: {
    clothes: {
      recipe: 32.61,
      value: 5,
    },
    secondhand: {
      recipe: -32.61,
      value: 5,
    },
  },
  [QUESTION_CAR]: {
    distance: {
      recipe: 113.93,
      value: 7700,
    },
    cars: {
      recipe: 60.6,
      value: 0.48,
    },
  },
  [QUESTION_DAIRY]: {
    milk: {
      recipe: 9.27,
      value: 7,
    },
    dairy: {
      recipe: 7.59,
      value: 4.2,
    },
    cheese: {
      recipe: 11.38,
      value: 9.2,
    },
    eggs: {
      recipe: 2.57,
      value: 1.65,
    },
  },
  [QUESTION_FLYING]: {
    flown: {
      recipe: 62.55,
      value: 6.11,
    },
    liftoff: {
      recipe: 4.40,
      value: 2.6,
    },
  },
  [QUESTION_FOOD]: {
    calories: {
      recipe: 24.16,
      value: 3230,
    },
    drinkNoAlco: {
      recipe: 20.05,
      value: 8.1,
    },
    drinkAlco: {
      recipe: 7.02,
      value: 0.85,
    },
    water: {
      recipe: 0.01,
      value: 590,
    },
    juice: {
      recipe: 2.18,
      value: 55,
    },
    coffee: {
      recipe: 8.31,
      value: 392,
    },
    tea: {
      recipe: 3.45,
      value: 225,
    },
    soda: {
      recipe: 6.1,
      value: 350,
    },
    beer: {
      recipe: 2.72,
      value: 92.3,
    },
    wine: {
      recipe: 3.98,
      value: 38.4,
    },
    liquor: {
      recipe: 0.33,
      value: 4.2,
    },
  },
  [QUESTION_HOUSING]: {
    house: { // Dit zijn de m2
      recipe: 144.81,
      value: 55,
    },
    KWH: {
      recipe: 16.10,
      value: 1294.34,
    },
    gas: {
      recipe: 97.23,
      value: 613,
    },
  },
  [QUESTION_MEAT]: {
    meatglobal: {
      recipe: 106.67,
      value: 7,
    },
    beef: {
      recipe: 106.67,
      value: 154,
    },
    pork: {
      recipe: 15.96,
      value: 366,
    },
    chicken: {
      recipe: 10.44,
      value: 221,
    },
    fish: {
      recipe: 3.31,
      value: 112,
    },
  },
  [QUESTION_POSSESSIONS]: {
    possessions: {
      recipe: 266.08,
      value: 1941,
    },
    digital: {
      recipe: 65.8,
      value: 231,
    },
    electric: {
      recipe: 18.9,
      value: 147,
    },
    books: {
      recipe: 2.7,
      value: 73.73,
    },
    other: {
      recipe: 173,
      value: 1488,
    },
  },
  [QUESTION_PUBLIC_TRANSPORT]: {
    train: {
      recipe: 5.96,
      value: 21.8,
    },
    bus: {
      recipe: 3.36,
      value: 4.58,
    },
    metro: {
      recipe: 0.34,
      value: 0.8,
    },
    tram: {
      recipe: 0.41,
      value: 0.79,
    },
  },
};

export default average;
