import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Stars = ({
  stars,
  negativeStars,
}) => (
  <div className="stars">
    {Array.from(Array(stars), (_, i) => <div key={i} className={classNames('star', 'positive')} />)}
    {Array.from(Array(negativeStars - stars), (_, i) => <div key={i} className={classNames('star', 'negative')} />)}
  </div>
);

Stars.propTypes = {
  stars: PropTypes.number.isRequired,
  negativeStars: PropTypes.number,
};

Stars.defaultProps = {
  negativeStars: 4,
};

export default Stars;
