import { combineReducers } from 'redux';

// Reducers
import questions from './questions';
import user from './user';
import languageLabels from './languageLabels';
import tooltips from './tooltips';
import dropdown from './dropdown';
import products from './product';
import teamCards from './teamCards';
import mobileView from './mobileView';
import languageParagraph from './languageParagraph';
import modalExplaining from './modalExplaining';
import counterGroup from './counterGroup';
import tooltipsPercentage from './tooltipPercentage';
import selectorBox from './selectorBox';
import actionCards from './actionCards';
import actionTips from './actionTips';
import modalTop10 from './modalTop10';

// Combine Reducers
const reducers = combineReducers({
  questions,
  user,
  languageLabels,
  languageParagraph,
  tooltips,
  dropdown,
  products,
  mobileView,
  teamCards,
  modalExplaining,
  counterGroup,
  tooltipsPercentage,
  selectorBox,
  actionCards,
  actionTips,
  modalTop10,
});

export default reducers;
