import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './style.scss';
import { lang } from '../../utils/locale';

const getLanguageText = (text) => {
  const en = {
    Volgende: 'Next',
    Vorige: 'Previous',
  };

  if (lang === 'en' && (text === 'Volgende' || text === 'Vorige')) {
    return en[text];
  }
  return text;
};

const Button = ({
  className,
  onClick,
  label,
  to,
  disabled,
  img,
  children,
}) => (
  to ? (
    <NavLink
      className={classNames('button', className, disabled && 'disabled')}
      to={to}
      onClick={onClick}
    >
      {img && (<img src={img} alt="img" />)}
      <span>{getLanguageText(label)}</span>
      {children}
      {className === 'border' && (<span className="border-top" />)}
      {className === 'border' && (<span className="bg" />)}
    </NavLink>
  ) : (
    <button
      type="submit"
      className={classNames('button', className, disabled && 'disabled')}
      disabled={disabled}
      onClick={onClick}
    >
      {img && (<img src={img} alt="img" />)}
      <span>{getLanguageText(label)}</span>
      {className === 'border' && (<span className="border-top" />)}
      {className === 'border' && (<span className="bg" />)}
    </button>
  )
);

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  to: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
  label: '',
  to: '',
  className: '',
  img: '',
  children: null,
};

export default (Button);
