const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const numLiftoffs = (questionResults.liftoff && questionResults.liftoff) > 0 ? questionResults.liftoff : 2.6;
  let liftoff = (numLiftoffs / average.liftoff.value) * average.liftoff.recipe;
  const flown = linearScaling('flown') || 0;

  if (flown === 0) {
    liftoff = 0;
  }
  const recipe = (flown + liftoff) || 0;

  return {
    flown,
    liftoff,
    recipe,
  };
};

export default calculate;
