import { call, put } from 'redux-saga/effects';

import { SET_MODAL_TOP_10 } from '../actions/modalTop10ActionTypes';
import { findModalTop10 } from '../connectors/contentful';

function* fetchModalTop10() {
  const response = yield call(() => findModalTop10());

  yield put({
    type: SET_MODAL_TOP_10,
    modalTop10: response.items,
  });
}

export default fetchModalTop10;
