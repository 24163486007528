import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactGA from 'react-ga';
import Button from '../Button';

import paths from '../../routes/paths';
import { QUESTIONSHOME } from '../../constants/pages';
import { cookiesAccepted, isLocalhost, isTestEnvironment } from '../../utils/environmentutil';

import './style.scss';

const StepIndicator = ({
  items,
  activeQuestion,
}) => {
  const [activeArray] = useState([]);

  useEffect(() => {
    if (activeArray.indexOf(activeQuestion) === -1) {
      activeArray.push(activeQuestion);
    }
  }, [activeQuestion]);

  const analyticsEvent = (item, index) => {
    if (cookiesAccepted && !isLocalhost && !isTestEnvironment) {
      ReactGA.event({
        category: 'Stepindicator button',
        action: `Clicked on ${item}, questions/${index}`,
      });
    }
  };

  return (
    <nav className="stepIndicator">
      {items.map((item, index) => (
        <Button
          key={item}
          className={classNames(
            'step',
            `ic-${item.toLowerCase()}`,
            item === activeQuestion && 'current',
            activeArray.includes(item) && 'done',
          )}
          to={
            items.indexOf(activeQuestion) > index
            || activeArray.includes(item)
              ? `${paths[QUESTIONSHOME]}/${index}`
              : ''
          }
          onClick={items.indexOf(activeQuestion) > index
            || activeArray.includes(item)
            ? () => analyticsEvent(item, index) : null}
        />
      ))}
    </nav>
  );
};

StepIndicator.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  activeQuestion: PropTypes.string,
};

StepIndicator.defaultProps = {
  items: [],
  activeQuestion: '',
};

export default StepIndicator;
