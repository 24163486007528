import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Water = ({ className }) => (
  <div className={classNames('water', className)} />
);

Water.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Water;
