import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Heading = ({
  afterIcon,
  beforeIcon,
  children,
  className,
  type: Type,
}) => (
  <Type
    className={classNames('heading', className)}
  >
    {beforeIcon && (<img src={beforeIcon} alt="icon" />)}
    {children}
    {afterIcon && (<img src={afterIcon} alt="icon" />)}
  </Type>
);

Heading.propTypes = {
  afterIcon: PropTypes.string,
  beforeIcon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
};

Heading.defaultProps = {
  afterIcon: '',
  beforeIcon: '',
  children: null,
  className: '',
  type: 'h2',
};

export default (Heading);
