import { SET_PRODUCT } from '../actions/productActionTypes';

import { getProducts } from '../transformers/productTransformer';

const initialState = {
  products: [],
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT: {
      const products = getProducts(action.products);

      return {
        ...state,
        products,
      };
    }
    default:
      return state;
  }
};

export default products;
