import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
  Amplify,
  Auth,
  API,
  graphqlOperation,
} from 'aws-amplify';


import {
  createUser as CreateUser,
  updateUser as UpdateUser,
} from '../../../graphql/mutations';

import mapStateToProps from '../mapState';
import mapDispatchToProps from '../mapDispatch';

import Form from '../../../components/Form';
import TextInput from '../../../components/Input/TextInput';
import PasswordInput from '../../../components/Input/PasswordInput';
import Button from '../../../components/Button';
import ToggleButton from '../../../components/ToggleButton';
import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import FormContainer from '../../../components/FormContainer';
import LanguageParagraph from '../../../components/LanguageParagraph';
import LanguageLabel from '../../../components/LanguageLabel';

import { errorEmail, errorConfirmPassword, errorPassword } from '../../../utils/errorHandling';

import './style.scss';
import getUser from '../../../lib/user';
import paths from '../../../routes/paths';
import { FORGOTPASSWORD, HOME } from '../../../constants/pages';

const LoginOrCreateUser = ({
  history,
  setUser,
  currentResultId,
  user,
  languageLabels,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [switchButton, setSwitchButton] = useState(false);
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] = useState('');
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorGlobal, setErrorGlobal] = useState('');
  const [verified, setVerified] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [errorCode, setErrorCode] = useState('');
  const location = useLocation();
  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';


  const handleSwitchButton = () => {
    setSwitchButton(!switchButton);
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setErrorGlobal('');
    setShowError(false);
    setLoadingCreate(false);
  };

  const handlePassword = (value) => {
    setPassword(value);
    setErrorPasswordMessage(errorPassword(value, lang));

    setErrorGlobal('');
    if (confirmPassword.length > 0) {
      setErrorConfirmPasswordMessage(errorConfirmPassword(value, confirmPassword, lang));
    }
  };

  const handlePasswordConfirm = (value) => {
    setConfirmPassword(value);
    setErrorConfirmPasswordMessage(errorConfirmPassword(value, password, lang));

    setErrorGlobal('');
  };

  const handleEmail = (value) => {
    setEmail(value);
    setErrorEmailMessage(errorEmail(value, lang));

    setErrorGlobal('');
  };

  const updateUser = async () => {
    if (user.user.results.find((item) => item === currentResultId)) {
      history.push(`/${lang}/dashboard`);
      return;
    }
    const newResults = user.user.results;
    newResults.unshift(currentResultId);

    try {
      const resp = await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.user.id,
            results: newResults,
          },
        }),
      );
      setUser(resp.data.updateUser);
      history.push(`/${lang}/dashboard`);
    } catch (err) {
      // console.log('error', err);
    }
  };

  const loginUser = async () => {
    if (
      email.length > 0
      && password.length > 0
      && !errorPasswordMessage
      && !errorEmailMessage
    ) {
      try {
        setLoadingLogin(false);
        await Auth.signIn(email.toLocaleLowerCase(), password);
        const resp = await Auth.currentUserInfo();
        getUser(resp.username);
        if (currentResultId.length === 0) {
          history.push(`/${lang}/dashboard`);
        }
      } catch (error) {
        setErrorCode(error.code);
        setLoadingLogin(true);
      }
    } else {
      setErrorGlobal('E-mailadres of wachtwoord is foutief');
      setLoadingLogin(true);
    }
  };


  const checkEmail = () => {
    setShowError(true);
  };

  const checkPassword = () => {
    setShowError(true);
  };

  const createUser = async () => {
    if (
      !errorPasswordMessage
      && !errorEmailMessage
      && !errorConfirmPasswordMessage
    ) {
      try {
        setLoadingCreate(false);
        const cognitoResp = await Auth.signUp(email.toLowerCase(), password);
        let results = [];
        if (currentResultId.length !== 0) results = [currentResultId];
        try {
          const object = {
            email: email.toLowerCase(),
            cognitoID: cognitoResp.userSub,
            results,
            acceptedActions: [],
            declinedActions: [],
            goals: [],
            groups: [],
          };
          Amplify.configure({
            aws_appsync_authenticationType: 'API_KEY',
          });
          await API.graphql(
            graphqlOperation(CreateUser, {
              input: object,
            }),
          );
          Amplify.configure({
            aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
          });
        } catch (err) {
          setLoadingCreate(true);
        }
        const user = {
          email: email.toLocaleLowerCase(),
        };
        setUser(user);
        history.push(`/${lang}/mailsend`);
      } catch (error) {
        if (error.code === 'UsernameExistsException') {
          setErrorGlobal('Het aanmaken van je account is helaas mislukt. Er bestaat al een account met dit emailadres.');
        }
      }
    } else {
      setLoadingCreate(true);
    }
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    if (!switchButton) {
      loginUser();
    } else {
      createUser();
    }
  };

  useEffect(() => {
    if (loadingLogin) {
      setErrorGlobal('E-mailadres of wachtwoord is foutief');
    }
  }, [loadingLogin]);

  // useEffect(() => {
  //   setErrorGlobal('');
  //   setShowError(false);
  //   setLoadingCreate(false);
  // }, [email, password, confirmPassword]);

  useEffect(() => {
    if (loadingCreate) {
      handleEmail(email);
      handlePassword(password);
      handlePasswordConfirm(confirmPassword);
      setShowError(true);
    }
  }, [loadingCreate]);

  useEffect(() => {
    if (currentResultId.length !== 0 && user.user.results) {
      updateUser();
    }
  }, [user]);

  useEffect(() => {
    if (location.search === '?verified') {
      setVerified(true);
    }
    if (location.search === '?account') {
      setSwitchButton(true);
    }
  }, []);

  const [ll, setLL] = useState({});

  useEffect(() => {
    const labelsToPrep = ['loginPasswordRequirements', 'loginEmailPlaceholder'];

    let labels = { ...ll };
    labelsToPrep.forEach((labelId) => {
      if (languageLabels && languageLabels.find((label) => label.id === labelId)) {
        const labelText = languageLabels.find((label) => label.id === labelId).label;

        labels = { ...labels, [labelId]: labelText };
      }
      setLL(labels);
    });
  }, [languageLabels]);

  const resendVerificationEmail = async (event) => {
    event.preventDefault();
    // console.log('resending verificationemail', email);
    await Auth.resendSignUp(email);
    history.push(`/${lang}/mailsend`);
  };

  const ErrorMessage = () => {
    let message = errorGlobal;
    // console.log(`Getting errormessage for ${errorCode}`);

    if (errorCode) {
      switch (errorCode) {
        case 'UserNotConfirmedException':
          message = 'Je moet je emailadres nog bevestigen. Controleer je inbox of verstuur de bevestigingsemail opnieuw.';
          break;
        default:
          break;
      }
    }
    return (
      <>
        <span className="global-error">{message}
          {errorCode === 'UserNotConfirmedException' && (
          <Button
            type="button"
            className="rounded secondary"
            label={<LanguageLabel id="loginResendVerification" />} // "Verstuur bevestigingemail opnieuw"
            onClick={(e) => resendVerificationEmail(e)}
          />
          )}
        </span>
      </>
    );
  };


  return (
    <div className="login-height">
      <FormContainer>
        <LanguageLabel id="" />
        <div className={classNames(verified ? 'formcontainer verified' : 'formcontainer')}>
          <Heading className="primary" type="h1">
            {verified ? <LanguageLabel id="LoginTitleVerified" /> : <LanguageLabel id="LoginTitle" />}
          </Heading>
          <Heading type="h2">
            {verified ? <LanguageLabel id="LoginSubTitleVerified" /> : <LanguageLabel id="LoginSubTitle" />}
          </Heading>
          <Paragraph>
            <LanguageParagraph id="LoginParagraph" />
          </Paragraph>
          <Form onSubmit={onFormSubmit}>
            <ToggleButton
              beforeText="Log in"
              onClick={() => handleSwitchButton()}
              toggleButtonValue={!switchButton}
              afterText={<LanguageLabel id="createAccount" />}
              isSliding={() => {}}
            />
            <>
              {errorGlobal && (<ErrorMessage />)}
              <TextInput
                className=""
                id={uuidv4()}
                error={showError}
                errorGlobal={errorGlobal}
                errorMessage={errorEmailMessage}
                label={<LanguageLabel id="LoginInputEmailTitle" />}
                onChange={(e) => handleEmail(e.target.value)}
                placeholder={ll.loginEmailPlaceholder} // "Vul je e-mailadres in"
                value={email}
                onBlur={checkEmail}
              />
              <PasswordInput
                className=""
                id={uuidv4()}
                error={showError}
                errorGlobal={errorGlobal}
                errorMessage={errorPasswordMessage}
                label={<LanguageLabel id="LoginInputPasswordTitle" />}
                onChange={(e) => handlePassword(e.target.value)}
                placeholder={ll.loginPasswordRequirements} // "Gebruik een hoofdletter en 2 symbolen of cijfers"
                value={password}
                onBlur={checkPassword}
              />
              {switchButton ? (
                <PasswordInput
                  className=""
                  id={uuidv4()}
                  error={showError}
                  errorGlobal={errorGlobal}
                  errorMessage={errorConfirmPasswordMessage}
                  label={<LanguageLabel id="RegisterInputConfirmPasswordTitle" />}
                  onChange={(e) => handlePasswordConfirm(e.target.value)}
                  placeholder={ll.loginPasswordRequirements} // "Gebruik een hoofdletter en 2 symbolen of cijfers"
                  value={confirmPassword}
                  onBlur={checkPassword}
                />
              ) : null }
              <Button
                type="submit"
                label={switchButton ? <LanguageLabel id="RegisterButtonTitle" /> : <LanguageLabel id="LoginButtonTitle" />}
                className="rounded secondary"
              />
              {!switchButton ? (
                <div className="row">
                  <Button
                    label={<LanguageLabel id="LoginForgotButtonTitle" />}
                    to={paths[FORGOTPASSWORD]}
                  />
                </div>
              ) : null }
              <div className="row">
                <Button
                  label={<LanguageLabel id="backToHome" />}
                  to={paths[HOME]}
                />
              </div>
            </>
          </Form>
        </div>
      </FormContainer>
    </div>
  );
};

LoginOrCreateUser.propTypes = {
  history: PropTypes.shape().isRequired,
  setUser: PropTypes.func.isRequired,
  currentResultId: PropTypes.string,
  user: PropTypes.shape({
    user: PropTypes.shape({
      results: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
    }),
  }),
  languageLabels: PropTypes.arrayOf(PropTypes.shape({})),
};

LoginOrCreateUser.defaultProps = {
  currentResultId: '',
  user: {},
  languageLabels: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOrCreateUser);
