import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  graphqlOperation, API,
} from 'aws-amplify';

import mapStateToProps from './mapState';
import mapDispatchToProps from '../mapDispatch';

import {
  getGoal as GetGoal,
} from '../../../graphql/queries';

import {
  createGoal as CreateGoal,
  updateUser as UpdateUser,
  updateGoal as UpdateGoal,
} from '../../../graphql/mutations';

import { getEarths } from '../../../utils/functions';
import { getCurrentDate } from '../../../utils/dateFormat';

import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import ResultChart from '../../../components/ResultChart';
import EarthCounter from '../../../components/EarthCounter';
import Button from '../../../components/Button';
import TextInput from '../../../components/Input/TextInput';
import LanguageLabel from '../../../components/LanguageLabel';
import LanguageParagraph from '../../../components/LanguageParagraph';
import GoalPageTemplate from '../../../components/GoalPageTemplate';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../../constants/questions';
import { lang } from '../../../utils/locale';

const results = {
  calculatedResults: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const goals = {
  results: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const GoalEdit = ({
  user, mobileView, match, history, setUser,
}) => {
  const [goalName, setGoalName] = useState('');
  const [earthsGoal, setEarthsGoal] = useState(0);
  const [earthsOriginal, setEarthsOriginal] = useState(0);

  const [edit, setEdit] = useState();
  const [preFilledTest, setPreFilledTest] = useState(results);
  const [preFilledGoal, setPreFilledGoal] = useState(goals);
  const [goalUpdate, setGoalUpdate] = useState(false);
  const { id } = match.params;

  const chartResultsFormatGoal = (resp, goal, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing > goal.bathing ? goal.bathing : resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car > goal.car ? goal.car : resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes > goal.clothes ? goal.clothes : resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary > goal.diary ? goal.diary : resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying > goal.flying ? goal.flying : resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food > goal.food ? goal.food : resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing > goal.housing ? goal.housing : resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat > goal.meat ? goal.meat : resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions > goal.possessions ? goal.possessions : resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport > goal.possessions ? goal.publictransport : resp.publictransport || 0 },
      },
    };
    return preFilled;
  };

  const chartResultsFormat = (resp, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport || 0 },
      },
    };
    return preFilled;
  };

  const getGoalById = async (id) => {
    try {
      const goal = await API.graphql(
        graphqlOperation(GetGoal, {
          id,
        }),
      );
      if (goal.data.getGoal) {
        setGoalName(goal.data.getGoal.title);
        setGoalUpdate(true);
      } else {
        history.push(`/${lang}/goals`);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const loadData = async () => {
    const resp = user.sortedResults;
    try {
      const goal = await API.graphql(graphqlOperation(GetGoal, { id }));
      setPreFilledTest(chartResultsFormat(resp[0], 'calculatedResults'));
      if (id === 'create') {
        setPreFilledGoal(chartResultsFormat(resp[0], 'results'));
        setEarthsGoal(getEarths(chartResultsFormat(resp[0], 'calculatedResults').calculatedResults));
      } else {
        setPreFilledGoal(chartResultsFormatGoal(resp[0], goal.data.getGoal, 'results'));
        setEarthsGoal(getEarths(chartResultsFormatGoal(resp[0], goal.data.getGoal, 'calculatedResults').calculatedResults));
      }
      setEarthsOriginal(getEarths(chartResultsFormat(resp[0], 'calculatedResults').calculatedResults));
    } catch (err) {
      // console.log(err);
    }
  };

  const UpdateGoalMock = async (reload = false) => {
    try {
      await API.graphql(
        graphqlOperation(UpdateGoal, {
          input: {
            id,
            title: goalName,
            bathing: preFilledGoal.results[QUESTION_BATHING].recipe || 0,
            car: preFilledGoal.results[QUESTION_CAR].recipe || 0,
            clothes: preFilledGoal.results[QUESTION_CLOTHES].recipe || 0,
            diary: preFilledGoal.results[QUESTION_DAIRY].recipe || 0,
            flying: preFilledGoal.results[QUESTION_FLYING].recipe || 0,
            food: preFilledGoal.results[QUESTION_FOOD].recipe || 0,
            housing: preFilledGoal.results[QUESTION_HOUSING].recipe || 0,
            meat: preFilledGoal.results[QUESTION_MEAT].recipe || 0,
            possessions: preFilledGoal.results[QUESTION_POSSESSIONS].recipe || 0,
            publictransport: preFilledGoal.results[QUESTION_PUBLIC_TRANSPORT].recipe || 0,
            earthsResult: earthsOriginal,
            earthsGoal,
          },
        }),
      );
      const updatedUser = user;
      const index = user.goals.indexOf(id);
      if (index === -1) {
        updatedUser.goals.push(id);
        setUser(updatedUser);
      }
      if (reload) {
        return;
      }
      history.push(`/${lang}/goals`);
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const updateUserGoalImage = async (file, goalId) => {
    if (file === 'random') {
      const key = `images/${Math.ceil(Math.random() * 8)}.jpg`;
      const url = `https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/${key}`;
      try {
        await API.graphql(
          graphqlOperation(UpdateGoal, {
            input: {
              id: goalId,
              image: url,
            },
          }),
        );
      } catch (err) {
        // console.log('random error: ', err);
      }
    }
  };
  // Goal aanmaken
  const CreateGoalMock = async (reload = false) => {
    let url = 'https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/';
    let goalId;
    const inputData = {
      title: goalName,
      bathing: preFilledGoal.results[QUESTION_BATHING].recipe || 0,
      car: preFilledGoal.results[QUESTION_CAR].recipe || 0,
      clothes: preFilledGoal.results[QUESTION_CLOTHES].recipe || 0,
      diary: preFilledGoal.results[QUESTION_DAIRY].recipe || 0,
      flying: preFilledGoal.results[QUESTION_FLYING].recipe || 0,
      food: preFilledGoal.results[QUESTION_FOOD].recipe || 0,
      housing: preFilledGoal.results[QUESTION_HOUSING].recipe || 0,
      meat: preFilledGoal.results[QUESTION_MEAT].recipe || 0,
      possessions: preFilledGoal.results[QUESTION_POSSESSIONS].recipe || 0,
      publictransport: preFilledGoal.results[QUESTION_PUBLIC_TRANSPORT].recipe || 0,
      earthsResult: earthsOriginal,
      createdOn: getCurrentDate(),
      earthsGoal,
      image: url += `images/${Math.ceil(Math.random() * 8)}.jpg`,
    };

    try {
      const createdGoal = await API.graphql(graphqlOperation(CreateGoal, { input: inputData }));
      const goalsList = user.goals;
      goalId = createdGoal.data.createGoal.id;
      const newGoalId = goalId;
      if (goalsList.length > 0) {
        goalId = user.goals;
        goalId.unshift(createdGoal.data.createGoal.id);
      }

      await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.id,
            goals: goalId,
          },
        }),
      );
      updateUserGoalImage('random', newGoalId);
      if (!goalsList.length > 0) {
        user.goals.push(newGoalId);
      }

      if (reload) {
        history.push(`/${lang}/goalsedit/${newGoalId}`);
        return;
      }
      history.push(`/${lang}/goals`);
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const onChangeGoals = (data) => {
    const key = Object.keys(data);
    const values = Object.values(data);
    const newObj = { ...preFilledGoal.results, [key]: { recipe: Number(values) } };
    setPreFilledGoal({ results: newObj });
    setEarthsGoal(getEarths(preFilledGoal.results));
  };

  const handleGoal = (reload) => {
    if (id !== 'create') {
      UpdateGoalMock(reload);
      return;
    }
    CreateGoalMock(reload);
  };

  const reloadGoal = () => {
    if (id !== 'create') {
      getGoalById(id);
    }
    if (user.results && user.results.length !== 0) {
      loadData();
    }
  };

  useEffect(() => {
    reloadGoal();
  }, [user]);

  useEffect(() => {
    if (mobileView) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, [mobileView]);

  return (
    <GoalPageTemplate className="goaledit">
      <header className="goalpagetemplate-header">
        <Heading className="primary"><LanguageLabel id="goalEditHeaderTitle" /></Heading>
        <Heading><LanguageLabel id="goalEditHeaderSubTitle" /></Heading>
        <Paragraph>
          <LanguageParagraph id="goalEditHeaderParagraph" />
        </Paragraph>
      </header>
      <section className="goalpagetemplate-container">
        <div className="goalpagetemplate-input">
          <TextInput
            onChange={(e) => setGoalName(e.target.value)}
            label={<LanguageLabel id="goalEditInputLabel" />}
            value={goalName}
            id="name"
          />
        </div>
        <EarthCounter transparentEarths={earthsOriginal} yellowBorderEarths={earthsGoal} greenEarth />
        <div className="goalpagetemplate-chart">
          <ResultChart
            user={user}
            questions={preFilledTest}
            goals={preFilledGoal}
            onChangeGoals={onChangeGoals}
            edit={edit}
            modal
            goalUpdate={goalUpdate}
            legend
            saveFunc={(e) => handleGoal(e)}
            cancelFunc={reloadGoal}
            earthsGoal={earthsGoal}
            earthsOriginal={earthsOriginal}
            paramId={id}
          />
        </div>
        <div className="goalpagetemplate-input-button">
          <Button className="rounded secondary" label={<LanguageLabel id="goalEditSaveButton" />} onClick={() => handleGoal()} />
        </div>
      </section>
      <div className="goalpagetemplate-background">
        <div className="mountain" />
        <div className="bg-white" />
      </div>
    </GoalPageTemplate>
  );
};

GoalEdit.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
    sortedResults: PropTypes.arrayOf(PropTypes.shape({})),
    goals: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setUser: PropTypes.func,
};

GoalEdit.defaultProps = {
  setUser: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalEdit);
