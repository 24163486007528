import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  graphqlOperation, API, Storage,
} from 'aws-amplify';

import mapStateToProps from './mapState';

import {
  createGroup as CreateGroup,
  updateUser as UpdateUser,
} from '../../graphql/mutations';

import { getCurrentDate, getDateFormat } from '../../utils/dateFormat';

import Button from '../../components/Button';
import Heading from '../../components/Heading';
import TextInput from '../../components/Input/TextInput';
import Switch from '../../components/Switch';
import Paragraph from '../../components/Paragraph';
import Anchor from '../../components/Anchor';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import Copy from '../../assets/icons/ic-copy.svg';

import './style.scss';

const NewGroup = ({
  history,
  user,
}) => {
  const [teamName, setTeamName] = useState('');
  const [groupState, setGroupState] = useState(true);
  const [teamNameError, setTeamNameError] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [randomIzer, setRandomIzer] = useState(Math.ceil(Math.random() * 8));
  const [copied, setCopiedState] = useState(false);

  const key = `images/${randomIzer}.jpg`;
  const url = 'https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/';
  const [imagePath, setImagePath] = useState(url + key);

  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';

  const handleRandomClick = () => {
    let randomNumber = Math.ceil(Math.random() * 8);
    if (randomNumber === randomIzer && randomNumber > 1) randomNumber -= 1;
    if (randomNumber === randomIzer && randomNumber < 7) randomNumber += 1;
    const tempKey = `images/${randomNumber}.jpg`;
    setRandomIzer(randomNumber);
    setImagePath(url + tempKey);
  };

  const handelChange = (value) => {
    setTeamName(value);
    if (buttonPressed) {
      if (value.length < 3) {
        setTeamNameError(true);
        return;
      }
    }
    setTeamNameError(false);
  };

  const uploadUserImage = async (file) => {
    if (file) {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      const { type: mimeType } = file;
      const key = `images/${user.id + Math.ceil(Math.random() * 8)}.${extension}`;
      const url = `https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/${key}`;
      try {
        await Storage.put(key, file, {
          contentType: mimeType,
        });
        setImagePath(url);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  const handleUpload = (event) => {
    const { target: { files } } = event;
    const fileForUpload = files[0];
    if (fileForUpload) {
      uploadUserImage(fileForUpload);
    }
  };


  const checkInput = async () => {
    setButtonPressed(true);
    if (teamName.length >= 3) {
      const userObject = {
        user: user.id,
        date: getDateFormat(getCurrentDate(), 'YYYY-MM-DD'),
      };
      const resultObject = {
        userID: user.id,
        resultID: user.results && user.results.length > 0 ? user.results[0] : '',
      };
      const inputData = {
        title: teamName,
        description: '',
        admins: [user.id],
        owner: user.id,
        image: imagePath,
        users: [JSON.stringify(userObject)],
        initialResults: [JSON.stringify(resultObject)],
        currentResults: [JSON.stringify(resultObject)],
        pendingRequests: [],
        goal: '',
        secluded: !groupState,
        location: '',
      };
      const createdGroup = await API.graphql(graphqlOperation(CreateGroup, { input: inputData }));
      const addedGroups = user.groups || [];
      addedGroups.push(createdGroup.data.createGroup.id);
      await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.id,
            groups: addedGroups,
          },
        }),
      );
      setGroupId(createdGroup.data.createGroup.id);
      setCreateGroup(true);
    }
    setTeamNameError(true);
  };

  const goToGroupPage = () => {
    history.push(`groups/${groupId}`);
  };

  return (
    <>
      <main className="newgroup">
        {!createGroup && (<Heading className="primary"><LanguageLabel id="newGroupTitle" /></Heading>)}
        <article className={classNames('box', createGroup && 'create')}>
          <figure className="box-img">
            <img src={imagePath} alt="groupimg" />
          </figure>
          {!createGroup ? (
            <>
              <div className="box-row">
                <div className="box-items">
                  <label htmlFor="files" className="upload-pic">
                    <input
                      id="files"
                      style={{ width: '0', visibility: 'hidden' }}
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={handleUpload}
                    />
                    <span><LanguageLabel id="newGroupUploadPhotoButton" /></span>
                  </label>
                  <Button label={<LanguageLabel id="randomPhotoLabel" />} onClick={handleRandomClick} />
                </div>
              </div>
              <TextInput
                className=""
                id="teamname"
                label={<LanguageLabel id="newGroupTeamNameLabel" />}
                onChange={(e) => handelChange(e.target.value)}
                placeholder={lang === 'nl' ? 'Type hier je groepsnaam' : 'Type your group name here'}
                value={teamName}
                error={teamNameError}
                errorMessage={<LanguageLabel id="newGroupTeamNameErrorMessage" />}
              />
              <Switch
                value={setGroupState}
                defaultValue={groupState}
              />
              <div className="box-button-container">
                <div className="box-button">
                  <Button className="secondary small" onClick={() => checkInput()} label={<LanguageLabel id="newGroupCreateGroupButton" />} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Heading className="primary"><LanguageLabel id="newGroupCreateGroupTitle" replace={teamName} /></Heading>
              <Paragraph><LanguageParagraph id="newGroupCreateGroupParagraph" /></Paragraph>
              <div className="box-button-container">
                <div className="box-button-highlight">
                  <div
                    onAnimationEnd={() => setCopiedState(false)}
                    className={classNames('copytext', copied && 'active-copy')}
                  ><LanguageLabel id="copiedPopupLabel" />
                  </div>
                  <CopyToClipboard
                    text={`https://www.mijnverborgenimpact.nl/groups/${groupId}`}
                    onCopy={() => setCopiedState(true)}
                  >
                    <Button img={Copy} className="rounded" label={<LanguageLabel id="newGroupCopyLinkButton" />} />
                  </CopyToClipboard>
                </div>
                <div className="box-button">
                  <Button className="secondary small" onClick={() => goToGroupPage()} label={<LanguageLabel id="newGroupViewGroupLabel" />} />
                </div>
              </div>
              <ul className="row">
                <li><Anchor
                  link={`mailto:username@example.com?subject=Welkom!&body=https://www.mijnverborgenimpact.nl/groups/${groupId || 0}`}
                  className="share mail"
                />
                </li>
                {groupId !== '' ? (
                  <>
                    <li>
                      <Anchor
                        link={`https://api.whatsapp.com/send?text=www.mijnverborgenimpact.nl/groups/${groupId || 0}`}
                        className="share whatsapp"
                      />
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Anchor
                        link="https://api.whatsapp.com"
                        className="share whatsapp"
                      />
                    </li>
                    <li>
                      <Anchor
                        link="https://twitter.com"
                        className="share twitter"
                      />
                    </li>
                    <li>
                      <Anchor
                        link="https://www.linkedin.com"
                        className="share linkedin"
                      />
                    </li>
                  </>
                )}
              </ul>
            </>
          )}
        </article>
      </main>
      <div className="background">
        <div className="base-bg" />
      </div>
    </>
  );
};

NewGroup.propTypes = {
  history: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.shape([]),
    groups: PropTypes.shape([]),
  }),
};

NewGroup.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(NewGroup);
