const FETCH_LANGUAGE_LABELS = 'FETCH_LANGUAGE_LABELS';
const SET_LANGUAGE_LABELS = 'SET_LANGUAGE_LABELS';

const FETCH_LANGUAGE_PARAGRAPH = 'FETCH_LANGUAGE_PARAGRAPH';
const SET_LANGUAGE_PARAGRAPH = 'SET_LANGUAGE_PARAGRAPH';

export {
  FETCH_LANGUAGE_LABELS,
  SET_LANGUAGE_LABELS,
  FETCH_LANGUAGE_PARAGRAPH,
  SET_LANGUAGE_PARAGRAPH,
};
