import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import axios from 'axios';
import {
  API,
  graphqlOperation,
} from 'aws-amplify';

import {
  listActions as ListActions,
  getQuizResult as GetQuizResult,
  getAction as GetAction,
} from '../../graphql/queries';

import {
  createAction as CreateAction,
  updateUser as UpdateUser,
  createNewsLetter as CreateNewsLetter,
} from '../../graphql/mutations';

import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import ActionCard from '../../components/ActionCard';
import Stars from '../../components/Stars';
import ModalConfirm from '../../components/ModalConfirm';

import Button from '../../components/Button';

import paths from '../../routes/paths';
import { QUESTIONSHOME } from '../../constants/pages';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import { ReactComponent as Bucket } from '../../assets/icons/trashcan-small.svg';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import './style.scss';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';
import { lang } from '../../utils/locale';

const getDeclineStyle = (isDraggingOver) => ({
  backgroundColor: isDraggingOver ? '#EA81C1' : 'transparent', transition: `all ease-in ${0.1}s`,
});

const getAcceptStyle = (isDraggingOver) => ({
  backgroundColor: isDraggingOver ? '#80CEDA' : 'transparent', transition: `all ease-in ${0.1}s`,
});

const Actions = ({
  fetchActionCards,
  fetchActionTips,
  actionCards,
  actionTips,
  user,
  questions,
}) => {
  const [dropEnd, setDropEnd] = useState('dragNeutral');
  const [actions, setActions] = useState({ items: [] });
  const [dataBaseActions, setDatabaseActions] = useState({});
  const [actionState, setActionState] = useState(false);
  const [newsLetter, setNewsLetter] = useState(user.newsLetter || false);
  const [newsLetterInput, setNewsLetterInput] = useState('');
  const [uniqueTipArry, setUniqueTipArry] = useState([]);

  const [confirmModal, setConfirmModal] = useState(false);
  const transArr = {
    car: 'Auto',
    dairy: 'Zuivel & Eieren',
    meat: 'Vlees en vis',
    food: 'Eten & drinken',
    clothes: 'Kleding',
    possessions: 'Spullen',
    housing: 'Wonen',
    bathing: 'Badkamer',
    flying: 'Vliegen',
    publictransport: 'Openbaar vervoer',
  };

  const transArrEN = {
    car: 'Car',
    dairy: 'Dairy products & Eggs',
    meat: 'Meat and fish',
    food: 'Eating & drinking',
    clothes: 'Clothing',
    possessions: 'Possessions',
    housing: 'Housing',
    bathing: 'Bathroom',
    flying: 'Flying',
    publictransport: 'Public transport',
  };

  const selectedTransArr = lang === 'en' ? transArrEN : transArr;

  useEffect(() => {
    fetchActionCards();
    fetchActionTips();
  }, []);

  const getResult = async (id) => {
    try {
      const result = await API.graphql(
        graphqlOperation(GetQuizResult, {
          id,
        }),
      );
      return result;
    } catch (err) {
      return null;
    // console.log('error: ', err);
    }
  };

  // Checken of de contentful acties in Dynamo staan en anders erin zetten
  useEffect(() => {
    const convertActions = async () => {
      const dbActions = await API.graphql(
        graphqlOperation(ListActions, { limit: 999 }),
      );
      setDatabaseActions(dbActions);
      const AC = actionCards;
      if (AC.length > 0 && user.results && user.results.length !== 0) {
        const filteredActions = AC.filter((o) => !dbActions.data.listActions.items.find((o2) => o.id === o2.contentfulId));
        await Promise.all(filteredActions.map(async (action) => {
          try {
            await API.graphql(graphqlOperation(CreateAction, { input: { contentfulId: action.id } }));
          } catch (err) {
            // console.log(err);
          }
        }));
      }
    };
    convertActions();
  }, [actionCards]);

  const retrieveUserCards = async (n) => {
    const result = await getResult(user.results[0]);
    const filteredResultObj = result.data.getQuizResult;
    delete filteredResultObj.id;
    delete filteredResultObj.stringResult;
    delete filteredResultObj.email;
    delete filteredResultObj.newsLetter;
    delete filteredResultObj.dateCreated;
    delete filteredResultObj.dateCreated;
    if (filteredResultObj.gender) {
      delete filteredResultObj.gender;
    }
    if (filteredResultObj.birthdate) {
      delete filteredResultObj.birthdate;
    }
    if (filteredResultObj.zipcode) {
      delete filteredResultObj.zipcode;
    }
    // Volgorde van de acties:
    const keysSorted = Object.keys(filteredResultObj).sort((a, b) => filteredResultObj[a] - filteredResultObj[b]);
    const actionsFromDb = dataBaseActions && dataBaseActions.data && dataBaseActions.data.listActions.items;
    const actions = {
      items: actionCards.map((item) => {
        const index = actionsFromDb && actionsFromDb.findIndex((x) => x.contentfulId === item.id);
        return (
          {
            id: item.id,
            title: item.title,
            desc: item.description,
            stars: item.stars,
            category: item.identity,
            dynamoID: actionsFromDb && actionsFromDb[index].id,
          }
        );
      }),
    };
    const filteredActions = {
      items: actions.items.filter((x) => {
        const acceptedIndex = user.acceptedActions.findIndex((y) => y === x.dynamoID);
        const declinedIndex = user.declinedActions.findIndex((y) => y === x.dynamoID);
        return acceptedIndex === -1 && declinedIndex === -1;
      }),
    };
    const A = {
      items: filteredActions.items.sort((a, b) => keysSorted.indexOf(b.category) - keysSorted.indexOf(a.category)).slice(0, n),
    };
    setActions(A);
  };

  const retrieveUserActions = async () => {
    const acceptedActions = [];
    await Promise.all(user.acceptedActions.map(async (action) => {
      try {
        const result = await API.graphql(
          graphqlOperation(GetAction, {
            id: action,
          }),
        );
        acceptedActions.push(result.data.getAction);
      } catch (err) {
        // console.log(err);
      }
    }));
    const selectedCards = [];
    acceptedActions.forEach((x) => {
      const index = actionCards.findIndex((y) => y.id === x.contentfulId);
      const card = {
        id: actionCards[index].id,
        title: actionCards[index].title,
        desc: actionCards[index].description,
        stars: actionCards[index].stars,
        category: actionCards[index].identity,
        dynamoID: x.id,
      };
      selectedCards.push(card);
    });

    const actions = {
      items: selectedCards,
    };
    setActions(actions);
  };

  useEffect(() => {
    // Als de user nog geen acties heeft
    if (user.results && user.results.length !== 0 && (!user.acceptedActions || !user.acceptedActions.length)) retrieveUserCards(10);
    // Als de user al wel actief heeft
    if (user.acceptedActions && user.acceptedActions.length !== 0 && actionCards.length !== 0) retrieveUserActions();
  }, [user, actionCards]);

  useEffect(() => {
    if (user.acceptedActions && user.acceptedActions.length !== 0) setActionState(true);
  }, [user]);

  const updateUser = async (key, value) => {
    let valueSet = value;
    if (key === 'newsLetter') {
      setNewsLetter(!newsLetter);
      valueSet = !user.newsLetter;
    }
    try {
      await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.id,
            [key]: valueSet,
          },
        }),
      );
    } catch (err) {
      // console.log(err)
    }
  };

  const decideOnAction = async (decision, actionId) => {
    let newActions = [];
    if (decision) {
      if (user.acceptedActions) newActions = user.acceptedActions;
      newActions.push(actionId);
      updateUser('acceptedActions', newActions);
    } else {
      if (user.declinedActions) newActions = user.declinedActions;
      newActions.push(actionId);
      updateUser('declinedActions', newActions);
    }
    const { items } = actions;
    items.splice(0, 1);
    const newItems = [...items];
    if (newItems.length === 0) {
      await retrieveUserActions();
      setActionState(!actionState);
    } else {
      setActions({
        items: newItems,
      });
    }
  };

  const setView = async () => {
    await retrieveUserCards(5);
    setActionState(!actionState);
  };

  const getItemStyle = (isDragging, draggableStyle, snapshot) => {
    const style = {
      boxShadow: isDragging ? '0 4px 10px 0 rgba(0,0,0,0.3)' : '0 4px 10px 0 rgba(0,0,0,0.1)',
      ...draggableStyle,
    };
    if (!snapshot.isDropAnimating
      || !dropEnd
      || snapshot.draggingOver === null
      || snapshot.draggingOver === 'dragList') {
      return style;
    }
    const { moveTo, duration } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    return {
      ...style,
      opacity: '0',
      scale: '0',
      transform: `${translate} scale(0)`,
      transition: `all ease-in ${duration}s`,
    };
  };

  const onDragUpdate = (result) => {
    if (!result.destination || result.destination.droppableId === 'dragList') {
      setDropEnd('dragNeutral');
    } else {
      setDropEnd(result.destination.droppableId);
    }
  };

  const onDragEnd = async (result) => {
    let newActions = [];
    const { source, destination, draggableId } = result;
    if (destination && source && destination.droppableId === source.droppableId) {
      return;
    }
    if (destination.droppableId === 'dragAccept') {
      if (user.acceptedActions) newActions = user.acceptedActions;
      newActions.push(draggableId);
      await updateUser('acceptedActions', newActions);
    }
    if (destination.droppableId === 'dragDecline') {
      if (user.declinedActions) newActions = user.declinedActions;
      newActions.push(draggableId);
      await updateUser('declinedActions', newActions);
    }
    setDropEnd('dragNeutral');
    const { items } = actions;
    items.splice(0, 1);
    const newItems = [...items];
    if (newItems.length === 0) {
      await retrieveUserActions();
      setActionState(!actionState);
    } else {
      setActions({
        items: newItems,
      });
    }
  };

  const removeAction = async (id) => {
    let newActions = [];
    if (user.declinedActions) newActions = user.declinedActions;
    newActions.push(id);
    await updateUser('declinedActions', newActions);
    if (user.acceptedActions) newActions = user.acceptedActions;
    const index = newActions.findIndex((x) => x === id);
    newActions.splice(index, 1);
    await updateUser('acceptedActions', newActions);
    const { items } = actions;
    const newItems = [...items.filter((x) => x.dynamoID !== id)];
    if (newItems.length === 0) {
      await retrieveUserActions();
      setActionState(!actionState);
    } else {
      setActions({
        items: newItems,
      });
    }
  };

  const getNewsLetterGateway = async () => {
    let res;
    try {
      res = await API.post('footprintapi', '/newsletter', { email: newsLetterInput });
    } catch (error) {
      // console.log(error);
    }
    return res;
  };

  const signUpForNewsletter = async () => {
    if (newsLetterInput.length > 5) {
      const newsLetterResult = getNewsLetterGateway();
      // console.log(newsLetterResult);

      setConfirmModal(!confirmModal);
      if (newsLetterResult.length === 0) {
        const data = {
          email: newsLetterInput,
        };
        await API.graphql(graphqlOperation(CreateNewsLetter, { input: { emailRecipient: newsLetterInput, yesOrNo: true } }));
        axios({
          method: 'post',
          url: 'https://mlx41wi9t5.execute-api.eu-west-1.amazonaws.com/email-dev',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          params:
          { data },
        });
      }
    }
  };

  const makeCardTips = () => {
    const dataToSort = questions.calculatedResults;
    const mappedData = {
      bathing: dataToSort[QUESTION_BATHING].recipe,
      car: dataToSort[QUESTION_CAR].recipe,
      clothes: dataToSort[QUESTION_CLOTHES].recipe,
      dairy: dataToSort[QUESTION_DAIRY].recipe,
      flying: dataToSort[QUESTION_FLYING].recipe,
      food: dataToSort[QUESTION_FOOD].recipe,
      housing: dataToSort[QUESTION_HOUSING].recipe,
      meat: dataToSort[QUESTION_MEAT].recipe,
      possessions: dataToSort[QUESTION_POSSESSIONS].recipe,
      publictransport: dataToSort[QUESTION_PUBLIC_TRANSPORT].recipe,
    };
    const keysSorted = Object.keys(mappedData).sort((a, b) => mappedData[a] - mappedData[b]).reverse();
    const uniqueArr = [];
    actionTips.map((item) => {
      if (uniqueArr.indexOf(item.type) === -1) {
        uniqueArr.push(item.type);
      }
      return null;
    });
    uniqueArr.sort((a, b) => keysSorted.indexOf(b) - keysSorted.indexOf(a));
    setUniqueTipArry(uniqueArr.reverse());
  };

  useEffect(() => {
    makeCardTips();
  }, [actionTips]);

  return (
    <div className={classNames('actions', 'tips')}>
      <ModalConfirm
        modalSwitch={confirmModal}
        onClick={() => setConfirmModal(!confirmModal)}
        modalContent="signUp"
        className="extra-confirm"
      />
      {user.id ? (
        <>
          <Heading className={classNames('primary', 'heading-actions')} type="h1">
            <span className="topheading">
              <LanguageLabel id="actionLoginTitle" />
            </span>
            <span className="subheading">
              <LanguageLabel id="actionLoginSubTitle" />
            </span>
          </Heading>
          <Paragraph className="paragraph-actions">
            <LanguageParagraph id="actionLoginParagraph" />
          </Paragraph>
          {!actionState ? (
            <div className="drag-section">
              <div className="backdrop">
                {actions.items.length > 1 ? (
                  <div className="multiple">
                    <div className="empty-card" />
                    <div className="empty-card" />
                  </div>
                ) : (
                  actions.items.length === 1 ? (
                    <div className="single"><div className="empty-card" /></div>
                  ) : (
                    null
                  )
                )}
              </div>
              <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                <Droppable droppableId="dragDecline">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getDeclineStyle(snapshot.isDraggingOver, snapshot)}
                      className="dragdecline circle"
                      id="decline-id"
                      onClick={() => decideOnAction(false, actions.items[0].dynamoID)}
                    >{provided.placeholder}
                      {snapshot.isDraggingOver
                        ? <div className="icon decline-big" /> : <div className="icon decline-small" />}
                    </div>
                  )}
                </Droppable>
                <div className="draglist">
                  <Droppable droppableId="dragList">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                      >
                        {actions.items.length > 0 ? (
                          <Draggable
                            key={actions.items[0].dynamoID}
                            draggableId={actions.items[0].dynamoID}
                            index={2}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="dragable"
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  snapshot,
                                )}
                              >
                                <ActionCard
                                  title={actions.items[0].title}
                                  category={actions.items[0].category}
                                  description={actions.items[0].desc}
                                  stars={actions.items[0].stars}
                                  ignoreAction={() => decideOnAction(false, actions.items[0].dynamoID)}
                                  acceptAction={() => decideOnAction(true, actions.items[0].dynamoID)}
                                  declineAction={() => decideOnAction(false, actions.items[0].dynamoID)}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : null}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <Droppable droppableId="dragAccept">
                  {(provided, snapshot) => (
                    <div
                      style={getAcceptStyle(snapshot.isDraggingOver)}
                      className="dragaccept circle"
                      ref={provided.innerRef}
                      onClick={() => decideOnAction(true, actions.items[0].dynamoID)}
                    > {provided.placeholder}
                      {snapshot.isDraggingOver
                        ? <div className="icon accept-big" /> : <div className="icon accept-small" />}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="placeholder">
                {dropEnd === 'dragAccept' ? (
                  <div className={classNames('accept', 'selected')}>
                    <div className="blob" />
                  </div>
                ) : null}
                {dropEnd === 'dragDecline' ? (
                  <div className={classNames('decline', 'selected')}>
                    <div className="blob" />
                  </div>
                ) : null}
                {dropEnd === 'dragNeutral' ? (
                  <div className={classNames('neutral', 'selected')}>
                    <div className="blob" />
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="action-list">
              <Heading className="primary" type="h2">
                <LanguageLabel id="ActionLoginListTitle" />
              </Heading>
              <ul>
                {actions.items.map((action) => (
                  <li key={action.id} className="action-list-item">
                    <div className="content">
                      <Stars stars={action.stars} />
                      <span>{action.desc || action.title}</span>
                    </div>
                    <div className="bucket">
                      <Bucket onClick={() => removeAction(action.dynamoID)} />
                    </div>
                  </li>
                ))}
              </ul>
              <Button
                label={<LanguageLabel id="ActionListShowNew" />}
                onClick={setView}
                className="small secondary"
              />
            </div>
          )}
        </>
      ) : (
        <div className="">
          <div className="title">
            <h1 className="centered">
              <span className="highlight"><LanguageLabel id="ActionNotLoginTitle" /></span>
            </h1>
            <h2 className="centered">
              <span>
                <LanguageLabel id="ActionNotLoginSubTitle" />
              </span>
            </h2>
            <p><LanguageParagraph id="ActionNotLoginParagraph" /></p>
          </div>
          <div className="tipsList">
            {uniqueTipArry.slice(0, 3).map((item) => (
              <div key={item} className="tipCard">
                <h1>{selectedTransArr[item]}</h1>
                <ul>
                  {actionTips.map((tip, i) => (
                    tip.type === item && (
                      <li key={`${item + i}`}>{tip.description}</li>
                    )
                  ))}
                </ul>
              </div>
            ))}
            <Button
              label={<LanguageLabel id="ActionNotLoginButton" />}
              to={`${paths[QUESTIONSHOME]}/0`}
              className="small secondary"
            />
          </div>
        </div>
      )}
      <div className="whiteout">
        <div className="getInfo">
          <div className="newsletter">
            <h2><LanguageLabel id="ActionNewsLetterTitle" /></h2>
            <form onClick={(e) => e.preventDefault()}>
              <label htmlFor="goNewsletter" className="input">
                <span><LanguageLabel id="ActionNewsLetterInputTitle" /></span>
                <input
                  id="goNewsletter"
                  placeholder={lang === 'nl' ? 'Vul je email adres in' : 'Enter your email address'}
                  type="text"
                  value={newsLetterInput}
                  onChange={(e) => setNewsLetterInput(e.target.value)}
                />
              </label>
              <Button
                label={<LanguageLabel id="ActionNewsLetterInputButtonTitle" />}
                className="small secondary"
                onClick={signUpForNewsletter}
              />
            </form>
          </div>
          <div className="shop">
            <h2><LanguageLabel id="ActionProductTitle" /></h2>
            <p>
              <LanguageParagraph id="ActionProductParagraph" />
            </p>
            <a
              className="button small outline-mobile"
              href="https://www.bol.com/nl/f/de-verborgen-impact/9200000058860511/"
              title=""
            >
              <span><LanguageLabel id="ActionProductButtonTitle" /></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Actions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.string,
    ),
    newsLetter: PropTypes.bool,
    acceptedActions: PropTypes.arrayOf(
      PropTypes.string,
    ),
    declinedActions: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }),
  questions: PropTypes.shape({
    calculatedResults: PropTypes.shape({
      recipe: PropTypes.number,
    }),
  }),
  fetchActionCards: PropTypes.func.isRequired,
  fetchActionTips: PropTypes.func.isRequired,
  actionCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    identity: PropTypes.string,
    description: PropTypes.string,
    stars: PropTypes.number,
  })).isRequired,
  actionTips: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
};

Actions.defaultProps = {
  user: {},
  questions: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
