import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import {
  QUESTION_DAIRY,
} from '../../constants/questions';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSliderDial } from '../../utils/functions';

import Heading from '../../components/Heading';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-zuivel-ei.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-zuivel-ei.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_2.svg';

import './style.scss';

const QuestionDairy = ({
  fetchTooltips,
  tooltips,
  setQuestionResult,
  setSwipeOnContainer,
  openSpecificModal,
  openGenericModal,
  mobileView,
  results,
  mode,
}) => {
  const [values, setValues] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);

  const quickQuestions = content.quick.questions;
  const quickLength = quickQuestions.length;

  const questionId = QUESTION_DAIRY;

  const currentItemIndex = order.indexOf(questionId);

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const submitClicked = () => {
    // Go to next in line, check in order
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  return (
    <div className="question-dairy">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="dairyQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('dairyQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="dairyQuestionDescription" />
      </Paragraph>
      {!mobileView ? (
        <>
          <div className="vertical-align-wrapper-flex">
            <div className="vertical-wrap-row">
              <Paragraph><LanguageLabel id="dairyQuestionTooltipTitle" /></Paragraph>
            </div>
            <div className="vertical-wrap-row">
              {quickQuestions.map((item) => (
                makeTooltipsSliderDial(
                  item.id,
                  openSpecificModal,
                  questionId,
                  results,
                  sliderChange,
                  tooltips,
                  updateSwipe,
                  values,
                )
              ))}
            </div>
          </div>
          <div className="questionButton-box">
            <QuestionButtons
              questionId={questionId}
              nextClick={() => submitClicked()}
            />
          </div>
        </>
      ) : (
        <>
          <div className="vertical-align-wrapper-flex">
            <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
              {quickQuestions.map((item) => (
                <div key={item.id} className="vertical-align-sliderdial">
                  {makeTooltipsSliderDial(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    tooltips,
                    updateSwipe,
                    values,
                  )}
                </div>
              ))}
            </QuestionSwiper>
          </div>
          <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
          <div className="questionButton-box">
            {currentSlide === quickLength - 1
              ? (
                <>
                  <QuestionButtons
                    questionId={questionId}
                    nextClick={() => submitClicked()}
                  />
                  <Button
                    label={<LanguageLabel id="questionPreviousLabel" />}
                    onClick={() => setCurrentSlide(currentSlide - 1)}
                    className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                  />
                </>
              )
              : (
                <>
                  <Button
                    label={<LanguageLabel id="questionNextLabel" />}
                    onClick={() => setCurrentSlide(currentSlide + 1)}
                    className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                  />
                  {currentSlide === 0 ? (
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  ) : (
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  )}
                </>
              )}
          </div>
        </>
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionDairy.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  mode: PropTypes.bool,
};

QuestionDairy.defaultProps = {
  results: PropTypes.shape({}),
  mode: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDairy);
