import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';

import { ReactComponent as Close } from '../../assets/icons/ic-close-blue.svg';
import defaultImg from '../../assets/images/default-profile-img.jpg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const Member = ({
  imgSrc,
  userId,
  name,
  acceptLink,
  rejectLink,
  removeMember,
  newMember,
  kind,
  accepted,
  edit,
  className,
  groupOwner,
}) => (
  <article className={classNames(edit ? 'memberedit' : 'member', className)}>
    {newMember && <span className="member-new"><LanguageLabel id="memberNewMemberLabel" /></span>}
    <figure>
      <img src={imgSrc || defaultImg} alt="icon" />
    </figure>
    {edit ? (
      <>
        <div className="content">
          <div className="member-name">{name}</div>
          <div className="member-kind">{kind}</div>
        </div>
        {groupOwner !== userId && (
        <Button
          className="rounded"
          onClick={removeMember}
          label={<><Close /><span><LanguageLabel id="memberRemoveMemberLabel" /></span></>}
        />
        )}
      </>
    ) : (
      accepted ? (
        <>
          <div className="member-name">{name}</div>
          <div className="member-kind">{kind}</div>
        </>
      ) : (
        <>
          <div className="member-name">{name}</div>
          <div className="member-buttons">
            <Button className="secondary small" onClick={acceptLink} label="memberAcceptMemberLabel" />
            <Button className="rounded" onClick={rejectLink} label="memberRejectMemberLabel" />
          </div>
        </>
      )
    )}
  </article>
);

Member.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  acceptLink: PropTypes.func,
  rejectLink: PropTypes.func,
  removeMember: PropTypes.func,
  className: PropTypes.string,
  newMember: PropTypes.bool,
  kind: PropTypes.string,
  accepted: PropTypes.bool,
  edit: PropTypes.bool,
  groupOwner: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

Member.defaultProps = {
  accepted: false,
  kind: 'Lid',
  edit: false,
  newMember: false,
  removeMember: null,
  acceptLink: null,
  rejectLink: null,
  className: '',
};

export default Member;
