const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const meatglobal = linearScaling('meatglobal');
  let recipePoints = meatglobal > 0 ? meatglobal : 0;

  const beef = linearScaling('beef') || 0;
  const pork = linearScaling('pork') || 0;
  const chicken = linearScaling('chicken') || 0;
  const fish = linearScaling('fish') || 0;

  const beefGrams = questionResults.beef;
  const beefAbroadPercentage = questionResults.nlvsabroad;
  let beefAbroadPoints = 0;
  let beefNLPoints = 0;

  if (beef !== 0) {
    const beefNLPercentage = 100 - (beefAbroadPercentage || 50);
    const beefNLFactor = beefNLPercentage / 100;
    const beefAbroadFactor = (beefAbroadPercentage || 50) / 100;
    const beefAbroadRecipe = 106.33;
    const beefNLRecipe = 15.99;

    beefAbroadPoints = (beefGrams / average.beef.value) * (beefAbroadRecipe * beefAbroadFactor);
    beefNLPoints = (beefGrams / average.beef.value) * (beefNLRecipe * beefNLFactor);

    recipePoints += beefAbroadPoints + beefNLPoints;
  }

  if (pork !== 0) recipePoints += pork;
  if (chicken !== 0) recipePoints += chicken;
  if (fish !== 0) recipePoints += fish;

  const recipe = recipePoints;

  return {
    meatglobal,
    recipe,
    beef,
    pork,
    chicken,
    fish,
    beefAbroadPoints,
    beefNLPoints,
  };
};

export default calculate;
