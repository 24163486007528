import { SET_USER } from '../../store/actions/userActionTypes';
import { FETCH_ACTIONCARDS } from '../../store/actions/actionCardsActionTypes';
import { FETCH_ACTIONTIPS } from '../../store/actions/actionTipsActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch({
    type: SET_USER,
    payload: {
      user,
    },
  }),
  fetchActionCards: () => dispatch({
    type: FETCH_ACTIONCARDS,
    payload: {},
  }),
  fetchActionTips: () => dispatch({
    type: FETCH_ACTIONTIPS,
    payload: {},
  }),
});

export default mapDispatchToProps;
