import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from '../Heading';
import Button from '../Button';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const ModalConfirm = ({
  className,
  modalSwitch,
  onClick,
  hideButton,
  confirmAction,
  modalContent,
}) => {
  const handleModalSwitch = (event) => {
    if (event.target.className === 'modal show') {
      onClick();
    }
  };

  return (
    <div onClick={(e) => handleModalSwitch(e)} className={classNames('modal-confirm', modalSwitch && 'show')}>
      <div className={classNames(className, 'popup-confirm')}>
        <div className="close" onClick={() => onClick()} />
        {modalContent !== 'signUp' ? (
          <>
            <Heading className="primary" type="h1">
              <LanguageLabel id="modalConfirmTitle" />
            </Heading>
            <span><LanguageLabel id="modalConfirmDescription" /></span>
            {!hideButton
        && (
          <div className="button-row">
            <Button
              label={<LanguageLabel id="modalConfirmNegativeLabel" />}
              onClick={() => onClick()}
              className="outline"
            />
            <Button
              label={<LanguageLabel id="modalConfirmPositiveLabel" />}
              onClick={() => confirmAction()}
              className="outline"
            />
          </div>
        )}
          </>
        ) : (
          <>
            <Heading className="primary" type="h1">
              <LanguageLabel id="modalConfirmSignUpTitle" />
            </Heading>
            <span>
              <LanguageLabel id="modalConfirmSignUpDescription" />
            </span>
          </>
        )}

      </div>
    </div>
  );
};

ModalConfirm.propTypes = {
  confirmAction: PropTypes.func,
  className: PropTypes.string,
  modalSwitch: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
  modalContent: PropTypes.string,
};

ModalConfirm.defaultProps = {
  className: '',
  confirmAction: null,
  hideButton: false,
  modalContent: '',
};

export default ModalConfirm;
