import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reactStringReplace from 'react-string-replace';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';
import { lang } from '../../utils/locale';

const LanguageLabel = ({
  fetchLanguageLabels,
  id,
  languageLabels,
  replace,
}) => {
  useEffect(() => {
    if (languageLabels.length === 0 || lang) {
      fetchLanguageLabels(lang);
    }
  }, [fetchLanguageLabels, lang]);

  const labelObj = languageLabels.filter((item) => item.id === id);
  const label = labelObj.length === 1 ? labelObj[0].label : <>{/* ${id} */}</>;

  if (replace || replace === 0) {
    const text = reactStringReplace(label, '{value}', () => replace || 0);
    return text;
  }

  return label || null;
};

LanguageLabel.propTypes = {
  fetchLanguageLabels: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  replace: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.number,
    PropTypes.string,
  ]),
  languageLabels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

LanguageLabel.defaultProps = {
  replace: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageLabel);
