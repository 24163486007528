import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

// TAG
import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSliderDial } from '../../utils/functions';

import {
  QUESTION_BATHING,
} from '../../constants/questions';

import Heading from '../../components/Heading';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import ToggleButton from '../../components/ToggleButton';
import SelectorBox from '../../components/SelectorBox';

import { ReactComponent as SunCollector } from '../../assets/icons/ic-suncollector.svg';
import { ReactComponent as RainShower } from '../../assets/icons/ic-rainshower.svg';
import { ReactComponent as EcoShower } from '../../assets/icons/ic-ecoshower.svg';
import { ReactComponent as SavingHeat } from '../../assets/icons/ic-savingheat.svg';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-badkamer.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-badkamer.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_6.svg';

import './style.scss';

const QuestionBathing = ({
  fetchTooltips,
  fetchSelectorBox,
  tooltips,
  selectorBox,
  setQuestionResult,
  setSwipeOnContainer,
  openSpecificModal,
  shouldIupdate,
  openGenericModal,
  mobileView,
  results,
}) => {
  const quickQuestions = content.quick.questions;
  const specificQuestionsSliderDial = content.specific.questions.sliderDial;
  const specificQuestionsSelectBox = content.specific.questions.selectBox;

  const quickLength = quickQuestions.length;
  const specificLength = specificQuestionsSliderDial.length + 1;

  const [values, setValues] = useState({});
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [mode, setMode] = useState(false);

  const questionId = QUESTION_BATHING;

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);
  // TAG
  const currentItemIndex = order.indexOf(questionId);

  const imgArr = [<SunCollector />, <EcoShower />, <RainShower />, <SavingHeat />];

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  const submitClicked = () => {
    // Go to next in line, check in order
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const selectBoxChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: !value });
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  const makeSelectBox = (id, i) => {
    const filterSelectBox = selectorBox.filter((item) => (item.id === id));
    return (
      filterSelectBox.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_BATHING] && results[QUESTION_BATHING][item.id]) defaultValue = results[QUESTION_BATHING][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <SelectorBox
            key={item.id}
            id={item.id}
            value={(val) => selectBoxChange(item.id, val)}
            label={item.label}
            defaultValue={values[item.id] || defaultValue}
          >
            <div className={`icon-${i}`}>
              {imgArr[i]}
            </div>
          </SelectorBox>
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (selectorBox.length === 0) {
      fetchSelectorBox();
    }
  }, [fetchSelectorBox]);

  return (
    <div className="question-bathing">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="bathingQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('bathingQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="bathingQuestionDescription" />
      </Paragraph>
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              <div className="vertical-wrap-row">
                {quickQuestions.map((item) => (
                  makeTooltipsSliderDial(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    tooltips,
                    updateSwipe,
                    values,
                  )
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quickQuestions.map((item) => (
                  <div key={item.id} className="vertical-align-sliderdial">
                    {makeTooltipsSliderDial(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      tooltips,
                      updateSwipe,
                      values,
                    )}
                  </div>
                ))}

              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
            <div className="questionButton-box">
              {currentSlide === quickLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSlide(currentSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      ) : (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              <div className="vertical-wrap-row">
                {specificQuestionsSliderDial.map((item) => (
                  makeTooltipsSliderDial(
                    item.id,
                    openSpecificModal,
                    questionId,
                    results,
                    sliderChange,
                    tooltips,
                    updateSwipe,
                    values,
                  )
                ))}
              </div>
              <div className="vertical-wrap-row">
                <Paragraph><LanguageLabel id="bathroomQuestionSelectorBoxTitle" /></Paragraph>
              </div>
              <div className="vertical-wrap-row">
                {specificQuestionsSelectBox.map((item, i) => (
                  makeSelectBox(item.id, i)
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                {specificQuestionsSliderDial.map((item) => (
                  <div key={item.id} className="vertical-align-sliderdial">
                    {makeTooltipsSliderDial(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      tooltips,
                      updateSwipe,
                      values,
                    )}
                  </div>
                ))}
                <div className="vertical-align-box">
                  {specificQuestionsSelectBox.map((item, i) => (
                    makeSelectBox(item.id, i)
                  ))}
                </div>
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
            <div className="questionButton-box">
              {currentSpecificSlide === specificLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSpecificSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionBathing.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchSelectorBox: PropTypes.func.isRequired,
  selectorBox: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  mode: PropTypes.bool.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionBathing.defaultProps = {
  results: PropTypes.shape({}),
};

QuestionBathing.defaultProps = {
  openSpecificModal: null,
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBathing);
