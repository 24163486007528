import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';

import close from '../../assets/icons/ic-close-black.svg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const ModalAvatar = ({
  className,
  modalSwitch,
  onClick,
  profile,
  randomImg,
  saveImg,
  saveProfileImage,
  setTempProfilePicture,
  imageError,
}) => {
  const [error, setError] = useState(imageError);
  const [tempUploadImg, setTempUploadImg] = useState('');
  const [antiRuneBool, setAntiRuleBool] = useState(false);

  const handleModalSwitch = (event) => {
    document.body.classList.remove('hide-scroll');
    if (event.target.className === 'modal show') {
      onClick();
    }
  };

  const closeModal = () => {
    setTempUploadImg();
    setTempProfilePicture('');
    onClick();
  };

  const handleChange = (event) => {
    setAntiRuleBool(false);
    const { target: { files } } = event;
    const fileForUpload = files[0];
    if (fileForUpload) {
      const image = URL.createObjectURL(fileForUpload);
      setTempUploadImg(image);
      setTempProfilePicture(image);
      saveProfileImage(fileForUpload);
    }
  };

  const handleSaveButton = () => {
    saveImg();
    onClick();
  };

  const insertRandom = () => {
    saveProfileImage('random');
    setTempProfilePicture('');
  };

  const handleError = () => {
    setError(true);
  };

  useEffect(() => {
    setAntiRuleBool(true);
  }, [modalSwitch]);

  return (
    <div onClick={(e) => handleModalSwitch(e)} className={classNames('modal-avatar', className, modalSwitch && 'show')}>
      <div className={classNames('popup-avatar', className)}>

        <ul>
          <figure>
            <span className="bg" />
            <img src={randomImg || tempUploadImg || profile} alt="profile" />
          </figure>
          <li>
            <strong>{className !== 'avatar-goals' ? <LanguageLabel id="changeAvatarTitle" /> : <LanguageLabel id="changePhotoTitle" />}</strong>
          </li>
          <li className="option">
            <span><LanguageLabel id="currentPhotoLabel" /></span>
            <img className="profile-modal icon-modal" src={profile} alt="" />
          </li>
          <li className="option" onClick={insertRandom}>
            <span><LanguageLabel id="useRandomPhotoLabel" /></span>
            <div className="icon-modal">
              {randomImg ? (
                <img src={randomImg} alt="random" />
              ) : (
                <div className="dice" />
              )}
            </div>
          </li>
          {error ? (
            <li className="option error" onClick={handleError}>
              <span className="text"><LanguageLabel id="imageMaxSizeLabel" /></span>
              <span className="icon">
                <img src={close} alt="appendix" />
              </span>
            </li>
          ) : (
            <li className="option">
              <span>Upload een nieuwe foto</span>
              <div className="input-button icon-modal">
                <label htmlFor="files">
                  <input id="files" style={{ visibility: 'hidden' }} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} />
                  <span className="btn" />
                  {!antiRuneBool && tempUploadImg && (<img src={tempUploadImg} alt="avatar" />)}
                </label>
              </div>
            </li>
          )}
          <li className="buttons">
            <Button onClick={() => handleSaveButton()} className="rounded secondary spacing" label={<LanguageLabel id="modalAvatarSaveButton" />} />
            <Button onClick={() => closeModal()} className="rounded" label={<LanguageLabel id="modalCloseLabel" />} />
          </li>
        </ul>
      </div>
    </div>
  );
};

ModalAvatar.propTypes = {
  className: PropTypes.string,
  modalSwitch: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  imageError: PropTypes.bool,
  profile: PropTypes.string,
  saveProfileImage: PropTypes.func,
  randomImg: PropTypes.string,
  saveImg: PropTypes.func,
  setTempProfilePicture: PropTypes.func,
};

ModalAvatar.defaultProps = {
  className: '',
  profile: '',
  saveProfileImage: null,
  imageError: false,
  randomImg: '',
  saveImg: () => {},
  setTempProfilePicture: () => {},
};

export default ModalAvatar;
