import { call, put } from 'redux-saga/effects';

import { SET_COUNTER_GROUP } from '../actions/counterGroupActionTypes';
import { findCounterGroup } from '../connectors/contentful';

function* fetchCounterGroup() {
  const response = yield call(() => findCounterGroup());

  yield put({
    type: SET_COUNTER_GROUP,
    counterGroup: response.items,
  });
}

export default fetchCounterGroup;
