import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import { v4 as uuidv4 } from 'uuid';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import {
  QUESTION_CAR,
} from '../../constants/questions';

// TAG
import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import Heading from '../../components/Heading';
import CardSmall from '../../components/CardSmall';
import DropDown from '../../components/DropDown';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Slider from '../../components/Slider';
import Button from '../../components/Button';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import SliderDial from '../../components/SliderDial';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import DropDownMulti from '../../components/DropDownMulti';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-auto.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-auto.svg';
import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_7.svg';

import { ReactComponent as BinHead } from '../../assets/icons/ic-bin-top.svg';
import { ReactComponent as BinBody } from '../../assets/icons/ic-bin-body.svg';

import { ReactComponent as NewItem } from '../../assets/icons/ic-newitem.svg';
import { ReactComponent as Cars } from '../../assets/icons/ic-cars.svg';
import { ReactComponent as Fill } from '../../assets/icons/ic-small-car.svg';

import './style.scss';

const QuestionCar = ({
  fetchTooltips,
  tooltips,
  fetchDropdown,
  dropdown,
  setQuestionResult,
  setSwipeOnContainer,
  shouldIupdate,
  openGenericModal,
  openSpecificModal,
  mobileView,
  results,
}) => {
  const quickQuestions = content.quick.questions;
  const specificQuestionsSliderDial = content.specific.questions.sliderDial;
  const specificQuestionsDropdown = content.specific.questions.dropdown;

  const quickLength = quickQuestions.length;
  const specificLength = specificQuestionsSliderDial.length + specificQuestionsDropdown.length;

  const [values, setValues] = useState({});
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [carArray, setCarArray] = useState([]);
  const [carDropdown, setCarDropdown] = useState(false);
  const [mode, setMode] = useState(false);

  const [currentCar, setCurrentCar] = useState(results[QUESTION_CAR] && results[QUESTION_CAR].length ? results[QUESTION_CAR].length - 1 : 0);
  const questionId = QUESTION_CAR;


  // TAG
  const currentItemIndex = order.indexOf(questionId);

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };


  const submitClicked = () => {
    // Go to next in line, check in order
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const radioChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const dropdownChange = (name, value) => {
    setValues({ ...values, [name]: value.value });
  };

  const replaceTextToBold = (content, data, defaultValue) => {
    let val;
    if (data) {
      val = data;
    } else {
      val = defaultValue;
    }
    if (defaultValue === 'money' || defaultValue === 'productie') val = 'Productie';
    const text = reactStringReplace(content, '{value}', () => (
      <strong key={data}>{val}</strong>
    ));
    return text;
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const submitClickedPrecise = () => {
    setQuestionResult(questionId, carArray, mode);
  };

  const makeNewCar = () => {
    setCarArray([...carArray, { [currentCar]: values }]);
    setCurrentCar(carArray.length);
  };

  const removeCar = (id) => {
    if (carArray.length > 1) {
      const newArray = [].concat(carArray);
      newArray.splice(id, 1);
      setCarArray(newArray);
      setCurrentCar(newArray.length - 1);
    }
  };

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  useEffect(() => {
    const getPastValues = Object.keys(values).map((item) => carArray.map((car) => ({
      id: item,
      current: 0,
      value: car[item],
    })));
    let newObject = {};
    getPastValues.forEach((item) => {
      newObject = {
        ...newObject,
        [item[currentCar].id]: item[currentCar].value,
      };
    });
    setValues(newObject);
    setCurrentSpecificSlide(0);
  }, [currentCar]);

  useEffect(() => {
    if (carArray !== []) {
      const newArray = [].concat(carArray);
      newArray.splice(currentCar, 1, values);
      setCarArray(newArray);
    }
  }, [values]);

  const valueTransformer = (value) => {
    switch (value) {
      case 0:
        return 0;
      case 1:
        return 0.5;
      default:
        return value - 1;
    }
  };

  const sliderChangeCustom = (sliderId, value) => {
    const newValue = valueTransformer(value);
    setValues({ ...values, [sliderId]: newValue });
  };

  const makeTooltipsSlider = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_CAR] && results[QUESTION_CAR][item.id]) defaultValue = results[QUESTION_CAR][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];

        return (
          <Slider
            key={item.id}
            defaultValue={values[item.id] || defaultValue}
            min={item.min}
            max={item.max}
            text={item.label}
            unit={item.unit}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            descriptions={item.descriptions}
            swiping={swipeOnQuestions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
            valueTransformer={item.id === 'cars' ? valueTransformer : null}
            updateParent={item.id === 'cars' ? (value) => sliderChangeCustom(item.id, value) : (value) => sliderChange(item.id, value)}
          />
        );
      })
    );
  };

  const makeTooltipsSliderDial = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_CAR] && results[QUESTION_CAR][item.id]) defaultValue = results[QUESTION_CAR][item.id];
        else if (carArray[currentCar] && carArray[currentCar][item.id]) defaultValue = carArray[currentCar][item.id];

        return (
          <SliderDial
            snapToZero={item.snapToZero}
            id={item.id}
            key={`${item.id + currentCar}`}
            defaultValue={values[item.id] || defaultValue}
            questionId
            min={item.min}
            max={item.max}
            money={item.money}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            changed={(value) => sliderChange(item.id, value)}
            description={item.label}
            unit={item.unit}
            descriptions={item.descriptions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
            decimal={item.id === 'carLifters'}
          />
        );
      })
    );
  };

  const makeDropdown = (id) => {
    const filterDropdown = dropdown.filter((item) => (item.id === id));
    return (
      filterDropdown.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_CAR] && results[QUESTION_CAR][item.id]) defaultValue = results[QUESTION_CAR][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <CardSmall
            key={item.id}
            className={item.classname}
          >
            <span className="boxlabel">
              {replaceTextToBold(item.label, values[item.id], item.defaultValue)}
              {item.modal && (<Button label="?" className="info" onClick={() => openSpecificModal(item.modal)} />)}
            </span>
            {item.type === 'radioButton' ? (
              <RadioButtonGroup
                defaultValue={values[item.id] || defaultValue}
                name="electric"
                onChange={(value) => radioChange(item.id, value)}
                options={item.options}
                customValue={values[item.id]}
              />
            ) : (
              <>
                {item.type === 'dropdown' ? (
                  <DropDown
                    onChange={(value) => dropdownChange(item.id, value)}
                    popModal={() => openSpecificModal(item.modal)}
                    popModalButton={item.modal}
                    options={item.options}
                    defaultValue={values[item.id] || defaultValue}
                    customValue={values[item.id]}
                  />
                ) : (
                  <DropDownMulti
                    dropDownMultiValues={(value) => sliderChange(item.id, value)}
                    popModal={() => openSpecificModal(item.modal)}
                    popModalButton={item.modal}
                    optionsList={item.options}
                    defaultValue={values[item.id] || defaultValue}
                  />
                )}
              </>
            )}
          </CardSmall>
        );
      })
    );
  };

  const handleClickCar = (key) => {
    setCurrentCar(key);
    setCarDropdown(false);
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (dropdown.length === 0) {
      fetchDropdown();
    }
  }, [fetchDropdown]);

  return (
    <div className="question-car">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="carQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('carQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="carQuestionDescription" />
      </Paragraph>
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              {quickQuestions.map((item) => (
                makeTooltipsSlider(item.id)
              ))}
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quickQuestions.map((item) => (
                  makeTooltipsSlider(item.id)
                ))}
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
            <div className="questionButton-box">
              {currentSlide === quickLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSlide(currentSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      ) : (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              <div className="vertical-wrap-row">
                <div className="addCar">
                  {carArray.length > 1 && (
                    <div className={classNames('addCar-dropdown', carDropdown && 'active')}>
                      <button
                        className="addCar-buttonadd"
                        type="button"
                        onClick={() => setCarDropdown(!carDropdown)}
                      >
                        <Cars />
                      </button>
                      <div className={classNames('addCar-dropdown--content', carDropdown && 'active')}>
                        <ul className={classNames(carArray.length > 6 && 'highlight')}>
                          {carArray.map((item, key) => (
                            <li key={uuidv4()} className={classNames(key === currentCar && 'active')} onClick={() => handleClickCar(key)}>
                             Auto {key + 1}
                            </li>
                          ))}
                        </ul>
                        {carArray.length > 6 && <span className="gradiant" />}
                      </div>
                    </div>
                  )}
                  <div className="addCar-box">
                    <span>{currentCar + 1}</span>
                    <span className="addCar-car"><Fill /></span>
                    <button
                      type="button"
                      onClick={() => removeCar(currentCar)}
                      className="addCar-buttonremove"
                    >
                      <span>
                        <BinHead />
                        <BinBody />
                      </span>
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={() => makeNewCar()}
                    className="addCar-buttonadd"
                  >
                    <NewItem />
                  </button>
                </div>
              </div>
              <div className="vertical-wrap-row">
                {specificQuestionsSliderDial.map((item) => (
                  makeTooltipsSliderDial(item.id)
                ))}
              </div>
              <div className="dropdown-wrapper">
                {specificQuestionsDropdown.map((item) => (
                  makeDropdown(item.id)
                ))}
              </div>
            </div>
            <div className="questionButton-box">
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClickedPrecise()}
              />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                {specificQuestionsSliderDial.map((item, i) => (
                  <div key={`${item.id + i}`} className="vertical-align-sliderdial">
                    {makeTooltipsSliderDial(item.id, i)}
                  </div>
                ))}
                {specificQuestionsDropdown.map((item, i) => (
                  <>
                    <div key={item.id} className={classNames(specificQuestionsDropdown[i + 1] ? 'vertical-align-cards' : 'vertical-wrap-row')}>
                      {makeDropdown(item.id)}
                    </div>
                    {!specificQuestionsDropdown[i + 1] && (
                      <div key={item.id} className={classNames('vertical-wrap-row', carDropdown && 'active')}>
                        <div className="addCar">
                          {carArray.length > 1 && (
                          <div className={classNames('addCar-dropdown', carDropdown && 'active')}>
                            <button
                              className="addCar-buttonadd"
                              type="button"
                              onClick={() => setCarDropdown(!carDropdown)}
                            >
                              <Cars />
                            </button>
                            <div className={classNames('addCar-dropdown--content', carDropdown && 'active')}>
                              <ul className={classNames(carArray.length > 6 && 'highlight')}>
                                {carArray.map((item, key) => (
                                  <li key={uuidv4()} className={classNames(key === currentCar && 'active')} onClick={() => handleClickCar(key)}>
                                    Auto {key + 1}
                                  </li>
                                ))}
                              </ul>
                              {carArray.length > 6 && <span className="gradiant" />}
                            </div>
                          </div>
                          )}
                          <div className="addCar-box">
                            <span>{currentCar + 1}</span>
                            <Fill />
                            <button
                              type="button"
                              onClick={() => removeCar(currentCar)}
                              className="addCar-buttonremove"
                            >
                              <span>
                                <BinHead />
                                <BinBody />
                              </span>
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={() => makeNewCar()}
                            className="addCar-buttonadd"
                          >
                            <NewItem />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
            <div className="questionButton-box">
              {currentSpecificSlide === specificLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClickedPrecise()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSpecificSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        ) : (
          <div className="illustration-wrapper">
            <IllustrationMobile />
          </div>
        )}
      </>
    </div>
  );
};

QuestionCar.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchDropdown: PropTypes.func.isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  mode: PropTypes.bool.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionCar.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCar);
