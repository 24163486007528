import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const GoalPageTemplate = ({ children, className }) => (
  <main className={classNames('goalpagetemplate', className)}>
    {children}
  </main>
);

GoalPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GoalPageTemplate.defaultProps = {
  className: '',
};

export default GoalPageTemplate;
