import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ListRoutes } from './routes/factory';

import Analytics from './components/Analytics';
import Layout from './components/Layout';
import Cookie from './components/Cookie';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <Cookie />
      <ScrollToTop />
      <Layout>
        <ListRoutes />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
