import { call, put } from 'redux-saga/effects';

import { SET_DROPDOWN } from '../actions/dropdownActionTypes';

import { findDropdown } from '../connectors/contentful';

function* fetchDropdown() {
  const response = yield call(() => findDropdown());

  yield put({
    type: SET_DROPDOWN,
    dropdown: response.items,
  });
}

export default fetchDropdown;
