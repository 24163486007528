const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
const SET_QUESTION_RESULT = 'SET_QUESTION_RESULT';
const SET_ID_CURRENT_TEST = 'SET_ID_CURRENT_TEST';
const SET_FRESHSTART = 'SET_FRESHSTART';

export {
  SET_ACTIVE_QUESTION,
  SET_QUESTION_RESULT,
  SET_ID_CURRENT_TEST,
  SET_FRESHSTART,
};
