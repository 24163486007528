import React, { useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';


import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import EarthCounter from '../../components/EarthCounter';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import ModalOverlay from '../../components/ModalOverlay';
import Product from '../../components/Product';
import ResultChart from '../../components/ResultChart';
import TextInput from '../../components/Input/TextInput';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import { errorEmail } from '../../utils/errorHandling';
import { getCurrentDate } from '../../utils/dateFormat';

import {
  updateQuizResult as UpdateQuizResult,
  updateUser as UpdateUser,
  createQuizResult as CreateQuizResult,
} from '../../graphql/mutations';

import {
  getQuizResult as GetQuizResult,
} from '../../graphql/queries';

import getUser from '../../lib/user';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import bird1 from '../../assets/images/forest-bird-01.svg';
import bird2 from '../../assets/images/forest-bird-02.svg';
import bird3 from '../../assets/images/forest-bird-03.svg';
import deer from '../../assets/images/forest-deer.svg';
import logobfa from '../../assets/images/logo-bullfrog-avenue.svg';
import logocedelft from '../../assets/images/logo-ce-delft.png';
import logotban from '../../assets/images/logo-think-big-act-now.svg';

import './style.scss';
import {
  ACTIONS, GOALEDITCREATE, LOGINACCOUNT, TOP10INFO,
} from '../../constants/pages';
import paths from '../../routes/paths';

const Results = ({
  questions,
  fetchProducts,
  products,
  setIdCurrentTest,
  user,
  setFreshStart,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [newsLetter, setNewsLetter] = useState(false);
  const [id, setId] = useState('');
  const [preFilledTest, setPreFilledTest] = useState({});

  const [modalContentState, setModalContentState] = useState(true);
  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';

  const handleEmail = (value) => {
    setEmail(value);
    setErrorEmailMessage(errorEmail(value, lang));
  };

  const updateUser = async (identity) => {
    const newResults = user.results;
    newResults.unshift(identity);
    try {
      const resp = await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.id,
            results: newResults,
          },
        }),
      );

      getUser(resp.data.updateUser.cognitoID);
    } catch (err) {
      // console.log("error",err)
    }
  };

  const createEntry = async () => {
    // console.log('createEntry');
    const object = {
      email: 'none',
      stringResult: JSON.stringify(questions.results),
      newsLetter,
      bathing: questions.calculatedResults.QUESTION_BATHING.recipe || 0,
      car: questions.calculatedResults.QUESTION_CAR.recipe || 0,
      clothes: questions.calculatedResults.QUESTION_CLOTHES.recipe || 0,
      diary: questions.calculatedResults.QUESTION_DAIRY.recipe || 0,
      flying: questions.calculatedResults.QUESTION_FLYING.recipe || 0,
      food: questions.calculatedResults.QUESTION_FOOD.recipe || 0,
      housing: questions.calculatedResults.QUESTION_HOUSING.recipe || 0,
      meat: questions.calculatedResults.QUESTION_MEAT.recipe || 0,
      possessions: questions.calculatedResults.QUESTION_POSSESSIONS.recipe || 0,
      publictransport: questions.calculatedResults.QUESTION_PUBLIC_TRANSPORT.recipe || 0,
      gender: (questions
      && questions.results
      && questions.results.QUESTION_USER
      && questions.results.QUESTION_USER.gender
      && questions.results.QUESTION_USER.gender) || 0,
      birthdate: (questions
      && questions.results
      && questions.results.QUESTION_USER
      && questions.results.QUESTION_USER.year
      && questions.results.QUESTION_USER.year) || 0,
      zipcode: (questions
      && questions.results
      && questions.results.QUESTION_USER
      && questions.results.QUESTION_USER.zipCode
      && questions.results.QUESTION_USER.zipCode) || 0,
      dateCreated: getCurrentDate(),
    };

    const idAmsterdam = localStorage.getItem('idAmsterdam');
    const idProject = localStorage.getItem('idProject');

    if (idAmsterdam && idProject) {
      Object.assign(object, { idAmsterdam });
    }

    await fetch(`https://panel.amsterdam.nl/${idProject}/m0/redirect-complete.html?id=${idAmsterdam}`, {
      method: 'GET',
      mode: 'no-cors',
    })
      .catch((err) => console.log(err.message));


    try {
      Amplify.configure({
        aws_appsync_authenticationType: 'API_KEY',
      });
      const resp = await API.graphql(
        graphqlOperation(CreateQuizResult, {
          input: object,
        }),
      );

      localStorage.removeItem('idAmsterdam');
      localStorage.removeItem('idProject');
      Amplify.configure({
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      });

      // console.log('quiz result', resp);
      setId(resp.data.createQuizResult.id);
      setIdCurrentTest(resp.data.createQuizResult.id);
      if (user
        && user.results
        && user.results[0] !== resp.data.createQuizResult.id) {
        await updateUser(resp.data.createQuizResult.id);
      }
    } catch (err) {
      // console.log('error create...', err);
    }
  };

  const updateEntry = async () => {
    try {
      await API.graphql(
        graphqlOperation(UpdateQuizResult, {
          input: {
            id,
            email,
            newsLetter,
          },
        }),
      );
    } catch (err) {
      // console.log('error update...', err);
    }
  };

  const confirmEmail = () => {
    if (modalContentState && email) {
      updateEntry();
      setModalContentState(false);
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    let shouldIpush = false;
    const arrayOfObject = Object.keys(questions.calculatedResults);
    arrayOfObject.forEach((x) => {
      if (questions.calculatedResults[x].recipe !== 0 && questions.activeQuestion === QUESTION_PUBLIC_TRANSPORT) shouldIpush = true;
    });

    if (user.results && user.results.length !== 0 && !shouldIpush) {
      const loadData = async () => {
        try {
          // console.log('getting last testresults', user.results[0]);
          const userResults = await API.graphql(
            graphqlOperation(GetQuizResult, {
              id: user.results[0],
            }),
          );

          const responseResult = userResults.data.getQuizResult;
          const preFilled = {
            calculatedResults: {
              [QUESTION_BATHING]: { recipe: responseResult.bathing },
              [QUESTION_CAR]: { recipe: responseResult.car },
              [QUESTION_CLOTHES]: { recipe: responseResult.clothes },
              [QUESTION_DAIRY]: { recipe: responseResult.diary },
              [QUESTION_FLYING]: { recipe: responseResult.flying },
              [QUESTION_FOOD]: { recipe: responseResult.food },
              [QUESTION_HOUSING]: { recipe: responseResult.housing },
              [QUESTION_MEAT]: { recipe: responseResult.meat },
              [QUESTION_POSSESSIONS]: { recipe: responseResult.possessions },
              [QUESTION_PUBLIC_TRANSPORT]: { recipe: responseResult.publictransport },
            },
          };
          setPreFilledTest(preFilled);
        } catch (err) {
          // console.log(err);
        }
      };
      loadData();
    }
  }, [user]);

  let dataToCalculate = questions;
  if (user
    && user.results
    && user.results.length !== 0) {
    if (user.results[0] === id) {
      dataToCalculate = questions;
    } else if (preFilledTest.calculatedResults) {
      dataToCalculate = preFilledTest;
    }
  } else {
    dataToCalculate = questions;
  }
  const totalRecipePointsTop10 = 906;
  const recipePointsResults = Object.values(dataToCalculate.calculatedResults).map((item) => item.recipe || 0);
  const totalRecipePointsResults = recipePointsResults.reduce((a, b) => a + b, 0);

  const getEarths = () => {
    const recipePointsPerEarth = totalRecipePointsTop10 / 3;
    const numberOfEarths = (totalRecipePointsResults / recipePointsPerEarth) || 0;
    let roundedEarths = Math.round(numberOfEarths * 10) / 10;

    if (roundedEarths > 10) {
      roundedEarths = Math.round(roundedEarths);
    }
    return roundedEarths || 0;
  };

  const getTrees = () => {
    const treesGemNL = 2170;
    const haGemNL = 4.3;

    const recipeFactor = totalRecipePointsResults / totalRecipePointsTop10;
    const trees = Math.floor(recipeFactor * treesGemNL);
    const ha = Math.round((recipeFactor * haGemNL) * 10) / 10;

    return {
      trees,
      ha,
    };
  };

  const numberOfEarths = getEarths();
  const trees = getTrees();

  const BuyBookModalButton = () => {
    setShowModal(false);
    window.open('https://thinkbigactnow.org/nl/#home-shop', '_blank');
  };

  useEffect(() => {
    let shouldIpush = false;
    const arrayOfObject = Object.keys(questions.calculatedResults);
    arrayOfObject.forEach((x) => {
      // console.log(questions.calculatedResults[x].recipe);
      if (questions.calculatedResults[x].recipe !== 0 && questions.activeQuestion === QUESTION_PUBLIC_TRANSPORT) {
        shouldIpush = true;
      }
    });

    setFreshStart(false);
    // console.log('shouldipush', shouldIpush);
    if (shouldIpush) createEntry();
  }, []);

  useEffect(() => {
    if (products.length === 0) {
      fetchProducts();
    }
  }, [products]);

  return (
    <>
      <div className="resultPage background-override">
        <div className="container">
          <h1 className="centered">
            <span className="highlight"><LanguageLabel id="resultTitle" /></span>
          </h1>
          <h2 className="centered">
            <span>
              <LanguageLabel id="resultSubTitle" replace={<i className="highlight">{numberOfEarths}</i>} />
            </span>
          </h2>
          <div className="centered">
            <EarthCounter earths={numberOfEarths} transparentEarths={0} />
            <ResultChart questions={dataToCalculate} legend />
            <div className="resultPage-button-box">
              <Button
                className="rounded secondary"
                label={<LanguageLabel id="resultButtonWhat" />}
                to={paths[ACTIONS]}
              />
              {user.id ? (
                <Button
                  className="rounded"
                  label={<LanguageLabel id="resultButtonGoals" />}
                  to={paths[GOALEDITCREATE]}
                />
              ) : (
                <Button
                  className="rounded"
                  label={<LanguageLabel id="resultButtonNotLoggedIn" />}
                  to={paths[LOGINACCOUNT]}
                />
              )}
            </div>
            <Button
              label={<LanguageLabel id="resultButtonTop10" />}
              to={paths[TOP10INFO]}
            />
          </div>
        </div>
        <div className="forest">
          <div className="container-fluid forestcontent">
            <div className="row centered">
              <div className="col">
                <h2>
                  <span><LanguageLabel id="resultForestTitle" /></span>
                  <span><LanguageLabel id="resultForestTitle2" replace={<>{trees.trees}</>} /></span>
                </h2>
                <p><LanguageLabel id="resultForestSubTitle" replace={<>{trees.ha}</>} /></p>
                <Button
                  className="small primary"
                  label={<LanguageLabel id="resultButtonWhat" />}
                  to={paths[ACTIONS]}
                />
              </div>
            </div>
            <Image
              className="bird bird1"
              src={bird1}
              name="I'm a bird and I live in this forest"
            />
            <Image
              className="bird bird2"
              src={bird2}
              name="I like this forest, do you like it too?"
            />
            <Image
              className="bird bird3"
              src={bird3}
              name="My name is Bird, who are you?"
            />
            <Image
              className="deerbro"
              src={deer}
              name="My name is deerbro, bro!"
            />
          </div>
        </div>
        <div className="whiteout">
          <div className="container">
            <div className="row centered">
              <Heading type="h1" className="primary bottomtitle">
                <LanguageLabel id="resultShopTitle" />
              </Heading>
              <p className="col-sm-12 col-lg-7">
                <LanguageParagraph id="resultShop" />
              </p>
            </div>
            <div className="products row">
              {products.sort((a, b) => (a.order > b.order ? 1 : -1)).map((product) => (
                <div className="col-xl-3 col-md-6" key={product.id}>
                  <Product imgUrl={`https:${product.image}`}>
                    <Heading type="h3" className="primary">
                      {product.label}
                    </Heading>
                    <p>{product.description}</p>
                    <a
                      className="button small outline-mobile"
                      href={product.url}
                      title=""
                      target="blank"
                    >
                      <span>{product.button}</span>
                    </a>
                  </Product>
                </div>
              ))}
            </div>
            <div className="row centered">
              <h1><LanguageLabel id="resultPartnersTitle" /></h1>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="partner">
                  <a
                    href="http://www.thinkbigactnow.org"
                    title={<LanguageLabel id="partnerTBANTitle" />}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <figure>
                      <Image className="logo tban" src={logotban} name="Think Big Act Now" />
                    </figure>
                  </a>
                  <span><LanguageLabel id="partnerTBANKind" /></span>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="partner">
                  <a href="https://www.ce.nl/" title={<LanguageLabel id="partnerCEDELFTTitle" />} rel="noopener noreferrer" target="_blank">
                    <figure>
                      <Image className="logo cedelft" src={logocedelft} name="CE Delft" />
                    </figure>
                  </a>
                  <span><LanguageLabel id="partnerCEDELFTKind" /></span>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="partner">
                  <a
                    href="https://www.bullfrogavenue.com/"
                    title={<LanguageLabel id="partnerBFATitle" />}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <figure>
                      <Image className="logo bfa" src={logobfa} name="BullFrog Avenue" />
                    </figure>
                  </a>
                  <span><LanguageLabel id="partnerBFATitle" /></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 offset-lg-2">
                <div className="partner">
                  <ul>
                    <li><LanguageLabel id="partnerPMName1" /></li>
                    <li><LanguageLabel id="partnerPMName2" /></li>
                    <li><LanguageLabel id="partnerPMName3" /></li>
                  </ul>
                  <span><LanguageLabel id="partnerPMKind" /></span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="partner">
                  <ul>
                    <li><LanguageLabel id="partnerFDName1" /></li>
                    <li><LanguageLabel id="partnerFDName2" /></li>
                  </ul>
                  <span><LanguageLabel id="partnerFDKind" /></span>
                </div>
              </div>
            </div>
            <div className="row centered">
              <p className="footnote">
                <LanguageParagraph id="resultFootNote" />
              </p>
            </div>
          </div>
        </div>
        <ModalOverlay
          onClick={() => confirmEmail()}
          modalSwitch={showModal}
          buttonLabel={<LanguageLabel id="resultModalOverlaySubscribeLabel" />}
          headingText={<LanguageLabel id="resultModalTitle" />}
          hideButton={!modalContentState}
          closeModal={false}
        >
          <div className="close" onClick={() => setShowModal(false)} />
          <div className="heading-modal">
            <div className="row">
              <div className="col-12">
                {modalContentState ? (
                  <p>
                    <LanguageParagraph id="resultModal" />
                  </p>
                ) : (
                  <p className="paragraph-highlight">
                    <LanguageParagraph id="resultModalSec" />
                  </p>
                )}
              </div>
            </div>
            {modalContentState ? (
              <>
                <div className="row">
                  <span className="col-12 label">Email:</span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TextInput
                      className=""
                      id="email"
                      onChange={(e) => handleEmail(e.target.value)}
                      placeholder={lang === 'nl' ? 'Email adres' : 'E-mail address'}
                      value={email}
                      error={errorEmailMessage !== ''}
                      errorMessage={errorEmailMessage}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Checkbox
                      id="emailconsent"
                      label={<LanguageLabel id="resultModalCheckbox" />}
                      onChange={() => setNewsLetter(!newsLetter)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="button-container">
                <Button
                  label={<LanguageLabel id="resultModalOverlayBuyBookLabel" />}
                  onClick={() => BuyBookModalButton()}
                  className={classNames('outline', 'no-bg')}
                />
                <Button
                  label={<LanguageLabel id="modalCloseLabel" />}
                  onClick={() => setShowModal(false)}
                  className={classNames('outline', 'no-bg')}
                />
              </div>
            )}
          </div>
        </ModalOverlay>
      </div>
    </>
  );
};

Results.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  setIdCurrentTest: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.objectOf({
    user: PropTypes.shape({
      results: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
    }),
  }),
  questions: PropTypes.shape({
    activeQuestion: PropTypes.string,
    results: PropTypes.shape({
      QUESTION_USER: PropTypes.shape({
        gender: PropTypes.string,
        year: PropTypes.string,
        zipCode: PropTypes.string,
      }),
    }),
    calculatedResults: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }).isRequired,
  setFreshStart: PropTypes.func,
};

Results.defaultProps = {
  user: {},
  setFreshStart: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
