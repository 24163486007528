import { call, put } from 'redux-saga/effects';

import { SET_SELECTOR_BOX } from '../actions/selectorBoxActionTypes';

import { findSelectorBox } from '../connectors/contentful';

function* fetchSelectorBox() {
  const response = yield call(() => findSelectorBox());

  yield put({
    type: SET_SELECTOR_BOX,
    selectorBox: response.items,
  });
}

export default fetchSelectorBox;
