const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const clothesRecipe = linearScaling('clothes');
  const secondhandRecipe = linearScaling('secondhand');

  const linearRecipe = clothesRecipe + (secondhandRecipe || 0);
  let recipePoints = linearRecipe > 0 ? linearRecipe : 0;

  const allClothes = questionResults.clothes;
  const newClothes = questionResults.clothes - (questionResults.secondhand || 0);
  const wasFactor = (allClothes / average.clothes.value) * 6.33;
  recipePoints += wasFactor;

  // console.log('lineair + wasfactor', recipePoints);

  const woolPercentage = questionResults.recycledcottonvswool;
  let woolPoints = 0;
  let recycledCottonPoints = 0;
  if (woolPercentage) {
    const recycledCottonPercentage = 100 - woolPercentage;

    const recycledCottonFactor = recycledCottonPercentage / 100;
    const woolFactor = woolPercentage / 100;
    const woolRecipe = 37.19;
    const recycledCottonRecipe = 21.68;

    // console.log(recycledCottonFactor, woolFactor);

    woolPoints = (newClothes / average.clothes.value) * (woolRecipe * woolFactor);
    recycledCottonPoints = (newClothes / average.clothes.value) * (recycledCottonRecipe * recycledCottonFactor);

    recipePoints = wasFactor + woolPoints + recycledCottonPoints;
  }

  const recipe = recipePoints || 0;

  return {
    recipe,
    wasFactor,
    woolPoints,
    recycledCottonPoints,
  };
};

export default calculate;
