const mapStateToProps = (state) => {
  const { questions, tooltips, counterGroup } = state;
  return {
    results: questions.results,
    calculatedResults: questions.calculatedResults,
    activeQuestion: questions.activeQuestion,
    tooltips: tooltips.tooltips,
    counterGroup: counterGroup.counterGroup,
    freshStart: questions.boolean,
  };
};

export default mapStateToProps;
