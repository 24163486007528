import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

// import cardContent from '../../assets/data/Cards.json';

import Earth from '../../components/Earth';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import Water from '../../components/Water';
import Button from '../../components/Button';
import Table from '../../components/Table';
import Card from '../../components/Card';
import Item from '../../components/Item';
import PathLine from '../../components/PathLine';
import IconBackground from '../../components/IconsBackground';
import Intro from '../../components/Intro';
import Image from '../../components/Image';
import Quote from '../../components/Quote';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import icList from '../../assets/icons/ic-list.svg';
import icGoals from '../../assets/icons/ic-goals.svg';
import icLove from '../../assets/icons/ic-love.svg';
import afterIcon from '../../assets/icons/ic-dashed-arrow-down.svg';
import beforeIcon from '../../assets/icons/ic-dashed-arrow-up.svg';
import iceberg from '../../assets/icons/ic-iceberg.svg';

import paths from '../../routes/paths';
import { QUESTIONSHOME } from '../../constants/pages';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import './style.scss';
import { lang } from '../../utils/locale';

const Home = ({
  fetchTeamCards,
  teamCards,
  setQuestionResult,
  setFreshStart,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardClassNames = ['first', 'sec', 'third'];

  useEffect(() => {
    setQuestionResult(QUESTION_BATHING, { recipe: 0 }, false);
    setQuestionResult(QUESTION_CAR, { recipe: 0 }, false);
    setQuestionResult(QUESTION_CLOTHES, { recipe: 0 }, false);
    setQuestionResult(QUESTION_DAIRY, { recipe: 0 }, false);
    setQuestionResult(QUESTION_FLYING, { recipe: 0 }, false);
    setQuestionResult(QUESTION_FOOD, { recipe: 0 }, false);
    setQuestionResult(QUESTION_HOUSING, { recipe: 0 }, false);
    setQuestionResult(QUESTION_MEAT, { recipe: 0 }, false);
    setQuestionResult(QUESTION_POSSESSIONS, { recipe: 0 }, false);
    setQuestionResult(QUESTION_PUBLIC_TRANSPORT, { recipe: 0 }, false);
    setFreshStart(true);
  }, []);

  useEffect(() => {
    if (teamCards.length === 0 || lang) {
      fetchTeamCards(lang);
    }
  }, [fetchTeamCards, lang]);

  return (
    <>
      <VisibilitySensor onChange={(e) => setIsVisible(e)}>
        <div className={classNames('scrollTrigger')} />
      </VisibilitySensor>
      <Intro />
      <div className="homepageContainer">
        <div className="seaImpact sticky">
          {isVisible && <a href="#scrollTo">Ja er is nog veel meer</a>}
        </div>
        <div className="landing" id="scrollTo">
          <div className="block">
            <Heading className="primary" type="h1">
              <span className="topheading">
                <LanguageLabel id="homeLandingTitle" />
              </span>
              <span className="subheading"><LanguageLabel id="homeLandingSubTitle" /></span>
            </Heading>
            <Quote>
              <LanguageParagraph id="homeLandingQuote" />
            </Quote>
          </div>
        </div>
        <div className="seaImpact" />
        <div className="homepageItems">
          <section className="about">
            <div className="iceberg">
              <div className="iceberg-content">
                <Heading afterIcon={afterIcon} className="size">
                  <LanguageLabel id="homeAboutIcebergTitle" replace={<span className="small">21%</span>} />
                </Heading>
                <Image src={iceberg} name="iceberg" />
                <Heading beforeIcon={beforeIcon} className="light">
                  <LanguageLabel id="homeAboutIcebergTitle2" replace={<span className="big">79%</span>} />
                </Heading>
              </div>
            </div>
            <IconBackground />
            <div className="contentContainer">
              <Paragraph className="light">
                <LanguageParagraph id="homeAboutParagraph1" />
              </Paragraph>
              <Paragraph className="light">
                <LanguageParagraph id="homeAboutParagraph2" />
              </Paragraph>
              <div className="box">
                <Heading className="light" type="h1">
                  <LanguageLabel id="homeAboutTitle" />
                </Heading>
                <Paragraph className="light">
                  <LanguageParagraph id="homeAboutParagraph3" />
                </Paragraph>
                <Table>
                  <Item label={<LanguageLabel id="homeAboutListTitle" />} src={icList} />
                  <Item label={<LanguageLabel id="homeAboutGoalsTitle" />} src={icGoals} />
                  <Item label={<LanguageLabel id="homeAboutLoveTitle" />} src={icLove} />
                </Table>
                <Paragraph className="light">
                  <LanguageParagraph id="homeAboutParagraph4" />
                </Paragraph>
              </div>
              <Water className="below" />
            </div>
          </section>
          <section className="team">
            <div className="teamContainer">
              <Heading type="h1">
                <LanguageLabel id="homeTeamTitle" />
              </Heading>
              <PathLine>
                {teamCards.map((card, i) => (
                  <Card key={cardClassNames[i]} className={cardClassNames[i]} content={card.paragraph} name={card.name} job={card.job} />
                ))}
              </PathLine>
              <Heading className="primary" type="h1">
                <LanguageLabel id="homeTeamTitle2" />
              </Heading>
              <Paragraph className="primary">
                <LanguageParagraph id="homeTeamParagraph" />
              </Paragraph>
              <Earth className="background">
                <Heading className="light" type="h2">
                  <LanguageLabel id="homeTeamEarthTitle" />
                </Heading>
                <Button label={<LanguageLabel id="homeTeamEarthButton" />} to={`${paths[QUESTIONSHOME]}/0`} className="primary" />
              </Earth>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

Home.propTypes = {
  fetchTeamCards: PropTypes.func.isRequired,
  teamCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func,
  setFreshStart: PropTypes.func,
};

Home.defaultProps = {
  setQuestionResult: () => {},
  setFreshStart: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
