const mapStateToProps = (state) => {
  const { questions, user, actionCards } = state;
  return {
    questions,
    user: user.user,
    actionCards: actionCards.actionCards,
  };
};

export default mapStateToProps;
