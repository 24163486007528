/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuizResult = /* GraphQL */ `
  query GetQuizResult($id: ID!) {
    getQuizResult(id: $id) {
      id
      stringResult
      email
      newsLetter
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      dateCreated
      gender
      birthdate
      zipcode
      createdAt
      updatedAt
      idAmsterdam
      __typename
    }
  }
`;
export const listQuizResults = /* GraphQL */ `
  query ListQuizResults(
    $filter: ModelQuizResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stringResult
        email
        newsLetter
        bathing
        car
        clothes
        diary
        flying
        food
        housing
        meat
        possessions
        publictransport
        dateCreated
        gender
        birthdate
        zipcode
        createdAt
        updatedAt
        idAmsterdam
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      title
      description
      admins
      owner
      image
      users
      initialResults
      currentResults
      pendingRequests
      goal
      location
      secluded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        admins
        owner
        image
        users
        initialResults
        currentResults
        pendingRequests
        goal
        location
        secluded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      earthsGoal
      earthsResult
      title
      image
      createdOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bathing
        car
        clothes
        diary
        flying
        food
        housing
        meat
        possessions
        publictransport
        earthsGoal
        earthsResult
        title
        image
        createdOn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      contentfulId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentfulId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoID
      email
      name
      image
      goals
      groups
      results
      acceptedActions
      declinedActions
      newsLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        email
        name
        image
        goals
        groups
        results
        acceptedActions
        declinedActions
        newsLetter
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsLetter = /* GraphQL */ `
  query GetNewsLetter($id: ID!) {
    getNewsLetter(id: $id) {
      id
      yesOrNo
      emailRecipient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNewsLetters = /* GraphQL */ `
  query ListNewsLetters(
    $filter: ModelNewsLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsLetters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yesOrNo
        emailRecipient
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
