import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const SelectorBox = ({
  className,
  value,
  label,
  children,
  defaultValue,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  const update = () => {
    setSelected(!selected);
    value(selected);
  };

  return (
    <div className="selectorbox">
      <div
        onClick={() => update()}
        className={classNames('selectbox', className, selected && 'selectbox-active')}
      >
        <div className="icon">
          {children}
        </div>
        <span>{label}</span>
      </div>
    </div>
  );
};

SelectorBox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.bool,
};

SelectorBox.defaultProps = {
  className: '',
  defaultValue: false,
};

export default SelectorBox;
