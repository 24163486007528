import { linearScaling } from '../../utils/calculationUtil';
import { energyLabelMatrix, houseTypeMatrix, houseBouwMatrix } from './housingData';

const calculate = (questionResults, average) => {
  const {
    housetype,
    energylabel,
    duurzaam,
    people,
    house: m2,
  } = questionResults;

  // AVERAGE NL
  const recipeAvgNLbouw = 31.47;
  const duurzaamBouwFactor = 0.87;
  const avgNlZonderBouw = average.house.recipe - recipeAvgNLbouw;

  // M2 START
  const actualM2PerPerson = (m2 || 55) / (people || 1);
  const { value: m2AvgNL } = average.house;
  const recipePointsPerM2 = avgNlZonderBouw / m2AvgNL;

  const pointsBasedOnM2PerActualPersons = recipePointsPerM2 * actualM2PerPerson;

  let energyLabelHouseTypeFactor = 0;
  let pointsAfterEnergyHousetype = pointsBasedOnM2PerActualPersons;
  const housetypeFactors = energyLabelMatrix[housetype];
  energyLabelHouseTypeFactor = (100 + ((housetypeFactors && housetypeFactors[energylabel]) || 0)) / 100;
  pointsAfterEnergyHousetype = energyLabelHouseTypeFactor * pointsBasedOnM2PerActualPersons;
  const houseTypeFactor = (100 + houseTypeMatrix[housetype]) / 100;
  pointsAfterEnergyHousetype *= houseTypeFactor;
  const m2RecipePoints = pointsAfterEnergyHousetype;
  // console.log('m2RecipePoints', m2RecipePoints);
  // M2 END

  // BOUW START
  const recipeBouwPerM2 = recipeAvgNLbouw / m2AvgNL;
  const recipeBouwPerActualM2 = recipeBouwPerM2 * actualM2PerPerson;
  const bouwFactor = houseBouwMatrix[housetype];

  let bouwRecipePoints = recipeBouwPerActualM2 * bouwFactor;
  // console.log('bouwRecipePoints', bouwRecipePoints);
  // BOUW END

  // Recipe duurzaam
  if (duurzaam === 'na 1990') { // TODO: check contentful
    // m2RecipePoints *= duurzaamBouwFactor;
    bouwRecipePoints *= duurzaamBouwFactor;
  }

  let recipe = m2RecipePoints + bouwRecipePoints || 0;

  // Precieze berekening
  const housingMatrix = {
    heatpumpair: 0.73,
    heatpumpwater: 0.73,
    heatpumpground: 0.54,
    heatnetworkgethermia: 0.42,
    heatnetworkgas: 0.81,
    wood: 15.35,
    gas: 1,
    heating: 1,
  };

  if (questionResults.mode) {
    recipe = 0;
    let KWH = linearScaling(questionResults, average, 'KWH');
    let gas = linearScaling(questionResults, average, 'gas');
    if (isNaN(KWH)) KWH = 0;
    if (isNaN(gas)) gas = 0;


    if (questionResults.househeating) {
      gas *= housingMatrix[questionResults.househeating];
    }

    if (questionResults.electric || questionResults.electric === 'yes') {
      KWH *= 0.23;
    }

    gas /= (people || 1);
    KWH /= (people || 1);

    let bouw = (31.47 * (questionResults.house / 55)) / (people || 1);
    if (questionResults.building === 'standaard') {
      bouw *= 120;
    }
    recipe = KWH + gas + bouw;
  }

  return {
    pointsBasedOnM2PerActualPersons,
    energyLabelHouseTypeFactor,
    pointsAfterEnergyHousetype,
    recipe,
  };
};

export default calculate;
