import React from 'react';
import PropTypes from 'prop-types';

import icBird from '../../assets/icons/ic-bird.svg';

import './style.scss';

const PathLine = ({ children }) => (
  <div className="pathline">
    <span className="boll" />
    <img className="bird" src={icBird} alt="bird" />
    {children}
    <span className="boll" />
  </div>
);

PathLine.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PathLine;
