import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import { QUESTION_USER } from '../../constants/questions';

import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import QuestionButtons from '../../components/QuestionButtons';
import Button from '../../components/Button';
import GenderSelector from '../../components/GenderSelector';
import InputBox from '../../components/InputBox';
import QuestionSwiper from '../../components/QuestionSwiper';
import LanguageLabel from '../../components/LanguageLabel';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';

import { errorYear, errorZipCode } from '../../utils/errorHandling';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-user.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-user.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_1.svg';

import './style.scss';

const QuestionUser = ({
  setQuestionResult,
  openModalOverlay,
  mobileView,
  languageLabels,
}) => {
  const [gender, setGender] = useState('male');
  const [year, setYear] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [zipCodeCheck, setZipCodeCheck] = useState(true);
  const [yearCheck, setYearCheck] = useState(true);

  const [showErrors, setShowErrors] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);

  const questionId = QUESTION_USER;

  const submitClicked = () => {
    setQuestionResult(questionId, {
      gender,
      year,
      zipCode,
    });
  };

  if (/Android [4-6]/.test(navigator.appVersion)) {
    window.addEventListener('resize', () => {
      if (document.activeElement.tagName === 'INPUT') {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    });
  }

  const checkZipCode = (value) => {
    setZipCodeCheck(errorZipCode(value));
    setZipCode(value);
  };

  const checkYear = (value) => {
    setYearCheck(errorYear(value));
    setYear(value);
  };

  const updateFromSwiper = (category, value) => {
    window.scrollTo(0, 0);
    if (category === 'currentSlide') {
      setCurrentSlide(value);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSlide]);

  useEffect(() => {
    const privacyAccepted = window.localStorage.getItem('privacyAccepted') !== 'true';
    if (privacyAccepted) {
      openModalOverlay('privacy-modal');
    }
  }, []);

  const userQuestionBirthYearPlaceholder = languageLabels.filter((item) => item.id === 'userQuestionBirthYearPlaceholder');
  const userQuestionPostalCodePlaceholder = languageLabels.filter((item) => item.id === 'userQuestionPostalCodePlaceholder');

  return (
    <div className="question-user">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="userQuestionTitle" />
        </span>
      </Heading>
      <Paragraph className="paragraph-desktop">
        <LanguageLabel id="userQuestionDescription" />
      </Paragraph>
      {!mobileView ? (
        <>
          <div className="vertical-align-wrapper-flex">
            <span
              onClick={() => openModalOverlay('privacy-modal')}
              className="disclaimer-open"
            >
              <LanguageLabel id="userQuestionPrivacyButton" />
            </span>
            <div className="vertical-wrap-row">
              <InputBox
                type="year"
                description={<LanguageLabel id="userQuestionBirthdayTitle" />}
                onChange={(e) => checkYear(e.target.value)}
                value={year}
                error={showErrors && !yearCheck}
                placeholder={userQuestionBirthYearPlaceholder[0]?.label}
              />
              <InputBox
                type="adres"
                description={<LanguageLabel id="userQuestionPostalCodeTitle" />}
                onChange={(e) => checkZipCode(e.target.value)}
                value={zipCode}
                error={showErrors && !zipCodeCheck}
                placeholder={userQuestionPostalCodePlaceholder[0]?.label}
              />
            </div>
            <span className="gender-select"><LanguageLabel id="userQuestionGenderTitle" /></span>
            <div className="vertical-wrap-row">
              <GenderSelector sendGender={(val) => setGender(val)} />
            </div>
          </div>
          <div className="questionButton-box">
            {zipCodeCheck && yearCheck ? (
              <QuestionButtons
                questionId={questionId}
                nextClick={() => submitClicked()}
              />
            ) : (
              <QuestionButtons
                label={<LanguageLabel id="questionNextLabel" />}
                onClick={() => setShowErrors(true)}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <span
            onClick={() => openModalOverlay('privacy-modal')}
            className="disclaimer-mobile"
          >
            <LanguageLabel id="userQuestionPrivacyButton" />
          </span>
          <div className="vertical-align-wrapper-flex">
            <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={false}>
              <div className="vertical-align-sliders">
                <Paragraph className="paragraph-mobile">
                  <LanguageLabel id="userQuestionDescription" />
                </Paragraph>
                <InputBox
                  type="year"
                  description={<LanguageLabel id="userQuestionBirthdayTitle" />}
                  onChange={(e) => checkYear(e.target.value)}
                  value={year}
                  error={showErrors && !yearCheck}
                />
                <InputBox
                  type="adres"
                  description={<LanguageLabel id="userQuestionPostalCodeTitle" />}
                  onChange={(e) => checkZipCode(e.target.value)}
                  value={zipCode}
                  error={showErrors && !zipCodeCheck}
                />
              </div>
              <div className="vertical-align-sliders">
                <span className="gender-select">
                  <LanguageLabel id="userQuestionGenderTitle" />
                </span>
                <GenderSelector sendGender={(val) => setGender(val)} />
              </div>
            </QuestionSwiper>
          </div>
          <StepIndicatorMobile currentStep={currentSlide} steps={2} />
          <div className="questionButton-box">
            {currentSlide === 1 ? (
              <>
                <QuestionButtons
                  questionId={questionId}
                  nextClick={() => submitClicked()}
                  className="outline"
                />
                <Button
                  label={<LanguageLabel id="questionPreviousLabel" />}
                  onClick={() => setCurrentSlide(currentSlide - 1)}
                  className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                />
              </>
            ) : (
              zipCodeCheck && yearCheck ? (
                <>
                  <Button
                    label={<LanguageLabel id="questionNextLabel" />}
                    onClick={() => setCurrentSlide(currentSlide + 1)}
                    className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                  />
                  {currentSlide !== 0 && (
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  )}
                </>
              ) : (
                <>
                  <QuestionButtons
                    label={<LanguageLabel id="questionNextLabel" />}
                    nextClick={() => setShowErrors(true)}
                    className={classNames(
                      'outline-mobile',
                      'nextbottom',
                      'questionButtons',
                    )}
                  />
                  {currentSlide !== 0 && (
                  <Button
                    label={<LanguageLabel id="questionPreviousLabel" />}
                    onClick={() => setCurrentSlide(currentSlide - 1)}
                    className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                  />
                  )}
                </>
              )
            )}
          </div>
        </>
      )}
      {!mobileView ? (
        <>
          <div className="background-wrapper">
            <Background />
          </div>
          <div className="illustration-wrapper">
            <Illustration />
          </div>
        </>
      )
        : (
          <div className="illustration-wrapper">
            <IllustrationMobile />
          </div>
        )}
    </div>
  );
};

QuestionUser.propTypes = {
  setQuestionResult: PropTypes.func.isRequired,
  openModalOverlay: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  languageLabels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionUser);
