import { SET_QUESTION_RESULT, SET_FRESHSTART } from '../../store/actions/questionActionTypes';
import { FETCH_TEAM_CARDS } from '../../store/actions/teamCardActionTypes';

const mapDispatchToProps = (dispatch) => ({
  fetchTeamCards: (lang) => dispatch({
    type: FETCH_TEAM_CARDS,
    payload: { lang },
  }),
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
  setFreshStart: (boolean) => dispatch({
    type: SET_FRESHSTART,
    payload: {
      boolean,
    },
  }),
});

export default mapDispatchToProps;
