import {
  API,
  graphqlOperation,
} from 'aws-amplify';

import {
  getQuizResult as GetQuizResult,
} from '../graphql/queries';

import { awsDateToMS } from '../utils/dateFormat';
import { setUser } from './storehelper';

const getQuizResult = async (id) => {
  const result = await API.graphql(
    graphqlOperation(GetQuizResult, {
      id,
    }),
  );

  return result.data.getQuizResult;
};

const getUserGateway = async () => {
  let res;
  try {
    res = await API.get('footprintapi', '/user');
  } catch (error) {
    // console.log(error);
  }
  return res;
};

const getGroupUser = async (id) => {
  let res;
  try {
    res = await API.get('footprintapi', `/groupuser/${id}`);
  } catch (error) {
    // console.log(error);
  }
  return res;
};

const getUser = async () => {
  const user = await getUserGateway();
  // console.log(user);

  if (user && user.id) {
    // console.log('User found', user);
    // eslint-disable-next-line prefer-destructuring
    const quizResults = await Promise.all(user.results.map((id) => (
      getQuizResult(id)
    )));

    const notEmptyResult = quizResults.filter((item) => item !== null && item !== undefined);
    const sortedResults = notEmptyResult.sort((a, b) => awsDateToMS(b?.createdAt) - awsDateToMS(a?.createdAt));
    const sortedResultIds = sortedResults.map((result) => result?.id);
    const sortedResultsUser = { ...user, results: sortedResultIds, sortedResults };
    // console.log(sortedResultsUser);

    setUser(sortedResultsUser);
    return sortedResultsUser;
  }

  return user;
};

const getUserById = async (id) => {
  const user = await getGroupUser(id);
  // console.log(user);

  // if (user && user.id) {
  //   // console.log('User found', user);
  //   // eslint-disable-next-line prefer-destructuring
  //   // const quizResults = await Promise.all(user.results.map((id) => (
  //   //   getQuizResult(id)
  //   // )));

  //   // const sortedResults = quizResults.sort((a, b) => awsDateToMS(b.createdAt) - awsDateToMS(a.createdAt));
  //   // const sortedResultIds = sortedResults.map((result) => result.id);
  //   // const sortedResultsUser = { ...user, results: sortedResultIds, sortedResults };
  //   // // console.log(sortedResultsUser);

  //   return user;
  // }

  return user;
};

export default getUser;
export { getUserById };
