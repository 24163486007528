import { call, put } from 'redux-saga/effects';
import cache from 'memory-cache-pro';

import { SET_LANGUAGE_LABELS } from '../actions/languageActionTypes';

import { findLanguageLabels } from '../connectors/contentful';

function* fetchLanguageLabels({ payload }) {
  let items;
  if (!items) {
    const { lang } = payload;
    const response = yield call(() => findLanguageLabels(lang));
    items = response.items;
    cache.put('languageLabels', items);
  }

  yield put({
    type: SET_LANGUAGE_LABELS,
    languageLabels: items,
  });
}

export default fetchLanguageLabels;
