/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  API,
  Auth,
  graphqlOperation,
  Storage,
} from 'aws-amplify';

import {
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';

import {
  updateUser as UpdateUser,
  deleteUser as DeleteUser,
  createNewsLetter as CreateNewsLetter,
  deleteNewsLetter as DeleteNewsLetter,
} from '../../graphql/mutations';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import FormContainer from '../../components/FormContainer';
import ModalConfirm from '../../components/ModalConfirm';
import Form from '../../components/Form';
import TextInput from '../../components/Input/TextInput';
import PasswordInput from '../../components/Input/PasswordInput';
import Button from '../../components/Button';
import Heading from '../../components/Heading';
import CheckboxInput from '../../components/Input/CheckboxInput';
import ModalAvatar from '../../components/ModalAvatar';
import LanguageLabel from '../../components/LanguageLabel';

import { errorEmail, errorConfirmPassword, errorPassword } from '../../utils/errorHandling';

import defaultImg from '../../assets/images/default-profile-img.jpg';

import './style.scss';
import getUser from '../../lib/user';
import { lang } from '../../utils/locale';

const Profile = ({
  user,
  setUser,
  history,
  match,
}) => {
  let url = 'https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/';

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [newsLetter, setNewsLetter] = useState(false);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] = useState('');
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorOldPasswordMessage, setErrorOldPasswordMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showErrorFirst, setShowErrorFirst] = useState(false);
  const [showErrorSecond, setShowErrorSecond] = useState(false);
  const [showErrorThird, setShowErrorThird] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [errorGlobal, setErrorGlobal] = useState('');

  const [lastChangedImg, setLastChangeImg] = useState('');
  const [randomImgUrl, setRandomImgUrl] = useState('');
  const [uploadedImgUrl, setUploadedImgUrl] = useState('');
  const [fileData, setFileData] = useState('');

  const [tempProfilePicture, setTempProfilePictureProfile] = useState('');
  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';

  const handleEmail = (value) => {
    setEmail(value);
    setErrorEmailMessage(errorEmail(value, lang));
  };

  const handleOldPassword = (value) => {
    setOldPassword(value);
    setErrorOldPasswordMessage(errorPassword(value, lang));
    setErrorPasswordMessage(errorPassword(password, lang));
    setErrorConfirmPasswordMessage(errorConfirmPassword(confirmPassword, password, lang));
  };

  const handlePasswordConfirm = (value) => {
    setConfirmPassword(value);
    setErrorOldPasswordMessage(errorPassword(oldPassword, lang));
    setErrorPasswordMessage(errorPassword(password, lang));
    setErrorConfirmPasswordMessage(errorConfirmPassword(value, password, lang));
  };

  const handlePassword = (value) => {
    setPassword(value);
    setErrorOldPasswordMessage(errorPassword(oldPassword, lang));
    setErrorPasswordMessage(errorPassword(value, lang));
    setErrorConfirmPasswordMessage(errorConfirmPassword(confirmPassword, password, lang));
  };

  useEffect(() => {
    setErrorGlobal('');
    // setShowErrorFirst(false);
    // setShowErrorSecond(false);
    // setShowErrorThird(false);
  }, [email, name, password, oldPassword, confirmPassword]);

  useEffect(() => {
    const loadData = async () => {
      const resp = await Auth.currentUserInfo();
      if (resp !== null && !Object.values(user).includes('username')) {
        getUser(resp.username);
      } else {
        history.push('/login');
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
  }, [user]);

  useEffect(() => {
    if (user.id) {
      if (match.params.id !== user.id) {
        history.push(`/${lang}/dashboard`);
      }
    }
  }, [user]);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    let newsLetterID = '';
    if (newsLetter && !user.newsLetter) {
      try {
        const resp = await API.graphql(
          graphqlOperation(CreateNewsLetter, {
            input: {
              emailRecipient: user.email,
              yesOrNo: true,
            },
          }),
        );
        newsLetterID = resp.data.createNewsLetter.id;
      } catch (err) {
        setShowErrorFirst(true);
        setShowErrorSecond(true);
        setShowErrorThird(true);
      }
    }

    if (user.newsLetter && user.newsLetter.length > 0 && !newsLetter) {
      newsLetterID = '';
      await API.graphql(
        graphqlOperation(DeleteNewsLetter, {
          input: {
            id: user.newsLetter,
          },
        }),
      );
    }

    let updatedUser = {};

    if (email !== user.email) {
      if (!errorEmailMessage) {
        try {
          updatedUser = await API.graphql(
            graphqlOperation(UpdateUser, {
              input: {
                id: user.id,
                email,
              },
            }),
          );
        } catch (err) {
          setShowErrorFirst(true);
          setShowErrorSecond(true);
          setShowErrorThird(true);
        }
      }
    }
    if (name !== user.name) {
      try {
        updatedUser = await API.graphql(
          graphqlOperation(UpdateUser, {
            input: {
              id: user.id,
              name,
            },
          }),
        );
      } catch (err) {
        setShowErrorFirst(true);
        setShowErrorSecond(true);
        setShowErrorThird(true);
      }
    }
    try {
      updatedUser = await API.graphql(
        graphqlOperation(UpdateUser, {
          input: {
            id: user.id,
            newsLetter: newsLetterID,
          },
        }),
      );
    } catch (err) {
      setShowErrorFirst(true);
      setShowErrorSecond(true);
      setShowErrorThird(true);
    }
    if (oldPassword) {
      if (
        !errorPasswordMessage
        && !errorOldPasswordMessage
        && !errorConfirmPasswordMessage
      ) {
        try {
          await Auth.currentAuthenticatedUser()
            .then((user) => Auth.changePassword(user, oldPassword, confirmPassword));
        } catch (error) {
          if (error.code === 'NotAuthorizedException') {
            setErrorGlobal('Oude wachtwoord klopt niet');
          } else {
            setErrorGlobal(error.message);
          }
        }
      }
    }
    setUser(updatedUser.data.updateUser);
    history.push(`/${lang}/dashboard`);
    setShowErrorFirst(true);
    setShowErrorSecond(true);
    setShowErrorThird(true);
  };

  const resetImgs = () => {
    setRandomImgUrl('');
    setFileData('');
    setUploadedImgUrl('');
    setLastChangeImg('');
    document.body.classList.add('hide-scroll');
    setModal(!modal);
  };

  const handleImgState = (file) => {
    if (file === 'random') {
      url += `images/${Math.ceil(Math.random() * 8)}.jpg`;
      setRandomImgUrl(url);
    } else if (file) {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      url += `images/${user.id}.${extension}`;
      setUploadedImgUrl(url);
    }
    setFileData(file);
    setLastChangeImg(url);
  };

  const saveImg = async () => {
    let urlImg;
    if (lastChangedImg) {
      if (lastChangedImg === randomImgUrl) {
        urlImg = randomImgUrl;
        try {
          await API.graphql(
            graphqlOperation(UpdateUser, {
              input: {
                id: user.id,
                image: urlImg,
              },
            }),
          );
        } catch (err) {
          // console.log('error: ', err);
        }
        const resp = await Auth.currentUserInfo();
        if (resp !== null && !Object.values(user).includes('username')) {
          getUser(resp.username);
        } else {
          history.push('/login');
        }
      }
      if (lastChangedImg === uploadedImgUrl) {
        let extension = fileData.name.split('.');
        extension = extension[extension.length - 1];
        const key = `images/${user.id}.${extension}`;
        const { type: mimeType } = uploadedImgUrl;
        // try {
        await Storage.put(key, fileData, {
          contentType: mimeType,
        });
        urlImg = uploadedImgUrl;
        await API.graphql(
          graphqlOperation(UpdateUser, {
            input: {
              id: user.id,
              image: urlImg,
            },
          }),
        );
        const resp = await Auth.currentUserInfo();
        if (resp !== null && !Object.values(user).includes('username')) {
          getUser(resp.username);
        } else {
          history.push('/login');
        }
      }
    }
  };

  const deleteAccount = async () => {
    const PC = {
      UserPoolId: 'eu-west-1_GgEtcVuD1',
      ClientId: '67g5btcrjjh0ra64kvlgtktdd5',
    };

    const userPool = new CognitoUserPool(PC);
    const cognitoData = {
      Username: user.cognitoID,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(cognitoData);

    await Auth.currentSession().then((data) => {
      cognitoUser.setSignInUserSession(data);
    });

    cognitoUser.deleteUser(() => {});

    await API.graphql(
      graphqlOperation(DeleteUser, {
        input: {
          id: user.id,
        },
      }),
    );
    setUser({});
    history.push(`/${lang}/dashboard`);
  };

  const signOutUser = async () => {
    Auth.signOut();
    setUser({});
    history.push(`/${lang}/login`);
  };

  const checkEmail = () => {
    setShowError(true);
  };

  const checkPasswordFirst = () => {
    setShowErrorFirst(true);
  };

  const checkPasswordSecond = () => {
    setShowErrorSecond(true);
  };

  const checkPasswordThird = () => {
    setShowErrorThird(true);
  };

  const setTempProfilePicture = (image) => {
    setTempProfilePictureProfile(image);
  };

  return (
    <>
      <ModalAvatar
        onClick={() => resetImgs()}
        className="avatar-profile"
        modalSwitch={modal}
        profile={user.image || defaultImg}
        randomImg={randomImgUrl}
        saveProfileImage={handleImgState}
        error={imageError}
        saveImg={saveImg}
        setTempProfilePicture={setTempProfilePicture}
      />
      <div className="profile">
        <ModalConfirm modalSwitch={confirmModal} onClick={() => setConfirmModal(!confirmModal)} confirmAction={deleteAccount} />
        <FormContainer>
          <div className="formcontainer">
            <figure onClick={() => resetImgs()}>
              <span className="bg" />
              <img src={tempProfilePicture || randomImgUrl || user.image || defaultImg} alt="profile" />
              <span className="text"><LanguageLabel id="profileAvatarText" /></span>
            </figure>

            <Heading type="h1">
              {name}
            </Heading>
            <Form onSubmit={onFormSubmit}>
              {errorGlobal && (<span className="global-error">{errorGlobal}</span>)}
              <TextInput
                className=""
                id="Email"
                type="e mail"
                error={showError}
                errorMessage={errorEmailMessage}
                label={<LanguageLabel id="profileInputEmailTitle" />}
                onChange={(e) => handleEmail(e.target.value)}
                placeholder={lang === 'nl' ? 'Vul je email adres in' : 'Enter your email address'}
                value={email}
                onBlur={checkEmail}
              />
              <TextInput
                className=""
                id="name"
                label={<LanguageLabel id="profileInputNameTitle" />}
                onChange={(e) => setName(e.target.value)}
                placeholder={lang === 'nl' ? 'Vul je naam in' : 'Fill in your name'}
                value={name}
              />
              <strong><LanguageLabel id="profileChangePasswordTitle" /></strong>
              <PasswordInput
                className=""
                errorGlobal={errorGlobal}
                id="password-old"
                error={showErrorFirst}
                errorMessage={errorOldPasswordMessage}
                label={<LanguageLabel id="profileInputOldPasswordTitle" />}
                onChange={(e) => handleOldPassword(e.target.value)}
                placeholder={lang === 'nl' ? 'Gebruik een hoofdletter en 2 tekens' : 'Use a capital letter and 2 characters'}
                value={oldPassword}
                noInput
                onBlur={checkPasswordFirst}
              />
              <PasswordInput
                className=""
                id="password-new"
                error={showErrorSecond}
                errorMessage={errorPasswordMessage}
                label={<LanguageLabel id="profileInputNewPasswordTitle" />}
                onChange={(e) => handlePassword(e.target.value)}
                placeholder={lang === 'nl' ? 'Gebruik een hoofdletter en 2 tekens' : 'Use a capital letter and 2 characters'}
                value={password}
                onBlur={checkPasswordSecond}
              />
              <PasswordInput
                className=""
                id="password-new-confirm"
                error={showErrorThird}
                errorMessage={errorConfirmPasswordMessage}
                label={<LanguageLabel id="profileInputConfirmPasswordTitle" />}
                onChange={(e) => handlePasswordConfirm(e.target.value)}
                placeholder={lang === 'nl' ? 'Gebruik een hoofdletter en 2 tekens' : 'Use a capital letter and 2 characters'}
                value={confirmPassword}
                onBlur={checkPasswordThird}
              />
              <CheckboxInput
                id="newsletter"
                label={<LanguageLabel id="profileNewsLetterTitle" />}
                onChange={(e) => setNewsLetter(e.target.checked)}
                checked={(user.newsLetter && user.newsLetter.length > 1)}
              />
              <div className="button-wrap">
                <Button
                  type="submit"
                  label={<LanguageLabel id="profileSaveButton" />}
                  className="rounded secondary align-right"
                  onClick={(e) => onFormSubmit(e)}
                />
                <Button
                  onClick={() => signOutUser()}
                  label={<LanguageLabel id="profileLogoutButton" />}
                  className="rounded secondary align-left"
                />
              </div>
            </Form>
            <div className="hyperlink" onClick={() => setConfirmModal(!confirmModal)}><LanguageLabel id="profileRemoveAccountButton" /></div>
          </div>
        </FormContainer>
      </div>
    </>
  );
};

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  setUser: PropTypes.func,
  user: PropTypes.shape({
    user: PropTypes.string,
    image: PropTypes.string,
    newsLetter: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    cognitoID: PropTypes.string,
  }),
};

Profile.defaultProps = {
  user: {},
  history: {},
  setUser: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
