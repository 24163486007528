import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import storeConfig from './store/storeConfig';
import App from './App';

import './styles/index.scss';

import config from './aws-exports';
import { cookiesAccepted, isLocalhost, isTestEnvironment } from './utils/environmentutil';

Amplify.configure(config);

if (cookiesAccepted && !isLocalhost && !isTestEnvironment) {
  ReactGA.initialize('UA-165292634-1');
} else {
  // console.log('Not loading analytics', cookiesAccepted, isLocalhost, isTestEnvironment);
}

ReactDOM.render((
  <Provider store={storeConfig}>
    <App />
  </Provider>
), document.getElementById('root'));
