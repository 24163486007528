const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const distance = linearScaling('distance') || 0;
  const cars = linearScaling('cars') || 0;

  let recipe = (distance + cars) || 0;

  if (questionResults.length > 0) {
    let maintenanceValue; let productionValue; let
      drivingValue = 0;

    const classMapper = (value) => {
      let newValue = 'B klasse';
      if (value === '400-900 kg') newValue = 'A klasse';
      if (value === '901-1150 kg') newValue = 'B klasse';
      if (value === '1151-1400 kg') newValue = 'C klasse';
      if (value === '1400-2500 kg') newValue = 'D klasse';
      if (value === '2500+ kg') newValue = 'D klasse';
      return newValue;
    };

    // dit is voor precies berekening
    // console.log(questionResults);
    let recipeTotal = 0;
    questionResults.forEach((result) => {
      const decimalCarLifters = result.carLifters / 10;

      if (decimalCarLifters
        || result.carYear
        || result['distance-specific']) {
        if (decimalCarLifters > 1) {
          recipe /= decimalCarLifters;
        }

        // const maintenanceMatrix = {
        //   diesel: 0.0025703159,
        //   benzine: 0.0027216986,
        //   lpg: 0.0027216986,
        //   elektrisch: 0.0018131691,
        // };

        if (result.carFuel && result['distance-specific']) {
          const percentageCar = 1 / result.carOwner;
          maintenanceValue = 14.9 * (percentageCar / 0.48) * (result['distance-specific'] / 7700);

          if (result.carMade === 'money') {
            maintenanceValue *= 15;
          }
        } else {
          const percentageCar = 1 / (result.carOwner || 2);
          maintenanceValue = 14.9 * (percentageCar / 0.48) * ((result['distance-specific'] || 7700) / 7700);
        }

        const productionMatrix = {
          diesel:
      {
        'A klasse': 28.2823966883,
        'B klasse': 34.8758388610,
        'C klasse': 45.8764765913,
        'D klasse': 51.0124210206,
      },
          benzine:
      {
        'A klasse': 28.1091636451,
        'B klasse': 34.6622202004,
        'C klasse': 45.5954777163,
        'D klasse': 50.6999638751,
      },
          lpg:
      {
        'A klasse': 28.1091636451,
        'B klasse': 34.6622202004,
        'C klasse': 45.5954777163,
        'D klasse': 50.6999638751,
      },
          elektriciteit:
      {
        'A klasse': 35.6271902022,
        'B klasse': 43.9329155255,
        'C klasse': 57.7903625121,
        'D klasse': 64.2600853955,
      },
        };

        const carYearMatrix = [
          1.88,
          1.75,
          1.63,
          1.5,
          1.38,
          1.25,
          1.13,
          1,
          0.88,
          0.75,
          0.63,
          0.5,
          0.38,
          0.25,
          0.13,
          0,
        ];

        const multiplier = carYearMatrix[(result.carYear - 1) || 8];

        if (result.carFuel && result.carClass) {
          const percentageCar = 1 / (result.carOwner || 2);
          productionValue = (productionMatrix[result.carFuel][classMapper(result.carClass)] * multiplier) * (percentageCar / 0.48);
          if (result.carMade === 'money') {
            productionValue *= 15;
          }
        } else {
          const percentageCar = 1 / (result.carOwner || 2);
          productionValue = (productionMatrix.benzine['C klasse'] * multiplier) * (percentageCar / 0.48);
        }

        const drivingMatrix = {
          diesel:
      {
        'A klasse': 0.017658019,
        'B klasse': 0.017658019,
        'C klasse': 0.018358154,
        'D klasse': 0.019758425,
      },
          benzine:
      {
        'A klasse': 0.01681248,
        'B klasse': 0.019115854,
        'C klasse': 0.021209831,
        'D klasse': 0.023722604,
      },
          lpg:
      {
        'A klasse': 0.017237652,
        'B klasse': 0.017422907,
        'C klasse': 0.019090204,
        'D klasse': 0.022239543,
      },
          elektriciteit:
      {
        'A klasse': 0.011305422,
        'B klasse': 0.011305422,
        'C klasse': 0.012288502,
        'D klasse': 0.011182537,
      },
        };

        if (result.carFuel && result.carClass && result['distance-specific']) {
          drivingValue = (drivingMatrix[result.carFuel][classMapper(result.carClass)] * result['distance-specific']) / (decimalCarLifters || 1.4);
        }
        if (result.carFuel && result['distance-specific']) {
          drivingValue = (drivingMatrix[result.carFuel]['C klasse'] * result['distance-specific']) / (decimalCarLifters || 1.4);
        } else {
          drivingValue = (0.021209831 * 7700) / (decimalCarLifters || 1.4);
        }

        recipe = maintenanceValue + productionValue + drivingValue;
        recipeTotal += recipe;
      } else {
        recipe = 0;
      }
      recipe = recipeTotal;
    });
  }

  return {
    distance,
    cars,
    recipe,
  };
};

export default calculate;
