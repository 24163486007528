import { SET_SELECTOR_BOX } from '../actions/selectorBoxActionTypes';

import { getSelectorBox } from '../transformers/selectorBoxTransformer';

const initialState = {
  selectorBox: [],
};

const selectorBox = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTOR_BOX: {
      const selectorBox = getSelectorBox(action.selectorBox);

      return {
        ...state,
        selectorBox,
      };
    }
    default:
      return state;
  }
};

export default selectorBox;
