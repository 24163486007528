import { call, put } from 'redux-saga/effects';

import { SET_TEAM_CARDS } from '../actions/teamCardActionTypes';

import { findTeamCards } from '../connectors/contentful';

function* fetchTeamCards({ payload }) {
  const { lang } = payload;
  const response = yield call(() => findTeamCards(lang));

  yield put({
    type: SET_TEAM_CARDS,
    teamCards: response.items,
  });
}

export default fetchTeamCards;
