/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateQuizResult = /* GraphQL */ `
  mutation UpdateQuizResult(
    $input: UpdateQuizResultInput!
    $condition: ModelQuizResultConditionInput
  ) {
    updateQuizResult(input: $input, condition: $condition) {
      id
      stringResult
      email
      newsLetter
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      dateCreated
      gender
      birthdate
      zipcode
      createdAt
      updatedAt
      idAmsterdam
      __typename
    }
  }
`;
export const deleteQuizResult = /* GraphQL */ `
  mutation DeleteQuizResult(
    $input: DeleteQuizResultInput!
    $condition: ModelQuizResultConditionInput
  ) {
    deleteQuizResult(input: $input, condition: $condition) {
      id
      stringResult
      email
      newsLetter
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      dateCreated
      gender
      birthdate
      zipcode
      createdAt
      updatedAt
      idAmsterdam
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      title
      description
      admins
      owner
      image
      users
      initialResults
      currentResults
      pendingRequests
      goal
      location
      secluded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      title
      description
      admins
      owner
      image
      users
      initialResults
      currentResults
      pendingRequests
      goal
      location
      secluded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      title
      description
      admins
      owner
      image
      users
      initialResults
      currentResults
      pendingRequests
      goal
      location
      secluded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      earthsGoal
      earthsResult
      title
      image
      createdOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      earthsGoal
      earthsResult
      title
      image
      createdOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      earthsGoal
      earthsResult
      title
      image
      createdOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      contentfulId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      contentfulId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      contentfulId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoID
      email
      name
      image
      goals
      groups
      results
      acceptedActions
      declinedActions
      newsLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoID
      email
      name
      image
      goals
      groups
      results
      acceptedActions
      declinedActions
      newsLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNewsLetter = /* GraphQL */ `
  mutation CreateNewsLetter(
    $input: CreateNewsLetterInput!
    $condition: ModelNewsLetterConditionInput
  ) {
    createNewsLetter(input: $input, condition: $condition) {
      id
      yesOrNo
      emailRecipient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewsLetter = /* GraphQL */ `
  mutation UpdateNewsLetter(
    $input: UpdateNewsLetterInput!
    $condition: ModelNewsLetterConditionInput
  ) {
    updateNewsLetter(input: $input, condition: $condition) {
      id
      yesOrNo
      emailRecipient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewsLetter = /* GraphQL */ `
  mutation DeleteNewsLetter(
    $input: DeleteNewsLetterInput!
    $condition: ModelNewsLetterConditionInput
  ) {
    deleteNewsLetter(input: $input, condition: $condition) {
      id
      yesOrNo
      emailRecipient
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createQuizResult = /* GraphQL */ `
  mutation CreateQuizResult(
    $input: CreateQuizResultInput!
    $condition: ModelQuizResultConditionInput
  ) {
    createQuizResult(input: $input, condition: $condition) {
      id
      stringResult
      email
      newsLetter
      bathing
      car
      clothes
      diary
      flying
      food
      housing
      meat
      possessions
      publictransport
      dateCreated
      gender
      birthdate
      zipcode
      createdAt
      updatedAt
      idAmsterdam
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoID
      email
      name
      image
      goals
      groups
      results
      acceptedActions
      declinedActions
      newsLetter
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
