import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const StepIndicatorQuestionsMobile = ({
  activeQuestion,
}) => {
  const [activeArray] = useState([]);

  useEffect(() => {
    if (activeArray.indexOf(activeQuestion) === -1) {
      activeArray.push(activeQuestion);
    }
  }, [activeQuestion]);

  return (
    <div className="stepIndicatorQuestionsMobile">
      {Array.from(Array(activeQuestion), (_, i) => (
        <div
          key={i}
          className={classNames('active-question', 'positive', activeQuestion === 10 && 'remove-radius')}
        />
      ))}
    </div>
  );
};

StepIndicatorQuestionsMobile.propTypes = {
  activeQuestion: PropTypes.number,
};

StepIndicatorQuestionsMobile.defaultProps = {
  activeQuestion: 0,
};

export default StepIndicatorQuestionsMobile;
