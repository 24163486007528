/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

const errorPassword = (value, lang) => {
  const regexAll = /^(?=.{3,})(?=.*[A-Z])(?=.*[a-z])(.*[\W?0-9]){2,}/;
  const regexUppercase = /^(?=.*[A-Z])/;
  const regexSymbel = /^(.*[\W?0-9]){1,}/;
  const regexSymbel1 = /^(.*[\W?0-9]){2,}/;
  const regexSymbelUpper = /^(?=.*[A-Z])(.*[\W?0-9]){1,}/;
  const isDutchLang = lang === 'nl';

  if (value.length < 1) {
    return isDutchLang ? 'Je wachtwoord kan niet leeg zijn' : 'Your password cannot be empty';
  }

  if (!regexSymbel1.test(value)) {
    if (!regexSymbel.test(value)) {
      return isDutchLang ? 'Je mist 2 symbolen of cijfers' : 'You are missing 2 symbols or numbers';
    }
    if (!regexSymbelUpper.test(value)) {
      return isDutchLang ? 'Je mist 1 symbool of cijfer en een hoofdletter' : 'You are missing 1 symbol or number and a capital letter';
    }
    return isDutchLang ? 'Je mist 1 symbool of cijfer' : 'You are missing 1 symbol or number';
  }

  if (!regexUppercase.test(value)) {
    return isDutchLang ? 'Je mist een hoofdletter' : 'You are missing a capital letter';
  }

  if (!regexAll.test(value)) {
    return isDutchLang ? 'Je mist een hoofdletter en 2 symbolen of cijfers' : 'You are missing a capital letter and 2 symbols or numbers';
  }
  return '';
};

const errorConfirmPassword = (value, password, lang) => {
  if (password === value) {
    return '';
  }
  return lang === 'nl' ? 'Wachtwoord komt niet overeen' : 'Password does not match';
};

const errorEmail = (value, lang) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) {
    return '';
  }
  return lang === 'nl' ? 'Geen valide e-mailadres' : 'Not a valid email address';
};

const errorZipCode = (value) => {
  const regex = /^[1-9][0-9]{3} ?[A-Za-z]{2}$/;
  if (value.length <= 0) {
    return true;
  }
  if (regex.test(value)) {
    return true;
  }
  return false;
};

const errorYear = (value) => {
  const year = new Date().getFullYear();
  if (value.length <= 0) {
    return true;
  }
  if (year > value && value > 1900) {
    return true;
  }
  return false;
};

export {
  errorEmail,
  errorConfirmPassword,
  errorPassword,
  errorYear,
  errorZipCode,
};
