import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RadioButton, RadioGroup } from '@trendmicro/react-radio';
import '@trendmicro/react-radio/dist/react-radio.css';

import './style.scss';

const RadioButtonGroup = ({
  className,
  onChange,
  options,
  name,
  defaultValue,
  customValue,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = ((event) => {
    const { value } = event.target;
    setValue(value);
    onChange(value);
  });

  return (
    <div className={classNames('radiogroup', className)}>
      <RadioGroup
        name={name}
        value={customValue ? customValue || defaultValue : value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <RadioButton key={option.value} value={option.value}>{option.label}</RadioButton>
        ))}
      </RadioGroup>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  customValue: PropTypes.objectOf(PropTypes.string),
};

RadioButtonGroup.defaultProps = {
  className: '',
  customValue: null,
  onChange: () => {},
};

export default RadioButtonGroup;
