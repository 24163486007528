const mapStateToProps = (state) => {
  const {
    user, actionCards, actionTips, questions,
  } = state;
  return {
    user: user.user,
    actionCards: actionCards.actionCards,
    actionTips: actionTips.actionTips,
    questions,
  };
};

export default mapStateToProps;
