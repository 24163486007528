const FETCH_DROPDOWN = 'FETCH_DROPDOWN';
const SET_DROPDOWN = 'SET_DROPDOWN';

const FETCH_DROPDOWN_OPTION = 'FETCH_DROPDOWN_OPTION';
const SET_DROPDOWN_OPTION = 'SET_DROPDOWN_OPTION';

export {
  FETCH_DROPDOWN,
  SET_DROPDOWN,
  FETCH_DROPDOWN_OPTION,
  SET_DROPDOWN_OPTION,
};
