import React from 'react';
import classNames from 'classnames';

import Anchor from '../Anchor';
import Water from '../Water';

import { socialArr } from '../../assets/data/Footer.json';
import logo from '../../assets/icons/mijn-verborgen-impact-logo.svg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer className="footer" data-update-check="donatello">
    <Water className="footer" />
    <div className="content">
      <figcaption>
        <img src={logo} alt="Logo" />
      </figcaption>
      <h3>Contact</h3>
      <ul className="social-layout">
        {socialArr.map((item) => (
          <li key={item.label}><Anchor link={item.link} className={classNames('social', item.label)} /></li>
        ))}
      </ul>
      <p><LanguageLabel id="footerText" replace={currentYear} /></p>
    </div>
  </footer>
);

export default Footer;
