import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Plus } from '../../assets/icons/ic-plus.svg';
import { ReactComponent as Min } from '../../assets/icons/ic-min.svg';


import './style.scss';

const Counter = ({
  counterValue,
  content,
  max,
  count,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);

  const changeToInt = (data) => {
    const { value } = data.target;
    if (value === '') {
      setValue(0);
      return;
    }
    if (value >= max) {
      setValue(max);
      return;
    }
    setValue(value);
  };

  const changeMinValue = () => {
    const checkValue = value - count;
    if (checkValue <= 0) {
      setValue(0);
      return;
    }
    setValue(checkValue);
  };

  const changePlusValue = () => {
    const checkValue = value + count;
    if (checkValue >= max) {
      setValue(max);
      return;
    }
    setValue(checkValue);
  };

  useEffect(() => {
    counterValue(value);
  }, [value]);

  return (
    <div className="counter">
      <div className="counter-box">
        <button className="min" type="button" onClick={() => changeMinValue()}>
          <Min />
        </button>
        <input type="number" onChange={(e) => changeToInt(e)} value={value} disabled />
        <button className="plus" type="button" onClick={() => changePlusValue()}>
          <Plus />
        </button>
      </div>
      <span>
        {content}
      </span>
    </div>
  );
};

Counter.propTypes = {
  counterValue: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  defaultValue: PropTypes.number,
};

Counter.defaultProps = {
  defaultValue: 0,
};

export default Counter;
