import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RCSlider from 'rc-slider';

import './style.scss';

const { Handle } = RCSlider;

const ResultBar = ({
  benchmarkHeight,
  resultHeight,
  goalHeight,
  resultPercentage,
  iconClass,
  description,
  goalPercentage,
  edit,
  editValue,
  id,
  modal,
  handelOnClick,
  maxValue,
  maxGoalValue,
  editShow,
}) => {
  const [goalsValue, setGoalsValue] = useState(0.0);
  const [value, setValue] = useState(maxGoalValue || maxValue);
  const [hoverState, setHoverState] = useState(0.0);
  const [location, setLocation] = useState(0.0);
  const [mouseHover, setMouseHover] = useState(false);

  useEffect(() => {
    setGoalsValue(goalHeight > 100 ? 100 : goalHeight);
  }, [goalHeight]);

  const onChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    editValue({ [id]: value });
  }, [value]);

  const onMouseEnter = () => {
    setMouseHover(true);
    if (edit) {
      setHoverState(location);
    }
    if (goalHeight < 11) {
      setLocation(5);
    } else {
      setLocation(-10);
    }
  };

  useEffect(() => {
    if (mouseHover && edit) {
      if (goalHeight < 11) {
        setLocation(5);
      } else {
        setLocation(-10);
      }
      onMouseEnter();
    }
  }, [value]);

  const x = benchmarkHeight;
  const y = resultHeight;
  let modalBenchmarkHeight = 0;

  let switchResultHeight = resultHeight;

  if (modal) {
    switchResultHeight = y >= x ? 100 : y;
    modalBenchmarkHeight = x >= y ? 100 : x;
    if (x > y) {
      switchResultHeight = (x !== modalBenchmarkHeight) ? ((switchResultHeight / x) * 100) : switchResultHeight;
    }
    if (y > x) {
      modalBenchmarkHeight = (y !== switchResultHeight) ? ((modalBenchmarkHeight / y) * 100) : modalBenchmarkHeight;
    }
  }

  const handle = (props) => {
    const {
      value, dragging, index, ...restProps
    } = props;

    return (
      <Handle value={maxGoalValue || maxValue} {...restProps}>
        <div className="bar-handle" />
        <div className={classNames(
          'percentage-edit',
          (goalsValue + 2) < switchResultHeight && 'white-color',
          goalsValue < 10 && 'white-color-revers',
          switchResultHeight < 10 && 'red-bol',
          hoverState && 'hover-state',
          hoverState && goalsValue < 10 && 'hover-state-revers',

          switchResultHeight >= goalHeight - 1
              && goalsValue + 1 >= switchResultHeight
              && 'hide-color',
        )}
        >
          {`${Math.floor(goalPercentage)}% `}
        </div>
        <div className={classNames('percentage-modal')}>
          {`${Math.floor(goalPercentage)}%`}
        </div>
      </Handle>
    );
  };

  handle.propTypes = {
    dragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  };

  return (
    <div
      className={classNames('resultBar', edit && 'edit')}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => setHoverState(0)}
      onClick={() => handelOnClick()}
    >
      <div className="benchmark" style={{ height: `${modal ? modalBenchmarkHeight : benchmarkHeight}%` }} />
      <div
        className={classNames(
          'result',
          edit && goalsValue !== switchResultHeight && switchResultHeight < 10 && 'light-bg',
        )}
        style={{ height: `${switchResultHeight === 100 ? switchResultHeight - 1 : switchResultHeight}%` }}
      />
      {!edit && (
      <div
        className={classNames('bar-goal')}
        style={{ height: `${goalsValue}%` }}
      />
      )}
      {(edit && resultPercentage !== 0) && (
        <RCSlider
          max={switchResultHeight < goalHeight ? maxGoalValue : maxValue}
          className={
            classNames(
              'bar-slider',
              switchResultHeight >= goalHeight - 1
              && goalsValue + 1 >= switchResultHeight
              && 'hide-color',
            )
          }
          style={{ height: `${switchResultHeight < goalHeight ? goalsValue : switchResultHeight}%` }}
          vertical
          handle={handle}
          defaultValue={maxGoalValue || maxValue}
          value={maxGoalValue}
          onChange={onChange}
        />
      )}
      {editShow ? (
        <div
          className={classNames(
            'percentage',
            switchResultHeight >= (goalsValue + 2) && 'white-color',
            switchResultHeight < 10 && 'white-color-revers',

          )}
          style={
            !modal ? (
              hoverState !== 0 ? {
                bottom: `${goalsValue + hoverState}%`, color: '#D50283',
              } : {
                bottom: `${goalsValue + hoverState}%`,
              }
            ) : (
              { bottom: `${goalsValue}%` }
            )
          }
        >
          {`${Math.floor(goalPercentage)}%`}
        </div>
      ) : (
        !edit && (
        <div
          className={classNames(
            'percentage',
            benchmarkHeight === (switchResultHeight - 5) && 'inverted',
          )}
          style={{ bottom: `${switchResultHeight}%` }}
        >
          {`${Math.floor(resultPercentage)}%`}
        </div>
        )
      )}
      <div className={classNames('description', iconClass)}>
        <span>{description}</span>
      </div>
    </div>
  );
};

ResultBar.propTypes = {
  benchmarkHeight: PropTypes.number,
  iconClass: PropTypes.string,
  description: PropTypes.string,
  resultHeight: PropTypes.number,
  resultPercentage: PropTypes.number,
  goalHeight: PropTypes.number,
  goalPercentage: PropTypes.number,
  edit: PropTypes.bool,
  editShow: PropTypes.bool,
  editValue: PropTypes.func,
  id: PropTypes.string,
  modal: PropTypes.bool,
  handelOnClick: PropTypes.func,
  maxValue: PropTypes.number,
  maxGoalValue: PropTypes.number,
};

ResultBar.defaultProps = {
  benchmarkHeight: 75,
  iconClass: '',
  description: '',
  resultHeight: 50,
  resultPercentage: 66,
  goalHeight: 50,
  goalPercentage: 66,
  edit: false,
  editShow: false,
  editValue: null,
  id: '',
  modal: false,
  handelOnClick: () => {},
  maxValue: 0,
  maxGoalValue: 0,
};

export default ResultBar;
