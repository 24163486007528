import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import './style.scss';

const QuestionSwiper = ({
  children, updateParent, index,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateSlider = (i) => {
    updateParent('currentSlide', Math.floor(i));
    setCurrentSlide(Math.floor(i));
  };

  useEffect(() => {
    setCurrentSlide(index, currentSlide);
  }, [index]);

  return (
    <div className="questionswiper">
      <SwipeableViews
        index={index}
        onSwitching={(value) => updateSlider(value)}
        disabled
      >
        {children}
      </SwipeableViews>
    </div>
  );
};

QuestionSwiper.propTypes = {
  children: PropTypes.node,
  updateParent: PropTypes.func,
  index: PropTypes.number,
};

QuestionSwiper.defaultProps = {
  children: null,
  updateParent: null,
  index: 0,
};

export default QuestionSwiper;
