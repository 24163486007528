const getLanguageLabels = (languageLabels) => languageLabels.map((languageLabel) => ({
  id: languageLabel.fields.id,
  label: languageLabel.fields.label,
}));

const getLanguageParagraphs = (languageParagraphs) => languageParagraphs.map((languageParagraph) => ({
  id: languageParagraph.fields.id,
  paragraph: languageParagraph.fields.paragraph,
}));

export { getLanguageLabels, getLanguageParagraphs };
