import { call, put } from 'redux-saga/effects';

import { SET_MODAL_EXPLAINING } from '../actions/modalExplainingActionTypes';
import { findModalExplaining } from '../connectors/contentful';

function* fetchModalExplaining() {
  const response = yield call(() => findModalExplaining());

  yield put({
    type: SET_MODAL_EXPLAINING,
    modalExplaining: response.items,
  });
}

export default fetchModalExplaining;
