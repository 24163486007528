import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Heading from '../Heading';
import Button from '../Button';
import Paragraph from '../Paragraph';

import { ReactComponent as Plus } from '../../assets/icons/ic-plus.svg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const PodiumBlock = ({
  title,
  addPath,
  Path,
  list,
  rank,
  noRankParagraph,
}) => (
  <div className="podiumblock">
    {rank.length > 0 ? (
      <Heading type="h1">
        {title}
      </Heading>
    ) : (
      <Heading type="h1">
        <LanguageLabel id="podiumNoResultsYet" />
      </Heading>
    )}
    <div className="button-box">
      <Button label={<Plus />} to={addPath} className="add" />
    </div>
    {rank.length <= 0 && (
      <Paragraph className="result-paragraph">
        {noRankParagraph}
      </Paragraph>
    )}
    <div className="result-box">
      <div className="bg-bg" />
      <div className="bg-treebck" />
      <div className="bg-podium">
        <span className="place-number-1">1</span>
        <span className="place-number-2">2</span>
        <span className="place-number-3">3</span>
        {list.slice(0, 3).map((item, key) => (
          <div key={uuidv4()} className="place-box"><span className="place">{list[rank[key]]}</span></div>
        ))}
      </div>
    </div>
    <div className="button-container">
      {rank.length > 0 ? (
        <Button label={<LanguageLabel id="podiumCheckResults" />} to={Path} className="rounded highlight" />
      ) : (
        <Button label={<LanguageLabel id="podiumTakeTheTest" />} to={addPath} className="rounded highlight" />
      )}
    </div>
  </div>
);

PodiumBlock.propTypes = {
  title: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])).isRequired,
  addPath: PropTypes.string.isRequired,
  Path: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  rank: PropTypes.arrayOf(PropTypes.number),
  noRankParagraph: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])).isRequired,
};

PodiumBlock.defaultProps = {
  rank: [],
};
export default PodiumBlock;
