import { FETCH_LANGUAGE_PARAGRAPH } from '../../store/actions/languageActionTypes';

const mapDispatchToProps = (dispatch) => ({
  fetchLanguageParagraphs: (lang) => dispatch({
    type: FETCH_LANGUAGE_PARAGRAPH,
    payload: { lang },
  }),
});

export default mapDispatchToProps;
