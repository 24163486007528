import { SET_USER } from '../../store/actions/userActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch({
    type: SET_USER,
    payload: {
      user,
    },
  }),
});

export default mapDispatchToProps;
