import { SET_QUESTION_RESULT } from '../../store/actions/questionActionTypes';
import { FETCH_TOOLTIPS } from '../../store/actions/tooltipTypes';

const mapDispatchToProps = (dispatch) => ({
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
  fetchTooltips: () => dispatch({
    type: FETCH_TOOLTIPS,
    payload: {},
  }),
});

export default mapDispatchToProps;
