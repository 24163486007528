import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  graphqlOperation, API, Storage,
} from 'aws-amplify';

import {
  getGroup as GetGroup,
  getUser as GetUser,
  getQuizResult as GetQuizResult,
  getGoal as GetGoal,
} from '../../../graphql/queries';

import {
  updateGroup as UpdateGroup,
  updateUser as UpdateUser,
  updateGoal as UpdateGoal,
  createGoal as CreateGoal,
  deleteGroup as DeleteGroup,
} from '../../../graphql/mutations';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import Member from '../../../components/Member';
import Switch from '../../../components/Switch';
import TextInput from '../../../components/Input/TextInput';
import ResultChart from '../../../components/ResultChart';
import EarthCounter from '../../../components/EarthCounter';
import TextAreaInput from '../../../components/Input/TextAreaInput';
import LanguageParagraph from '../../../components/LanguageParagraph';
import LanguageLabel from '../../../components/LanguageLabel';

import { getEarths } from '../../../utils/functions';
import { getCurrentDate } from '../../../utils/dateFormat';

import Copy from '../../../assets/icons/ic-copy.svg';

import { ReactComponent as AvatarMountain } from '../../../assets/icons/ic-avatar-ijsbergen.svg';
import { ReactComponent as Cloud } from '../../../assets/icons/ic-cloud.svg';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../../constants/questions';

import '../style.scss';
import './style.scss';
import { lang } from '../../../utils/locale';

const goalX = {
  results: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const results = {
  calculatedResults: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

let originalGoalsId = null;
let originalGoals = null;
let originalEarthsGoal = null;

const GroupEdit = ({
  mobileView, user, history,
}) => {
  const [teamName, setTeamName] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [groupState, setGroupState] = useState();
  const [group, setGroup] = useState({});
  const [result, setResult] = useState({});
  const [goals, setGoals] = useState(goalX);
  const [earthsGoal, setEarthsGoal] = useState(0);
  const [earthsOriginal, setEarthsOriginal] = useState(0);
  const [randomIzer, setRandomIzer] = useState(Math.ceil(Math.random() * 8));
  const key = `images/${randomIzer}.jpg`;
  const url = 'https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/';
  const [imagePath, setImagePath] = useState(url + key);
  const [members, setMembers] = useState([]);
  const [edit, setEdit] = useState([]);
  const [goalId, setGoalId] = useState('');
  const [copied, setCopiedState] = useState(false);

  const [showMembers, setShowMembers] = useState(8);
  const UpdateGoalMock = async () => {
    try {
      await API.graphql(
        graphqlOperation(UpdateGoal, {
          input: {
            id: goalId.id,
            title: teamName,
            bathing: goals.results[QUESTION_BATHING].recipe || 0,
            car: goals.results[QUESTION_CAR].recipe || 0,
            clothes: goals.results[QUESTION_CLOTHES].recipe || 0,
            diary: goals.results[QUESTION_DAIRY].recipe || 0,
            flying: goals.results[QUESTION_FLYING].recipe || 0,
            food: goals.results[QUESTION_FOOD].recipe || 0,
            housing: goals.results[QUESTION_HOUSING].recipe || 0,
            meat: goals.results[QUESTION_MEAT].recipe || 0,
            possessions: goals.results[QUESTION_POSSESSIONS].recipe || 0,
            publictransport: goals.results[QUESTION_PUBLIC_TRANSPORT].recipe || 0,
            earthsResult: earthsOriginal,
            earthsGoal,
          },
        }),
      );
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const CreateGoalMock = async () => {
    let goalId;
    const inputData = {
      title: teamName,
      bathing: goals.results[QUESTION_BATHING].recipe || 0,
      car: goals.results[QUESTION_CAR].recipe || 0,
      clothes: goals.results[QUESTION_CLOTHES].recipe || 0,
      diary: goals.results[QUESTION_DAIRY].recipe || 0,
      flying: goals.results[QUESTION_FLYING].recipe || 0,
      food: goals.results[QUESTION_FOOD].recipe || 0,
      housing: goals.results[QUESTION_HOUSING].recipe || 0,
      meat: goals.results[QUESTION_MEAT].recipe || 0,
      possessions: goals.results[QUESTION_POSSESSIONS].recipe || 0,
      publictransport: goals.results[QUESTION_PUBLIC_TRANSPORT].recipe || 0,
      earthsResult: earthsOriginal,
      createdOn: getCurrentDate(),
      earthsGoal,
    };

    try {
      const createdGoal = await API.graphql(graphqlOperation(CreateGoal, { input: inputData }));
      goalId = createdGoal.data.createGoal.id;
      await API.graphql(
        graphqlOperation(UpdateGroup, {
          input: {
            id: group.id,
            goal: goalId,
          },
        }),
      );
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const chartResultsFormat = (resp, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport || 0 },
      },
    };
    return preFilled;
  };

  const getUsersCall = async (currentGroup) => {
    const users = [];
    await Promise.all(currentGroup.users.map(async (userId) => {
      const object = JSON.parse(userId);
      try {
        const result = await API.graphql(
          graphqlOperation(GetUser, {
            id: object.user,
          }),
        );
        const newObject = {
          ...result.data.getUser,
          dateAdded: object.date,
        };
        users.push(newObject);
      } catch (err) {
      // console.log(err);
      }
    }));
    return users;
  };

  const cleanUpResult = (result) => {
    const resultForEarthCounter = result;
    delete resultForEarthCounter.id;
    delete resultForEarthCounter.stringResult;
    delete resultForEarthCounter.email;
    delete resultForEarthCounter.newsLetter;
    delete resultForEarthCounter.dateCreated;
    delete resultForEarthCounter.dateCreated;
    if (resultForEarthCounter.gender) {
      delete resultForEarthCounter.gender;
    }
    if (resultForEarthCounter.birthdate) {
      delete resultForEarthCounter.birthdate;
    }
    if (resultForEarthCounter.zipcode) {
      delete resultForEarthCounter.zipcode;
    }
    return resultForEarthCounter;
  };

  const retrieveInformation = async () => {
    if (Object.keys(user).length > 0) {
      const groupID = history.location.pathname.split('/')[3];
      if (groupID.length > 3) {
        const contents = await API.graphql(graphqlOperation(GetGroup, { id: groupID }));
        const currentGroup = contents.data.getGroup;
        setTeamName(currentGroup.title);
        setLocation(currentGroup.location);
        setDescription(currentGroup.description);
        setImagePath(currentGroup.image);
        let isUserInGroup = -1;
        // console.log(currentGroup.users, user);
        currentGroup.users.forEach((x, i) => {
          const y = JSON.parse(x);
          if (y.user === user.id) isUserInGroup = i;
          // console.log(y, user, isUserInGroup);
        });
        if (isUserInGroup === -1) {
          // Als de user geen lid is
          if (!currentGroup.secluded) {
            const path = `/groupsubscription/request/${currentGroup.id}`;
            history.push(path);
            return;
          }
          const path = `/groupsubscription/request-opengroup/${currentGroup.id}`;
          history.push(path);
          return;
        }
        if (user.id !== currentGroup.owner) {
          // Als de user geen admin is
          const path = `/groups/${currentGroup.id}`;
          history.push(path);
          return;
        }
        setGroup(currentGroup);
        if (currentGroup.goal.length !== 0) {
          const goalContents = await API.graphql(graphqlOperation(GetGoal, { id: currentGroup.goal }));

          originalGoals = chartResultsFormat(goalContents.data.getGoal, 'results');
          setGoals(originalGoals);
          originalGoalsId = goalContents.data.getGoal;
          setGoalId(originalGoalsId);
          originalEarthsGoal = getEarths(chartResultsFormat(goalContents.data.getGoal, 'results').results);
          setEarthsGoal(originalEarthsGoal);
        }
        if (currentGroup.currentResults.length > 0) {
          const results = [];
          await Promise.all(currentGroup.currentResults.map(async (resultObject) => {
            const object = JSON.parse(resultObject);
            try {
              const result = await API.graphql(
                graphqlOperation(GetQuizResult, {
                  id: object.resultID,
                }),
              );
              const resultForEarthCounter = cleanUpResult(result.data.getQuizResult);
              results.push(resultForEarthCounter);
            } catch (err) {
              // console.log(err);
            }
          }));
          if (results.length > 0) {
            const addedResults = {
              bathing: 0,
              car: 0,
              clothes: 0,
              diary: 0,
              flying: 0,
              food: 0,
              housing: 0,
              meat: 0,
              possessions: 0,
              publictransport: 0,
            };
            results.forEach((item) => {
              Object.keys(addedResults).forEach((key) => {
                addedResults[key] += item[key];
              });
            });
            Object.keys(addedResults).forEach((key) => {
              addedResults[key] /= results.length;
            });
            setResult(chartResultsFormat(addedResults, 'calculatedResults'));
            setEarthsOriginal(getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults));
          }
          const users = await getUsersCall(currentGroup);
          setMembers(users);
        }
      }
    }
  };

  const saveEdit = async () => {
    if (group.goal.length === 0) {
      CreateGoalMock();
    } else {
      UpdateGoalMock();
    }
    const input = {
      id: group.id,
      title: teamName,
      description,
      owner: group.owner,
      users: group.users,
      location,
      secluded: groupState,
      image: imagePath,
    };
    const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup, { input }));
    setGroup(updatedGroup.data.updateGroup);
    history.push(`/${lang}/groups/${group.id}`);
  };

  const removeUser = async (id) => {
    if (id === group.owner) {
      group.users.forEach(async (x) => {
        const y = JSON.parse(x);
        const groupUser = await API.graphql(
          graphqlOperation(GetUser, {
            id: y.user,
          }),
        );
        let tonedDownUser = groupUser.data.getUser;
        if (tonedDownUser.groups && tonedDownUser.groups.length > 0) {
          tonedDownUser.groups.forEach((u, i) => {
            if (u === group.id) tonedDownUser = { ...tonedDownUser, groups: tonedDownUser.groups.splice(1, i) };
          });
        }
        try {
          await API.graphql(graphqlOperation(UpdateUser, { input: tonedDownUser }));
        } catch (err) {
          // console.log('error: ', err);
        }
      });
      await API.graphql(
        graphqlOperation(DeleteGroup, {
          input: {
            id: group.id,
          },
        }),
      );
      history.push('/groups/');
    } else {
      let index = -1;
      group.users.forEach((x, i) => {
        const y = JSON.parse(x);
        if (y.user === id) {
          index = i;
        }
      });
      if (index !== -1) {
        const currentUsers = group.users;
        currentUsers.splice(index, 1);
        const newInitialResults = group.initialResults;
        const newCurrentResults = group.currentResults;

        if (newInitialResults.length > 0) {
          newInitialResults.forEach((x, i) => {
            const y = JSON.parse(x);
            if (y.userID === id) {
              newInitialResults.splice(i, 1);
            }
          });
        }

        if (newCurrentResults.length > 0) {
          newCurrentResults.forEach((x, i) => {
            const y = JSON.parse(x);
            if (y.userID === id) {
              newCurrentResults.splice(i, 1);
            }
          });
        }

        const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup,
          {
            input:
          {
            id: group.id,
            users: currentUsers,
            initialResults: newInitialResults,
            currentResults: newCurrentResults,
          },
          }));
        const newUsers = await getUsersCall(updatedGroup.data.updateGroup);
        const userObject = user;
        userObject.groups.forEach(async (x, i) => {
          if (x === group.id) {
            const newGroupsUser = userObject.groups;
            newGroupsUser.splice(i, 1);
            await API.graphql(graphqlOperation(UpdateUser, { input: { id, groups: newGroupsUser } }));
          }
        });
        setMembers(newUsers);
      }
    }
  };

  const onChangeGoals = (data) => {
    const key = Object.keys(data);
    const values = Object.values(data);
    const newObj = { ...goals.results, [key]: { recipe: Number(values) } };
    setGoals({ results: newObj });
    setEarthsGoal(getEarths(goals.results));
  };

  const changeGroupState = () => {
    setGroupState(!groupState);
  };

  const uploadUserImage = async (file) => {
    if (file) {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      const { type: mimeType } = file;
      const key = `images/${user.id + Math.ceil(Math.random() * 8)}.${extension}`;
      const url = `https://fpcdev1f03d9a466ae44cdb24dad6c1343d0d3112334-dev.s3.eu-west-1.amazonaws.com/public/${key}`;
      try {
        await Storage.put(key, file, {
          contentType: mimeType,
        });
        setImagePath(url);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  const handleUpload = (event) => {
    const { target: { files } } = event;
    const fileForUpload = files[0];
    if (fileForUpload) {
      uploadUserImage(fileForUpload);
    }
  };

  const handleRandomClick = () => {
    let randomNumber = Math.ceil(Math.random() * 8);
    if (randomNumber === randomIzer && randomNumber > 1) randomNumber -= 1;
    if (randomNumber === randomIzer && randomNumber < 7) randomNumber += 1;
    const tempKey = `images/${randomNumber}.jpg`;
    setRandomIzer(randomNumber);
    setImagePath(url + tempKey);
  };

  const reloadGoal = () => {
    setGoals(originalGoals);
    setGoalId(originalGoalsId);
    setEarthsGoal(originalEarthsGoal);
  };

  useEffect(() => {
    retrieveInformation();
  }, [user]);

  useEffect(() => {
    if (mobileView) {
      setShowMembers(4);
      setEdit(false);
      return;
    }
    setShowMembers(8);
    setEdit(true);
  }, [mobileView]);

  useEffect(() => {
    setGroupState(group.secluded);
  }, [group]);


  return (
    <main className="group group-edit">
      {Object.keys(group).length > 0
      && (
      <>
        <header className="group-header">
          <div className="container">
            <div
              onAnimationEnd={() => setCopiedState(false)}
              className={classNames('copytext', copied && 'active-copy')}
            ><LanguageLabel id="copiedPopupLabel" />
            </div>
            <CopyToClipboard
              text={`https://www.mijnverborgenimpact.nl/${lang}/groups/${group.id}`}
              onCopy={() => setCopiedState(true)}
            >
              <Button img={Copy} className="rounded" label={<LanguageLabel id="groupInviteOthersLabel" />} />
            </CopyToClipboard>
            <div className="spacial">
              <Button onClick={() => saveEdit()} className="secondary small" label={<LanguageLabel id="groupEditSaveChangesButton" />} />
            </div>
          </div>
        </header>
        <section className="group-intro">
          <div className="group-icon">
            <figure>
              <img src={imagePath} alt="" />
            </figure>
            <span className="cloud one"><Cloud /></span>
            <span className="cloud two"><Cloud /></span>
            <span className="cloud three"><Cloud /></span>
            <span className="cloud four"><Cloud /></span>
            <span className="mountain"><AvatarMountain /></span>
          </div>
          <div className="container">
            <div className="button-box">
              <label htmlFor="files" className="upload-pic">
                <input
                  id="files"
                  style={{ width: '0', visibility: 'hidden' }}
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={handleUpload}
                />
                <span className="upload-button"><LanguageLabel id="groupEditUploadPhotoButton" /></span>
              </label>
              <Button label={<LanguageLabel id="randomPhotoLabel" />} onClick={handleRandomClick} />
            </div>
            <TextInput
              id="teamName"
              label={<LanguageLabel id="groupNameInputLabel" />}
              onChange={(e) => setTeamName(e.target.value)}
              type="text"
              value={teamName}
            />
            <TextInput
              id="location"
              label={<LanguageLabel id="locationInputLabel" />}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
              value={location}
              placeholder={lang === 'nl' ? 'Nederland' : 'The Netherlands'}
            />
            <TextAreaInput
              id="description"
              label={<LanguageLabel id="descriptionInputLabel" />}
              onChange={(e) => setDescription(e.target.value)}
              type="textarea"
              value={description}
              placeholder={lang === 'nl' ? 'Er is nog geen beschrijving' : 'There is no description yet'}
            />
            <Switch
              value={changeGroupState}
              defaultValue={!groupState}
            />
          </div>
        </section>
        <section className="graphic">
          <Heading className="primary">
            <LanguageLabel id="groupEditChartTitle" />
          </Heading>
          <Heading>
            {(earthsGoal || earthsOriginal) > 1 ? (
              <><span>{earthsGoal || earthsOriginal}</span>aardes</>
            ) : (
              <LanguageLabel id="groupEditChartSubTitle" replace={earthsGoal || earthsOriginal} />
            )}
          </Heading>
          <EarthCounter transparentEarths={earthsOriginal} yellowBorderEarths={earthsGoal} greenEarth />
          <ResultChart
            user={user}
            questions={Object.keys(result).length > 0 ? result : results}
            goals={goals}
            onChangeGoals={onChangeGoals}
            edit={edit}
            modal
            groupLegend
            earthsGoal={earthsGoal}
            earthsOriginal={earthsOriginal}
            paramId={group.id}
            cancelFunc={reloadGoal}
          />
          <div className="graphic-bg" />
        </section>
        <section className="members">
          <Heading className="primary"><LanguageLabel id="groupEditMembersTitle" replace={members.length} /></Heading>
          <Paragraph><LanguageParagraph id="groupEditMembersParagraph" /></Paragraph>
          <div className="layout">
            {members.slice(0, showMembers).map((item) => {
              const name = item.name ? item.name : (
                item.email ? item.email.split('@')[0] : <LanguageLabel id="memberNoNameLabel" />
              );

              return (
                <Member
                  edit
                  className="slim"
                  userId={item.id}
                  groupOwner={group.owner}
                  key={item.id}
                  imgSrc={item.image}
                  name={name}
                  kind={item.id === group.owner ? 'Eigenaar' : 'Lid'}
                  removeMember={() => removeUser(item.id)}
                />
              );
            })}
          </div>
          {members.length !== showMembers && members.length > showMembers && (
          <Button onClick={() => setShowMembers(members.length)} className="outline-mobile" label={<LanguageLabel id="loadMoreLabel" />} />
          )}
          <div className="graphic-bg" />
        </section>
      </>
      )}
    </main>
  );
};

GroupEdit.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape({
        split: PropTypes.func,
      }),
    }),
    push: PropTypes.func,
  }).isRequired,
};

GroupEdit.defaultProps = {
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupEdit);
