import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from '../Heading';
import Button from '../Button';

import './style.scss';
import { lang } from '../../utils/locale';

const Modal = ({
  buttonLabel,
  children,
  className,
  headingText,
  modalSwitch,
  onClick,
  hideButton,
}) => {
  const handleModalSwitch = (event) => {
    if (event.target.className === 'modal show') {
      onClick();
    }
  };

  return (
    <div onClick={(e) => handleModalSwitch(e)} className={classNames('modal', modalSwitch && 'show')}>
      <div className={classNames(className, 'popup')}>
        <div className="close" onClick={() => onClick()} />
        <Heading className="primary" type="h1">
          {headingText}
        </Heading>
        {children}
        {!hideButton
        && (
        <Button
          label={buttonLabel}
          onClick={() => onClick()}
          className="outline"
        />
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  buttonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingText: PropTypes.string,
  modalSwitch: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};

Modal.defaultProps = {
  buttonLabel: lang === 'en' ? 'Continue' : 'Verder',
  className: '',
  headingText: lang === 'en' ? 'Explanation' : 'Uitleg',
  hideButton: false,
};

export default Modal;
