import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import bathingCalculation from './bathing';
import carCalculations from './cars';
import clothesCalculations from './clothes';
import dairyCalculation from './dairy';
import foodCalculation from './food';
import flyingCalculation from './flying';
import housingCalculation from './housing';
import meatCalculation from './meat';
import possessionsCalculation from './possessions';
import publicTransportCalculation from './publicTransport';

const calculations = {
  [QUESTION_BATHING]: (questionResults, average) => bathingCalculation(questionResults, average),
  [QUESTION_CAR]: (questionResults, average) => carCalculations(questionResults, average),
  [QUESTION_CLOTHES]: (questionResults, average) => clothesCalculations(questionResults, average),
  [QUESTION_DAIRY]: (questionResults, average) => dairyCalculation(questionResults, average),
  [QUESTION_FLYING]: (questionResults, average) => flyingCalculation(questionResults, average),
  [QUESTION_FOOD]: (questionResults, average, mode) => foodCalculation(questionResults, average, mode),
  [QUESTION_HOUSING]: (questionResults, average) => housingCalculation(questionResults, average),
  [QUESTION_MEAT]: (questionResults, average) => meatCalculation(questionResults, average),
  [QUESTION_PUBLIC_TRANSPORT]: (questionResults) => publicTransportCalculation(questionResults),
  [QUESTION_POSSESSIONS]: (questionResults, average, mode) => possessionsCalculation(questionResults, average, mode),
  [QUESTION_PUBLIC_TRANSPORT]: (questionResults, average) => publicTransportCalculation(questionResults, average),
};

export default calculations;
