const mapStateToProps = (state) => {
  const { questions, tooltips, dropdown } = state;
  return {
    results: questions.results,
    calculatedResults: questions.calculatedResults,
    activeQuestion: questions.activeQuestion,
    tooltips: tooltips.tooltips,
    dropdown: dropdown.dropdown,
  };
};

export default mapStateToProps;
