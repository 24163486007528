const getDropdown = (Dropdown) => Dropdown.map((item) => ({
  id: item.fields.id,
  label: item.fields.label,
  modal: item.fields.modal ? item.fields.modal.fields.id : '',
  defaultValue: item.fields.defaultValue,
  type: item.fields.type,
  classname: item.fields.classname,
  options: item.fields.options.map((item) => ({
    id: item.fields.id,
    label: item.fields.label,
    value: item.fields.value,
  })),
}));

export { getDropdown };
