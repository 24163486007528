import React from 'react';

const VerifyEmailPage = () => (
  <div>
    Verify Email
  </div>
);

VerifyEmailPage.propTypes = {

};

export default VerifyEmailPage;
