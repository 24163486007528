const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  const train = linearScaling('train') || 0;
  const bus = linearScaling('bus') || 0;
  const metro = linearScaling('metro') || 0;
  const tram = linearScaling('tram') || 0;

  const recipe = (train + bus + metro + tram) || 0;

  return {
    train,
    bus,
    metro,
    tram,
    recipe,
  };
};

export default calculate;
