const getTooltips = (tooltips) => tooltips.map((tooltip) => ({
  id: tooltip.fields.id,
  label: tooltip.fields.label,
  explanation: (tooltip.fields.explanation && tooltip.fields.explanation.fields) ? tooltip.fields.explanation.fields.id : '',
  unit: tooltip.fields.unit,
  decimal: tooltip.fields.decimal,
  money: tooltip.fields.money,
  defaultValue: tooltip.fields.defaultValue ? tooltip.fields.defaultValue : '',
  min: tooltip.fields.min,
  snapToZero: tooltip.fields.snapToZero,
  max: tooltip.fields.max,
  descriptions: tooltip.fields.descriptions ? tooltip.fields.descriptions.map((description) => ({
    id: description.fields.id,
    start: description.fields.start,
    end: description.fields.end,
    label: description.fields.label,
  })) : [],
}));

export { getTooltips };
