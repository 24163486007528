const getProducts = (products) => products.map((product) => ({
  id: product.fields.id,
  order: product.fields.order,
  image: product.fields.image.fields.file.url,
  label: product.fields.label,
  description: product.fields.description,
  url: product.fields.url,
  button: product.fields.button,
}));

export { getProducts };
