const ACTIONS = 'ACTIONS';
const APIMOCK = 'APIMOCK';
const CHECKPAGE = 'CHECKPAGE';
const COMPONENTS = 'COMPONENTS';
const DASHBOARD = 'DASHBOARD';
const FORGOTPASSWORD = 'FORGOTPASSWORD';
const GOALDETAIL = 'GOALDETAIL';
const GOALEDIT = 'GOALEDIT';
const GOALEDITCREATE = 'GOALEDITCREATE';
const GOALS = 'GOALS';
const GROUPDETAIL = 'GROUPDETAIL';
const GROUPSUB = 'GROUPSUB';
const GROUPEDIT = 'GROUPEDIT';
const GROUPS = 'GROUPS';
const HOME = 'HOME';
const LOGIN = 'LOGIN';
const LOGINACCOUNT = 'LOGINACCOUNT';
const CREATEUSERSUCCES = 'CREATEUSERSUCCES';
const MAILSEND = 'MAILSEND';
const NEWACTION = 'NEWACTION';
const NEWGOAL = 'NEWGOAL';
const NEWGROUP = 'NEWGROUP';
const NEWMEMBER = 'NEWMEMBER';
const NEWPASSWORD = 'NEWPASSWORD';
const PROFILEBASE = 'PROFILEBASE';
const PROFILEDETAIL = 'PROFILEDETAIL';
const QUESTIONS = 'QUESTIONS';
const QUESTIONSHOME = 'QUESTIONSHOME';
const RESULTS = 'RESULTS';
const TOP10INFO = 'TOP10INFO';
const VERIFYEMAIL = 'VERIFYEMAIL';

export {
  ACTIONS,
  APIMOCK,
  CHECKPAGE,
  COMPONENTS,
  CREATEUSERSUCCES,
  DASHBOARD,
  FORGOTPASSWORD,
  GOALDETAIL,
  GOALEDIT,
  GOALEDITCREATE,
  GOALS,
  GROUPDETAIL,
  GROUPEDIT,
  GROUPS,
  GROUPSUB,
  HOME,
  LOGIN,
  LOGINACCOUNT,
  MAILSEND,
  NEWACTION,
  NEWGOAL,
  NEWGROUP,
  NEWMEMBER,
  NEWPASSWORD,
  PROFILEBASE,
  PROFILEDETAIL,
  QUESTIONS,
  QUESTIONSHOME,
  RESULTS,
  TOP10INFO,
  VERIFYEMAIL,
};
