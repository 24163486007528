import { FETCH_LANGUAGE_LABELS } from '../../store/actions/languageActionTypes';

const mapDispatchToProps = (dispatch) => ({
  fetchLanguageLabels: (lang) => dispatch({
    type: FETCH_LANGUAGE_LABELS,
    payload: { lang },
  }),
});

export default mapDispatchToProps;
