import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import { setStorageItem } from '../../utils/localStorageUtil';
import './style.scss';


const PageAmsterdam = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const idAmsterdam = searchParams.get('id');
  const idProject = searchParams.get('project');
  const { lang } = useParams();

  if (!(idAmsterdam && idProject)) {
    history.push(`/${lang}`);
    return null;
  }

  setStorageItem('idProject', idProject);
  setStorageItem('idAmsterdam', idAmsterdam, () => history.push(`/${lang}/questions`));

  return (
    <div className="amsterdam-wrapper">
      <LoadingIndicator type="" />
    </div>
  );
};
PageAmsterdam.propTypes = {

};

export default PageAmsterdam;
