import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from './mapState';

import data from './data.json';
import dataEN from './data_en.json';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import LanguageLabel from '../LanguageLabel';
import ResultBar from './ResultBar';
import ModalChart from './ModalChart';
import Timeline from './Timeline';

import './style.scss';
import { lang } from '../../utils/locale';

const ResultChart = ({
  className,
  questions,
  goals,
  edit,
  modal,
  timeLine,
  legend,
  groupLegend,
  mobileView,
  timestamps,
  onTimeLineClick,
  onChangeGoals,
  goalUpdate,
  saveFunc,
  cancelFunc,
  earthsGoal,
  earths,
  earthsOriginal,
  paramId,
  customTimeLine,
  returnValueToGroups,
  groupTimeLine,
  editShow,
}) => {
  const [activeChart, setActiveChart] = useState();
  const [activeModal, setActiveModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const selectedData = lang === 'en' ? dataEN : data;

  const $body = document.querySelector('body');
  let goalRecipe;

  const top10 = {
    [QUESTION_BATHING]: 25.78,
    [QUESTION_CAR]: 174.5,
    [QUESTION_CLOTHES]: 38.94,
    [QUESTION_DAIRY]: 30.81,
    [QUESTION_FLYING]: 66.95,
    [QUESTION_FOOD]: 46.72,
    [QUESTION_HOUSING]: 138.42,
    [QUESTION_MEAT]: 106.67,
    [QUESTION_POSSESSIONS]: 266.08,
    [QUESTION_PUBLIC_TRANSPORT]: 10.07,
  };

  const resultRecipe = {
    [QUESTION_BATHING]: questions.calculatedResults[QUESTION_BATHING].recipe,
    [QUESTION_CAR]: questions.calculatedResults[QUESTION_CAR].recipe,
    [QUESTION_CLOTHES]: questions.calculatedResults[QUESTION_CLOTHES].recipe,
    [QUESTION_DAIRY]: questions.calculatedResults[QUESTION_DAIRY].recipe,
    [QUESTION_FLYING]: questions.calculatedResults[QUESTION_FLYING].recipe,
    [QUESTION_FOOD]: questions.calculatedResults[QUESTION_FOOD].recipe,
    [QUESTION_HOUSING]: questions.calculatedResults[QUESTION_HOUSING].recipe,
    [QUESTION_MEAT]: questions.calculatedResults[QUESTION_MEAT].recipe,
    [QUESTION_POSSESSIONS]: questions.calculatedResults[QUESTION_POSSESSIONS].recipe,
    [QUESTION_PUBLIC_TRANSPORT]: questions.calculatedResults[QUESTION_PUBLIC_TRANSPORT].recipe,
  };

  if (goals) {
    goalRecipe = {
      [QUESTION_BATHING]: goals.results[QUESTION_BATHING].recipe,
      [QUESTION_CAR]: goals.results[QUESTION_CAR].recipe,
      [QUESTION_CLOTHES]: goals.results[QUESTION_CLOTHES].recipe,
      [QUESTION_DAIRY]: goals.results[QUESTION_DAIRY].recipe,
      [QUESTION_FLYING]: goals.results[QUESTION_FLYING].recipe,
      [QUESTION_FOOD]: goals.results[QUESTION_FOOD].recipe,
      [QUESTION_HOUSING]: goals.results[QUESTION_HOUSING].recipe,
      [QUESTION_MEAT]: goals.results[QUESTION_MEAT].recipe,
      [QUESTION_POSSESSIONS]: goals.results[QUESTION_POSSESSIONS].recipe,
      [QUESTION_PUBLIC_TRANSPORT]: goals.results[QUESTION_PUBLIC_TRANSPORT].recipe,
    };
  }
  const getPercentage = (value, total) => ((value / total) * 100 || 0);

  const top10HighScore = Math.max(...Object.values(top10));
  const resultRecipeScores = Math.max(...Object.values(resultRecipe));
  const setChartHight = Math.max(top10HighScore, resultRecipeScores);

  const resultRecipeHighScore = Math.max(...Object.values(resultRecipe)) || 0;
  const highScore = Math.max(top10HighScore, resultRecipeHighScore);

  const handleDisabelScroll = () => {
    setScrollPosition(window.pageYOffset);
    const pageLocation = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${pageLocation}px`;
  };

  const handleEnableScroll = () => {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    window.scrollTo(0, scrollPosition);
  };

  const handleResultBar = (event) => {
    if (mobileView && modal) {
      setActiveChart(event);
      setActiveModal(true);
      handleDisabelScroll();
    }
  };

  useEffect(() => {
    if (!mobileView) {
      setActiveModal(false);
      handleEnableScroll();
    }
  }, [mobileView]);


  return (
    <>
      <div className={classNames('resultChart', className)}>
        <div className="chart">
          {selectedData.map((item) => (
            <ResultBar
              id={item.id}
              key={item.id}
              benchmarkHeight={getPercentage(top10[item.id], highScore)}
              resultHeight={getPercentage(resultRecipe[item.id], setChartHight)}
              maxValue={resultRecipe[item.id]}
              maxGoalValue={goals && goalRecipe[item.id]}
              resultPercentage={getPercentage(resultRecipe[item.id], top10[item.id])}
              goalHeight={goals && getPercentage(goalRecipe[item.id], setChartHight)}
              goalPercentage={goals && getPercentage(goalRecipe[item.id], top10[item.id])}
              iconClass={`ic-${item.icon}`}
              description={item.name}
              edit={edit}
              editShow={editShow}
              handelOnClick={() => handleResultBar(item.id)}
              editValue={onChangeGoals}
              goalUpdate={goalUpdate}
              paramId={paramId}
            />
          ))}
        </div>
        {legend && (
          <div className="legend">
            <span className="dottedbox"><LanguageLabel id="resultChartLegendDottedbox" /></span>
            <span className="filledbox"><LanguageLabel id="resultChartLegendFilledbox" /></span>
            {goals && (<span className="goalbox"><LanguageLabel id="resultChartMyGoalLabel" /></span>)}
          </div>
        )}
        {groupLegend && (
          <div className="legend">
            <span className="dottedbox"><LanguageLabel id="resultChartLegendDottedbox" /></span>
            <span className="filledbox"><LanguageLabel id="resultChartOurImpactLabel" /></span>
            {goals && (<span className="goalbox"><LanguageLabel id="resultChartOurGoalLabel" /></span>)}
          </div>
        )}
        {timeLine && (
          <Timeline
            timestamps={timestamps}
            currentDate={onTimeLineClick}
            customTimeLine={customTimeLine}
            returnValueToGroups={returnValueToGroups}
            groupTimeLine={groupTimeLine}
          />
        )}
      </div>
      {modal && (
        <ModalChart
          data={data}
          mobileView={mobileView}
          activeChart={activeChart}
          activeModal={activeModal}
          getPercentage={getPercentage}
          editValue={onChangeGoals}
          edit
          goals={goals}
          goalRecipe={goalRecipe}
          top10={top10}
          resultRecipe={resultRecipe}
          goalScore={setChartHight}
          handleModal={() => setActiveModal(false)}
          highScore={setChartHight}
          goalUpdate={goalUpdate}
          saveFunc={(e) => saveFunc(e)}
          cancelFunc={cancelFunc}
          earthsGoal={earthsGoal}
          earths={earths}
          earthsOriginal={earthsOriginal}
          paramId={paramId}
          handleEnableScroll={handleEnableScroll}
        />
      )}
    </>
  );
};

ResultChart.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.shape({
    calculatedResults: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }).isRequired,
  goals: PropTypes.shape({
    results: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }),
  edit: PropTypes.bool,
  timeLine: PropTypes.bool,
  legend: PropTypes.bool,
  groupLegend: PropTypes.bool,
  modal: PropTypes.bool,
  mobileView: PropTypes.bool.isRequired,
  timestamps: PropTypes.arrayOf(PropTypes.object),
  onTimeLineClick: PropTypes.func,
  onChangeGoals: PropTypes.func,
  goalUpdate: PropTypes.bool,
  editShow: PropTypes.bool,
  saveFunc: PropTypes.func,
  cancelFunc: PropTypes.func,
  earthsGoal: PropTypes.number,
  earths: PropTypes.number,
  earthsOriginal: PropTypes.number,
  paramId: PropTypes.string,
  customTimeLine: PropTypes.bool,
  groupTimeLine: PropTypes.bool,
  returnValueToGroups: PropTypes.func,
};

ResultChart.defaultProps = {
  className: '',
  goals: false,
  edit: false,
  editShow: false,
  timeLine: false,
  legend: false,
  groupLegend: false,
  modal: false,
  timestamps: [{}],
  onTimeLineClick: () => {},
  goalUpdate: false,
  onChangeGoals: () => {},
  saveFunc: () => {},
  cancelFunc: () => {},
  earthsGoal: 0,
  earths: 0,
  earthsOriginal: 0,
  paramId: '',
  customTimeLine: false,
  groupTimeLine: false,
  returnValueToGroups: null,
};

export default connect(mapStateToProps)(ResultChart);
