import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import Heading from '../Heading';
import Stars from '../Stars';
import Button from '../Button';
import LanguageLabel from '../LanguageLabel';

const ActionCard = ({
  className,
  title,
  category,
  stars,
  description,
  declineAction,
  acceptAction,
  ignoreAction,
}) => (
  <div className={classNames('actioncard', className)}>
    <div className={classNames(category, 'blob')} />
    <div className="action-content">
      <Heading className="primary" type="h1">
        {title}
      </Heading>
      <span className="description">{description}</span>
      <div className="commitment">
        <span>Hoeveel draag je bij</span>
        <Stars stars={stars} />
      </div>
      <div className="main-options">
        <Button
          label={<LanguageLabel id="actionCardNegativeLabel" />}
          onClick={declineAction}
          className="filled"
        />
        <Button
          label={<LanguageLabel id="actionCardPositiveLabel" />}
          onClick={acceptAction}
          className="filled"
        />
      </div>
      <Button
        label={<LanguageLabel id="actionCardNeutralLabel" />}
        onClick={ignoreAction}
      />
    </div>
  </div>
);

ActionCard.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired,
  description: PropTypes.string,
  declineAction: PropTypes.func.isRequired,
  acceptAction: PropTypes.func.isRequired,
  ignoreAction: PropTypes.func.isRequired,
};

ActionCard.defaultProps = {
  className: '',
  description: '',
};

export default ActionCard;
