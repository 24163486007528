/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  graphqlOperation, API, Auth,
} from 'aws-amplify';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import {
  getGroup as GetGroup,
  getUser as GetUser,
  getGoal as GetGoal,
  getQuizResult as GetQuizResult,
} from '../../graphql/queries';

import {
  updateGroup as UpdateGroup,
  updateUser as UpdateUser,
} from '../../graphql/mutations';

import { getEarths } from '../../utils/functions';
import {
  getCurrentDate, getDateFormat, getFormattedDate, dutchTimeFormatter,
} from '../../utils/dateFormat';

import Button from '../../components/Button';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import Member from '../../components/Member';
import ResultChart from '../../components/ResultChart';
import EarthCounter from '../../components/EarthCounter';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import Copy from '../../assets/icons/ic-copy.svg';

import { ReactComponent as AvatarMountain } from '../../assets/icons/ic-avatar-ijsbergen.svg';
import { ReactComponent as Sun } from '../../assets/icons/ic-sun.svg';
import { ReactComponent as Cloud } from '../../assets/icons/ic-cloud.svg';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import './style.scss';
import { getUserById } from '../../lib/user';
import { lang } from '../../utils/locale';

const results = {
  calculatedResults: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const goalX = {
  results: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const Group = ({
  user,
  history,
  setUser,
}) => {
  const [admin, setAdmin] = useState(false);
  const [showMembers, setShowMembers] = useState(4);
  const [group, setGroup] = useState({});
  const [result, setResult] = useState({});
  const [members, setMembers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [goals, setGoals] = useState(goalX);
  const [earthsGoal, setEarthsGoal] = useState(0);
  const [earthsOriginal, setEarthsOriginal] = useState(0);
  const [chartResults, setChartResults] = useState([]);
  const [currentResult, setCurrentResult] = useState({});
  const [currentEarthsOriginal, setCurrentEarthsOriginal] = useState({});
  const [initialResults, setInitialResult] = useState({});
  const [initialEarthsOriginal, setInitialEarthsOriginal] = useState({});
  const [copied, setCopiedState] = useState(false);
  const [latestDate, setLatestDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [previousDate, setPreviousDate] = useState('');

  const chartResultsFormat = (resp, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport || 0 },
      },
    };
    return preFilled;
  };

  const getUsersCall = async (currentGroup) => {
    const users = [];
    await Promise.all(currentGroup.users.map(async (userId) => {
      const object = JSON.parse(userId);
      try {
        const result = await getUserById(object.user);
        // const result = await API.graphql(
        //   graphqlOperation(GetUser, {
        //     id: object.user,
        //   }),
        // );
        const newObject = {
          ...result,
          dateAdded: object.date,
        };
        users.push(newObject);
      } catch (err) {
      // console.log(err);
      }
    }));
    return users;
  };

  const getPendingUsersCall = async (currentGroup) => {
    const requestUsers = [];
    await Promise.all(currentGroup.pendingRequests.map(async (userId) => {
      try {
        const result = await API.graphql(
          graphqlOperation(GetUser, {
            id: userId,
          }),
        );
        requestUsers.push(result.data.getUser);
      } catch (err) {
      // console.log(err);
      }
    }));
    return requestUsers;
  };

  const cleanUpResult = (result) => {
    const resultForEarthCounter = result;
    delete resultForEarthCounter.id;
    delete resultForEarthCounter.stringResult;
    delete resultForEarthCounter.email;
    delete resultForEarthCounter.newsLetter;
    delete resultForEarthCounter.dateCreated;
    delete resultForEarthCounter.dateCreated;
    if (resultForEarthCounter.gender) {
      delete resultForEarthCounter.gender;
    }
    if (resultForEarthCounter.birthdate) {
      delete resultForEarthCounter.birthdate;
    }
    if (resultForEarthCounter.zipcode) {
      delete resultForEarthCounter.zipcode;
    }
    return resultForEarthCounter;
  };

  const acceptUser = async (id) => {
    const userObject = {
      user: id,
      date: getDateFormat(getCurrentDate(), 'YYYY-MM-DD'),
    };
    const newMembers = group.users;
    newMembers.unshift(JSON.stringify(userObject));

    const newPendingRequests = group.pendingRequests;
    let userIndex = 0;
    group.pendingRequests.forEach((x, i) => {
      if (x === id) userIndex = i;
    });
    newPendingRequests.splice(userIndex, 1);

    const newStartingPoint = group.initialResults;
    const cUser = user;
    if (cUser.results && cUser.results.length > 0) {
      const resultObject = {
        userID: cUser.id,
        resultID: cUser.results && cUser.results.length > 0 ? cUser.results[0] : '',
      };
      if (cUser.results && cUser.results.length > 0) {
        newStartingPoint.push(JSON.stringify(resultObject));
      }
    }
    const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup, {
      input:
      {
        id: group.id,
        users: newMembers,
        pendingRequests: newPendingRequests,
        initialResults: newStartingPoint,
      },
    }));
    const userGroup = cUser.groups;
    userGroup.unshift(group.id);
    const updatedUser = await API.graphql(graphqlOperation(UpdateUser, {
      input:
      {
        id,
        groups: userGroup,
      },
    }));
    setUser(updatedUser.data.updateUser);
    setGroup(updatedGroup.data.updateGroup);
    const users = await getUsersCall(updatedGroup.data.updateGroup);
    setMembers(users);
    const requestUsers = await getPendingUsersCall(updatedGroup.data.updateGroup);
    setPendingUsers(requestUsers);
    // accept user by id
  };

  const rejectUser = async (id) => {
    // reject user by id
    const newPendingRequests = group.pendingRequests;
    let userIndex = 0;
    group.pendingRequests.forEach((x, i) => {
      if (x === id) userIndex = i;
    });
    newPendingRequests.splice(userIndex, 1);
    const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup, {
      input:
      {
        id: group.id,
        pendingRequests: newPendingRequests,
      },
    }));
    const requestUsers = await getPendingUsersCall(updatedGroup.data.updateGroup);
    setPendingUsers(requestUsers);
  };

  const isMemberNew = (date) => {
    const currentDay = getDateFormat(getCurrentDate(), 'YYYY-MM-DD');
    const checkDay = getDateFormat(date, 'YYYY-MM-DD');

    if (checkDay === currentDay) {
      return true;
    }
    return false;
  };

  const returnValueToGroups = (value) => {
    if (value === 'Voorheen1') {
      setLatestDate(previousDate);
      setResult(initialResults);
      setEarthsOriginal(initialEarthsOriginal);
    } else {
      setLatestDate(currentDate);
      setResult(currentResult);
      setEarthsOriginal(currentEarthsOriginal);
    }
  };

  const retrieveInformation = async () => {
    if (Object.keys(user).length > 0) {
      const groupID = history.location.pathname.split('/')[3];
      if (groupID.length > 3) {
        const contents = await API.graphql(graphqlOperation(GetGroup, { id: groupID }));
        let currentGroup = contents.data.getGroup;
        let isUserInGroup = -1;
        if (currentGroup && currentGroup.users) {
          currentGroup.users.forEach((x, i) => {
            const y = JSON.parse(x);
            if (y.user === user.id) isUserInGroup = i;
          });
        }
        if (isUserInGroup === -1) {
          // Als de user geen lid is
          if (currentGroup && !currentGroup.secluded) {
            const path = `/groupsubscription/request/${currentGroup.id}`;
            history.push(path);
            return;
          }
          const path = `/groupsubscription/request-opengroup/${currentGroup?.id}`;
          history.push(path);
          return;
        }
        if (currentGroup.owner === user.id) setAdmin(true);
        // Initial results wordt gezet bij het accepteren van een user of het toevoegen van een user
        if (currentGroup.users.length > 0) {
          currentGroup.users.forEach(async (cUser) => {
            const parsedUser = JSON.parse(cUser);
            if (parsedUser.user === user.id) {
              const userObject = user;
              let index = -1;
              for (let i = 0; i < currentGroup.currentResults.length; i++) {
                const result = JSON.parse(currentGroup.currentResults[i]);
                if (result.userID === user.id) index = i;
              }
              const cUser = user;
              if (index !== -1 && userObject.results.length > 0) {
                const currentInput = JSON.parse(currentGroup.currentResults[index]);
                const currentUserResult = cUser.results[0];
                if (currentInput.resultID !== currentUserResult) {
                  // Als de resultaten niet meer gelijk zijn
                  const newUserResultForGroup = JSON.stringify({
                    userID: user.id,
                    resultID: cUser.results[0],
                  });
                  const newGroupCurrentResults = currentGroup.currentResults;
                  newGroupCurrentResults[index] = newUserResultForGroup;
                  try {
                    const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup, { input: { id: groupID, currentResults: newGroupCurrentResults } }));
                    currentGroup = updatedGroup.data.updateGroup;
                  } catch (err) {
                    // console.log(err);
                  }
                }
              }
              if (index === -1 && cUser.results.length > 0) {
                // User heeft nog nooit iets toegevoegd
                const newUserResultForGroup = JSON.stringify({
                  userID: user.id,
                  resultID: cUser.results[0],
                });
                const newGroupCurrentResults = currentGroup.currentResults;
                newGroupCurrentResults.push(newUserResultForGroup);
                try {
                  const updatedGroup = await API.graphql(graphqlOperation(UpdateGroup, { input: { id: groupID, currentResults: newGroupCurrentResults } }));
                  currentGroup = updatedGroup.data.updateGroup;
                } catch (err) {
                  // console.log(err);
                }
              }
            }
          });
        }
        setGroup(currentGroup);
        if (currentGroup.goal.length !== 0) {
          const goalContents = await API.graphql(graphqlOperation(GetGoal, { id: currentGroup.goal }));
          setGoals(chartResultsFormat(goalContents.data.getGoal, 'results'));
          setEarthsGoal(getEarths(chartResultsFormat(goalContents.data.getGoal, 'results').results));
        }

        const resp = [];
        if (currentGroup.currentResults.length > 0) {
          const results = [];
          let currentDate = getCurrentDate();
          await Promise.all(currentGroup.currentResults.map(async (resultObject) => {
            const object = JSON.parse(resultObject);
            try {
              const result = await API.graphql(
                graphqlOperation(GetQuizResult, {
                  id: object.resultID,
                }),
              );
              const newDate = result.data.getQuizResult.dateCreated;
              if (getFormattedDate(currentDate) > getFormattedDate(newDate)) currentDate = newDate;
              const resultForEarthCounter = cleanUpResult(result.data.getQuizResult);
              results.push(resultForEarthCounter);
            } catch (err) {
              // console.log(err);
            }
          }));
          setCurrentDate(dutchTimeFormatter(currentDate));
          setLatestDate(dutchTimeFormatter(currentDate));
          if (results.length > 0) {
            const addedResults = {
              bathing: 0,
              car: 0,
              clothes: 0,
              diary: 0,
              flying: 0,
              food: 0,
              housing: 0,
              meat: 0,
              possessions: 0,
              publictransport: 0,
            };
            results.forEach((item) => {
              Object.keys(addedResults).forEach((key) => {
                addedResults[key] += item[key];
              });
            });
            Object.keys(addedResults).forEach((key) => {
              addedResults[key] /= results.length;
            });
            resp.push({ ...addedResults, type: 'Vandaag' });
            setResult(chartResultsFormat(addedResults, 'calculatedResults'));
            setEarthsOriginal(getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults));
            setCurrentResult(chartResultsFormat(addedResults, 'calculatedResults'));
            setCurrentEarthsOriginal(getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults));
          }
          const users = await getUsersCall(currentGroup);
          setMembers(users);
          const requestUsers = await getPendingUsersCall(currentGroup);
          setPendingUsers(requestUsers);
        }


        if (currentGroup.initialResults.length > 0) {
          const results = [];
          let currentDate = getCurrentDate();
          await Promise.all(currentGroup.initialResults.map(async (resultObject) => {
            const object = JSON.parse(resultObject);
            try {
              const result = await API.graphql(
                graphqlOperation(GetQuizResult, {
                  id: object.resultID,
                }),
              );
                // console.log('??', result.data.getQuizResult);
              const newDate = result.data.getQuizResult.dateCreated;
              if (getFormattedDate(currentDate) > getFormattedDate(newDate)) currentDate = newDate;
              const resultForEarthCounter = cleanUpResult(result.data.getQuizResult);
              results.push(resultForEarthCounter);
            } catch (err) {
              // console.log(err);
            }
          }));
          setPreviousDate(dutchTimeFormatter(currentDate));
          // setPreviousDate(currentDate);
          if (results.length > 0) {
            const addedResults = {
              bathing: 0,
              car: 0,
              clothes: 0,
              diary: 0,
              flying: 0,
              food: 0,
              housing: 0,
              meat: 0,
              possessions: 0,
              publictransport: 0,
            };
            results.forEach((item) => {
              Object.keys(addedResults).forEach((key) => {
                addedResults[key] += item[key];
              });
            });
            Object.keys(addedResults).forEach((key) => {
              addedResults[key] /= results.length;
            });
            resp.push({ ...addedResults, type: 'Voorheen' });
            setInitialResult(chartResultsFormat(addedResults, 'calculatedResults'));
            setInitialEarthsOriginal(getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults));
          }
        }
        setChartResults(resp);
      }
    } else {
      const currentSession = await Auth.currentUserInfo();
      if (currentSession === null) {
        const groupID = history.location.pathname.split('/')[2];
        const contents = await API.graphql(graphqlOperation(GetGroup, { id: groupID }));
        const currentGroup = contents.data.getGroup;
        if (!currentGroup.secluded) {
          const path = `/groupsubscription/request/${currentGroup.id}`;
          history.push(path);
          return;
        }
        const path = `/groupsubscription/request-opengroup/${currentGroup.id}`;
        history.push(path);
      }
    }
  };

  const getGroupGoal = async () => {
    if (group.length !== 0) {
      const goalContents = await API.graphql(graphqlOperation(GetGoal, { id: group.goal }));
      setGoals(chartResultsFormat(goalContents.data.getGoal, 'results'));
      setEarthsGoal(getEarths(chartResultsFormat(goalContents.data.getGoal, 'results').results));
    }
  };

  useEffect(() => {
    retrieveInformation();
  }, [user]);

  useEffect(() => {
    getGroupGoal();
  }, [group]);

  return (
    <main className="group">
      <header className="group-header">
        <div className="container">
          <div
            onAnimationEnd={() => setCopiedState(false)}
            className={classNames('copytext', copied && 'active-copy')}
          ><LanguageLabel id="copiedPopupLabel" />
          </div>
          <CopyToClipboard
            text={`https://www.mijnverborgenimpact.nl/${lang}/groups/${group.id}`}
            onCopy={() => setCopiedState(true)}
          >
            <Button img={Copy} className="rounded" label={<LanguageLabel id="groupInviteOthersLabel" />} />
          </CopyToClipboard>
          {admin && (
            <div className="spacial">
              <Button to={`/${lang}/groupsedit/${group.id}`} className="secondary small" label={<LanguageLabel id="groupChangeGroupLabel" />} />
            </div>
          )}
        </div>
      </header>
      <section className="group-intro">
        <div className="group-icon">
          <figure>
            <img src={group.image} alt="" />
          </figure>
          <span className="sun"><Sun /></span>
          <span className="cloud one"><Cloud /></span>
          <span className="cloud two"><Cloud /></span>
          <span className="cloud three"><Cloud /></span>
          <span className="cloud four"><Cloud /></span>
          <span className="mountain"><AvatarMountain /></span>
        </div>
        <div className="container">
          <Heading className="primary">{group.title}</Heading>
          {!group.secluded ? (
            <span className={classNames('highlight', 'closed')}><LanguageLabel id="groupDetailPrivateGroupLabel" /></span>
          ) : (
            <span className={classNames('highlight', 'open')}><LanguageLabel id="groupDetailPublicGroupLabel" /></span>
          )}
          <Paragraph>{group.location || <LanguageLabel id="groupDetailLocationDefaultLabel" />}
            <span
              className="highlight"
            >
              {group && group.users && group.users.length} {group.users && group.users.length && group.users.length > 1 ? <LanguageLabel id="memberPluralLabel" /> : <LanguageLabel id="memberSingularLabel" />}
            </span>
          </Paragraph>
          <Paragraph>{group.description || <LanguageLabel id="groupDetailDescriptionDefaultLabel" />}</Paragraph>
        </div>
      </section>
      {admin && (group.pendingRequests && group.pendingRequests.length > 0) && (
        <section className="requests">
          <Heading><LanguageLabel id="groupPageRequestsTitle" /></Heading>
          <div className="layout-box">
            <div className="layout">
              {pendingUsers.slice(0, 3).map((item) => (
                <Member
                  key={item.id}
                  imgSrc={item.image}
                  name={item.name || item.email.split('@')[0]}
                  acceptLink={() => acceptUser(item.id)}
                  rejectLink={() => rejectUser(item.id)}
                />
              ))}
            </div>
          </div>
        </section>
      )}
      <section className="graphic">
        <span className="graphic-desc"><LanguageParagraph id="groupDetailResultParagraph" /> {group && group.currentResults && group.currentResults.length} {group.users && group.users.length && group.users.length > 1 ? <LanguageLabel id="personPluralLabel" /> : <LanguageLabel id="personSingularLabel" />} <LanguageLabel id="groupDetailOnDateLabel" /> {latestDate}</span>
        <Heading className="primary">
          <LanguageLabel id="groupPageChartTitle" replace={group.title} />
        </Heading>
        <Heading>
          <LanguageLabel id="groupPageChartSubTitle" replace={<span>{earthsOriginal}</span>} />
        </Heading>
        <EarthCounter earths={earthsOriginal} yellowBorderEarths={earthsGoal} greenEarth />
        <ResultChart
          questions={Object.keys(result).length > 0 ? result : results}
          goals={goals}
          groupLegend
          timeLine
          editShow={group.goal}
          timestamps={chartResults}
          customTimeLine
          returnValueToGroups={returnValueToGroups}
          groupTimeLine
        />
        <div className="graphic-bg" />
      </section>
      <section className="members">
        <Heading className="primary"><LanguageLabel id="groupPageMembersTitle" /></Heading>
        <Paragraph><LanguageParagraph id="groupPageMembersParagraph" /></Paragraph>
        <div className="layout">
          {members.slice(0, showMembers).map((item) => {
            const name = item.name ? item.name : (
              item.email ? item.email.split('@')[0] : <LanguageLabel id="memberNoNameLabel" />
            );

            return (
              <Member
                newMember={isMemberNew(item.dateAdded)}
                accepted
                key={item.email}
                userId={item.id}
                groupOwner={group.owner}
                imgSrc={item.image}
                name={name}
                kind={item.id === group.owner ? <LanguageLabel id="memberOwnerLabel" /> : <LanguageLabel id="memberMemberLabel" />}
                className="slim"
              />
            );
          })}
        </div>
        {members.length > 4 && members.length !== showMembers && (
          <Button onClick={() => setShowMembers(members.length)} className="secondary small" label={<LanguageLabel id="loadMoreLabel" />} />
        )}
        <div className="graphic-bg" />
      </section>
    </main>
  );
};

Group.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape({
        split: PropTypes.func,
      }),
    }),
    push: PropTypes.func,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

Group.defaultProps = {
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);
