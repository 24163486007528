import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Product from '../../components/Product';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import top10 from '../../assets/images/top10.png';
import logobfa from '../../assets/images/logo-bullfrog-avenue.svg';
import logocedelft from '../../assets/images/logo-ce-delft.png';
import logotban from '../../assets/images/logo-think-big-act-now.svg';
import RichText from '../../components/LanguageRichParagraph';

import './style.scss';

const Top10Info = ({
  products,
  fetchModalTop10s,
  modalTop10,
  fetchProducts,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState('');
  const setModal = (id) => {
    setShowModal(!showModal);
    setCategoryModal(id);
  };

  useEffect(() => {
    fetchModalTop10s();
  }, [modalTop10]);

  useEffect(() => {
    if (products.length === 0) {
      fetchProducts();
    }
  }, [products]);

  return (
    <div className={classNames('resultPage', showModal && 'showTop10', categoryModal)}>
      <div className="container">
        <h1 className="centered">
          <span className="highlight"><LanguageLabel id="top10PageHeaderTitle" /></span>
        </h1>
        <h2 className="centered">
          <span>
            <LanguageLabel id="top10PageHeaderSubTitle" />
          </span>
        </h2>
        <div className="centered">
          <figure className="top10">
            <img src={top10} alt="Top 10" useMap="#image-map" />
            <map name="image-map" className="imageMap">
              <area target="" alt="Spullen" title="Spullen" href="#" onClick={() => setModal('possessions')} coords="93,957,23,104" shape="rect" />
              <area target="" alt="Auto" title="Auto" href="#" onClick={() => setModal('car')} coords="165,957,96,330" shape="rect" />
              <area target="" alt="Wonen" title="Wonen" href="#" onClick={() => setModal('housing')} coords="174,444,237,956" shape="rect" />
              <area target="" alt="Vlees en vis" title="Vlees en vis" href="#" onClick={() => setModal('meat')} coords="246,474,313,957" shape="rect" />
              <area target="" alt="Vliegen" title="Vliegen" href="#" onClick={() => setModal('flying')} coords="320,624,379,957" shape="rect" />
              <area
                target=""
                alt="Eten, drinken en plantaardig"
                title="Eten, drinken en plantaardig"
                href="#"
                onClick={() => setModal('food')}
                coords="386,514,461,957"
                shape="rect"
              />
              <area
                target=""
                alt="Kleding en textiel"
                title="Kleding en textiel"
                href="#"
                onClick={() => setModal('clothes')}
                coords="467,677,530,957"
                shape="rect"
              />
              <area
                target=""
                alt="Zuivel en eieren"
                title="Zuivel en eieren"
                href="#"
                onClick={() => setModal('dairy')}
                coords="534,664,606,957"
                shape="rect"
              />
              <area target="" alt="Douche en bad" title="Douche en bad" href="#" onClick={() => setModal('bathing')} coords="611,648,681,957" shape="rect" />
              <area target="" alt="ov" title="ov" href="#" onClick={() => setModal('publictransport')} coords="685,755,755,957" shape="rect" />
            </map>
          </figure>
        </div>
      </div>
      <div className="whiteout">
        <div className="container">
          <div className="row centered">
            <Heading type="h1" className="primary bottomtitle">
              <LanguageLabel id="top10PageShopTitle" />
            </Heading>
            <p className="col-sm-12 col-lg-7">
              <LanguageParagraph id="top10PageShopParagraph" />
            </p>
          </div>
          <div className="products row">
            {products.sort((a, b) => (a.order > b.order ? 1 : -1)).map((product) => (
              <div className="col-xl-3 col-md-6" key={product.id}>
                <Product imgUrl={`https:${product.image}`}>
                  <Heading type="h3" className="primary">
                    {product.label}
                  </Heading>
                  <p>{product.description}</p>
                  <a
                    className="button small outline-mobile"
                    href={product.url}
                    title=""
                  >
                    <span>{product.button}</span>
                  </a>
                </Product>
              </div>
            ))}
          </div>
          <div className="row centered">
            <h1><LanguageLabel id="top10PagePartnersTitle" /></h1>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="partner">
                <a
                  href="http://www.thinkbigactnow.org"
                  title={<LanguageLabel id="partnerTBANTitle" />}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <figure>
                    <Image className="logo tban" src={logotban} name="Think Big Act Now" />
                  </figure>
                </a>
                <span><LanguageLabel id="partnerTBANKind" /></span>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="partner">
                <a href="https://www.ce.nl/" title={<LanguageLabel id="partnerCEDELFTTitle" />} rel="noopener noreferrer" target="_blank">
                  <figure>
                    <Image className="logo cedelft" src={logocedelft} name="CE Delft" />
                  </figure>
                </a>
                <span><LanguageLabel id="partnerCEDELFTKind" /></span>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="partner">
                <a
                  href="https://www.bullfrogavenue.com/"
                  title={<LanguageLabel id="partnerBFATitle" />}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <figure>
                    <Image className="logo bfa" src={logobfa} name="BullFrog Avenue" />
                  </figure>
                </a>
                <span><LanguageLabel id="partnerBFATitle" /></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 offset-lg-2">
              <div className="partner">
                <ul>
                  <li><LanguageLabel id="partnerPMName1" /></li>
                  <li><LanguageLabel id="partnerPMName2" /></li>
                  <li><LanguageLabel id="partnerPMName3" /></li>
                </ul>
                <span><LanguageLabel id="partnerPMKind" /></span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="partner">
                <ul>
                  <li><LanguageLabel id="partnerFDName1" /></li>
                  <li><LanguageLabel id="partnerFDName2" /></li>
                </ul>
                <span><LanguageLabel id="partnerFDKind" /></span>
              </div>
            </div>
          </div>
          <div className="row centered">
            <p className="footnote">
              <LanguageParagraph id="top10PageFootNote" />
            </p>
          </div>
        </div>
      </div>
      {modalTop10.filter((item) => item.type === categoryModal).map((item) => (
        <div key={item.type} className="top10Modal">
          <div className="content">
            <div className="close" onClick={() => setShowModal(false)} />
            <div className="scrollpane">
              <div className="title">
                <h2><span>1.</span>{item.title}</h2>
              </div>
              <div className="body">
                <RichText text={item.content} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Top10Info.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questions: PropTypes.shape({
    results: PropTypes.shape({}),
    calculatedResults: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }).isRequired,
  fetchModalTop10s: PropTypes.func.isRequired,
  modalTop10: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Top10Info);
