import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Paragraph = ({
  children,
  className,
}) => (
  <p
    className={classNames('paragraph', className)}
  >
    {children}
  </p>
);

Paragraph.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Paragraph.defaultProps = {
  children: null,
  className: '',
};

export default (Paragraph);
