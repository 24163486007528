import { SET_QUESTION_RESULT } from '../../store/actions/questionActionTypes';
import { SET_CONSENT } from '../../store/actions/userActionTypes';
import { FETCH_MODAL_EXPLAINING } from '../../store/actions/modalExplainingActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
  updateConsent: (consent) => dispatch({
    type: SET_CONSENT,
    payload: {
      consent,
    },
  }),
  fetchModalExplaining: () => dispatch({
    type: FETCH_MODAL_EXPLAINING,
    payload: {},
  }),
});

export default mapDispatchToProps;
