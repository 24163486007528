/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphqlOperation, API } from 'aws-amplify';
import classNames from 'classnames';

import {
  getQuizResult as GetQuizResult,
  getGoal as GetGoal,
  getGroup as GetGroup,
  getAction as GetAction,
} from '../../graphql/queries';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Heading from '../../components/Heading';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';
import EarthCounter from '../../components/EarthCounter';
import InfoList from '../../components/InfoList';
import PodiumBlock from '../../components/PodiumBlock';

import {
  GOALS,
  RESULTS,
  ACTIONS,
  QUESTIONSHOME,
  GROUPS,
} from '../../constants/pages';
import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import paths from '../../routes/paths';

import { getEarths } from '../../utils/functions';

import ActionImg from '../../assets/icons/ic-list.svg';
import GroupImg from '../../assets/icons/ic-love.svg';
import GoalsImg from '../../assets/icons/ic-goals.svg';

import { ReactComponent as Auto } from '../../assets/icons/top10/ic-auto.svg';
import { ReactComponent as Shower } from '../../assets/icons/top10/ic-douche.svg';
import { ReactComponent as Food } from '../../assets/icons/top10/ic-eten.svg';
import { ReactComponent as House } from '../../assets/icons/top10/ic-huis.svg';
import { ReactComponent as Clothes } from '../../assets/icons/top10/ic-kleding.svg';
import { ReactComponent as Ov } from '../../assets/icons/top10/ic-ov.svg';
import { ReactComponent as Possessions } from '../../assets/icons/top10/ic-spullen.svg';
import { ReactComponent as Meat } from '../../assets/icons/top10/ic-vlees.svg';
import { ReactComponent as Flying } from '../../assets/icons/top10/ic-vliegen.svg';
import { ReactComponent as Dairy } from '../../assets/icons/top10/ic-zuivel.svg';

import { ReactComponent as Background } from '../../assets/backgrounds/bg-dashboard.svg';

import './style.scss';
import { lang } from '../../utils/locale';

const imgListFormat = {
  car: 0,
  bathing: 1,
  food: 2,
  housing: 3,
  clothes: 4,
  publictransport: 5,
  possessions: 6,
  meat: 7,
  flying: 8,
  dairy: 9,
};

const imgList = [
  <Auto />,
  <Shower />,
  <Food />,
  <House />,
  <Clothes />,
  <Ov />,
  <Possessions />,
  <Meat />,
  <Flying />,
  <Dairy />,
];

const Dashboard = ({
  user,
  actionCards,
  fetchActionCards,
  questions,
}) => {
  const [numberOfEarthsToGoal, setNumberOfEarthsToGoal] = useState(0);
  const [numberOfEarths, setNumberOfEarths] = useState(0);
  const [numberOfEarthsTotal, setNumberOfEarthsTotal] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [topRank, setTopRank] = useState([]);
  const [goalList, setGoalList] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const format = (resp, order) => {
    const preFilled = {
      [QUESTION_BATHING]: { recipe: resp[order].bathing },
      [QUESTION_CAR]: { recipe: resp[order].car },
      [QUESTION_CLOTHES]: { recipe: resp[order].clothes },
      [QUESTION_DAIRY]: { recipe: resp[order].diary },
      [QUESTION_FLYING]: { recipe: resp[order].flying },
      [QUESTION_FOOD]: { recipe: resp[order].food },
      [QUESTION_HOUSING]: { recipe: resp[order].housing },
      [QUESTION_MEAT]: { recipe: resp[order].meat },
      [QUESTION_POSSESSIONS]: { recipe: resp[order].possessions },
      [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp[order].publictransport },
    };
    return preFilled;
  };

  const createTopResults = (data) => {
    const topResults = [];
    const checkArr = ['id', 'stringResult', 'email', 'newsLetter', 'dateCreated'];
    const results = data;
    const resultsArray = Object
      .keys(results)
      .map((result) => ({ [result]: results[result] }));
    resultsArray
      .sort((a, b) => {
        const aVal = Object.values(a)[0];
        const bVal = Object.values(b)[0];
        return bVal - aVal;
      })
      .map((item) => {
        const check = String(Object.keys(item));
        if (!checkArr.includes(check)) {
          if (item !== null) {
            topResults.push(imgListFormat[Object.keys(item)]);
          }
        }
        return null;
      });
    return topResults;
  };

  const getTestData = async () => {
    const resp = user.sortedResults;
    try {
      setNumberOfEarths(getEarths(format(resp, 0)));
      setNumberOfEarthsTotal(getEarths(format(resp, resp.length - 1)));
      setTopRank(createTopResults(resp[0]).slice(0, 3));
    } catch (err) {
      // console.log('error:', err);
    }
  };

  const getStoreResults = () => {
    const result = questions.calculatedResults;
    const preFilled = {
      bathing: result.QUESTION_BATHING.recipe || 0,
      car: result.QUESTION_CAR.recipe || 0,
      clothes: result.QUESTION_CLOTHES.recipe || 0,
      diary: result.QUESTION_DAIRY.recipe || 0,
      flying: result.QUESTION_FLYING.recipe || 0,
      food: result.QUESTION_FOOD.recipe || 0,
      housing: result.QUESTION_HOUSING.recipe || 0,
      meat: result.QUESTION_MEAT.recipe || 0,
      possessions: result.QUESTION_POSSESSIONS.recipe || 0,
      publictransport: result.QUESTION_PUBLIC_TRANSPORT.recipe || 0,
    };
    setTopRank(createTopResults(preFilled).slice(0, 3));
  };

  const getGoalList = async () => {
    const resp = [];
    try {
      await Promise.all(user.goals.map(async (goals) => {
        if (goals !== '' && goals !== undefined && goals.length !== 0) {
          const contents = await API.graphql(graphqlOperation(GetGoal, { id: goals }));
          resp.push(contents.data.getGoal);
        }
      }));
      const oldArray = resp.filter((item) => item.createdOn === null);
      const newArray = resp.filter((item) => item.createdOn !== null);

      newArray.sort((a, b) => a.createdOn.localeCompare(b.createdOn)).reverse();

      const mergedArry = newArray.concat(oldArray);
      setNumberOfEarthsToGoal(mergedArry[0].earthsGoal);

      setGoalList(mergedArry);
    } catch (err) {
      // console.log('error: ', err);
    }
  };

  const getActionsAcceptedList = async () => {
    const resp = [];
    try {
      await Promise.all(user.acceptedActions.map(async (action) => {
        if (action !== null || action !== undefined) {
          const result = await API.graphql(
            graphqlOperation(GetAction, {
              id: action,
            }),
          );
          const { contentfulId } = result.data.getAction;
          (actionCards.filter((item) => {
            if (item.id === contentfulId) {
              resp.push(item);
            }
            return null;
          }));
          setActionList(resp);
        }
      }));
    } catch (err) {
      // console.log(err);
    }
  };

  const getGroupsUser = async () => {
    const resp = [];
    try {
      await Promise.all(user.groups.map(async (groupId) => {
        if (groupId !== null) {
          const result = await API.graphql(
            graphqlOperation(GetGroup, {
              id: groupId,
            }),
          );
          resp.push(result.data.getGroup);
        }
      }));
      setGroupList(resp);
    } catch (err) {
      // console.log(err);
    }
  };
  const dashboardItems = [
    {
      path: paths[GOALS],
      addPath: `/${lang}/goalsedit/create`,
      items: goalList,
      type: 'goalsitem',
      maxItems: 3,
      noItemImg: GoalsImg,
      buttonTitleNoItems: <LanguageLabel id="dashboardGoalsButtonNoItemsTitle" />,
      buttonTitleItems: <LanguageLabel id="dashboardGoalsButtonItemsTitle" />,
      noItemsParagraph: <LanguageParagraph id="dashboardGoalsParagraph" />,
      title: <LanguageLabel id="dashboardGoalsTitle" />,
    },
    {
      path: paths[ACTIONS],
      addPath: paths[ACTIONS],
      items: actionList,
      type: 'actionitem',
      maxItems: 3,
      noItemImg: ActionImg,
      buttonTitleNoItems: <LanguageLabel id="dashboardActionButtonNoItemsTitle" />,
      buttonTitleItems: <LanguageLabel id="dashboardActionButtonItemsTitle" />,
      noItemsParagraph: <LanguageParagraph id="dashboardActionParagraph" />,
      title: <LanguageLabel id="dashboardActionTitle" />,
    },
    // {
    //   path: paths[GROUPS],
    //   addPath: '/newgroup',
    //   items: groupList,
    //   type: 'groupitem',
    //   maxItems: 3,
    //   noItemImg: GroupImg,
    //   buttonTitleNoItems: <LanguageLabel id="dashboardGroupButtonNoItemsTitle" />,
    //   buttonTitleItems: <LanguageLabel id="dashboardGroupButtonItemsTitle" />,
    //   noItemsParagraph: <LanguageParagraph id="dashboardGroupParagraph" />,
    //   title: <LanguageLabel id="dashboardGroupTitle" />,
    // },
  ];

  useEffect(() => {
    fetchActionCards();
  }, []);

  useEffect(() => {
    if (questions.currentResultId !== '') {
      getStoreResults();
    }
    if (user.results) {
      getTestData();
      getGoalList();
      getActionsAcceptedList();
      getGroupsUser();
      setLoggedIn(true);
    }
  }, [user, questions]);

  return (
    <>
      <div className="dashboard">
        {topRank.length > 0 && goalList.length > 0 && loggedIn && (
          <div className="dashboard-header">
            <Heading className="primary">
              <LanguageLabel
                id="dashboardTitle"
                replace={<span className="earth-number">{Math.round(numberOfEarthsTotal - numberOfEarths)}</span>}
              />
            </Heading>
            <Heading>
              {Math.round(numberOfEarthsTotal - numberOfEarths) < 1 ? (
                <LanguageLabel
                  id="dashboardSubtitleNegativeEarths"
                  replace={<span className="earth-number">{Math.round(numberOfEarths - numberOfEarthsTotal)}</span>}
                />
              ) : (
                <LanguageLabel
                  id="dashboardSubtitle"
                  replace={<span className="earth-number">{Math.round(numberOfEarthsTotal - numberOfEarths)}</span>}
                />
              )}
            </Heading>
            <EarthCounter earths={numberOfEarths} transparentEarths={numberOfEarthsTotal} yellowBorderEarths={numberOfEarthsToGoal} />
          </div>
        )}
        <div className={classNames('dashboardblock', topRank && 'no-items')}>
          <PodiumBlock
            title={<LanguageLabel id="dashboardResultTitle" />}
            addPath={paths[QUESTIONSHOME]}
            Path={paths[RESULTS]}
            list={imgList}
            rank={topRank}
            noRankParagraph={<LanguageParagraph id="dashboardResultParagraph" />}
          />
        </div>
        {dashboardItems.map((item) => (
          <div key={item.type} className={classNames('dashboardblock', item.items.length === 0 && 'no-items')}>
            <InfoList
              accountPath="/login?account"
              addPath={item.addPath}
              addFunction={item.addFunction}
              buttonTitleItems={item.buttonTitleItems}
              buttonTitleNoItems={item.buttonTitleNoItems}
              items={item.items}
              type={item.type}
              loggedIn={loggedIn}
              maxItems={item.maxItems}
              noItemImg={item.noItemImg}
              noItemsParagraph={item.noItemsParagraph}
              path={item.path}
              title={item.title}
              earths={numberOfEarths}
              itemComplete={item}
              topRank={topRank}
            />
          </div>
        ))}
      </div>
      <div className="background-illustrations">
        {!actionList.length > 0 && !goalList.length > 0 && loggedIn && (
        <>
          <div className="background"><Background /></div>
        </>
        )}
      </div>
    </>
  );
};

Dashboard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
    sortedResults: PropTypes.arrayOf(PropTypes.shape({})),
    goals: PropTypes.arrayOf(PropTypes.string),
    acceptedActions: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
  fetchActionCards: PropTypes.func.isRequired,
  actionCards: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  questions: PropTypes.shape({
    currentResultId: PropTypes.string,
    results: PropTypes.shape({}),
    calculatedResults: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }),
};

Dashboard.defaultProps = {
  user: PropTypes.shape({
    id: '',
    results: [],
    goals: [],
    acceptedActions: [],
  }),
  questions: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
