// @Remove comments after live

import { lang } from '../../../utils/locale';

const menuStructure = {
  loggedIn: {
    testDone: [
      {
        className: '',
        disable: false,
        img: '',
        label: 'Dashboard',
        to: `/${lang}/dashboard/`,
      },
      {
        className: '',
        disable: false,
        img: '',
        label: lang === 'en' ? 'Actions' : 'Acties',
        to: `/${lang}/actions`,
      },
      {
        className: '',
        disable: false,
        img: '',
        label: lang === 'en' ? 'Results' : 'Resultaat',
        to: `/${lang}/results`,
      },
      // {
      //   className: '',
      //   disable: false,
      //   img: '',
      //   label: 'Groepen',
      //   to: '/groups',
      // },
      {
        className: '',
        disable: false,
        img: '',
        label: lang === 'en' ? 'Goals' : 'Doelen',
        to: `/${lang}/goals`,
      },
    ],
    noTest: [
      {
        className: '',
        disable: false,
        img: '',
        label: 'Dashboard',
        to: `/${lang}/dashboard`,
      },
      {
        className: '',
        disable: true,
        img: '',
        label: lang === 'en' ? 'Actions' : 'Acties',
        to: `/${lang}/actions`,
      },
      {
        className: '',
        disable: true,
        img: '',
        label: lang === 'en' ? 'Results' : 'Resultaat',
        to: `/${lang}/result`,
      },
      // {
      //   className: '',
      //   disable: true,
      //   img: '',
      //   label: 'Groepen',
      //   to: '/groups',
      // },
      {
        className: '',
        disable: true,
        img: '',
        label: lang === 'en' ? 'Goals' : 'Doelen',
        to: `/${lang}/goals`,
      },
    ],
  },
  notLoggedIn: {
    testDone: [
      {
        className: '',
        disable: false,
        img: '',
        label: 'Dashboard',
        to: `/${lang}/dashboard`,
      },
      {
        className: '',
        disable: false,
        img: '',
        label: lang === 'en' ? 'Actions' : 'Acties',
        to: `/${lang}/actions`,
      },
      {
        className: '',
        disable: false,
        img: '',
        label: lang === 'en' ? 'Results' : 'Resultaat',
        to: `/${lang}/results`,
      },
    ],
    noTest: [
      {
        className: '',
        disable: false,
        img: '',
        label: 'Dashboard',
        to: `/${lang}/dashboard`,
      },
    ],
  },
};

export default menuStructure;
