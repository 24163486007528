import { applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { takeLeading } from 'redux-saga/effects';

// ACTION TYPES
import { FETCH_LANGUAGE_LABELS, FETCH_LANGUAGE_PARAGRAPH } from '../actions/languageActionTypes';
import { FETCH_TOOLTIPS } from '../actions/tooltipTypes';
import { FETCH_DROPDOWN } from '../actions/dropdownActionTypes';
import { FETCH_PRODUCT } from '../actions/productActionTypes';
import { FETCH_TEAM_CARDS } from '../actions/teamCardActionTypes';
import { FETCH_MODAL_EXPLAINING } from '../actions/modalExplainingActionTypes';
import { FETCH_COUNTER_GROUP } from '../actions/counterGroupActionTypes';
import { FETCH_TOOLTIPS_PERCENTAGE } from '../actions/tooltipPercentageActionTypes';
import { FETCH_SELECTOR_BOX } from '../actions/selectorBoxActionTypes';
import { FETCH_ACTIONCARDS } from '../actions/actionCardsActionTypes';
import { FETCH_ACTIONTIPS } from '../actions/actionTipsActionTypes';
import { FETCH_MODAL_TOP_10 } from '../actions/modalTop10ActionTypes';

// import { FETCH_SUGGESTIONS } from '../actions/suggestionActionTypes';

// sagas
import fetchLanguageLabels from './languagelabels';
import fetchTooltips from './tooltips';
import fetchDropdown from './dropdown';
import fetchProducts from './product';
import fetchLanguageParagraphs from './languageParagraph';
import fetchTeamCards from './teamCards';
import fetchModalExplaining from './modalExplaining';
import fetchCounterGroup from './counterGroup';
import fetchTooltipsPercentage from './tooltipPercentage';
import fetchSelectorBox from './selectorBox';
import fetchActionCards from './actionCards';
import fetchActionTips from './actionTips';
import fetchModalTop10 from './modalTop10';

// import fetchSuggestions from './suggestions';

const sagaMiddleWare = createSagaMiddleware();
const sagaConfig = applyMiddleware(sagaMiddleWare);

const sagas = [
  function* languageLabels() {
    yield takeLeading(FETCH_LANGUAGE_LABELS, fetchLanguageLabels);
  },
  function* tooltips() {
    yield takeLeading(FETCH_TOOLTIPS, fetchTooltips);
  },
  function* dropdown() {
    yield takeLeading(FETCH_DROPDOWN, fetchDropdown);
  },
  function* products() {
    yield takeLeading(FETCH_PRODUCT, fetchProducts);
  },
  function* languageParagraphs() {
    yield takeLeading(FETCH_LANGUAGE_PARAGRAPH, fetchLanguageParagraphs);
  },
  function* teamCards() {
    yield takeLeading(FETCH_TEAM_CARDS, fetchTeamCards);
  },
  function* modalExplaining() {
    yield takeLeading(FETCH_MODAL_EXPLAINING, fetchModalExplaining);
  },
  function* counterGroup() {
    yield takeLeading(FETCH_COUNTER_GROUP, fetchCounterGroup);
  },
  function* tooltipsPercentage() {
    yield takeLeading(FETCH_TOOLTIPS_PERCENTAGE, fetchTooltipsPercentage);
  },
  function* selectorBox() {
    yield takeLeading(FETCH_SELECTOR_BOX, fetchSelectorBox);
  },
  function* actionCards() {
    yield takeLeading(FETCH_ACTIONCARDS, fetchActionCards);
  },
  function* actionTips() {
    yield takeLeading(FETCH_ACTIONTIPS, fetchActionTips);
  },
  function* modalTop10s() {
    yield takeLeading(FETCH_MODAL_TOP_10, fetchModalTop10);
  },
];

const startSagas = () => {
  sagas.forEach((saga) => sagaMiddleWare.run(saga));
};

export { sagaConfig, startSagas };
