import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './style.scss';

const { Handle } = RCSlider;

const SliderPercentage = ({
  updateParent,
  leftContent,
  rightContent,
  isSliding,
  defaultValue,
}) => {
  const initialStartLeft = () => {
    if (defaultValue > 50) return defaultValue;
    if (defaultValue < 50) return 100 - defaultValue;
    return 50;
  };

  const initialStartRight = () => {
    if (defaultValue < 50) return defaultValue;
    if (defaultValue > 50) return 100 - defaultValue;
    return 50;
  };

  const [leftBarWidth, setLeftBarWidth] = useState(initialStartLeft().toString());
  const [rightBarWidth, setRightBarWidth] = useState(initialStartRight().toString());

  const handle = (props) => {
    const {
      value, dragging, index, ...restProps
    } = props;
    return (
      <Handle value={value} {...restProps}>
        <div className={classNames('tooltip-custom')} />
      </Handle>
    );
  };

  handle.propTypes = {
    dragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  };

  const onChange = (val) => {
    updateParent(val);
    const percent = val * (100 / 100);
    setLeftBarWidth(percent);
    setRightBarWidth(100 - percent);
  };

  const setSliding = (sliding) => {
    isSliding(sliding);
  };

  useEffect(() => {
    setSliding(false);
    setSliding(true);
  }, []);

  return (
    <div
      className="slider-percentage"
      onMouseEnter={() => setSliding(false)}
      onMouseLeave={() => setSliding(true)}
      onTouchMove={() => setSliding(false)}
      onTouchEnd={() => setSliding(true)}
    >
      <RCSlider min={0} max={100} defaultValue={defaultValue} handle={handle} onChange={onChange} />
      <div className="gradiant-bar">
        <div className="left-bar" style={{ width: `${leftBarWidth}%` }} />
        <div className="right-bar" style={{ width: `${rightBarWidth}%` }} />
      </div>
      <div className="values">
        <div className="left">
          <span>{rightBarWidth}%</span>
          <span>{leftContent}</span>
        </div>
        <div className="right">
          <span>{leftBarWidth}%</span>
          <span>{rightContent}</span>
        </div>
      </div>
    </div>
  );
};

SliderPercentage.propTypes = {
  updateParent: PropTypes.func,
  isSliding: PropTypes.func.isRequired,
  leftContent: PropTypes.string.isRequired,
  rightContent: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
};

SliderPercentage.defaultProps = {
  updateParent: null,
  defaultValue: 50,
};

export default (SliderPercentage);
