import { call, put } from 'redux-saga/effects';
import cache from 'memory-cache-pro';

import { SET_ACTIONTIPS } from '../actions/actionTipsActionTypes';

import { findActionTips } from '../connectors/contentful';

function* fetchActionTips() {
  let items = cache.get('actionTips');

  if (!items) {
    const response = yield call(() => findActionTips());
    items = response.items;
    cache.put('actionTips', items);
  }

  yield put({
    type: SET_ACTIONTIPS,
    actionTips: items,
  });
}

export default fetchActionTips;
