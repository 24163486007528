import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CloudSVG } from '../../assets/icons/ic-cloud.svg';

import './style.scss';

const Cloud = ({
  pathname,
}) => {
  const [className, setClassName] = useState();
  const cloudLocations = {
    dashboard: {
      style: 'dashboard',
      count: ['one', 'two'],
    },
    groups: {
      style: 'group',
      count: ['one', 'two'],
    },
    newgroup: {
      style: 'newgroup',
      count: ['one', 'two'],
    },
    goals: {
      style: 'goals',
      count: ['one', 'two'],
    },
  };
  const pathnameClean = pathname.replace('/', '');

  const cloudFormation = () => {
    if (cloudLocations[pathnameClean] !== undefined) {
      setClassName(cloudLocations[pathnameClean].style);
      return;
    }
    setClassName();
  };

  useEffect(() => {
    cloudFormation();
  }, [pathname]);

  const array = [];

  if (cloudLocations[pathnameClean] !== undefined) {
    cloudLocations[pathnameClean].count.map((item) => {
      array.push(
        <div key={uuidv4()} className={classNames(`cloud cloud-${className} ${item}`)}>
          <CloudSVG />
        </div>,
      );
      return null;
    });
  }

  return array;
};

Cloud.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Cloud;
