import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from '../../Heading';
import EarthCounter from '../../EarthCounter';
import Button from '../../Button';
import ResultBar from '../ResultBar';
import StepIndicatorMobile from '../../StepIndicatorMobile';

import order from '../../../config/chart';

import ModalBG from '../../../assets/backgrounds/group-bg.svg';

import './style.scss';
import LanguageLabel from '../../LanguageLabel';

const ModalChart = ({
  activeChart,
  activeModal,
  handleModal,
  data,
  getPercentage,
  editValue,
  top10,
  edit,
  goals,
  goalRecipe,
  goalScore,
  resultRecipe,
  highScore,
  goalUpdate,
  earthsGoal,
  earthsOriginal,
  saveFunc,
  cancelFunc,
  handleEnableScroll,
}) => {
  const [showModal, setShowModal] = useState(activeChart);
  const [modalNumber, setModalNumber] = useState();

  const changeModal = () => {
    setShowModal(order[modalNumber]);
  };

  const changeModalNumber = (add) => {
    if (add) {
      setModalNumber(modalNumber + 1);
      return;
    }
    setModalNumber(modalNumber - 1);
  };

  const handleClickModal = (e) => {
    handleEnableScroll();
    if (e.target.className === 'modal-container') {
      handleModal();
    }
  };

  const cancelButton = () => {
    handleModal();
    cancelFunc();
    handleEnableScroll();
  };

  const saveButton = () => {
    handleModal();
    saveFunc(true);
    handleEnableScroll();
  };

  useEffect(() => {
    const x = order.indexOf(activeChart);
    setModalNumber(x);
    setShowModal(order[x]);
  }, [activeChart]);

  useEffect(() => {
    changeModal();
  }, [modalNumber]);

  return (
    <div className={classNames('resultChart-modal', activeModal && 'active')} onClick={(e) => handleClickModal(e)}>
      <div className="modal-container">
        {data.filter((item) => item.id === showModal).map((item) => (
          <div key={item.id} className="modal-box">
            Stel je doel in voor
            <Heading className="primary">{item.name}</Heading>
            <div className="earthcounter-box">
              <EarthCounter transparentEarths={earthsOriginal} yellowBorderEarths={earthsGoal} size="small" />
            </div>
            {modalNumber !== 0 && <div className="modal-button left" onClick={() => changeModalNumber(false)}><span>&lt;</span></div>}
            {modalNumber !== 9 && <div className="modal-button right" onClick={() => changeModalNumber(true)}><span>&gt;</span></div>}
            <div className="chart">
              <ResultBar
                id={item.id}
                key={item.id}
                iconClass={`ic-${item.icon}`}
                description={item.name}
                edit={edit}
                maxValue={resultRecipe[item.id]}
                maxGoalValue={goalRecipe[item.id]}
                benchmarkHeight={getPercentage(top10[item.id], highScore)}
                resultHeight={getPercentage(resultRecipe[item.id], highScore)}
                goalHeight={goals && getPercentage(goalRecipe[item.id], goalScore)}
                resultPercentage={getPercentage(resultRecipe[item.id], top10[item.id])}
                goalPercentage={goals && getPercentage(goalRecipe[item.id], top10[item.id])}
                modal
                editValue={editValue}
                goalUpdate={goalUpdate}
              />
            </div>
            <div className="modal-bg">
              <img src={ModalBG} alt="modal-bg" />
            </div>
            <div className="modal-footer">
              <Button onClick={saveButton} className="secondary small" label={<LanguageLabel id="modalChartSaveButton" />} />
              <Button onClick={cancelButton} className="rounded" label={<LanguageLabel id="modalChartCancelButton" />} />
            </div>
            <div className="modal-steps">
              <StepIndicatorMobile steps={10} currentStep={modalNumber} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ModalChart.propTypes = {
  activeModal: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getPercentage: PropTypes.func.isRequired,
  editValue: PropTypes.func.isRequired,
  top10: PropTypes.shape({}).isRequired,
  edit: PropTypes.bool.isRequired,
  goals: PropTypes.shape({}).isRequired,
  goalRecipe: PropTypes.shape({}).isRequired,
  goalScore: PropTypes.number.isRequired,
  resultRecipe: PropTypes.shape({}).isRequired,
  highScore: PropTypes.number.isRequired,
  activeChart: PropTypes.string.isRequired,
  handleModal: PropTypes.func.isRequired,
  goalUpdate: PropTypes.bool,
  earthsGoal: PropTypes.number.isRequired,
  earthsOriginal: PropTypes.number.isRequired,
  saveFunc: PropTypes.func,
  cancelFunc: PropTypes.func,
  handleEnableScroll: PropTypes.func.isRequired,
};

ModalChart.defaultProps = {
  goalUpdate: false,
  saveFunc: () => {},
  cancelFunc: () => {},
};

export default ModalChart;
