import React from 'react';
import SliderDial from '../components/SliderDial';
import Slider from '../components/Slider';

const getEarths = (results) => {
  const totalRecipePointsTop10 = 906;
  const recipePointsResults = Object.values(results).map((item) => item.recipe || 0);
  const totalRecipePointsResults = recipePointsResults.reduce((a, b) => a + b, 0);
  const recipePointsPerEarth = totalRecipePointsTop10 / 3;
  const numberOfEarths = (totalRecipePointsResults / recipePointsPerEarth) || 0;
  let roundedEarths = Math.round(numberOfEarths * 10) / 10;

  if (roundedEarths > 10) {
    roundedEarths = Math.round(roundedEarths);
  }
  return roundedEarths || 0;
};

// makeTooltipsSliderDial:
//   QUESTION_POSSESSIONS
//   QUESTION_MEAT
//   QUESTION_FOOD
//   QUESTION_FLYING
//   QUESTION_DAIRY
//   QUESTION_BATHING
//   QUESTION_PUBLIC_TRANSPORT

//   const: id, questionId, tooltips, results, values,
//   function: openSpecificModal, sliderChange, updateSwipe
const makeTooltipsSliderDial = (
  id,
  openSpecificModal,
  questionId,
  results,
  sliderChange,
  tooltips,
  updateSwipe,
  values,
) => {
  const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
  return (
    filterTooltips.map((item) => {
      let { defaultValue } = item;
      if (results[questionId] && results[questionId][item.id]) defaultValue = results[questionId][item.id];
      else if (values && values[item.id]) defaultValue = values[item.id];
      return (
        <SliderDial
          changed={(value) => sliderChange(item.id, value)}
          defaultValue={values[item.id] || defaultValue}
          description={item.label}
          descriptions={item.descriptions}
          isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
          key={item.id}
          max={item.max}
          min={item.min}
          money={item.money}
          popModal={() => openSpecificModal(item.explanation)}
          popModalButton={item.explanation}
          snapToZero={item.snapToZero}
          unit={item.unit}
        />
      );
    })
  );
};

// makeTooltipsSlider:
//   QUESTION_FOOD
//   QUESTION_HOUSING

//   const: id, results, questionId, values, swipeOnQuestions, tooltips
//   function: sliderChange, openSpecificModal, updateSwipe

const makeTooltipsSlider = (
  id,
  openSpecificModal,
  questionId,
  results,
  sliderChange,
  swipeOnQuestions,
  tooltips,
  updateSwipe,
  values,
) => {
  const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
  return (
    filterTooltips.map((item) => {
      let { defaultValue } = item;
      if (results[questionId] && results[questionId][item.id]) defaultValue = results[questionId][item.id];
      else if (values && values[item.id]) defaultValue = values[item.id];
      return (
        <Slider
          defaultValue={values[item.id] || defaultValue}
          descriptions={item.descriptions}
          isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
          key={item.id}
          max={item.max}
          min={item.min}
          popModal={() => openSpecificModal(item.explanation)}
          popModalButton={item.explanation}
          swiping={swipeOnQuestions}
          text={item.label}
          unit={item.unit}
          updateParent={(value) => sliderChange(item.id, value)}
        />
      );
    })
  );
};

export {
  getEarths,
  makeTooltipsSliderDial,
  makeTooltipsSlider,
};
