import { SET_DROPDOWN } from '../actions/dropdownActionTypes';

import { getDropdown } from '../transformers/dropdownTransformer';

const initialState = {
  dropdown: [],
};

const dropdown = (state = initialState, action) => {
  switch (action.type) {
    case SET_DROPDOWN: {
      const dropdown = getDropdown(action.dropdown);
      return {
        ...state,
        dropdown,
      };
    }
    default:
      return state;
  }
};

export default dropdown;
