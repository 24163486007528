const calculate = (questionResults, average, mode) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  let recipe = 0;

  // console.log(mode)

  if (mode) {
    const digital = linearScaling('digital') || 0;
    const electric = linearScaling('electric') || 0;
    const books = linearScaling('books') || 0;
    const other = linearScaling('other') || 0;
    recipe = (digital + electric + books + other) || 0;
  } else {
    const globalslider = linearScaling('possessions') || 0;
    recipe = globalslider || 0;
  }

  return {
    // digital,
    // electric,
    // books,
    // other,
    // globalslider,
    recipe,
  };
};

export default calculate;
