/* eslint-disable no-unused-vars */
import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as loading from '../../animations/loading.json';

import './style.scss';

const LoadingIndicator = ({
  className,
  type,
}) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className="loading-animation">
      <Lottie
        options={animationOptions}
      />
    </div>
  );
};

LoadingIndicator.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  className: '',
};

export default LoadingIndicator;
