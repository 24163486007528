import { SET_USER } from '../../store/actions/userActionTypes';
import { SET_MOBILE_VIEW } from '../../store/actions/mobileViewActionTypes';

const mapDispatchToProps = (dispatch) => ({
  updateMobileView: (mobileView) => dispatch({
    type: SET_MOBILE_VIEW,
    payload: {
      mobileView,
    },
  }),

  setUser: (user) => dispatch({
    type: SET_USER,
    payload: {
      user,
    },
  }),
});

export default mapDispatchToProps;
