import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import order from '../../config/questions';

import QuestionBathing from '../QuestionBathing';
import QuestionCar from '../QuestionCar';
import QuestionClothes from '../QuestionClothes';
import QuestionDairy from '../QuestionDairy';
import QuestionFlying from '../QuestionFlying';
import QuestionFood from '../QuestionFood';
import QuestionHousing from '../QuestionHousing';
import QuestionMeat from '../QuestionMeat';
import QuestionPossessions from '../QuestionPossessions';
import QuestionPublicTransport from '../QuestionPublicTransport';
import QuestionUser from '../QuestionUser';

import StepIndicator from '../../components/StepIndicator';
import Checkbox from '../../components/Checkbox';
import Question from '../../components/Question';
// import ToggleButton from '../../components/ToggleButton';
import Modal from '../../components/Modal';
import ModalOverlay from '../../components/ModalOverlay';
import RichText from '../../components/LanguageRichParagraph';
import LanguageLabel from '../../components/LanguageLabel';
import StepIndicatorQuestionsMobile from '../../components/StepIndicatorQuestionsMobile';

// import { cookiesAccepted, isLocalhost, isTestEnvironment } from '../../utils/environmentutil';

import './style.scss';

const Questions = ({
  match,
  fetchModalExplaining,
  history,
  updateConsent,
  modalExplaining,
  mobileView,
}) => {
  const { params } = match;
  const { id: paramId } = params;
  const id = paramId || 0;

  // const [mode, setMode] = useState(false);
  const [genericModalState, setGenericModalState] = useState(false);
  const [specificModalState, setSpecificModalState] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(Number(id));
  const [consent, setConsent] = useState(false);
  const [hideToggle, setHideToggle] = useState(false);
  const [modalCloser, setModalCloser] = useState(false);

  const setSwipe = () => {};

  // const changeMode = () => {
  //   if (cookiesAccepted && !isLocalhost && !isTestEnvironment) {
  //     ReactGA.event({
  //       category: 'Mode Switch',
  //       action: `Set mode to ${!mode ? 'precies' : 'snel'}`,
  //     });
  //   }
  //   setMode(!mode);
  // };

  const updateSlider = (value) => {
    if (Number.isInteger(value) && Number.isInteger(value) !== 11) {
      setCurrentSlide(Math.floor(value));
      if (match.path.includes('questions')) {
        history.push(`${value}`);
      } else {
        history.push(`questions/${value}`);
      }
    }
  };

  const handleModalCloser = () => {
    if (window.innerWidth < 767) setModalCloser(true);
    else {
      setModalCloser(false);
    }
  };

  useEffect(() => {
    handleModalCloser();
    window.addEventListener('resize', handleModalCloser);
    return () => window.removeEventListener('resize', handleModalCloser);
  }, []);

  // Set the question numbers for which you do NOT want to have the toggle
  const toggleDisabledOn = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const toggleHide = () => {
    setHideToggle(
      toggleDisabledOn.includes(Number(id)),
    );
  };

  const changeUrl = (url) => {
    window.location = url;
  };

  const setPrivacyAgreement = () => {
    window.localStorage.setItem('privacyAccepted', consent);
    setSpecificModalState();
  };

  useEffect(() => {
    toggleHide();
  }, []);

  useEffect(() => {
    setCurrentSlide(Number(id));
    toggleHide();
  }, [id]);

  useEffect(() => {
    updateConsent(!consent);
  }, [consent]);

  useEffect(() => {
    if (modalExplaining.length === 0) {
      fetchModalExplaining();
    }
  }, [fetchModalExplaining]);

  const filterModalContentMobile = () => {
    const ids = [
      { id: '' },
      { id: 'foodQuestion-modal' },
      { id: 'meatAndFishQuestion-modal' },
      { id: 'dairyQuestion-modal' },
      { id: 'clothesQuestion-modal' },
      { id: 'possessionsQuestion-modal' },
      { id: 'housingQuestion-modal' },
      { id: 'bathingQuestion-modal' },
      { id: 'carQuestion-modal' },
      { id: 'flyingQuestion-modal' },
      { id: 'publicTransportQuestion-modal' },
    ];
    const currentModal = modalExplaining.filter((item) => item.id === ids[id].id);
    return currentModal;
  };

  return (
    <div className="questions">
      <Question
        modalContent={filterModalContentMobile()}
        modalCloser={modalCloser}
        hideToggle={hideToggle}
        modalId={genericModalState}
        cloudPosition={currentSlide.toString()}
      >
        <StepIndicator items={order} activeQuestion={order[currentSlide]} />
        <StepIndicatorQuestionsMobile activeQuestion={currentSlide} />
        {/* <ToggleButton
          beforeText="snel"
          toggleButtonValue={!mode}
          onClick={() => changeMode()}
          afterText="precies"
          className={hideToggle ? 'show-toggle' : 'show-toggle'}
          isSliding={(swipe) => setSwipe(swipe)}
        /> */}
        <SwipeableViews
          index={currentSlide}
          onSwitching={(value) => updateSlider(value)}
          disabled
          className="swipeableview"
        >
          <QuestionUser
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            openModalOverlay={(e) => setSpecificModalState(e)} // setModalOverlayState
            mobileView={mobileView}
            consent={consent}
          />
          <QuestionFood
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionMeat
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionDairy
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionClothes
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionPossessions
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionHousing
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionBathing
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openModalOverlay={(e) => setSpecificModalState(e)}
            openGenericModal={(e) => setGenericModalState(e)}
            mobileView={mobileView}
          />
          <QuestionCar
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionFlying
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openGenericModal={(e) => setGenericModalState(e)}
            openSpecificModal={(e) => setSpecificModalState(e)}
            mobileView={mobileView}
          />
          <QuestionPublicTransport
            setSwipeOnContainer={(swipe) => setSwipe(swipe)}
            shouldIupdate={currentSlide}
            openModalOverlay={(e) => setSpecificModalState(e)}
            openGenericModal={(e) => setGenericModalState(e)}
            mobileView={mobileView}
          />
        </SwipeableViews>
      </Question>

      {modalExplaining.map((item) => (
        <Modal key={item.id} onClick={() => setGenericModalState()} modalSwitch={genericModalState === item.id}>
          <div className={classNames('question-modal', 'heading-modal')}>
            <p>
              {ReactHtmlParser(item.content)}
            </p>
          </div>
        </Modal>
      ))}
      {modalExplaining.map((item) => (
        <ModalOverlay
          key={item.id}
          itemId={item.id}
          onClick={() => setPrivacyAgreement()}
          changeUrl={item.id === 'privacy-modal' && (() => changeUrl('/'))}
          modalSwitch={specificModalState === item.id}
          buttonLabel={item.id === 'privacy-modal' ? <LanguageLabel id="modalContinueLabel" /> : <LanguageLabel id="modalCloseLabel" />}
          // eslint-disable-next-line max-len
          headingText={item.id === 'privacy-modal' ? <LanguageLabel id="userQuestionPrivacyModalTitle" /> : <LanguageLabel id="userQuestionModalExplanationTitle" />}
          showSubBool={item.id === 'privacy-modal' ? !consent : false}
          showSubText={item.id === 'privacy-modal' ? <LanguageLabel id="userQuestionModalSubText" /> : {}}
        >
          <>
            {item.id === 'privacy-modal' ? (
              <div className="modal-box">
                <div className="heading-modal">
                  <div className="row">
                    <div className="col-12">
                      <div className="disclaimer-content">
                        <p><i><LanguageLabel id="userQuestionModalTitle" /></i></p>
                        <div className="scrollContainer">
                          <div className="scroll">
                            <RichText text={item.richContent} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('row', 'check-container')}>
                    <Checkbox
                      id="emailconsent"
                      label={<LanguageLabel id="userQuestionModalCheckbox" />}
                      onChange={() => {
                        setConsent(!consent);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="heading-modal">
                <div className="row">
                  <div className="col-12">
                    <p>{ReactHtmlParser(item.content)}</p>
                  </div>
                </div>
              </div>
            )}
          </>
        </ModalOverlay>
      ))}
    </div>
  );
};

Questions.propTypes = {
  fetchModalExplaining: PropTypes.func.isRequired,
  modalExplaining: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape().isRequired,
  updateConsent: PropTypes.func,
  mobileView: PropTypes.bool.isRequired,
};

Questions.defaultProps = {
  id: '',
  updateConsent: null,
  match: {
    path: '',
    params: {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
