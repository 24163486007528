import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CheckboxInput = ({
  id,
  label,
  onChange,
  checked,
}) => (
  <label
    htmlFor={id}
    className="checkboxinput"
  >
    <input
      id={id}
      onChange={onChange}
      type="checkbox"
      defaultChecked={checked}
    />
    <span className="checkmark" />
    <span className="text">
      {label}
    </span>
  </label>
);

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

CheckboxInput.defaultProps = {
  label: '',
};

export default CheckboxInput;
