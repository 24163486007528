/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';

import Heading from '../Heading';
import Button from '../Button';
import EarthCounter from '../EarthCounter';
import ModalAvatar from '../ModalAvatar';

import Copy from '../../assets/icons/ic-copy.svg';
import { ReactComponent as Cloud } from '../../assets/icons/ic-cloud.svg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const GroupCard = ({
  goal,
  goalId,
  link,
  img,
  name,
  location,
  people,
  earth,
  goalEarth,
  totalEarths,
  updateUserAvatar,
  imageError,
  transparentEarths,
}) => {
  const [modal, setModal] = useState(false);
  const [copied, setCopiedState] = useState(false);

  const handleClickCard = (event) => {
    if (event.target.className !== 'group-img' && !modal) {
      window.location.pathname = link;
    }
  };

  return (
    <div className={classNames('groupcard', goal && 'goalscard', modal && 'show')}>
      {!goal ? (
        <>
          <Heading className="primary">
            {name}
            <span>
              <div
                onAnimationEnd={() => setCopiedState(false)}
                className={classNames('copytext', copied && 'active-copy')}
              >
                <LanguageLabel id="copiedPopupLabel" />
              </div>
              <CopyToClipboard
                text={`https://www.mijnverborgenimpact.nl${link}`}
                onCopy={() => setCopiedState(true)}
              >
                <img className="copy" src={Copy} alt="copy" />
              </CopyToClipboard>
            </span>
          </Heading>
        </>
      ) : (
        <Heading className="primary">
          {name}
        </Heading>
      )}
      <div onClick={handleClickCard}>
        {goal ? (
          <>
            <figure onClick={() => setModal(!modal)} className="group-logo">
              <span className="bg" />
              <img src={img} alt="profile" className="group-img" />
            </figure>
            <ModalAvatar
              onClick={() => setModal(false)}
              className="avatar-goals"
              modalSwitch={modal}
              profile={img}
              saveProfileImage={(e) => updateUserAvatar(e, goalId)}
              error={imageError}
            />
          </>
        ) : (
          <figure onClick={() => setModal(!modal)} className="group-logo">
            <span className="bg" />
            <img src={img} alt="profile" className="group-img" />
          </figure>
        )}
        <div className="logo-design">
          <div className="logo-design-cloud-one"><Cloud /></div>
          <div className="logo-design-cloud-two"><Cloud /></div>
          <div className="logo-design-cloud-three"><Cloud /></div>
          <div className="logo-design-cloud-four"><Cloud /></div>
        </div>
        <div className="text">
          {!goal ? (
            <>
              {location} <span>{people} <LanguageLabel id="memberPluralLabel" /></span>
            </>
          ) : (
            <>
              <span><LanguageLabel id="groupCardGoalEarthLabel" /> {goalEarth} <LanguageLabel id="earthSingularLabel" /></span>
              <span><LanguageLabel id="groupCardCurrentEarthLabel" /> {earth} <LanguageLabel id="earthPluralLabel" /></span>
            </>
          )}
        </div>
        <div className="earthcounter-box">
          <EarthCounter earths={totalEarths} transparentEarths={transparentEarths} yellowBorderEarths={goalEarth} />
        </div>
        <Button
          className="secondary small"
          to={link}
          label={!goal ? <LanguageLabel id="viewGroupLabel" /> : <LanguageLabel id="viewGoalLabel" />}
        />
      </div>
    </div>
  );
};

GroupCard.propTypes = {
  goal: PropTypes.bool,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  people: PropTypes.number,
  earth: PropTypes.number,
  goalEarth: PropTypes.number,
  totalEarths: PropTypes.number,
  updateUserAvatar: PropTypes.func,
  imageError: PropTypes.bool,
  goalId: PropTypes.string,
};

GroupCard.defaultProps = {
  goal: false,
  goalEarth: 0,
  totalEarths: 0,
  earth: 0,
  updateUserAvatar: null,
  imageError: false,
  goalId: '',
  location: '',
  people: 0,
};

export default GroupCard;
