import { createStore, compose } from 'redux';
import reducers from './reducers/index';
import {
  sagaConfig,
  startSagas,
} from './sagas/index';

const composeEnhancers = process.env.NODE_ENV !== 'production'
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;
const enhancer = composeEnhancers(sagaConfig);

const store = createStore(
  reducers,
  enhancer,
);

startSagas();

export default store;
