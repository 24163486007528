import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RadialSlider } from 'react-radial-slider';

import Button from '../Button';

import { ReactComponent as SliderKnob } from '../../assets/icons/slider_knob.svg';

import './style.scss';

const SliderDial = ({
  defaultValue,
  max,
  min,
  changed,
  description,
  descriptions,
  unit,
  className,
  popModal,
  popModalButton,
  isSliding,
  money,
  snapToZero,
  questionId,
  id,
}) => {
  const maxValue = max;

  const [value, setValue] = useState(0);
  const [rotation, setRotation] = useState('no-rotate');

  const [defaultValueStat, setDefaultValueStat] = useState(defaultValue || 0.0);

  const onChange = (v) => {
    if (rotation === 'rotate') return;

    let val = v;
    if (val < 1) {
      changed(0);
      return;
    }

    if (val > max - 1) {
      setValue(max);
      changed(max);
      return;
    }

    if (val < snapToZero) val = 0;
    setValue(val);
    setDefaultValueStat(val);
    changed(val);
  };

  const setSliding = (sliding) => {
    isSliding(sliding);
  };

  const resetValue = () => {
    const cutter = 100000;
    let timeleft = value;
    setRotation('rotate');
    setDefaultValueStat(0);
    setValue(0);
    onChange(0);
    const downloadTimer = setInterval(() => {
      if (timeleft === 0 || timeleft < 0) {
        clearInterval(downloadTimer);
        setRotation('no-rotate');
      }
      timeleft -= cutter;
      if (timeleft < 0) timeleft = 0;
      onChange(timeleft);
      timeleft -= 1;
    }, 1);
  };

  const getDescription = (v) => {
    const description = descriptions.filter(({ start, end }) => v >= start && v <= end);
    return description.length > 0 ? description[0].label : 'noval';
  };

  const descr = getDescription(value);

  return (
    <div
      className={classNames(className, 'sliderdial')}
      onMouseEnter={() => setSliding(false)}
      onMouseLeave={() => setSliding(true)}
      onTouchMove={() => setSliding(false)}
      onTouchEnd={() => setSliding(true)}
    >
      <div className={classNames('reset-button', rotation)} onClick={resetValue} />
      <div>
        <RadialSlider
          width={162}
          min={min}
          max={maxValue}
          dataIndex={questionId ? defaultValue : defaultValueStat}
          progressLineCap="flat"
          hideLabelValue
          progressColorFrom="#ffca05"
          progressColorTo="#f49908"
          progressSize={10}
          trackColor="#99D7E1"
          trackSize={10}
          knobPosition="top"
          knobColor="transparent"
          onChange={onChange}
        >
          <SliderKnob
            width="100%"
            height="100%"
          />
        </RadialSlider>
        <div className="info-button">
          {popModalButton && (<Button label="?" className="info" onClick={popModal} />)}
        </div>

        <div className="content-blob">
          <div className="wrap">
            <span className="value">
              {money && '€'}
              {id === 'carLifters'
                ? (defaultValueStat < 10 ? (defaultValueStat > 5 ? 1 : 0) : defaultValue / 10)
                : (questionId ? value : defaultValueStat)}
              {value === maxValue && '+'}
            </span>
            <span className="description-static">{description}</span>
            <span className="unit">{unit}</span>
          </div>
        </div>
        <span className="description-dynamic">{descr !== 'noval' && descr}</span>
      </div>
    </div>
  );
};

SliderDial.propTypes = {
  defaultValue: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  changed: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  popModal: PropTypes.func,
  popModalButton: PropTypes.string,
  unit: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  isSliding: PropTypes.func.isRequired,
  money: PropTypes.bool,
  questionId: PropTypes.bool,
  snapToZero: PropTypes.number,
  id: PropTypes.string,
};

SliderDial.defaultProps = {
  className: '',
  snapToZero: 0,
  popModal: null,
  popModalButton: '',
  money: false,
  defaultValue: 0,
  id: '',
  questionId: false,
};

export default (SliderDial);
