import { SET_MODAL_EXPLAINING } from '../actions/modalExplainingActionTypes';

import { getModalExplaining } from '../transformers/modalExplainingTransformer';

const initialState = {
  modalExplaining: [],
};

const modalExplaining = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_EXPLAINING: {
      const modalExplaining = getModalExplaining(action.modalExplaining);

      return {
        ...state,
        modalExplaining,
      };
    }
    default:
      return state;
  }
};

export default modalExplaining;
