import React from 'react';
// import PropTypes from 'prop-types';

import Heading from '../Heading';
import Earth from '../Earth';
import Paragraph from '../Paragraph';
import Button from '../Button';
import LanguageLabels from '../LanguageLabel';
import LanguageParagraphs from '../LanguageParagraph';

import paths from '../../routes/paths';
import { QUESTIONSHOME } from '../../constants/pages';

import './style.scss';

const Intro = () => (
  <div className="earthIntro">
    <div className="earthContainer">
      <div className="cloud first" />
      <div className="cloud sec" />
      <Heading className="primary" type="h1">
        <span className="topheading">
          <LanguageLabels id="homeIntroTitle" />
        </span>
        <span className="subheading">
          <LanguageLabels id="homeIntroSubTitle" />
        </span>
        <span className="subheading">
          <LanguageLabels id="homeIntroSubTitle2" />
        </span>
      </Heading>
      <Earth>
        <Heading className="light" type="h2">
          <LanguageLabels id="homeIntroEarthTitle" />
        </Heading>
        <Button label={<LanguageLabels id="homeIntroEarthButton" />} to={`${paths[QUESTIONSHOME]}/0`} className="primary" />
      </Earth>
      <Paragraph>
        <LanguageParagraphs id="homeIntroParagraph" />
      </Paragraph>
    </div>
  </div>
);

Intro.propTypes = {

};

export default Intro;
