import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Anchor = ({
  className,
  label,
  link,
  disabled,
}) => (
  <a
    className={classNames('anchor', className, disabled && 'disabled')}
    href={link && link}
    target="blank"
  >
    {label}
  </a>
);

Anchor.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
};

Anchor.defaultProps = {
  disabled: false,
  label: '',
  link: '',
  className: '',
};

export default Anchor;
