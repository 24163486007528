import { SET_TOOLTIPS } from '../actions/tooltipTypes';

import { getTooltips } from '../transformers/tooltipTransformer';

const initialState = {
  tooltips: [],
};

const tooltips = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOOLTIPS: {
      const tooltips = getTooltips(action.tooltips);

      return {
        ...state,
        tooltips,
      };
    }
    default:
      return state;
  }
};

export default tooltips;
