import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const StepIndicatorMobile = ({
  currentStep,
  steps,
}) => (
  <div className="stepindicator-mobile">
    {currentStep !== 0 ? Array(currentStep).fill(<div className={classNames('blob', 'filled')} />)
      : null }
    <div className={classNames('blob', 'active')} />
    {currentStep !== 0 ? Array(steps - (currentStep + 1)).fill(<div className={classNames('blob', 'negative')} />)
      : Array(steps - 1).fill(<div className={classNames('blob', 'negative')} />) }
  </div>
);

StepIndicatorMobile.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number,
};

StepIndicatorMobile.defaultProps = {
  currentStep: 0,
};

export default StepIndicatorMobile;
