import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Quote = ({ children }) => (
  <div className="quote">
    {children}
  </div>
);

Quote.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default Quote;
