import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Item = ({ src, label }) => (
  <div className="item">
    <img src={src} alt="icon" />
    <span>{label}</span>
  </div>
);

Item.propTypes = {
  src: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default Item;
