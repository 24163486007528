const mapStateToProps = (state) => {
  const { languageLabels, questions } = state;
  return {
    results: questions.results,
    calculatedResults: questions.calculatedResults,
    activeQuestion: questions.activeQuestion,
    languageLabels: languageLabels.labels,

  };
};

export default mapStateToProps;
