// Inspired by https://stackoverflow.com/questions/46994034/is-there-a-way-to-check-if-a-javascript-function-accepts-a-callback
/**
 * Set a value in web storage regardless of whether it's sync or async
 */

export function setStorageItem(key, value, callback) {
  const result = window.localStorage.setItem(key, value);

  if (result instanceof window.Promise && callback) {
    result.then(callback);
  } else if (callback) {
    callback();
  }
}
