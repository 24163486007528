import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import LanguageLabel from '../LanguageLabel';

const Switch = ({
  value,
  defaultValue,
}) => {
  const [switchState, setSwitchState] = useState();

  useEffect(() => {
    setSwitchState(defaultValue);
  }, [defaultValue]);

  return (
    <div className="switch">
      <div className={classNames('closed', switchState && 'active')} onClick={() => value(true)}>
        <span><LanguageLabel id="switchPrivateGroupLabel" /></span>
      </div>
      <div className={classNames('open', !switchState && 'active')} onClick={() => value(false)}>
        <span><LanguageLabel id="switchPublicGroupLabel" /></span>
      </div>
    </div>
  );
};

Switch.propTypes = {
  value: PropTypes.func.isRequired,
  defaultValue: PropTypes.bool.isRequired,
};

export default Switch;
