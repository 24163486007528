import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Heading from '../Heading';
import Button from '../Button';
import Stars from '../Stars';
import Paragraph from '../Paragraph';

import { ReactComponent as Plus } from '../../assets/icons/ic-plus.svg';
import Copy from '../../assets/icons/ic-copy.svg';

import './style.scss';
import LanguageLabel from '../LanguageLabel';
import { lang } from '../../utils/locale';

const InfoList = ({
  accountPath,
  addPath,
  buttonTitleItems,
  buttonTitleNoItems,
  items,
  loggedIn,
  maxItems,
  noItemImg,
  noItemsParagraph,
  path,
  title,
  type,
  earths,
  itemComplete,
  topRank,
}) => {
  const [copied, setCopiedState] = useState(false);
  const history = useHistory();
  const goToSinglePage = (id, url) => {
    let path;
    if (url === 'goalsitem') {
      path = `goals/${id}`;
    }
    if (url === 'actionitem') {
      path = 'actions';
    }
    if (url === 'groupitem') {
      path = `groups/${id}`;
    }
    history.push(path);
  };

  const handleClickCard = (id, url, event) => {
    if (event.target.nodeName === 'SPAN') {
      goToSinglePage(id, url);
    }
  };

  const handleCopyState = (index) => {
    setCopiedState(index);
  };

  const createContent = (contentType, item, index) => {
    if (contentType === 'actionitem') {
      return (
        <>
          <p>{item.title || item.content}</p>
          <div className="stars">
            <Stars stars={item.stars} negativeStars={4} />
          </div>
        </>
      );
    }
    if (contentType === 'goalsitem') {
      return (
        <>
          <div>
            <Heading className="primary" type="h2">{item.title}</Heading>
            <p>Doel: {item.earthsGoal || 0} aardes</p>
            <p>Huidig: {earths || 0} aardes</p>
          </div>
          <div>
            <Button className="rounded secondary" label={<LanguageLabel id="viewGoalLabel" />} />
          </div>
        </>
      );
    }
    return (
      <>
        <figure>
          <img src={item.image} alt="groep icon" />
        </figure>
        <div className="container">
          <div className="box">
            <Heading className="primary" type="h2">{item.title}
              {/* <span><img src={Copy} alt="copy" /></span> */}
              {/* <div className="copyclicker"> */}
              <div
                onAnimationEnd={() => handleCopyState(-1)}
                className={classNames('copytext', copied === index && 'active-copy')}
              ><LanguageLabel id="copiedPopupLabel" />
              </div>
              <CopyToClipboard
                text={`https://www.mijnverborgenimpact.nl/groups/${itemComplete.items[index].id}`}
                onCopy={() => handleCopyState(index)}
                // className="copyclicker"
              >
                <span className="copyclicker"><img src={Copy} alt="copy" /></span>
              </CopyToClipboard>
              {/* </div> */}
            </Heading>

            <p>{item.location || 'Nederland'}</p>
            <p>{item.users.length} leden</p>
          </div>
          <Button className="rounded secondary" label={<LanguageLabel id="viewGroupLabel" />} />
        </div>
      </>
    );
  };

  if (items.length > 0) {
    return (
      <article className="infoList">
        <Heading type="h1">
          {title}
        </Heading>
        {addPath && type !== 'actionitem' && (
          <div className="button-box">
            <Button label={<Plus />} to={addPath} className="add" />
          </div>
        )}
        <ul>
          {items.slice(0, maxItems).map((item, i) => (
            item && (
              <li key={item.id} className={type} onClick={(e) => handleClickCard(item.id, type, e)}>
                {createContent(type, item, i)}
              </li>
            )
          ))}
        </ul>
        <div className="button-container">
          <Button label={buttonTitleItems} to={path} className="rounded" />
        </div>
      </article>
    );
  }

  return (
    <article className="infoList">
      <Heading type="h1">
        {title}
      </Heading>
      <Paragraph>
        {noItemsParagraph}
      </Paragraph>
      <img src={noItemImg} alt="goals img" />
      <div className="button-container">
        {loggedIn ? (
          topRank.length > 0 && (
            <Button label={buttonTitleNoItems} to={addPath} className="rounded" />
          )
        ) : (
          topRank.length > 0 && type === 'actionitem' ? (
            <Button label={<LanguageLabel id="dashboardCreateActionList" />} to={`/${lang}/actions`} className="rounded" />
          ) : (
            <Button label={<LanguageLabel id="dashboardCreateAnAccount" />} to={`/${lang}${accountPath}`} className="rounded" />
          )
        )}
      </div>
    </article>
  );
};

InfoList.propTypes = {
  topRank: PropTypes.arrayOf(PropTypes.number),
  accountPath: PropTypes.string.isRequired,
  addPath: PropTypes.string,
  buttonTitleItems: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])).isRequired,
  buttonTitleNoItems: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  loggedIn: PropTypes.bool.isRequired,
  maxItems: PropTypes.number,
  noItemImg: PropTypes.string.isRequired,
  noItemsParagraph: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])),
  path: PropTypes.string.isRequired,
  title: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.symbol,
  ])).isRequired,
  history: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  earths: PropTypes.number,
  itemComplete: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }),
};

InfoList.defaultProps = {
  addPath: '',
  items: [],
  maxItems: 3,
  noItemsParagraph: '',
  history: {},
  earths: 0,
  topRank: [],
  itemComplete: {},
};

export default InfoList;
