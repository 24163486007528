import moment from 'moment';

const globalFormat = 'YYYY-MM-DDTHH:MM:SS';

export function getCurrentDate() {
  const today = new Date();
  return moment(today).format(globalFormat);
}

export function getFormattedDate(date) {
  let seconds = Number(date.substr(date.length - 2));
  if (seconds > 60) {
    const alterationArray = date.split(':');
    const minutes = Number(alterationArray[1]) + 1;
    seconds -= 60;
    const newDate = [alterationArray[0], ':', minutes, ':', seconds].join('');
    return moment(newDate).format('YYYY-MM-DDThh:mm:ss');
  }
  return moment(date).format('YYYY-MM-DDThh:mm:ss');
}

export function getDateFormat(date, dateformat) {
  return moment(date, globalFormat).format(dateformat);
}

export function dutchTimeFormatter(date) {
  const currentMonth = Number(date.split('-')[1]);
  let currentMonthString = '';
  switch (currentMonth) {
    case 1:
      currentMonthString = 'januari';
      break;
    case 2:
      currentMonthString = 'februari';
      break;
    case 3:
      currentMonthString = 'maart';
      break;
    case 4:
      currentMonthString = 'april';
      break;
    case 5:
      currentMonthString = 'mei';
      break;
    case 6:
      currentMonthString = 'juni';
      break;
    case 7:
      currentMonthString = 'juli';
      break;
    case 8:
      currentMonthString = 'augustus';
      break;
    case 9:
      currentMonthString = 'september';
      break;
    case 10:
      currentMonthString = 'oktober';
      break;
    case 11:
      currentMonthString = 'november';
      break;
    case 12:
      currentMonthString = 'december';
      break;
    default:
      // code block
  }
  currentMonthString = `${date.split('-')[2].split('T')[0]} ${currentMonthString}`;
  return currentMonthString;
}

export const awsDateToMS = (date) => (
  moment(date).format('x')
);
