const mapStateToProps = (state) => {
  const { questions, products, modalTop10 } = state;
  return {
    questions,
    products: products.products,
    modalTop10: modalTop10.modalTop10,
  };
};

export default mapStateToProps;
