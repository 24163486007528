const QUESTION_BATHING = 'QUESTION_BATHING';
const QUESTION_CAR = 'QUESTION_CAR';
const QUESTION_CLOTHES = 'QUESTION_CLOTHES';
const QUESTION_DAIRY = 'QUESTION_DAIRY';
const QUESTION_FLYING = 'QUESTION_FLYING';
const QUESTION_FOOD = 'QUESTION_FOOD';
const QUESTION_HOUSING = 'QUESTION_HOUSING';
const QUESTION_MEAT = 'QUESTION_MEAT';
const QUESTION_POSSESSIONS = 'QUESTION_POSSESSIONS';
const QUESTION_PUBLIC_TRANSPORT = 'QUESTION_PUBLIC_TRANSPORT';
const QUESTION_USER = 'QUESTION_USER';

export {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
  QUESTION_USER,
};
