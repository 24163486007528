import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from '../Heading';
import Button from '../Button';

import './style.scss';
import LanguageLabel from '../LanguageLabel';
import { lang } from '../../utils/locale';

const ModalOverlay = ({
  itemId,
  buttonLabel,
  children,
  className,
  headingText,
  modalSwitch,
  onClick,
  hideButton,
  closeModal,
  showSubText,
  showSubBool,
  changeUrl,
}) => {
  const handleModalSwitch = (event) => {
    if (event.target.className === 'modal show') {
      onClick();
    }
  };

  const click = () => {
    if (changeUrl) {
      changeUrl();
      return;
    }
    onClick();
  };

  return (
    <div onClick={(e) => handleModalSwitch(e)} className={classNames('modal-overlay', modalSwitch && 'show')}>
      <div className={classNames(className, 'popup')}>
        {closeModal ? (
          <div className="close" onClick={() => click()} />
        ) : null}
        <Heading className="primary 13" type="h1">
          {headingText}
        </Heading>
        {children}
        {showSubBool && <span className="subtext">{showSubText}</span>}
        {!hideButton && itemId === 'privacy-modal' ? (
          <Button
            label={<LanguageLabel id="privacyModalButton" />}
            onClick={() => onClick()}
            className={classNames('outline', showSubBool && 'disabled')}
          />
        ) : (
          <Button
            label={buttonLabel}
            onClick={() => onClick()}
            className={classNames('outline', showSubBool && 'disabled')}
          />
        )}
      </div>
    </div>
  );
};

ModalOverlay.propTypes = {
  itemId: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingText: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  modalSwitch: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
  closeModal: PropTypes.bool,
  changeUrl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  showSubText: PropTypes.shape({}),
  showSubBool: PropTypes.bool,

};

ModalOverlay.defaultProps = {
  buttonLabel: lang === 'en' ? 'Continue' : 'Verder',
  className: '',
  headingText: '',
  hideButton: false,
  closeModal: true,
  showSubText: {},
  changeUrl: null,
  showSubBool: false,
};

export default ModalOverlay;
