import { SET_MOBILE_VIEW } from '../actions/mobileViewActionTypes';

const initialState = {
  mobileView: false,
};

const mobileView = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_VIEW: {
      const { mobileView } = action.payload;

      return {
        ...state,
        mobileView,
      };
    }
    default:
      return state;
  }
};

export default mobileView;
