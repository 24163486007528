import { SET_COUNTER_GROUP } from '../actions/counterGroupActionTypes';

import { getCounterGroup } from '../transformers/counterGroupTransformer';

const initialState = {
  counterGroup: [],
};

const counterGroups = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTER_GROUP: {
      const counterGroup = getCounterGroup(action.counterGroup);
      return {
        ...state,
        counterGroup,
      };
    }
    default:
      return state;
  }
};

export default counterGroups;
