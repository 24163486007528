import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  graphqlOperation, API,
} from 'aws-amplify';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import {
  getGoal as GetGoal,
} from '../../graphql/queries';

import {
  deleteGoal as DeleteGoal,
  updateUser as UpdateUser,
} from '../../graphql/mutations';

import { getEarths } from '../../utils/functions';

import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import Button from '../../components/Button';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import ResultChart from '../../components/ResultChart';
import GoalPageTemplate from '../../components/GoalPageTemplate';
import EarthCounter from '../../components/EarthCounter';
import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';
import ModalConfirm from '../../components/ModalConfirm';
import { lang } from '../../utils/locale';

const results = {
  calculatedResults: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const goals = {
  results: {
    [QUESTION_BATHING]: { recipe: 0 },
    [QUESTION_CAR]: { recipe: 0 },
    [QUESTION_CLOTHES]: { recipe: 0 },
    [QUESTION_DAIRY]: { recipe: 0 },
    [QUESTION_FLYING]: { recipe: 0 },
    [QUESTION_FOOD]: { recipe: 0 },
    [QUESTION_HOUSING]: { recipe: 0 },
    [QUESTION_MEAT]: { recipe: 0 },
    [QUESTION_POSSESSIONS]: { recipe: 0 },
    [QUESTION_PUBLIC_TRANSPORT]: { recipe: 0 },
  },
};

const Goal = ({
  user,
  match,
  history,
}) => {
  const [goalName, setGoalName] = useState('');
  const [preFilledTest, setPreFilledTest] = useState(results);
  const [preFilledGoal, setPreFilledGoal] = useState(goals);
  const [goalUpdate, setGoalUpdate] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [chartResults, setChartResults] = useState([]);

  const [numberOfEarthsGoal, setNumberOfEarthsGoal] = useState(0.0);
  const [numberOfEarths, setNumberOfEarths] = useState(0.0);
  const [numberOfEarthsTotal, setNumberOfEarthsTotal] = useState(0.0);

  const [earthsRemoved] = useState(numberOfEarths - numberOfEarthsGoal);
  const [earthsRemovedTotal] = useState(numberOfEarthsTotal - numberOfEarths);

  const { id } = match.params;

  const chartResultsFormat = (resp, objectName, location) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp[location].bathing || 0 },
        [QUESTION_CAR]: { recipe: resp[location].car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp[location].clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp[location].diary || 0 },
        [QUESTION_FLYING]: { recipe: resp[location].flying || 0 },
        [QUESTION_FOOD]: { recipe: resp[location].food || 0 },
        [QUESTION_HOUSING]: { recipe: resp[location].housing || 0 },
        [QUESTION_MEAT]: { recipe: resp[location].meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp[location].possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp[location].publictransport || 0 },
      },
    };
    return preFilled;
  };

  const chartResultsFormatGoals = (resp, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport || 0 },
      },
    };
    return preFilled;
  };

  const getGoalById = async (id) => {
    try {
      const goal = await API.graphql(
        graphqlOperation(GetGoal, {
          id,
        }),
      );
      if (goal.data.getGoal) {
        setNumberOfEarthsGoal(goal.data.getGoal.earthsGoal);
        setPreFilledGoal(chartResultsFormatGoals(goal.data.getGoal, 'results'));
        setGoalName(goal.data.getGoal.title);
        setGoalUpdate(true);
      } else {
        history.push('/goals');
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const loadData = async () => {
    const resp = user.sortedResults;
    try {
      let highestIndex = 0;
      let oldEarth = 0;
      resp.forEach((x, i) => {
        const earth = getEarths(chartResultsFormat(resp, 'calculatedResults', i).calculatedResults);
        if (earth > oldEarth) {
          oldEarth = earth;
          highestIndex = i;
        }
      });
      setChartResults(resp);
      setPreFilledTest(chartResultsFormat(resp, 'calculatedResults', 0));
      setNumberOfEarths(
        // Transparent earths
        getEarths(chartResultsFormat(resp, 'calculatedResults', highestIndex).calculatedResults),
      );
      setNumberOfEarthsTotal(getEarths(chartResultsFormat(resp, 'calculatedResults', 0).calculatedResults));
    } catch (err) {
      // console.log(err);
    }
  };

  const getChartResultsOnDate = (date) => {
    const dateResult = chartResults.filter((result) => result.dateCreated === date);
    if (dateResult.length > 0) {
      setPreFilledTest(chartResultsFormat(dateResult, 'calculatedResults', [0]));
      setNumberOfEarthsTotal(
        getEarths(chartResultsFormat(dateResult, 'calculatedResults', [0]).calculatedResults),
      );
    }
  };

  useEffect(() => {
    if (user.results && user.results.length !== 0) {
      loadData();
    }
  }, [user]);

  useEffect(() => {
    getGoalById(id);
  }, [user]);

  const showContentEarthRemovedTotal = () => {
    switch (true) {
      case earthsRemovedTotal < 0:
        return <LanguageLabel id="goalDetailParagraph0EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      case earthsRemovedTotal < 1:
        return <LanguageLabel id="goalDetailParagraph1EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      case earthsRemovedTotal < 2:
        return <LanguageLabel id="goalDetailParagraph2EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      case earthsRemovedTotal < 3:
        return <LanguageLabel id="goalDetailParagraph3EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      case earthsRemovedTotal < 4:
        return <LanguageLabel id="goalDetailParagraph4EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      case earthsRemovedTotal < 5:
        return <LanguageLabel id="goalDetailParagraph5EarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
      default:
        return <LanguageLabel id="goalDetailParagraphEarthRemovedTotal" replace={<>{earthsRemovedTotal}</>} />;
    }
  };

  const showContentEarthRemoved = () => {
    switch (true) {
      case earthsRemoved < 0:
        return <LanguageLabel id="goalDetailParagraph0EarthRemoved" />;
      case earthsRemoved < 1:
        return <LanguageLabel id="goalDetailParagraph1EarthRemoved" />;
      case earthsRemoved < 2:
        return <LanguageLabel id="goalDetailParagraph2EarthRemoved" />;
      case earthsRemoved < 3:
        return <LanguageLabel id="goalDetailParagraph3EarthRemoved" />;
      case earthsRemoved < 4:
        return <LanguageLabel id="goalDetailParagraph4EarthRemoved" />;
      case earthsRemoved < 5:
        return <LanguageLabel id="goalDetailParagraph5EarthRemoved" />;
      default:
        return <LanguageLabel id="goalDetailParagraphEarthRemoved" />;
    }
  };

  const removeGoal = async () => {
    let userGoals = [];
    if (user
      && user.goals
      && user.goals.length !== 0) {
      const index = user.goals.indexOf(id);
      user.goals.splice(index, 1);
      userGoals = user.goals.filter((item) => item !== id);
      try {
        await API.graphql(
          graphqlOperation(UpdateUser, {
            input: {
              id: user.id,
              goals: userGoals,
            },
          }),
        );
        await API.graphql(
          graphqlOperation(DeleteGoal, {
            input: {
              id,
            },
          }),
        );
        history.push('/goals');
      } catch (err) {
        // console.log(err);
      }
    }
  };

  return (
    <GoalPageTemplate className="goal">
      <ModalConfirm modalSwitch={confirmModal} onClick={() => setConfirmModal(!confirmModal)} confirmAction={removeGoal} />
      <header className="goalpagetemplate-header">
        <Heading className="primary">{goalName}</Heading>
        <Paragraph>
          {showContentEarthRemoved()} {showContentEarthRemovedTotal()}
          <LanguageParagraph id="goalDetailParagraph" />
        </Paragraph>
      </header>
      <section className="goalpagetemplate-container">
        <EarthCounter transparentEarths={numberOfEarths} yellowBorderEarths={numberOfEarthsGoal} earths={numberOfEarthsTotal} />
        <div className="goalpagetemplate-chart">
          <ResultChart
            questions={preFilledTest}
            goals={preFilledGoal}
            timeLine
            onTimeLineClick={getChartResultsOnDate}
            timestamps={chartResults}
            legend
            editShow
            goalUpdate={goalUpdate}
          />
        </div>
        <div className="goalpagetemplate-button">
          <Button to={paths[QUESTIONSHOME]} className="rounded secondary" label={<LanguageLabel id="goalDetailTakeTestLabel" />} />
          <Button to={`/${lang}/goalsedit/${id}`} className="rounded" label={<LanguageLabel id="goalDetailAdjustTargetLabel" />} />
        </div>
        <div className="hyperlink" onClick={() => setConfirmModal(!confirmModal)}><LanguageLabel id="goalDetailRemoveTargetLabel" /></div>
      </section>
      <div className="goalpagetemplate-background">
        <div className="mountain" />
        <div className="bg-white" />
      </div>

    </GoalPageTemplate>
  );
};

Goal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
    sortedResults: PropTypes.arrayOf(PropTypes.shape({})),
    goals: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
