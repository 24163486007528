import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import { lang } from '../../utils/locale';

const getLanguageText = (text) => {
  const en = {
    snel: 'quick',
    precies: 'precise',
  };

  if (lang === 'en' && (text === 'snel' || text === 'precies')) {
    return en[text];
  }
  return text;
};

const ToggleButton = ({
  beforeText,
  onClick,
  afterText,
  className,
  toggleButtonValue,
}) => {
  const [toggled, setToggled] = useState(true);

  const toggle = () => {
    setToggled(!toggled);
    if (onClick) onClick(toggled);
  };

  useEffect(() => {
    if (toggleButtonValue !== toggled) {
      setToggled(toggleButtonValue);
    }
  }, [toggleButtonValue]);

  return (
    <div
      className={classNames('toggleButton', className, !toggled && 'toggled')}
    >
      <span onClick={!toggled ? toggle : undefined}>{getLanguageText(beforeText)}</span>
      <span onClick={toggled ? toggle : undefined}>{getLanguageText(afterText)}</span>
    </div>
  );
};

ToggleButton.propTypes = {
  beforeText: PropTypes.string,
  onClick: PropTypes.func,
  afterText: PropTypes.string,
  className: PropTypes.string,
  toggleButtonValue: PropTypes.bool,
};

ToggleButton.defaultProps = {
  toggleButtonValue: false,
  beforeText: '',
  afterText: '',
  onClick: null,
  className: '',
};

export default ToggleButton;
