import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import content from './content.json';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import {
  QUESTION_CLOTHES,
} from '../../constants/questions';

import Heading from '../../components/Heading';
import QuestionButtons from '../../components/QuestionButtons';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Slider from '../../components/Slider';
import SliderPercentage from '../../components/SliderPercentage';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-kleding.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-kleding.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_3.svg';

import './style.scss';

const QuestionClothes = ({
  fetchTooltips,
  fetchTooltipsPercentage,
  tooltips,
  tooltipsPercentage,
  setQuestionResult,
  setSwipeOnContainer,
  openGenericModal,
  mobileView,
  openSpecificModal,
  results,
  shouldIupdate,
}) => {
  const [values, setValues] = useState({});
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [mode, setMode] = useState(false);
  const questionId = QUESTION_CLOTHES;

  const currentItemIndex = order.indexOf(questionId);

  const quickQuestions = content.quick.questions;
  const specificQuestionsSlider = content.specific.questions.slider;
  const specificQuestionsSliderPercentage = content.specific.questions.sliderPercentage;

  const quickLength = quickQuestions.length;
  const specificLength = specificQuestionsSlider.length + specificQuestionsSliderPercentage.length;

  const submitClicked = () => {
    // Go to next in line, check in order
    // const set = mode ? 'precize' : 'fast';
    setQuestionResult(questionId, values, mode);
  };

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const getValueSecondHand = (max) => {
    if (results[QUESTION_CLOTHES] && results[QUESTION_CLOTHES].clothes) return results[QUESTION_CLOTHES].clothes;
    if (values.clothes) return values.clothes;
    return max;
  };

  const makeTooltipsSlider = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_CLOTHES] && results[QUESTION_CLOTHES][item.id]) defaultValue = results[QUESTION_CLOTHES][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <Slider
            key={item.id}
            defaultValue={values[item.id] || defaultValue}
            min={item.min}
            max={item.id === 'secondhand' ? getValueSecondHand(item.max) : item.max}
            updateParent={(value) => sliderChange(item.id, value)}
            text={item.label}
            unit={item.unit}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            descriptions={item.descriptions}
            swiping={swipeOnQuestions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
          />
        );
      })
    );
  };

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  const makeTooltipsPercentage = (id) => {
    const filterTooltips = tooltipsPercentage.filter((tooltipPercentage) => (tooltipPercentage.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_CLOTHES] && results[QUESTION_CLOTHES][item.id]) defaultValue = results[QUESTION_CLOTHES][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <div className="slider-info" key={item.id}>
            <span className="text">
              {item.label}
            </span>
            <SliderPercentage
              updateParent={(value) => sliderChange(item.id, value)}
              leftContent={item.leftValue}
              rightContent={item.rightValue}
              isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
              defaultValue={values[item.id] || defaultValue}
            />
          </div>
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (tooltipsPercentage.length === 0) {
      fetchTooltipsPercentage();
    }
  }, [fetchTooltipsPercentage]);

  return (
    <div className="question-clothes">
      <Heading className="primary" type="h1">
        <span>
          <LanguageLabel id="clothesQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('clothesQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="clothesQuestionDescription" />
      </Paragraph>
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              {quickQuestions.map((item) => (
                makeTooltipsSlider(item.id)
              ))}
            </div>
            <div className="questionButton-box">
              <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quickQuestions.map((item) => (
                  makeTooltipsSlider(item.id)
                ))}
              </QuestionSwiper>
            </div>
            <StepIndicatorMobile currentStep={currentSlide} steps={quickLength} />
            <div className="questionButton-box">
              {currentSlide === quickLength - 1
                ? (
                  <>
                    <QuestionButtons
                      questionId={questionId}
                      nextClick={() => submitClicked()}
                    />
                    <Button
                      label={<LanguageLabel id="questionPreviousLabel" />}
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                    />
                  </>
                )
                : (
                  <>
                    <Button
                      label={<LanguageLabel id="questionNextLabel" />}
                      onClick={() => setCurrentSlide(currentSlide + 1)}
                      className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                    />
                    {currentSlide === 0 ? (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    ) : (
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        )
      ) : (
        !mobileView
          ? (
            <>
              <div className="vertical-align-wrapper-flex">
                {specificQuestionsSlider.map((item) => (
                  makeTooltipsSlider(item.id)
                ))}
                <div className="vertical-wrap-row-two">
                  {specificQuestionsSliderPercentage.map((item) => (
                    makeTooltipsPercentage(item.id)
                  ))}
                </div>
              </div>
              <div className="questionButton-box">
                <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
              </div>
            </>
          ) : (
            <>
              <div className="vertical-align-wrapper-flex">
                <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                  {specificQuestionsSlider.map((item) => (
                    makeTooltipsSlider(item.id)
                  ))}
                  {specificQuestionsSliderPercentage.map((item) => (
                    makeTooltipsPercentage(item.id)
                  ))}
                </QuestionSwiper>
              </div>
              <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
              <div className="questionButton-box">
                {currentSpecificSlide === specificLength - 1
                  ? (
                    <>
                      <QuestionButtons
                        questionId={questionId}
                        nextClick={() => submitClicked()}
                      />
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    </>
                  )
                  : (
                    <>
                      <Button
                        label={<LanguageLabel id="questionNextLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                      />
                      {currentSpecificSlide === 0 ? (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      ) : (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      )}
                    </>
                  )}
              </div>
            </>
          )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionClothes.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchTooltipsPercentage: PropTypes.func.isRequired,
  tooltipsPercentage: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  shouldIupdate: PropTypes.bool,
  openGenericModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  results: PropTypes.shape({}),
};

QuestionClothes.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionClothes);
