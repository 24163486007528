import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';
import { lang } from '../../utils/locale';

const LanguageParagraph = ({ fetchLanguageParagraphs, id, languageParagraphs }) => {
  useEffect(() => {
    if (languageParagraphs.length === 0 || lang) {
      fetchLanguageParagraphs(lang);
    }
  }, [fetchLanguageParagraphs, lang]);

  const paragraphObj = languageParagraphs.filter((item) => item.id === id);
  const paragraph = paragraphObj.length === 1 ? paragraphObj[0].paragraph : ''; // [[${id}]]

  return (
    <>{ReactHtmlParser(paragraph)}</>
  );
};

LanguageParagraph.propTypes = {
  fetchLanguageParagraphs: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  languageParagraphs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    paragraph: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageParagraph);
