const houseTypeMatrix = {
  Appartement: -26,
  Tussenwoning: 0,
  Hoekwoning: 16,
  '2 onder 1 kap': 30,
  'Vrijstaande woning': 91,
};

const houseBouwMatrix = {
  Appartement: 0.9,
  Tussenwoning: 1,
  Hoekwoning: 1.1,
  '2 onder 1 kap': 1.1,
  'Vrijstaande woning': 1.25,
};

const energyLabelMatrix = {
  Appartement:
  {
    'A++': -10,
    'A+': -26,
    A: -17,
    B: -10,
    C: 0,
    D: 12,
    E: 21,
    F: 26,
    G: 29,
  },
  Tussenwoning:
  {
    'A++': -34,
    'A+': -31,
    A: -16,
    B: -9,
    C: 0,
    D: 8,
    E: 15,
    F: 18,
    G: 20,
  },
  Hoekwoning:
  {
    'A++': -33,
    'A+': -36,
    A: -18,
    B: -9,
    C: 0,
    D: 8,
    E: 14,
    F: 21,
    G: 24,
  },
  '2 onder 1 kap':
  {
    'A++': -34,
    'A+': -38,
    A: -15,
    B: -6,
    C: 0,
    D: 6,
    E: 9,
    F: 15,
    G: 20,
  },
  'Vrijstaande woning':
  {
    'A++': -24,
    'A+': -35,
    A: -18,
    B: -10,
    C: 0,
    D: 5,
    E: 8,
    F: 8,
    G: 6,
  },
};

export {
  houseTypeMatrix,
  houseBouwMatrix,
  energyLabelMatrix,
};
