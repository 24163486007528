import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import './style.scss';
import { lang } from '../../utils/locale';

const getPlaceholder = () => {
  if (lang === 'en') {
    return 'Pick an option';
  }
  return 'Maak een keuze';
};

const DropDown = ({
  className,
  onChange,
  options,
  defaultValue,
  customValue,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = ((value) => {
    setValue(value);
    onChange(value);
  });

  useEffect(() => {
    setValue(customValue);
  }, [customValue]);

  return (
    <div className={classNames('dropdown', className)}>
      <Dropdown
        options={options}
        onChange={handleChange}
        value={value}
        placeholder={getPlaceholder()}
        arrowClosed={<span className="arrow-closed" />}
        arrowOpen={<span className="arrow-open" />}
      />
    </div>
  );
};

DropDown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  onChange: PropTypes.func,
  customValue: PropTypes.objectOf(PropTypes.string),
};

DropDown.defaultProps = {
  className: '',
  defaultValue: '',
  customValue: null,
  onChange: () => {},
};

export default DropDown;
