import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import '../style.scss';

const PasswordInput = ({
  className,
  error,
  errorGlobal,
  errorMessage,
  id,
  label,
  onChange,
  placeholder,
  value,
  noInput,
  onBlur,
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  return (
    <label
      htmlFor={id}
      className={classNames('input', className, (errorMessage && error) || errorGlobal ? 'error' : '')}
    >
      <span>{label}</span>
      <span
        type="button"
        onClick={() => setVisiblePassword(!visiblePassword)}
        className={classNames('visible', visiblePassword && 'open')}
      />
      <input
        autoComplete={noInput ? 'new-password' : 'false'}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        type={visiblePassword ? 'text' : 'password'}
        value={value}
        onBlur={onBlur}
      />
      {error && (<span className="error-text">{errorMessage}</span>)}
    </label>
  );
};
PasswordInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  errorGlobal: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  noInput: PropTypes.bool,
  onBlur: PropTypes.func,
};

PasswordInput.defaultProps = {
  noInput: false,
  className: '',
  error: '',
  errorGlobal: '',
  errorMessage: '',
  label: '',
  placeholder: '',
  onBlur: null,
};

export default PasswordInput;
