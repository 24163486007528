import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import cookie from '../../assets/icons/cookies.svg';

import Button from '../Button';
import LanguageLabel from '../LanguageLabel';
import Heading from '../Heading';
import LanguageParagraph from '../LanguageParagraph';

import './style.scss';

const cookiesAccepted = window.localStorage.getItem('cookiesAccepted') === 'true';

const Cookie = () => {
  const [showCookie, setShowCookie] = useState(true);

  if (cookiesAccepted) {
    return null;
  }

  const setCookieAgreement = (choose) => {
    window.localStorage.setItem('cookiesAccepted', choose);

    if (choose) {
      window.location.reload(false);
    } else {
      setShowCookie(choose);
    }
  };

  return (
    <div className={classNames('cookie', showCookie && ('show'))}>
      <div className="cookie-popup">
        <header>
          <Heading className="primary">
            <LanguageLabel id="cookieTitle" />
          </Heading>
        </header>
        <p>
          <LanguageParagraph id="cookieParagraph" />
        </p>
        <img src={cookie} alt="cookie" />
        <div className="cookie-footer">
          <Button
            label={<LanguageLabel id="acceptCookieAgreement" />} // "Accepteer cookies"
            onClick={() => setCookieAgreement(true)}
            className="outline-mobile"
          />
          <Button
            label={<LanguageLabel id="declineCookieAgreement" />}
            onClick={() => setCookieAgreement(false)}
          />
        </div>
      </div>
    </div>
  );
};

Cookie.propTypes = {

};

export default Cookie;
