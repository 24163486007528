import { SET_TEAM_CARDS } from '../actions/teamCardActionTypes';

import { getTeamCards } from '../transformers/teamCardTransformer';

const initialState = {
  teamCards: [],
};

const teamCard = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_CARDS: {
      const teamCards = getTeamCards(action.teamCards);

      return {
        ...state,
        teamCards,
      };
    }
    default:
      return state;
  }
};

export default teamCard;
