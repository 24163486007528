import client from './config/contentful';

const getLocale = () => {
  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';

  let locale = 'en-US';
  if (lang && lang.length && lang.length > 0) {
    if (lang === 'en') { locale = 'en-US'; } else { locale = lang; }
  }

  return locale;
};

function* findLanguageLabels(lang, limit = 999) {
  let locale = 'en-US';
  if (lang && lang.length && lang.length > 0) {
    if (lang === 'en') { locale = 'en-US'; } else { locale = lang; }
  }

  const entries = yield client.getEntries({
    content_type: 'languageLabel',
    include: 1,
    order: '-fields.id',
    limit,
    locale,
  });

  return entries;
}

function* findLanguageParagraphs(lang, limit = 999) {
  let locale = 'en-US';
  if (lang && lang.length && lang.length > 0) {
    if (lang === 'en') { locale = 'en-US'; } else { locale = lang; }
  }

  return yield client.getEntries({
    content_type: 'languageParagraph',
    include: 1,
    limit,
    locale,
  });
}

function* findTeamCards(lang, limit = 999) {
  let locale = 'en-US';
  if (lang && lang.length && lang.length > 0) {
    if (lang === 'en') { locale = 'en-US'; } else { locale = lang; }
  }

  return yield client.getEntries({
    content_type: 'teamCard',
    include: 1,
    limit,
    locale,
  });
}

// NOT TRANSLATED YET

function* findActionCards(limit = 999) {
  return yield client.getEntries({
    content_type: 'actionCard',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findActionTips(limit = 999) {
  return yield client.getEntries({
    content_type: 'actionTip',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findToolTip(limit = 999) {
  return yield client.getEntries({
    content_type: 'tooltip',
    include: 1,
    order: '-fields.id',
    limit,
    locale: getLocale(),
  });
}

function* findToolTipPercentage(limit = 999) {
  return yield client.getEntries({
    content_type: 'tooltipPercentage',
    include: 1,
    order: '-fields.id',
    limit,
    locale: getLocale(),
  });
}

function* findSuggestions(limit = 999) {
  return yield client.getEntries({
    content_type: 'suggestion',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findDropdown(limit = 999) {
  return yield client.getEntries({
    content_type: 'dropdown',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findProducts(limit = 999) {
  return yield client.getEntries({
    content_type: 'product',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findModalExplaining(limit = 999) {
  return yield client.getEntries({
    content_type: 'modalExplaining',
    include: 1,
    limit,
    locale: getLocale(),
  });
}


function* findSelectorBox(limit = 999) {
  return yield client.getEntries({
    content_type: 'selectorBox',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findCounterGroup(limit = 999) {
  return yield client.getEntries({
    content_type: 'counterGroup',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

function* findModalTop10(limit = 999) {
  return yield client.getEntries({
    content_type: 'modalTop10',
    include: 1,
    limit,
    locale: getLocale(),
  });
}

export {
  findLanguageLabels,
  findToolTip,
  findToolTipPercentage,
  findSuggestions,
  findDropdown,
  findProducts,
  findTeamCards,
  findLanguageParagraphs,
  findModalExplaining,
  findCounterGroup,
  findSelectorBox,
  findActionCards,
  findActionTips,
  findModalTop10,
};
