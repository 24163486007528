const calculate = (questionResults, average) => {
  const linearScaling = (key) => (
    (questionResults[key] / average[key].value) * average[key].recipe
  );

  let shower = linearScaling('shower') || 0;
  const bath = linearScaling('bath') || 0;

  if (questionResults.rainingShower) {
    shower *= 2;
  }
  if (questionResults.savingShower) {
    shower *= 0.8;
  }

  let recipe = (shower + bath) || 0;

  if (questionResults.heatSaving) {
    recipe *= 0.58;
  }

  if (questionResults.sunWater) {
    recipe *= 0.38;
  }

  return {
    shower,
    bath,
    recipe,
  };
};

export default calculate;
