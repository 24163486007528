import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  graphqlOperation, API,
} from 'aws-amplify';
import { connect } from 'react-redux';
import GroupSubscriptionCard from '../../components/GroupSubscriptionCard';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import {
  getGroup as GetGroup,
} from '../../graphql/queries';

import {
  updateGroup as UpdateGroup,
  updateUser as UpdateUser,
} from '../../graphql/mutations';

import { getCurrentDate, getDateFormat } from '../../utils/dateFormat';

import { getImage } from '../../utils/imgHandling';

import LanguageLabel from '../../components/LanguageLabel';
import LanguageParagraph from '../../components/LanguageParagraph';

import './style.scss';


const GroupSubscription = ({ match, user, history }) => {
  const { type } = match.params;
  const { id } = match.params;

  const [groupInfo, setGroupInfo] = useState({});


  useEffect(() => {
    const getGroup = async () => {
      const contents = await API.graphql(graphqlOperation(GetGroup, { id }));
      setGroupInfo(contents.data.getGroup);
    };

    getGroup();
  }, []);

  useEffect(() => {
    const getGroup = async () => {
      const contents = await API.graphql(graphqlOperation(GetGroup, { id }));
      setGroupInfo(contents.data.getGroup);
      if (type === 'request-send' && user.id) {
        let indexRequest = -1;
        let indexAccepted = -1;
        const group = contents.data.getGroup;
        group.pendingRequests.forEach((x) => {
          if (x === user.id) indexRequest = 0;
        });
        group.users.forEach((x) => {
          const y = JSON.parse(x);
          if (y.user === user.id) indexAccepted = 0;
        });
        const newPendingRequests = group.pendingRequests;
        newPendingRequests.push(user.id);
        if (indexRequest === -1 && indexAccepted === -1) {
          await API.graphql(graphqlOperation(UpdateGroup, { input: { id, pendingRequests: newPendingRequests } }));
        }
      }
    };
    getGroup();
  }, [user, type]);

  const acceptUser = async () => {
    const cUser = user;
    const resultObject = {
      userID: cUser.id,
      resultID: cUser.results && cUser.results.length > 0 ? cUser.results[0] : '',
    };
    const userObject = {
      user: cUser.id,
      date: getDateFormat(getCurrentDate(), 'YYYY-MM-DD'),
    };
    const oldInitial = groupInfo.initialResults;
    if (cUser.results && cUser.results.length > 0) {
      oldInitial.push(JSON.stringify(resultObject));
    }
    const oldUsers = groupInfo.users;
    oldUsers.push(JSON.stringify(userObject));

    await API.graphql(graphqlOperation(UpdateGroup, { input: { id, initialResults: oldInitial, users: oldUsers } }));
    const newUserGroups = cUser.groups || [];
    newUserGroups.push(groupInfo.id);
    await API.graphql(graphqlOperation(UpdateUser, { input: { id: user.id, groups: newUserGroups } }));

    history.push(`/groups/${groupInfo.id}`);
  };

  // console.log(user);

  const showContentType = () => {
    switch (type) {
      case 'request':
        if (Object.keys(user).length > 1) {
          return (
            <GroupSubscriptionCard
              img={groupInfo.image}
              title={<LanguageLabel id="groupCreateRequestTitle" replace={groupInfo.title} />}
              text={<LanguageParagraph id="groupCreateRequestParagraph" />}
              buttonLink={`/groupsubscription/request-send/${groupInfo.id}`}
              buttonText={<LanguageLabel id="groupCreateRequestButton" />}
            />
          );
        }
        if (Object.keys(user).length === 0) {
          return (
            <GroupSubscriptionCard
              img={getImage()}
              title={<LanguageLabel id="groupCreateRequestNotLoggedinTitle" replace={groupInfo && groupInfo.title} />}
              text={<LanguageParagraph id="groupCreateRequestNotLoggedinParagraph" />}
              buttonLink="/login"
              buttonLinkSec="/login?account"
              buttonText={<LanguageLabel id="groupCreateRequestNotLoggedinButton" />}
              buttonTextSec={<LanguageLabel id="groupCreateRequestNotLoggedinButton2" />}
            />
          );
        }
        break;
      case 'request-send':
        if (Object.keys(user).length > 1) {
          return (
            <GroupSubscriptionCard
              img={groupInfo.image}
              title={<LanguageLabel id="groupCreateRequestSendTitle" />}
              text={<LanguageParagraph id="groupCreateRequestSendParagraph" />}
            />
          );
        }
        break;
      case 'request-opengroup':
        if (Object.keys(user).length > 1) {
          return (
            <GroupSubscriptionCard
              img={groupInfo.image}
              title={<LanguageLabel id="groupCreateRequestOpenGroupTitle" replace={groupInfo.title} />}
              text={<LanguageParagraph id="groupCreateRequestOpenGroupParagraph" />}
              onClick={acceptUser}
              buttonText={<LanguageLabel id="groupCreateRequestOpenGroupButton" />}
            />
          );
        }
        if (Object.keys(user).length === 0) {
          // console.log('????');
          return (
            <GroupSubscriptionCard
              img={groupInfo.image}
              title={<LanguageLabel id="groupCreateRequestOpenGroupNotLoggedinTitle" replace={groupInfo.title} />}
              text={<LanguageParagraph id="groupCreateRequestOpenGroupNotLoggedinParagraph" />}
              buttonLink="/login"
              buttonLinkSec="/login?account"
              buttonText={<LanguageLabel id="groupCreateRequestOpenGroupButton" />}
              buttonTextSec={<LanguageLabel id="groupCreateRequestOpenGroupButton2" />}
            />
          );
        }
        break;
      default:
        return (
          <GroupSubscriptionCard
            img={groupInfo.image}
            title={<LanguageLabel id="groupCreateRequestNotLoggedinTitle" replace={groupInfo.title} />}
            text={<LanguageParagraph id="groupCreateRequestNotLoggedinParagraph" />}
            buttonLink="/login"
            buttonLinkSec="/login?account"
            buttonText={<LanguageLabel id="groupCreateRequestNotLoggedinButton" />}
            buttonTextSec={<LanguageLabel id="groupCreateRequestNotLoggedinButton2" />}
          />
        );
    }
    return null;
  };

  return (
    <>
      <main className="groupsubscription">
        {showContentType()}
      </main>
      <div className="groupsubscription-background">
        <div className="base-bg" />
      </div>
    </>
  );
};

GroupSubscription.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape({
        split: PropTypes.func,
      }),
    }),
    push: PropTypes.func,
  }).isRequired,
};

GroupSubscription.defaultProps = {
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSubscription);
