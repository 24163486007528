import { createClient } from 'contentful';

// const client = createClient({
//   space: '4c46gdupzk8o',
//   accessToken: 'luPOqf2Dla3x-1qm9Nh4SDOI_51YzRo0DS9kitdAh7g',
// });

const client = createClient({
  space: 'SECRET',
  environment: 'multilanguage',
  accessToken: 'SECRET',
  host: `${process.env.REACT_APP_MHI_API_ID || '23p2anmct6'}.execute-api.eu-west-1.amazonaws.com`,
  basePath: `/${process.env.REACT_APP_USER_BRANCH || 'test'}/content`,
  headers: {},
});

export default client;
