import { call, put } from 'redux-saga/effects';

import { SET_TOOLTIPS_PERCENTAGE } from '../actions/tooltipPercentageActionTypes';
import { findToolTipPercentage } from '../connectors/contentful';

function* fetchTooltipsPercentage() {
  const response = yield call(() => findToolTipPercentage());

  yield put({
    type: SET_TOOLTIPS_PERCENTAGE,
    tooltipsPercentage: response.items,
  });
}

export default fetchTooltipsPercentage;
