import { SET_USER } from '../store/actions/userActionTypes';

import store from '../store/storeConfig';

export const setUser = (user) => {
  store.dispatch({
    type: SET_USER,
    payload: {
      user,
    },
  });
};
