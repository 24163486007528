import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Auth,
  API,
  graphqlOperation,
} from 'aws-amplify';

import {
  createNewsLetter as CreateNewsLetter,
} from '../../../graphql/mutations';

import urlParser from '../../../utils/urlParser';
import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import LoadingIndicator from '../../../components/LoadingIndicator';
import LanguageLabel from '../../../components/LanguageLabel';
import LanguageParagraph from '../../../components/LanguageParagraph';
import Button from '../../../components/Button';

import './style.scss';

const CreateUserSucces = () => {
  const [verified, setVerified] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);

  const location = useLocation();

  const authenticaUser = async (authCode, email) => {
    try {
      await Auth.confirmSignUp(email, authCode);
      setVerified(true);
    } catch (err) {
      if (err.code === 'NotAuthorizedException') {
        setVerified(true);
      }
    }
  };

  const signUpForNewsletter = async (email) => {
    const object = {
      emailRecipient: email.toLowerCase(),
      yesOrNo: true,
    };
    await API.graphql(
      graphqlOperation(CreateNewsLetter, {
        input: object,
      }),
    );
  };

  useEffect(() => {
    const sArray = urlParser(location.search);
    if (sArray.code && sArray.email) {
      authenticaUser(sArray.code, sArray.email);
    }
    if (sArray.email && !sArray.code) {
      signUpForNewsletter(sArray.email);
      setNewsletterAccepted(true);
    }
  }, []);

  return (
    <div className="createuser">
      {verified ? (
        <div className="createuser-box">
          <Heading className="primary" type="h1">
            <LanguageLabel id="createUserSuccessTitle" />
          </Heading>
          <Paragraph>
            <LanguageParagraph id="createUserSuccessParagraph" />
          </Paragraph>
          <Button className="small secondary" to="/login" label={<LanguageLabel id="createUserSuccessButton" />} />
        </div>
      ) : !verified && !newsletterAccepted && (
        <div className="loading-indicator">
          <LoadingIndicator />
        </div>
      )}
      {newsletterAccepted ? (
        <div className="createuser-box">
          <Heading className="primary" type="h1">
            <LanguageLabel id="createUserSuccessTitle" />
          </Heading>
          <Paragraph>
            <LanguageLabel id="createUserSuccessNewsletterAcceptedParagraph" />
          </Paragraph>
        </div>
      ) : !verified && !newsletterAccepted && (
        <div className="loading-indicator">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

export default CreateUserSucces;
