import { SET_LANGUAGE_PARAGRAPH } from '../actions/languageActionTypes';

import { getLanguageParagraphs } from '../transformers/languageTransformer';

const initialState = {
  languageParagraphs: [],
};

const languageParagraph = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_PARAGRAPH: {
      const languageParagraphs = getLanguageParagraphs(action.languageParagraphs);

      return {
        ...state,
        languageParagraphs,
      };
    }
    default:
      return state;
  }
};

export default languageParagraph;
