import React, { useMemo } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { cookiesAccepted, isLocalhost, isTestEnvironment } from '../../utils/environmentutil';

const Analytics = ({ history }) => {
  useMemo(() => {
    if (cookiesAccepted && !isLocalhost && !isTestEnvironment) {
      history.listen((location) => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  }, []);

  return (<div />);
};

Analytics.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

Analytics.defaultProps = {
};

export default withRouter(Analytics);
