import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LanguageLabel from '../LanguageLabel';

import { ReactComponent as Male } from '../../assets/icons/man.svg';
import { ReactComponent as Female } from '../../assets/icons/vrouw.svg';
import { ReactComponent as Trans } from '../../assets/icons/anders.svg';

import './style.scss';

const GenderSelector = ({
  className,
  sendGender,
}) => {
  const [gender, setGender] = useState('');

  const toggle = (val) => {
    if (gender !== val) {
      setGender(val);
      sendGender(val);
      return;
    }
    setGender('');
    sendGender('');
  };

  return (
    <div className={classNames('genderselector', className)}>
      <div
        onClick={() => toggle('male')}
        className={classNames('selectbox', className, gender === 'male' && 'selectbox-active')}
      >
        <div className="gender-icon">
          <Male />
          <span><LanguageLabel id="userQuestionGenderFirst" /></span>
        </div>
      </div>
      <div
        onClick={() => toggle('female')}
        className={classNames('selectbox', className, gender === 'female' && 'selectbox-active')}
      >
        <div className="gender-icon">
          <Female />
          <span><LanguageLabel id="userQuestionGenderSec" /></span>
        </div>
      </div>
      <div
        onClick={() => toggle('X')}
        className={classNames('selectbox', className, gender === 'X' && 'selectbox-active')}
      >
        <div className="gender-icon">
          <Trans />
          <span><LanguageLabel id="userQuestionGenderThird" /></span>
        </div>
      </div>
    </div>
  );
};

GenderSelector.propTypes = {
  className: PropTypes.string,
  sendGender: PropTypes.func.isRequired,
};

GenderSelector.defaultProps = {
  className: '',
};

export default GenderSelector;
