import React from 'react';
import PropTypes from 'prop-types';

import Heading from '../Heading';
import Button from '../Button';
import Paragraph from '../Paragraph';

import './style.scss';

const GroupCard = ({
  img,
  title,
  text,
  buttonLink,
  buttonLinkSec,
  buttonText,
  buttonTextSec,
  onClick,
}) => (
  <article className="groupsubcard">
    <figure className="groupsubcard-logo">
      <img src={img} alt="willem" />
    </figure>
    <Heading className="primary">
      {title}
    </Heading>
    <Paragraph>{text}</Paragraph>
    {onClick && buttonText && <Button className="secondary small" onClick={onClick} label={buttonText} />}
    {buttonText && !onClick && <Button className="secondary small" to={buttonLink} label={buttonText} />}
    {buttonTextSec && <Button className="outline-mobile" to={buttonLinkSec} label={buttonTextSec} />}
  </article>
);

GroupCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonLinkSec: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonTextSec: PropTypes.string,
  onClick: PropTypes.func,
};

GroupCard.defaultProps = {
  buttonText: '',
  buttonTextSec: '',
  onClick: null,
};
export default GroupCard;
