import React from 'react';
import PropTypes from 'prop-types';
import {
  Auth,
} from 'aws-amplify';
import { connect } from 'react-redux';

import mapStateToProps from '../mapState';

import Form from '../../../components/Form';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import FormContainer from '../../../components/FormContainer';

import './style.scss';

import butterfly from '../../../assets/icons/ic-butterfly.svg';
import LanguageLabel from '../../../components/LanguageLabel';
import LanguageParagraph from '../../../components/LanguageParagraph';

const MailSend = ({
  user,
}) => {
  const onFormSubmit = async (event) => {
    event.preventDefault();
    await Auth.resendSignUp(user.user.email);
  };

  return (
    <div className="mailsend-wrapper">
      <FormContainer>
        <div className="formcontainer mailsend">
          <img src={butterfly} alt="butterfly" />
          <Heading className="primary" type="h1">
            <LanguageLabel id="MailSendTitle" />
          </Heading>
          <Paragraph>
            <LanguageParagraph id="MailSendParagraph" />
          </Paragraph>
          <Form onSubmit={onFormSubmit}>
            <Button
              onClick={onFormSubmit}
              label={<LanguageLabel id="mailSendButtonTitle" />}
              className="rounded secondary"
            />
            <div className="row">
              <Button
                label={<LanguageLabel id="mailSendButtonToLoginTitle" />}
                to="/login"
              />
            </div>
          </Form>
        </div>
      </FormContainer>
    </div>
  );
};

MailSend.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(mapStateToProps, null)(MailSend);
