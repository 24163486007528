const mapStateToProps = (state) => {
  const { user, questions, languageLabels } = state;
  return {
    user,
    results: questions.results,
    currentResultId: questions.currentResultId,
    languageLabels: languageLabels.labels,
  };
};

export default mapStateToProps;
