import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_FOOD,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
  QUESTION_USER,
} from '../constants/questions';

const order = [
  QUESTION_USER,
  QUESTION_FOOD,
  QUESTION_MEAT,
  QUESTION_DAIRY,
  QUESTION_CLOTHES,
  QUESTION_POSSESSIONS,
  QUESTION_HOUSING,
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_FLYING,
  QUESTION_PUBLIC_TRANSPORT,
];

export default order;
