import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';

import Heading from '../../../components/Heading';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Button';
import TextInput from '../../../components/Input/TextInput';
import FormContainer from '../../../components/FormContainer';
import Form from '../../../components/Form';
import LanguageLabel from '../../../components/LanguageLabel';
import LanguageParagraph from '../../../components/LanguageParagraph';

import { errorEmail } from '../../../utils/errorHandling';

import './style.scss';

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState('');
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const [send, setSend] = useState(false);

  const location = useLocation();
  const lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : 'nl';


  useEffect(() => {
    if (location.search === '?send') {
      setSend(true);
    }
  }, [location]);

  const handleEmail = (value) => {
    setEmail(value);
    setErrorEmailMessage(errorEmail(value, lang));
  };

  const checkEmail = () => {
    setShowError(true);
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (
      !errorEmailMessage
      && email.length > 0
    ) {
      try {
        await Auth.forgotPassword(email);
        history.push({
          path: '/forgotpassword',
          search: '?send',
        });
      } catch (error) {
        setShowError(true);
      }
    }
    setShowError(true);
    handleEmail(email);
  };

  return (
    <FormContainer>
      <div className={classNames('formcontainer', send ? 'forgotpassword' : 'forgotmailpassword')}>
        <Heading className="primary" type="h1">
          {!send
            ? <LanguageLabel id="forgotPasswordTitle1" />
            : <LanguageLabel id="forgotPasswordTitleSend" />}
        </Heading>
        <Paragraph>
          {!send
            ? <LanguageParagraph id="forgotPasswordParagraph1" />
            : <LanguageParagraph id="forgotPasswordParagraphSend" />}
        </Paragraph>
        <Form onSubmit={onFormSubmit}>
          {!send && (
          <>
            <TextInput
              className=""
              id={uuidv4()}
              error={showError}
              errorMessage={errorEmailMessage}
              label={<LanguageLabel id="forgotInputEmailTitle" />}
              onChange={(e) => handleEmail(e.target.value)}
              placeholder={lang === 'nl' ? 'Vul je email adres in' : 'Enter your email address'}
              value={email}
              onBlur={checkEmail}
            />
            <Button
              type="submit"
              label={<LanguageLabel id="forgotButtonTitle" />}
              className="rounded secondary"
            />
          </>
          )}
          <div className="row">
            <Button
              label={<LanguageLabel id="forgotButtonToLoginTitle" />}
              to="/login"
            />
          </div>
        </Form>
      </div>
    </FormContainer>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ForgotPassword;
