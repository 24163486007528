import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../style.scss';

const TextInput = ({
  className,
  error,
  errorGlobal,
  errorMessage,
  id,
  label,
  onChange,
  placeholder,
  type,
  value,
  onBlur,
}) => (
  <label
    htmlFor={id}
    className={classNames('input', className, (errorMessage && error) || errorGlobal ? 'error' : '')}
  >
    <span>{label}</span>
    <input
      autoComplete={0}
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
      onBlur={onBlur}
    />
    {error && (<span className="error-text">{errorMessage}</span>)}
  </label>
);

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  errorGlobal: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
};

TextInput.defaultProps = {
  className: '',
  error: false,
  errorGlobal: '',
  errorMessage: '',
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  onBlur: null,
};

export default TextInput;
