// @Remove comments after live

import React from 'react';
import {
  Route, Redirect, useHistory, Switch,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Actions from '../pages/Actions';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/Login/ForgotPassword';
import Goal from '../pages/Goal';
import GoalEdit from '../pages/Goal/GoalEdit';
import Goals from '../pages/Goals';
import Group from '../pages/Group';
import GroupEdit from '../pages/Group/GroupEdit';
import Groups from '../pages/Groups';
import GroupSub from '../pages/GroupSubscription';
import Home from '../pages/Home';
import Login from '../pages/Login/LoginOrCreateUser';
import CreateUserSucces from '../pages/Login/CreateUserSucces';
import MailSend from '../pages/Login/MailSend';
import NewGroup from '../pages/NewGroup';
import NewPassword from '../pages/Login/NewPassword';
import Profile from '../pages/Profile';
import Questions from '../pages/Questions';
import Results from '../pages/Results';
import Top10Info from '../pages/Top10Info';
import VerifyEmailPage from '../pages/VerifyEmailPage';

import {
  ACTIONS,
  DASHBOARD,
  FORGOTPASSWORD,
  GOALDETAIL,
  GOALS,
  GROUPDETAIL,
  GROUPEDIT,
  GROUPS,
  GROUPSUB,
  HOME,
  LOGIN,
  MAILSEND,
  NEWGROUP,
  GOALEDIT,
  NEWPASSWORD,
  PROFILEDETAIL,
  QUESTIONS,
  QUESTIONSHOME,
  RESULTS,
  TOP10INFO,
  VERIFYEMAIL,
  CREATEUSERSUCCES,
} from '../constants/pages';
import basePaths from './basepaths';
import PageAmsterdam from '../pages/Amsterdam';

const registry = {
  [ACTIONS]: Actions,
  [DASHBOARD]: Dashboard,
  [FORGOTPASSWORD]: ForgotPassword,
  [GOALDETAIL]: Goal,
  [GOALEDIT]: GoalEdit,
  [GOALS]: Goals,
  [GROUPDETAIL]: Group,
  [GROUPS]: Groups,
  [GROUPEDIT]: GroupEdit,
  [GROUPSUB]: GroupSub,
  [HOME]: Home,
  [LOGIN]: Login,
  [MAILSEND]: MailSend,
  [NEWGROUP]: NewGroup,
  [NEWPASSWORD]: NewPassword,
  [PROFILEDETAIL]: Profile,
  [QUESTIONS]: Questions,
  [QUESTIONSHOME]: Questions,
  [RESULTS]: Results,
  [TOP10INFO]: Top10Info,
  [VERIFYEMAIL]: VerifyEmailPage,
  [CREATEUSERSUCCES]: CreateUserSucces,
  AMSTERDAM: PageAmsterdam,
};

const ListRoutes = () => {
  const allowedLang = ['en', 'nl'];
  const history = useHistory();
  const { host, pathname } = window.location;
  const lang = pathname.split('/')[1];

  let rootRedirect = lang;
  if (!allowedLang.includes(lang)) {
    if (host === 'www.myhiddenimpact.com') {
      rootRedirect = 'en';
    } else {
      rootRedirect = 'nl';
    }
    history.push(rootRedirect);
  }

  return (
    <Switch>
      {Object.keys(registry).map((pageId) => (
        <Route key={uuidv4()} exact path={`/:lang${basePaths[pageId]}`} component={registry[pageId]} />
      ))}
      <Route path="*" component={() => <Redirect push to={`/${rootRedirect}/`} />} />
    </Switch>
  );
};

export { registry, ListRoutes };
