import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';

const EarthCounter = ({
  earths, transparentEarths, yellowBorderEarths, size, greenEarth,
}) => {
  const planets = {
    earths,
    transparentEarths,
    yellowBorderEarths,
  };
  const maxEarths = Object.keys(planets)
    .reduce((keya, keyb) => (planets[keya] > planets[keyb] ? keya : keyb));

  const earthSize = size ? 30 : 60;

  const renderEarths = (num) => {
    const flatNum = Math.floor(num);
    const rest = num - flatNum;
    const result = [];
    if (flatNum > 0) {
      for (let i = 1; i <= flatNum; i++) {
        result.push(<figure
          key={uuidv4()}
          className={classNames('result', greenEarth && 'green-earth')}
        />);
      }
    }

    if (rest > 0) result.push(<div key={result.length} className={classNames('result', greenEarth && 'green-earth')} style={{ width: rest * earthSize }} />);

    return result;
  };

  return (
    <div
      className={classNames('earthcounter', size && 'small')}
    >
      <div className={classNames('earths', 'earths--transparent', maxEarths === 'transparentEarths' && 'relative')}>
        {renderEarths(transparentEarths)}
      </div>
      <div className={classNames('earths', maxEarths === 'earths' && 'relative')}>
        {renderEarths(earths)}
      </div>
      <div className={classNames(
        'earths',
        'earths--yellowborder',
        maxEarths === 'yellowBorderEarths' && 'relative',

      )}
      >
        {renderEarths(yellowBorderEarths)}
      </div>
    </div>
  );
};

EarthCounter.propTypes = {
  earths: PropTypes.number.isRequired,
  transparentEarths: PropTypes.number,
  yellowBorderEarths: PropTypes.number,
  size: PropTypes.string,
  greenEarth: PropTypes.bool,
};

EarthCounter.defaultProps = {
  transparentEarths: 0,
  yellowBorderEarths: 0,
  size: '',
  greenEarth: true,
};

export default EarthCounter;
