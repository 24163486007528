import { SET_ACTIONTIPS } from '../actions/actionTipsActionTypes';

import { getActionTips } from '../transformers/actionTipsTransformer';

const initialState = {
  actionTips: [],
};

const actionTips = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIONTIPS: {
      const actionTipsResp = getActionTips(action.actionTips);
      return {
        ...state,
        actionTips: actionTipsResp,
      };
    }
    default:
      return state;
  }
};

export default actionTips;
