import { SET_QUESTION_RESULT, SET_ID_CURRENT_TEST, SET_FRESHSTART } from '../actions/questionActionTypes';

import averageNL from '../../config/average_NL';
import calculations from '../calculations';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
  QUESTION_USER,
} from '../../constants/questions';

const initialState = {
  activeQuestion: '',
  results: {},
  currentResultId: '',
  calculatedResults: {
    [QUESTION_BATHING]: {
      recipe: 0,
    },
    [QUESTION_CAR]: {
      recipe: 0,
    },
    [QUESTION_CLOTHES]: {
      recipe: 0,
    },
    [QUESTION_DAIRY]: {
      recipe: 0,
    },
    [QUESTION_FLYING]: {
      recipe: 0,
    },
    [QUESTION_FOOD]: {
      recipe: 0,
    },
    [QUESTION_HOUSING]: {
      recipe: 0,
    },
    [QUESTION_MEAT]: {
      recipe: 0,
    },
    [QUESTION_POSSESSIONS]: {
      recipe: 0,
    },
    [QUESTION_PUBLIC_TRANSPORT]: {
      recipe: 0,
    },
    [QUESTION_USER]: {
      recipe: 0,
    },
  },
  average: averageNL,
};

const questions = (state = initialState, action) => {
  switch (action.type) {
    case SET_FRESHSTART: {
      const { boolean } = action.payload;
      return {
        ...state,
        activeQuestion: '',
        boolean,
      };
    }
    case SET_ID_CURRENT_TEST: {
      const { currentResultId } = action.payload;
      return {
        ...state,
        currentResultId,
      };
    }
    case SET_QUESTION_RESULT: {
      const { questionId, questionResult, set } = action.payload;
      const average = averageNL[questionId];
      return {
        ...state,
        activeQuestion: questionId,
        results: {
          ...state.results,
          [questionId]: { ...questionResult, mode: set },

        },
        calculatedResults: {
          ...state.calculatedResults,
          [questionId]: calculations[questionId] ? calculations[questionId](questionResult, average, set) : {},
        },
      };
    }
    default:
      return state;
  }
};

export default questions;
