import { SET_QUESTION_RESULT, SET_ID_CURRENT_TEST } from '../../store/actions/questionActionTypes';
import { FETCH_PRODUCT } from '../../store/actions/productActionTypes';
import { FETCH_MODAL_TOP_10 } from '../../store/actions/modalTop10ActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
  setIdCurrentTest: (currentResultId) => dispatch({
    type: SET_ID_CURRENT_TEST,
    payload: {
      currentResultId,
    },
  }),
  fetchProducts: () => dispatch({
    type: FETCH_PRODUCT,
    payload: {},
  }),
  fetchModalTop10s: () => dispatch({
    type: FETCH_MODAL_TOP_10,
    payload: {},
  }),
});

export default mapDispatchToProps;
