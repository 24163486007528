import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../Image';

import './style.scss';

const Product = ({
  className,
  children,
  imgUrl,
}) => (
  <div className={classNames('product', className)}>
    <div className="shopImage">
      <Image src={imgUrl} name="book" />
    </div>
    <div className="productcontent">
      {children}
    </div>
  </div>
);

Product.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  imgUrl: PropTypes.string,
};

Product.defaultProps = {
  className: '',
  imgUrl: '',
};

export default Product;
