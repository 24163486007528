import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const DropDownMulti = ({
  dropDownMultiValues,
  optionsList,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [options, setOptions] = useState([]);

  const editOptionsList = (id) => {
    // if (options.includes(id)) {
    //   setOptions(options.filter((option) => option !== id));
    //   return;
    // }
    setOptions([id]);
  };

  const changeDropdown = (/* event */) => {
    // const { id } = event.target;
    // if (id === 'dropdown') {
    setDropdown(!dropdown);
    // }
  };

  useEffect(() => {
    dropDownMultiValues(options);
  }, [options]);

  const getCorrectSpan = (value) => (optionsList[optionsList.findIndex((x) => x.value === value)].label);

  return (
    <div className="dropdownmulti">
      <div className="dropdownmulti-root">
        <div id="dropdown" onClick={(e) => changeDropdown(e)} className="dropdownmulti-box">
          {options.length !== 0 ? (
            options.map((item) => (
              <>
                <span id="dropdown" className={classNames(`ic-${item}`)} />
                <span>{getCorrectSpan(item)}</span>
              </>
            ))
          ) : 'Kies wat jij hebt' }
          <span id="dropdown" className={classNames(dropdown ? 'arrow-closed' : 'arrow-open')} />
        </div>
        <div className={classNames('dropdownmulti-options', dropdown && 'active')}>
          {optionsList.map((option) => (
            option.value !== 'solarcollector'
            && (
            <div
              onClick={() => editOptionsList(option.value)}
              id={option.value}
              className={classNames('dropdownmulti-items', options.map((item) => (item === option.value && 'selected')))}
            >
              <span>{option.label}</span>
              <span className={classNames(`ic-${option.value}`)} />
            </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

DropDownMulti.propTypes = {
  dropDownMultiValues: PropTypes.func.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DropDownMulti;
