import { SET_QUESTION_RESULT, SET_ID_CURRENT_TEST, SET_FRESHSTART } from '../../store/actions/questionActionTypes';
import { FETCH_PRODUCT } from '../../store/actions/productActionTypes';
import { SET_USER } from '../../store/actions/userActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
  setFreshStart: (boolean) => dispatch({
    type: SET_FRESHSTART,
    payload: {
      boolean,
    },
  }),
  setIdCurrentTest: (currentResultId) => dispatch({
    type: SET_ID_CURRENT_TEST,
    payload: {
      currentResultId,
    },
  }),
  fetchProducts: () => dispatch({
    type: FETCH_PRODUCT,
    payload: {},
  }),
  setUser: (user) => dispatch({
    type: SET_USER,
    payload: {
      user,
    },
  }),
});

export default mapDispatchToProps;
