import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphqlOperation, API } from 'aws-amplify';

import { NEWGROUP } from '../../constants/pages';
import paths from '../../routes/paths';

import {
  getGroup as GetGroup,
  getQuizResult as GetQuizResult,
  getGoal as GetGoal,
} from '../../graphql/queries';

import {
  QUESTION_BATHING,
  QUESTION_CAR,
  QUESTION_CLOTHES,
  QUESTION_DAIRY,
  QUESTION_FLYING,
  QUESTION_FOOD,
  QUESTION_HOUSING,
  QUESTION_MEAT,
  QUESTION_POSSESSIONS,
  QUESTION_PUBLIC_TRANSPORT,
} from '../../constants/questions';

import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import Button from '../../components/Button';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import GroupCard from '../../components/GroupCard';
import LanguageParagraph from '../../components/LanguageParagraph';
import LanguageLabel from '../../components/LanguageLabel';

// import Cat from '../../assets/images/cat-group.jpg';
import { ReactComponent as Plus } from '../../assets/icons/ic-plus.svg';

import { getEarths } from '../../utils/functions';

import './style.scss';
import { lang } from '../../utils/locale';


const Groups = ({ user }) => {
  const [groupList, setGroupList] = useState([]);
  const [numberOfEarthsTotal, setNumberOfTotalEarths] = useState(0);

  const cleanUpResult = (result) => {
    const resultForEarthCounter = result;
    delete resultForEarthCounter.id;
    delete resultForEarthCounter.stringResult;
    delete resultForEarthCounter.email;
    delete resultForEarthCounter.newsLetter;
    delete resultForEarthCounter.dateCreated;
    delete resultForEarthCounter.dateCreated;
    if (resultForEarthCounter.gender) {
      delete resultForEarthCounter.gender;
    }
    if (resultForEarthCounter.birthdate) {
      delete resultForEarthCounter.birthdate;
    }
    if (resultForEarthCounter.zipcode) {
      delete resultForEarthCounter.zipcode;
    }
    return resultForEarthCounter;
  };

  const chartResultsFormat = (resp, objectName) => {
    const preFilled = {
      [objectName]: {
        [QUESTION_BATHING]: { recipe: resp.bathing || 0 },
        [QUESTION_CAR]: { recipe: resp.car || 0 },
        [QUESTION_CLOTHES]: { recipe: resp.clothes || 0 },
        [QUESTION_DAIRY]: { recipe: resp.diary || 0 },
        [QUESTION_FLYING]: { recipe: resp.flying || 0 },
        [QUESTION_FOOD]: { recipe: resp.food || 0 },
        [QUESTION_HOUSING]: { recipe: resp.housing || 0 },
        [QUESTION_MEAT]: { recipe: resp.meat || 0 },
        [QUESTION_POSSESSIONS]: { recipe: resp.possessions || 0 },
        [QUESTION_PUBLIC_TRANSPORT]: { recipe: resp.publictransport || 0 },
      },
    };
    return preFilled;
  };


  const getGroupsUser = async () => {
    const resp = [];
    try {
      await Promise.all(user.groups.map(async (groupId) => {
        if (groupId !== null) {
          const result = await API.graphql(
            graphqlOperation(GetGroup, {
              id: groupId,
            }),
          );
          if (result.data.getGroup === null) return;
          let currentEarths = 0;
          if (result.data.getGroup.currentResults.length > 0) {
            const results = [];
            await Promise.all(result.data.getGroup.currentResults.map(async (resultObject) => {
              const object = JSON.parse(resultObject);
              try {
                const result = await API.graphql(
                  graphqlOperation(GetQuizResult, {
                    id: object.resultID,
                  }),
                );
                const resultForEarthCounter = cleanUpResult(result.data.getQuizResult);
                results.push(resultForEarthCounter);
                if (results.length > 0) {
                  const addedResults = {
                    bathing: 0,
                    car: 0,
                    clothes: 0,
                    diary: 0,
                    flying: 0,
                    food: 0,
                    housing: 0,
                    meat: 0,
                    possessions: 0,
                    publictransport: 0,
                  };
                  results.forEach((item) => {
                    Object.keys(addedResults).forEach((key) => {
                      addedResults[key] += item[key];
                    });
                  });
                  Object.keys(addedResults).forEach((key) => {
                    addedResults[key] /= results.length;
                  });
                  currentEarths = getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults);
                }
              } catch (err) {
                // console.log(err);
              }
            }));
          }

          let initialEarths = 0;
          if (result.data.getGroup.initialResults.length > 0) {
            const results = [];
            await Promise.all(result.data.getGroup.initialResults.map(async (resultObject) => {
              const object = JSON.parse(resultObject);
              try {
                const result = await API.graphql(
                  graphqlOperation(GetQuizResult, {
                    id: object.resultID,
                  }),
                );
                const resultForEarthCounter = cleanUpResult(result.data.getQuizResult);
                results.push(resultForEarthCounter);
                if (results.length > 0) {
                  const addedResults = {
                    bathing: 0,
                    car: 0,
                    clothes: 0,
                    diary: 0,
                    flying: 0,
                    food: 0,
                    housing: 0,
                    meat: 0,
                    possessions: 0,
                    publictransport: 0,
                  };
                  results.forEach((item) => {
                    Object.keys(addedResults).forEach((key) => {
                      addedResults[key] += item[key];
                    });
                  });
                  Object.keys(addedResults).forEach((key) => {
                    addedResults[key] /= results.length;
                  });
                  initialEarths = getEarths(chartResultsFormat(addedResults, 'calculatedResults').calculatedResults);
                  // console.log(initialEarths);
                  setNumberOfTotalEarths(initialEarths);
                }
              } catch (err) {
                // console.log(err);
              }
            }));
          }

          let goal = 0;
          if (result.data.getGroup.goal.length !== 0) {
            const goalContents = await API.graphql(graphqlOperation(GetGoal, { id: result.data.getGroup.goal }));
            goal = getEarths(chartResultsFormat(goalContents.data.getGoal, 'results').results);
          }

          resp.push({
            ...result.data.getGroup, currentEarths, initialEarths, goal,
          });
        }
      }));
      setGroupList(resp);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getGroupsUser();
  }, [user]);

  return (
    <main className="groups">
      <section className="containter">
        <Heading className="primary"><LanguageLabel id="groupsPageTitle" /></Heading>
        {groupList.length > 0 ? (
          <>
            <Button
              className="outline"
              to={paths[NEWGROUP]}
              label={<LanguageLabel id="groupsAddGroupLabel" />}
            />
            <div className="button-box">
              <Button label={<Plus />} to={paths[NEWGROUP]} className="add" />
            </div>
            <div className="cardlayout">
              {groupList.map((item) => (
                <GroupCard
                  key={item.id}
                  img={item.image}
                  name={item.title}
                  location={item.location}
                  people={item.users.length}
                  earth={item.currentEarths}
                  totalEarths={item.currentEarths}
                  path={item.id}
                  link={`/${lang}/groups/${item.id}`}
                  goalEarth={item.goal}
                  transparentEarths={numberOfEarthsTotal}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="empty">
            <div className="empty-content">
              <Heading className="secondary">
                <LanguageLabel id="addGroupTitle" />
              </Heading>
              <Paragraph>
                <LanguageParagraph id="addGroupParagraph" />
              </Paragraph>
            </div>
            <div className="empty-placeholder">
              <Button
                className="outline"
                label={<LanguageLabel id="groupsCreateGroupButton" />}
                to={paths[NEWGROUP]}
              />

            </div>
          </div>
        )}
      </section>
      <div className="background">
        {groupList.length < 0 ? (
          <div className="base-bg" />
        ) : (
          <div className="penguin">
            <span className="duo-penguin" />
          </div>
        )}
      </div>
    </main>
  );
};

Groups.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
    goals: PropTypes.arrayOf(PropTypes.string),
    acceptedActions: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
  // fetchActionCards: PropTypes.func.isRequired,
  // actionCards: PropTypes.arrayOf(
  //   PropTypes.object,
  // ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  questions: PropTypes.shape({
    currentResultId: PropTypes.string,
    results: PropTypes.shape({}),
    calculatedResults: PropTypes.shape([{
      recipe: PropTypes.Number,
    }]),
  }),
};

Groups.defaultProps = {
  user: PropTypes.shape({
    id: '',
    results: [],
    goals: [],
    acceptedActions: [],
  }),
  questions: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
