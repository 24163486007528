import { SET_QUESTION_RESULT } from '../../store/actions/questionActionTypes';

const mapDispatchToProps = (dispatch) => ({
  setQuestionResult: (questionId, questionResult, set) => dispatch({
    type: SET_QUESTION_RESULT,
    payload: {
      questionId,
      questionResult,
      set,
    },
  }),
});

export default mapDispatchToProps;
