import { SET_ACTIONCARDS } from '../actions/actionCardsActionTypes';

import { getActionCards } from '../transformers/actionCardsTransformer';

const initialState = {
  actionCards: [],
};

const actionCards = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIONCARDS: {
      const actionCardsResp = getActionCards(action.actionCards);
      return {
        ...state,
        actionCards: actionCardsResp,
      };
    }
    default:
      return state;
  }
};

export default actionCards;
