const getCounterGroup = (counterGroups) => counterGroups.map((counterGroup) => ({
  id: counterGroup.fields.id,
  counters: counterGroup.fields.counters ? counterGroup.fields.counters.map((counter) => ({
    id: counter.fields.id,
    label: counter.fields.label,
    count: counter.fields.count,
    max: counter.fields.max,
  })) : [],
}));

export { getCounterGroup };
