import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Table = ({ children }) => (
  <div className="table">
    {children}
  </div>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Table;
