import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mapStateToProps from './mapState';

import menuStructure from '../../assets/data/header/menu';

import login from '../../assets/icons/ic-login.svg';
import burger from '../../assets/icons/ic-burger.svg';
import close from '../../assets/icons/ic-close.svg';
import logo from '../../assets/icons/logo-think-big-act-now.svg';
import impact from '../../assets/icons/mijn-verborgen-impact-logo-text.svg';

import Button from '../Button';

import defaultImg from '../../assets/images/default-profile-img.jpg';

import './style.scss';
import { lang } from '../../utils/locale';

const Header = ({
  user,
  className,
  results,
  mobileView,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const isHome = path === '/nl/' || path === '/en/';
  const [activeSwitchMenu, setActiveSwitchMenu] = useState(false);

  const [headerButton, setHeaderButton] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [testDone, setTestDone] = useState(false);
  const [stickTop, setStickTop] = useState(false);


  const changeLocation = () => {
    // if (isHome) {
    //   window.open('https://thinkbigactnow.org/', '_blank');
    // }
  };

  const checkScrollTop = () => {
    if (window.pageYOffset <= 20) {
      setStickTop(false);
    } else {
      setStickTop(true);
    }
  };
  window.addEventListener('scroll', checkScrollTop);

  useEffect(() => {
    if (activeSwitchMenu && mobileView) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }, [mobileView, activeSwitchMenu]);

  useEffect(() => {
    const isLoggedIn = user && Object.values(user).length > 2;
    const hasResults = (results && Object.values(results).length > 10) || (user && user.results && user.results.length > 0);
    const structure = isLoggedIn ? menuStructure.loggedIn : menuStructure.notLoggedIn;
    const content = hasResults ? structure.testDone : structure.noTest;

    setLoggedIn(isLoggedIn);
    setTestDone(hasResults);
    setHeaderButton(content);
  }, [user, results]);


  return (
    <header className={classNames('header', isHome && (stickTop ? 'landing stickTop' : 'landing'), className)}>
      <div className="header-bg" />
      <div className="screen">
        <div className="row">
          <Button
            className="logo"
            img={isHome ? logo : impact}
            to={isHome ? '' : `/${lang}/`}
            onClick={() => changeLocation()}
          />
        </div>
        <div className="desktop">
          {isHome ? (
            <Button
              key="dashboard"
              label="Dashboard"
              disabled={false}
              to={`/${lang}/dashboard`}
            />
          ) : (
            headerButton.map((item) => (
              <Button
                key={item.label}
                className={classNames(item.className)}
                label={item.label}
                disabled={item.disable}
                to={`${item.to}`}
              />
            ))
          )}
          {loggedIn ? (
            <Button
              className="logo"
              to={`/${lang}/profile/${user.id}`}
            >
              {user.image ? (
                <img src={user.image} alt="" className="profile-image-header" />
              ) : (
                <img src={defaultImg} alt="" className="profile-image-header" />
              )}
            </Button>
          ) : (
            <Button
              className="rounded"
              label="Login"
              img={login}
              to={`/${lang}/login`}
            />
          )}
        </div>
      </div>
      <div className="mobile">
        <nav id="mobile-menu" className={classNames(activeSwitchMenu && 'active')} onClick={() => setActiveSwitchMenu(false)}>
          {loggedIn ? (
            <Button
              className="logo"
              to={`/profile/${user.id}`}
            >
              {user.image ? (
                <img src={user.image} alt="" className="profile-image-header" />
              ) : (
                <img src={defaultImg} alt="" className="profile-image-header" />
              )}
            </Button>
          ) : (
            <Button
              className="logo"
              img={login}
              to="/login"
            />
          )}
          {headerButton.map((item) => (
            <Button
              key={item.label}
              className={item.className}
              label={item.label}
              disabled={item.disable}
              to={item.to}
            />
          ))}
        </nav>
        {loggedIn || testDone ? (
          <Button
            className="rounded"
            img={activeSwitchMenu ? close : burger}
            onClick={() => setActiveSwitchMenu(!activeSwitchMenu)}
          />
        ) : (
          <Button
            className="rounded login-icon"
            img={login}
            to="/login"
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.string),
  }),
  results: PropTypes.shape({}),
  className: PropTypes.string,
  mobileView: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  user: null,
  results: {},
  className: '',
};

export default connect(mapStateToProps)(Header);
