import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const CardSmall = ({
  className,
  children,
}) => (
  <div className={classNames('cardsmall', className)}>
    {children}
  </div>
);

CardSmall.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

CardSmall.defaultProps = {
  className: '',
  children: null,
};

export default CardSmall;
