import { FETCH_ACTIONCARDS } from '../../store/actions/actionCardsActionTypes';

const mapDispatchToProps = (dispatch) => ({
  fetchActionCards: () => dispatch({
    type: FETCH_ACTIONCARDS,
    payload: {},
  }),
});

export default mapDispatchToProps;
