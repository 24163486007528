import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mapStateToProps from './mapState';
import mapDispatchToProps from './mapDispatch';

import { QUESTION_MEAT } from '../../constants/questions';

import order from '../../config/questions';
import { QUESTIONSHOME } from '../../constants/pages';
import paths from '../../routes/paths';

import { makeTooltipsSliderDial } from '../../utils/functions';

import Heading from '../../components/Heading';
import Button from '../../components/Button';
import Paragraph from '../../components/Paragraph';
import QuestionButtons from '../../components/QuestionButtons';
import Slider from '../../components/Slider';
import SliderPercentage from '../../components/SliderPercentage';
import QuestionSwiper from '../../components/QuestionSwiper';
import StepIndicatorMobile from '../../components/StepIndicatorMobile';
import LanguageLabel from '../../components/LanguageLabel';
import ToggleButton from '../../components/ToggleButton';

import { ReactComponent as Illustration } from '../../assets/backgrounds/vraag-ill-vlees.svg';
import { ReactComponent as Background } from '../../assets/backgrounds/vraag-bg-vlees.svg';

import { ReactComponent as IllustrationMobile } from '../../assets/backgrounds/vraag_1.svg';

import content from './content.json';

import './style.scss';

const QuestionMeat = ({
  fetchTooltips,
  fetchTooltipsPercentage,
  tooltips,
  tooltipsPercentage,
  setQuestionResult,
  setSwipeOnContainer,
  openGenericModal,
  openSpecificModal,
  mobileView,
  results,
  shouldIupdate,
}) => {
  const quick = content.quick.questions;
  const specific = content.specific.questions;
  const specificMobile = content.specificMobile.questions;

  const specificLength = specificMobile.length;

  const [values, setValues] = useState({});
  const [firstValue, setFirstValue] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSpecificSlide, setCurrentSpecificSlide] = useState(0);
  const [swipeOnQuestions, setSwipeOnQuestions] = useState(false);
  const [mode, setMode] = useState(false);
  const questionId = QUESTION_MEAT;

  const currentItemIndex = order.indexOf(questionId);

  const globalSliderId = quick[0].id;

  const valueTransformer = (value) => {
    switch (value) {
      case 0:
        return 0;
      case 1:
        return 0.25;
      case 2:
        return 0.5;
      default:
        return value - 2;
    }
  };

  const sliderChangeCustom = (sliderId, value) => {
    const newValue = valueTransformer(value);
    setFirstValue(newValue);
    setValues({ ...values, [sliderId]: newValue });
    if (sliderId !== globalSliderId) {
      const total = (values.beef || 0) + (values.pork || 0) + (values.chicken || 0) + (values.fish || 0);
      setValues({ ...values, [globalSliderId]: total });
    }
  };

  const submitClicked = () => {
    // Go to next in line, check in order
    setQuestionResult(questionId, values, mode);
  };

  useEffect(() => {
    if (results[questionId] && results[questionId].mode !== mode) {
      setMode(results[questionId].mode);
    }
  }, [shouldIupdate]);

  const sliderChange = (sliderId, value) => {
    setValues({ ...values, [sliderId]: value });
  };

  const updateFromSwiper = (category, value) => {
    if (category === 'currentSlide') {
      setCurrentSlide(value);
    }
  };

  const updateSwipe = (value) => {
    setSwipeOnQuestions(value);
    setSwipeOnContainer(value);
  };

  const makeTooltipsSlider = (id) => {
    const filterTooltips = tooltips.filter((tooltip) => (tooltip.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_MEAT] && results[QUESTION_MEAT][item.id]) defaultValue = results[QUESTION_MEAT][item.id];
        else if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <Slider
            key={item.id}
            defaultValue={values[item.id] || defaultValue}
            min={item.min}
            max={item.max}
            updateParent={(value) => sliderChangeCustom(item.id, value)}
            text={item.label}
            unit={item.unit}
            customValue={firstValue}
            popModal={() => openSpecificModal(item.explanation)}
            popModalButton={item.explanation}
            descriptions={item.descriptions}
            swiping={swipeOnQuestions}
            isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
            valueTransformer={valueTransformer}
          />
        );
      })
    );
  };

  const makeTooltipsPercentage = (id) => {
    const filterTooltips = tooltipsPercentage.filter((tooltipPercentage) => (tooltipPercentage.id === id));
    return (
      filterTooltips.map((item) => {
        let { defaultValue } = item;
        if (results[QUESTION_MEAT] && results[QUESTION_MEAT][item.id]) defaultValue = results[QUESTION_MEAT][item.id];
        if (values && values[item.id]) defaultValue = values[item.id];
        return (
          <div className="slider-info" key={item.id}>
            <span className="text">
              {item.label}
            </span>
            <SliderPercentage
              updateParent={(value) => sliderChange(item.id, value)}
              leftContent={item.leftValue}
              rightContent={item.rightValue}
              isSliding={(swipeOnQuestions) => updateSwipe(swipeOnQuestions)}
              defaultValue={defaultValue}
            />
          </div>
        );
      })
    );
  };

  useEffect(() => {
    if (tooltips.length === 0) {
      fetchTooltips();
    }
  }, [fetchTooltips]);

  useEffect(() => {
    if (tooltipsPercentage.length === 0) {
      fetchTooltipsPercentage();
    }
  }, [fetchTooltipsPercentage]);

  const updateMode = (mode) => {
    setQuestionResult(questionId, values, mode);
    setMode(mode);
  };

  return (
    <div className="question-meat">
      <Heading className={classNames('primary', 'heading-top')} type="h1">
        <span>
          <LanguageLabel id="meatQuestionTitle" />
        </span>
        <Button
          label="?"
          className={classNames('info', 'info-header')}
          onClick={() => openGenericModal('meatAndFishQuestion-modal')}
        />
      </Heading>
      <Paragraph>
        <LanguageLabel id="meatQuestionDescription" />
      </Paragraph>
      <ToggleButton
        beforeText="snel"
        toggleButtonValue={!mode}
        onClick={() => updateMode(!mode)}
        afterText="precies"
        className="show-toggle"
      />
      {!mode ? (
        !mobileView ? (
          <>
            <div className="vertical-align-wrapper-flex">
              {quick.map((item) => (
                makeTooltipsSlider(item.id)
              ))}
            </div>
            <div className="questionButton-box">
              <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
            </div>
          </>
        ) : (
          <>
            <div className="vertical-align-wrapper-flex">
              <QuestionSwiper updateParent={updateFromSwiper} index={currentSlide} disabled={!swipeOnQuestions}>
                {quick.map((item) => (
                  makeTooltipsSlider(item.id)
                ))}
              </QuestionSwiper>
            </div>
            <div className="questionButton-box">
              <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
              <Button
                label={<LanguageLabel id="questionPreviousLabel" />}
                to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
              />
            </div>
          </>
        )
      ) : (
        !mobileView
          ? (
            <>
              <div className="vertical-align-wrapper-flex">
                <div className="vertical-wrap-row">
                  {specific.map((item) => (
                    makeTooltipsSliderDial(
                      item.id,
                      openSpecificModal,
                      questionId,
                      results,
                      sliderChange,
                      tooltips,
                      updateSwipe,
                      values,
                    )
                  ))}
                </div>

                <div className="vertical-wrap-row-two">
                  {makeTooltipsPercentage('nlvsabroad')}
                </div>
              </div>
              <div className="questionButton-box">
                <QuestionButtons questionId={questionId} nextClick={() => submitClicked()} />
              </div>
            </>
          )
          : (
            <>
              <div className="vertical-align-wrapper-flex">
                <QuestionSwiper updateParent={updateFromSwiper} index={currentSpecificSlide} disabled={!swipeOnQuestions}>
                  {specificMobile.map((item) => (
                    item.id === 'nlvsabroad' ? (
                      makeTooltipsPercentage(item.id)
                    ) : (
                      <div className="vertical-align-sliderdial">
                        {makeTooltipsSliderDial(
                          item.id,
                          openSpecificModal,
                          questionId,
                          results,
                          sliderChange,
                          tooltips,
                          updateSwipe,
                          values,
                        )}
                      </div>
                    )
                  ))}
                </QuestionSwiper>
              </div>
              <StepIndicatorMobile currentStep={currentSpecificSlide} steps={specificLength} />
              <div className="questionButton-box">
                {currentSpecificSlide === specificLength - 1
                  ? (
                    <>
                      <QuestionButtons
                        questionId={questionId}
                        nextClick={() => submitClicked()}
                      />
                      <Button
                        label={<LanguageLabel id="questionPreviousLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                      />
                    </>
                  )
                  : (
                    <>
                      <Button
                        label={<LanguageLabel id="questionNextLabel" />}
                        onClick={() => setCurrentSpecificSlide(currentSpecificSlide + 1)}
                        className={classNames('outline-mobile', 'nextbottom', 'questionButtons')}
                      />
                      {currentSpecificSlide === 0 ? (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          to={`${paths[QUESTIONSHOME]}/${currentItemIndex - 1}`}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      ) : (
                        <Button
                          label={<LanguageLabel id="questionPreviousLabel" />}
                          onClick={() => setCurrentSpecificSlide(currentSpecificSlide - 1)}
                          className={classNames('outline-mobile', 'nextbottom', 'questionbuttons-return', 'questionButtons')}
                        />
                      )}
                    </>
                  )}
              </div>
            </>
          )
      )}
      <>
        {!mobileView ? (
          <>
            <div className="background-wrapper">
              <Background />
            </div>
            <div className="illustration-wrapper">
              <Illustration />
            </div>
          </>
        )
          : (
            <div className="illustration-wrapper">
              <IllustrationMobile />
            </div>
          )}
      </>
    </div>
  );
};

QuestionMeat.propTypes = {
  fetchTooltips: PropTypes.func.isRequired,
  tooltips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchTooltipsPercentage: PropTypes.func.isRequired,
  tooltipsPercentage: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestionResult: PropTypes.func.isRequired,
  setSwipeOnContainer: PropTypes.func.isRequired,
  openGenericModal: PropTypes.func.isRequired,
  openSpecificModal: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  results: PropTypes.shape({}),
  shouldIupdate: PropTypes.bool,
};

QuestionMeat.defaultProps = {
  results: PropTypes.shape({}),
  shouldIupdate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionMeat);
