import { SET_LANGUAGE_LABELS } from '../actions/languageActionTypes';

import { getLanguageLabels } from '../transformers/languageTransformer';

const initialState = {
  labels: [],
};

const languageLabels = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_LABELS: {
      const labels = getLanguageLabels(action.languageLabels);

      // window.localStorage.setItem('languageLabels', JSON.stringify(labels));
      return {
        ...state,
        labels,
      };
    }
    default:
      return state;
  }
};

export default languageLabels;
