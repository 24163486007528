import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const InputBox = ({
  className,
  description,
  type,
  onChange,
  value,
  error,
  placeholder,
}) => {
  let addSpace = true;
  const changeInput = (e) => {
    if (type === 'year' && e.target.value.length < 5) {
      onChange(e);
      addSpace = true;
    } else if (type === 'adres') {
      if (e.target.value.length < 5 && !isNaN(e.target.value)) {
        onChange(e);
        if (e.target.value.length === 4 && addSpace) {
          addSpace = false;
          const string = {
            target: {
              value: `${e.target.value} `,
            },
          };
          onChange(string);
        }
      } else if (e.target.value.length < 8) {
        onChange(e);
      }
    }
  };

  return (
    <div className={classNames('inputbox', className)}>
      <div className="content">
        <span>{description}</span>
        <input
          className={classNames(error && 'error')}
          autoComplete={0}
          id={type}
          onChange={changeInput}
          placeholder={placeholder}
          type={type === 'year' ? 'number' : 'text'}
          value={value}
          maxLength={type === 'year' ? 4 : 7}
        />
      </div>
    </div>
  );
};

InputBox.propTypes = {
  className: PropTypes.string,
  description: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

InputBox.defaultProps = {
  className: '',
  placeholder: '',
};

export default InputBox;
