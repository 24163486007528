import React from 'react';

import icDrop from '../../assets/icons/ic-drop.svg';
import icFish from '../../assets/icons/ic-fish.svg';
import icRing from '../../assets/icons/ic-ring.svg';
import icTurtle from '../../assets/icons/ic-turtle.svg';

import './style.scss';

const dropLoop = () => {
  const drops = [];
  for (let i = 1; i < 18; i += 1) {
    drops.push(
      <img key={`drop${i}`} id={`drop${i}`} className="drop" src={icDrop} alt="drop" />,
    );
  }
  return drops;
};

const ringLoop = () => {
  const rings = [];
  for (let i = 1; i < 5; i += 1) {
    rings.push(
      <img key={`ring${i}`} id={`ring${i}`} className="ring" src={icRing} alt="ring" />,
    );
  }
  return rings;
};

const IconBackground = () => (
  <div className="iconbackground">
    {dropLoop()}
    <img className="fish" src={icFish} alt="fish" />
    {ringLoop()}
    <img className="turtle" src={icTurtle} alt="turtle" />
  </div>
);

export default IconBackground;
