import React, { useState } from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as earth from '../../animations/earth-intro.json';
import * as earthLoop from '../../animations/earth-loop.json';

import './style.scss';

const earthOptions = {
  loop: false,
  autoplay: true,
  animationData: earth.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const earthLoopOptions = {
  loop: true,
  autoplay: true,
  animationData: earthLoop.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Earth = ({
  children,
  className,
}) => {
  const [animationOptions, setAnimationOptions] = useState(earthOptions);

  return (
    <div className={classNames('go', className)}>
      <div className="earth">
        <Lottie
          options={animationOptions}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => setAnimationOptions(earthLoopOptions),
            },
          ]}
        />
        <div className="text">
          {children}
        </div>
      </div>
    </div>
  );
};

Earth.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Earth.defaultProps = {
  className: '',
};

export default Earth;
