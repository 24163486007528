import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Card = ({
  className,
  content,
  job,
  name,
}) => (
  <div className={classNames('card', className)}>
    <p>
      {content}
    </p>
    <span className="name">
      {name}
    </span>
    {job && (
      <span className="job">
        {job}
      </span>
    )}
  </div>
);

Card.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  job: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Card.defaultProps = {
  job: '',
};

export default Card;
